import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadMyClientsSuccessAction, MyClientsActionTypes} from '../actions/my-clients';
import {Client} from '../models/client';

export interface MyClientsState {
  array: Client[];
}

const initialState: MyClientsState = {
  array: []
};

export function myClientsReducer(state = initialState, action: Action): MyClientsState {
  switch (action.type) {
    case MyClientsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccess(state, action);
    case MyClientsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadSuccess(state: MyClientsState, action: LoadMyClientsSuccessAction): MyClientsState {
  const newState = _.clone(state);
  newState.array = action.payload || [];
  return newState;
}

function handleLoadFailAction(state: MyClientsState): MyClientsState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const myClientsSelectors = {
  array: (state: MyClientsState) => _.get(state, 'array', [])
};

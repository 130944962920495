import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {Timezone} from '../models/timezone';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class TimezonesService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'TimezonesService'; }

  protected get isAnonymous(): boolean { return true; }

  getList(): Observable<SendBackResult<ArrayResponse<Timezone>>> { return this.get<ArrayResponse<Timezone>>(`/timezones`); }
}

import * as moment from 'moment';
import {Observable, OperatorFunction} from 'rxjs';
import {environment} from './environments/environment';

export function debug<T>(message?: string, showOnComplete?: boolean): OperatorFunction<T, T> {
  return function(source$: Observable<T>): Observable<T> {
    return new Observable<T>(observer => {
      const wrapper = {
        next: value => {
          if (!environment.production) {
            console.log(`[${getTimestamp()}] ${message}`, value); // tslint:disable-line
          }
          observer.next(value);
        },
        error: err => {
          console.error(`[${getTimestamp()}] ${message}`, err);
          observer.error(err);
        },
        complete: () => {
          if (!environment.production && showOnComplete) {
            console.log(`[${getTimestamp()}] Completed`, message); // tslint:disable-line
          }
          observer.complete();
        }
      };
      return source$.subscribe(wrapper);
    });
  }
}
// export function debug<T>(this: Observable<T>, message: string, showOnComplete?: boolean): Observable<T> {
//   return new Observable(observer => {
//     const obs = {
//       next: val => {
//         if (!environment.production) {
//           console.log(`[${getTimestamp()}] ${message}`, val);
//         }
//
//         observer.next(val);
//       },
//       error: err => {
//         console.error(`[${getTimestamp()}] ${message}`, err);
//         observer.error(err);
//       },
//       complete: () => {
//         if (!environment.production && showOnComplete) {
//           console.log(`[${getTimestamp()}] Completed`, message);
//         }
//
//         observer.complete();
//       }
//     };
//     return this.subscribe(obs);
//   });
// }

export function always<T>(this: Observable<T>, callback: () => void): Observable<T> {
  return new Observable(observer => {
    const obs = {
      next: val => {
        if (callback) {
          callback();
        }

        observer.next(val);
      },
      error: err => {
        if (callback) {
          callback();
        }

        observer.error(err);
      },
      complete: () => {
        if (callback) {
          callback();
        }

        observer.complete();
      }
    };
    return this.subscribe(obs);
  });
}

// Observable.prototype.debug = debug;
// Observable.prototype.always = always;

function getTimestamp(): string {
  const now = moment();
  return now.format('DD/MM/YYYY HH:mm:ss.SSS');
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {LoadTagsListSuccessAction, TagsListActionTypes} from '../actions/tags';
import {TagsList} from '../models/tags-list';

export interface TagsState {
  array: TagsList[];
}

const initialState: TagsState = {
  array: []
};

export function tagsReducer(state = initialState, action: Action): TagsState {
  switch (action.type) {
    case TagsListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case TagsListActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: TagsState, action: LoadTagsListSuccessAction): TagsState {
  const newState = _.clone(state);
  newState.array = action.payload.array;
  return newState;
}

function handleLoadFailAction(state: TagsState): TagsState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const tagsSelectors = {
  array: (state: TagsState) => _.get(state, 'array', [])
};

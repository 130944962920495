import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {GroupRolesActionTypes, LoadGroupRolesFailAction, LoadGroupRolesSuccessAction} from '../actions/group-roles';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {GroupRole} from '../models/group-role';
import {SendBackResult} from '../models/send-back-result';
import * as fromRoot from '../reducers';
import {GroupRolesService} from '../services/group-roles.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class GroupRolesEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$  = createEffect(
    () => {  return this.actions$.pipe(
    ofType(GroupRolesActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Group roles load action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.groupRolesService.getList(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<GroupRole>>) => new LoadGroupRolesSuccessAction(payload.data.array)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(GroupRolesActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving group roles list.'),
                                 map(() => error(this.translation.translate('GROUP_ROLES_LOAD_ERROR'),
                                   this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromRoot.AppState>,
              translation: L10nTranslationService,
              private groupRolesService: GroupRolesService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadGroupRolesFailAction(response.status); }
}

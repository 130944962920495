import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const UpdateCulturesActionTypes = {
  UPDATE_CULTURES: type('[Update Cultures] Update Cultures'),
  UPDATE_CULTURES_SUCCESS: type('[Update Cultures] Update Cultures Success'),
  UPDATE_CULTURES_SELF: type('[Update Cultures] Update Cultures Self'),
  UPDATE_CULTURES_FAIL: type('[Update Cultures] Update Cultures Server Error')
};

export class UpdateCulturesAction implements Action {
  readonly type = UpdateCulturesActionTypes.UPDATE_CULTURES;

  constructor(public payload?: [string]) {}
}

export class UpdateCulturesSuccessAction implements Action {
  readonly type = UpdateCulturesActionTypes.UPDATE_CULTURES_SUCCESS;

  constructor() {}
}

export class UpdateCulturesSelfAction implements Action {
  readonly type = UpdateCulturesActionTypes.UPDATE_CULTURES_SELF;

  constructor() {}
}

export class UpdateCulturesFailAction implements Action {
  readonly type = UpdateCulturesActionTypes.UPDATE_CULTURES_FAIL;

  constructor(public payload?: number) {}
}

import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {QuickSearchMediasAction} from '../../../shared/actions/medias-list';
import {AddMediaForm} from '../../models/add-media';
import * as fromMedia from '../../reducers';

@Component({ selector: 'storever-add-medias-dialog', templateUrl: './add-medias-dialog.component.html', styleUrls: ['./add-medias-dialog.component.scss'] })
export class AddMediasDialogComponent {
  @ViewChild('AddMediasModal') modal: ModalDirective;
  @Input('showAsPopup') showAsPopup: boolean;
  @Output() addMedia = new EventEmitter<any>();
  constructor(private store: Store<fromMedia.AppState>, @Inject(L10N_LOCALE) public locale: L10nLocale) {}
  open(): void { this.modal.show(); }
  // close when click cross
  // medial to upload list is emptyµ
  close($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.store.dispatch(new QuickSearchMediasAction('null'));
    this.modal.hide();
  }
  AddMedia(file: AddMediaForm): void { this.addMedia.emit(file); }
}

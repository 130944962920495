import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {L10nTranslationModule} from 'angular-l10n';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TableModule} from 'primeng/table';

import {DashboardRoutingModule} from './dashboard-routing.module';
import {ActivityFeedEffect} from './effects/activity-feed.effect';
import {CampaignListEffect} from './effects/campaign-list.effect';
import {MediasToValidateListEffect} from './effects/medias-to-validate-list.effect';
import {ProgramNoPlaningEffect} from './effects/program-no-planning.effect';
import {PublicationsFailedListEffect} from './effects/publication-failed-list.effect';
import {PublicationsListEffect} from './effects/publication-list.effect';
import {SiteWithNoTemplateListEffect} from './effects/site-with-no-tempate-list.effect';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {reducers} from './reducers/index';
import {DashboardService} from './services/dashboard.service';
import {SharedModule} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    L10nTranslationModule,
    TableModule,
    PopoverModule,
    StoreModule.forFeature('dashboard', reducers),
    EffectsModule.forFeature([
      ActivityFeedEffect,
      CampaignListEffect,
      MediasToValidateListEffect,
      ProgramNoPlaningEffect,
      PublicationsListEffect,
      PublicationsFailedListEffect,
      SiteWithNoTemplateListEffect
    ]),
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DashboardComponent]
})
export class DashboardModule {
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {ChangeMediasPageSizeAction} from '../actions/medias-list';
import {GetSetRowSizeFailedAction, UserPreferences} from '../actions/user-preferences';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {error} from '../index';
import {SendBackResult} from '../models/send-back-result';
import * as fromRoot from '../reducers';
import {ClientsService} from '../services/clients.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class PreferencesEffect extends BaseEffect {

  getPreferencesRowSize$  = createEffect(
    () => {  return  this.actions$.pipe(
    ofType(UserPreferences.GET_ROW_SIZE),
    debug('get row size.'),
    switchMap((action) => this.clientsService.getRowSize(action).pipe(
                map((result: SendBackResult<any>) => { return new ChangeMediasPageSizeAction(result && result[0] ? result[0].value : DEFAULT_PAGE_SIZE) }),
                catchError((res: Response) => this.catchResponseError(res)))))});

  setPreferencesRowSize$  = createEffect(
    () => {  return  this.actions$.pipe(
    ofType(UserPreferences.SET_ROW_SIZE),
    debug('set row size.'),
    switchMap((action) => this.clientsService.setRowSize(action).pipe(
                map((result: SendBackResult<any>) => { return new ChangeMediasPageSizeAction(result && result[0] ? result[0].value : DEFAULT_PAGE_SIZE) }),
                catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return  this.actions$.pipe(ofType(UserPreferences.GET_SET_ROW_FAILED_SIZE),
                                 debug('A server error occurred while get or set row size.'),
                                 map(() => error(this.translation.translate('GET_SET_ROW_FAILED_SIZE'),
                                   this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private store: Store<fromRoot.AppState>,
              private actions$: Actions,
              translation: L10nTranslationService,
              router: Router,
              private clientsService: ClientsService) {
    super(router, translation);
  }
  protected handleUnhandledError(response: Response): Action { return new GetSetRowSizeFailedAction(response.status); }
}

import {Injectable} from '@angular/core';
import {Actions, ofType,  createEffect} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as auth0 from 'auth0-js';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {error} from '../actions/toasts';
import {LoadUserInfoFailAction, LoadUserInfoSuccessAction, LogoutAction, UserInfoActionTypes} from '../actions/user-info';
import {Auth0UserInfo} from '../models/auth0-user-info';
import * as fromRoot from '../reducers';
import {Auth0AuthenticationService} from '../services/auth0-authentication.service';
import {inject} from '@angular/core/testing';

@Injectable()
export class UserInfoEffect {
  load$ = createEffect(
    () => {  return this.actions$.pipe(ofType(UserInfoActionTypes.LOAD),
                             debug('Load user info action received'),
                             switchMap(() => this.authService.getUserInfo().pipe(withLatestFrom(this.store.select(fromRoot.selectors.getRouterState)),
                                                                                 switchMap(([userInfo]) => of<Action>(new LoadUserInfoSuccessAction(userInfo))),
                                                                                 catchError(res => this.handleAuth0Error(res)))))});
  loadFail$ = createEffect(
    () => { return this.actions$.pipe(ofType(UserInfoActionTypes.LOAD_FAIL),
                                 debug('Auth0 /userinfo error (in payload):'),
                                 map((action: LoadUserInfoFailAction) => error(this.translation.translate('AUTH_GET_USER_INFO_ERROR', action.payload),
                                                                               this.translation.translate('TOAST_ERROR_TITLE'))))});

  logout$ = createEffect(
    () => {  return this.actions$.pipe(ofType(UserInfoActionTypes.LOGOUT),
                               debug('Logout action received.'),
                               tap((action: LogoutAction) => this.authService.logout(action.payload)))});

  constructor(private actions$: Actions,
              private translation: L10nTranslationService,
              private authService: Auth0AuthenticationService,
              private store: Store<fromRoot.AppState>) {}

  private handleAuth0Error(err: auth0.Auth0Error): Observable<Action> { return of(new LoadUserInfoFailAction(err)); }
}

import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';

import {Auth0AuthenticationService} from '../../../shared';

@Component({ selector: 'storever-callback', templateUrl: './callback.component.html', styleUrls: ['./callback.component.scss'] })
export class CallbackComponent implements OnInit {
  get errorMessage(): string { return this.authService.handleAuthenticationError; }

  constructor(private authService: Auth0AuthenticationService, private router: Router, @Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {
    setTimeout(function() {
      // console.log(' redirection ');
      if (document.location.pathname === '/callback') {
        document.location.href = document.location.origin;
      }

      // this.router.navigate(['/']);
    }, 10000);
    this.authService.handleAuthentication();
  }

  backToLogin(): void { this.router.navigate(['/login']); }
}

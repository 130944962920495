import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const DeleteTagsActionTypes = {
  DELETE_TAGS: type('[Delete Tags] Delete subscription Tags'),
  DELETE_TAGS_SUCCESS: type('[Delete Tags] Delete Tags subscription Success'),
  DELETE_TAGS_SELF: type('[Delete Tags] Delete Tags subscription Self'),
  DELETE_TAGS_FAIL: type('[Delete Tags] Delete Tags subscription Server Error')
};

export class DeleteTagsAction implements Action {
  readonly type = DeleteTagsActionTypes.DELETE_TAGS;
  constructor(public payload?: any) {}
}

export class DeleteTagsSuccessAction implements Action {
  readonly type = DeleteTagsActionTypes.DELETE_TAGS_SUCCESS;
  constructor() {}
}

export class DeleteTagsSelfAction implements Action {
  readonly type = DeleteTagsActionTypes.DELETE_TAGS_SELF;
  constructor() {}
}

export class DeleteTagsFailAction implements Action {
  readonly type = DeleteTagsActionTypes.DELETE_TAGS_FAIL;
  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import {type} from '../../shared';

export const AddExceptionActionTypes = {
  ADD_EXCEPTION: type('[Add Exception sub] Add Exception'),
  ADD_EXCEPTION_SUCCESS: type('[Add Exception sub] Add Exception Success'),
  ADD_EXCEPTION_SELF: type('[Add Exception sub] Add Exception Self'),
  ADD_EXCEPTION_FAIL: type('[Add Exception sub] Add Exception Server Error')
};

export class AddExceptionAction implements Action {
  readonly type = AddExceptionActionTypes.ADD_EXCEPTION;

  constructor(public payload?: any) {}
}

export class AddExceptionSuccessAction implements Action {
  readonly type = AddExceptionActionTypes.ADD_EXCEPTION_SUCCESS;

  constructor() {}
}

export class AddExceptionSelfAction implements Action {
  readonly type = AddExceptionActionTypes.ADD_EXCEPTION_SELF;

  constructor() {}
}

export class AddExceptionFailAction implements Action {
  readonly type = AddExceptionActionTypes.ADD_EXCEPTION_FAIL;

  constructor(public payload?: number) {}
}

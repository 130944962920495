import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

import {ChangeClientAction} from '..';
import * as fromRoot from '../reducers';

@Injectable()
export class HnResolver  {
  constructor(private store: Store<fromRoot.AppState>) {}

  resolve(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) {
    this.store.dispatch(new ChangeClientAction({ clientId: route.params['clientId'], redirect: false }));
    return of('Hello Alligator!');
  }
}

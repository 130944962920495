const KEY_STR = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const fromCharCode = String.fromCharCode;

/**
 * Encodes the provided string as Base64.
 *
 * @param {string} plainText &mdash; The plain text to encode, may contain UTF-8 symbols
 * @returns {string} &mdash; The Base64-encoded string
 *
 */
export function encodeBase64(plainText: string): string {
  const input = encodeUTF8(plainText);
  let output = '';
  let i = 0;

  while (i < input.length) {
    const chr1 = input.charCodeAt(i++), chr2 = input.charCodeAt(i++), chr3 = input.charCodeAt(i++);

    /* tslint:disable:no-bitwise */
    const enc1 = chr1 >> 2, enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    let enc3 = ((chr2 & 15) << 2) | (chr3 >> 6), enc4 = chr3 & 63;
    /* tslint:enable:no-bitwise */

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output = output + KEY_STR.charAt(enc1) + KEY_STR.charAt(enc2) + KEY_STR.charAt(enc3) + KEY_STR.charAt(enc4);
  }

  return output;
}

function encodeUTF8(input: string): string {
  let output = '';

  for (let i = 0; i < input.length; i++) {
    const c = input.charCodeAt(i);

    /* tslint:disable:no-bitwise */
    if (c < 0x80) {
      // One byte
      output += fromCharCode(c);
    } else if (c < 0x800) {
      // Two bytes
      output += fromCharCode(0xc0 | (c >>> 6));
      output += fromCharCode(0x80 | (c & 0x3f));
    } else if (c < 0x10000) {
      // Three bytes
      output += fromCharCode(0xe0 | (c >>> 12));
      output += fromCharCode(0x80 | ((c >>> 6) & 0x3f));
      output += fromCharCode(0x80 | (c & 0x3f));
    }
    /* tslint:enable:no-bitwise */
  }

  return output;
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const UpdateTagsActionTypes = {
  UPDATE_TAGS: type('[Update Tags] Update Tags'),
  UPDATE_TAGS_SUCCESS: type('[Update Tags] Update Tags Success'),
  UPDATE_TAGS_SELF: type('[Update Tags] Update Tags Self'),
  UPDATE_TAGS_FAIL: type('[Update Tags] Update Tags Server Error')
};

export class UpdateTagsAction implements Action {
  readonly type = UpdateTagsActionTypes.UPDATE_TAGS;

  constructor(public payload?: any) {}
}

export class UpdateTagsSuccessAction implements Action {
  readonly type = UpdateTagsActionTypes.UPDATE_TAGS_SUCCESS;

  constructor() {}
}

export class UpdateTagsSelfAction implements Action {
  readonly type = UpdateTagsActionTypes.UPDATE_TAGS_SELF;

  constructor() {}
}

export class UpdateTagsFailAction implements Action {
  readonly type = UpdateTagsActionTypes.UPDATE_TAGS_FAIL;

  constructor(public payload?: number) {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {SelectedMedias} from '../../../shared/models/selected-medias';
import {UsedMedias} from '../../models/used-medias';

@Component({
  selector: 'storever-media-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmMediaDeleteDialogComponent implements OnChanges {
  @ViewChild('deleteMediaModal') modal: ModalDirective;
  @Input() selectedMedias: SelectedMedias[];
  @Input() usedMedias: UsedMedias[];
  @Output() confirm = new EventEmitter<boolean>();
  @Output() deleteNotUsed = new EventEmitter<any>();
  UMedias: UsedMedias[] = [];
  notUsedMedias: any;
  messageMapping: { [k: string]: string } = { '=1': 'CONFIRM_DELETE_MEDIAS_DIALOG_SINGLE', other: 'CONFIRM_DELETE_MEDIAS_DIALOG_MULTI' };

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {

    if (_.has(changes, 'usedMedias')) {
      this.UMedias = [];
      const change = _.get(changes, 'usedMedias');
      const value: UsedMedias[] = change.currentValue;
      this.UMedias = value;
    }
  }
  // toggleDetails($event: MouseEvent): void {
  //   $event.preventDefault();
  //   $event.stopPropagation();
  //   this.showDetails = !this.showDetails;
  // }
  open(): void { this.modal.show(); }

  close(): void { this.modal.hide(); }

  accept(): void {
    this.confirm.emit(true);
    this.modal.hide();
  }
  acceptOnly(): void {
    this.notUsedMedias = _.differenceBy(this.selectedMedias, this.usedMedias, 'id');
    this.deleteNotUsed.emit(this.notUsedMedias);
    this.modal.hide();
  }
}

import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {DataTableActionsTemplateDirective} from './datatable-actions-template.directive';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'storever-datatable-actions' })
export class DataTableActionsDirective {
  @Input() className: string;

  @ContentChild(DataTableActionsTemplateDirective, { read: TemplateRef }) template: TemplateRef<any>;
}

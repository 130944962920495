import {Action} from '@ngrx/store';

import {ArrayResponse} from '../';
import {TagsList, TagsLists} from '../../medias/models/tags-list';
import {Media} from '../models/media';
import {MediasFilterForm} from '../models/medias-filter-form';
import {type} from '../utilities/type';

export const MediasListActionTypes = {
  TOGGLE_SEARCH: type('[Medias] Toggle Search Form'),
  QUICK_SEARCH: type('[Medias] Quick Search'),
  SEARCH: type('[Medias] Search'),
  CHANGE_PAGE_SIZE: type('[Medias] Change Page Size'),
  PAGE_TO: type('[Medias] Page To'),
  ORDER_BY: type('[Medias] Order By'),
  LOAD: type('[Medias] Load'),
  LOAD_SUCCESS: type('[Medias] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Medias] Load Validation Fail'),
  LOAD_FAIL: type('[Medias] Load Fail'),
  SELECT_PAGE: type('[Medias] Select Page'),
  UNSELECT_PAGE: type('[Medias] Unselect Page'),
  TOGGLE_SELECTION: type('[Medias] Toggle Selection'),
  CLEAR: type('[Medias] Clear Data'),
  TOGGLE_SELECTION_TAGS: type('[Medias] Toggle Selection Tags')
};

export class ToggleSearchMediasAction implements Action {
  readonly type = MediasListActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class QuickSearchMediasAction implements Action {
  readonly type = MediasListActionTypes.QUICK_SEARCH;
  constructor(public payload?: string) {}
}

export class SearchMediasAction implements Action {
  readonly type = MediasListActionTypes.SEARCH;
  constructor(public payload?: MediasFilterForm) {}
}

export class ChangeMediasPageSizeAction implements Action {
  readonly type = MediasListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageMediasToAction implements Action {
  readonly type = MediasListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderMediasByAction implements Action {
  readonly type = MediasListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadMediasAction implements Action {
  readonly type = MediasListActionTypes.LOAD;
  constructor() {}
}

export class LoadMediasSuccessAction implements Action {
  readonly type = MediasListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Media>) {}
}

export class LoadMediasValidationFailAction implements Action {
  readonly type = MediasListActionTypes.LOAD_VALIDATION_FAIL;
  constructor() {}
}

export class LoadMediasFailAction implements Action {
  readonly type = MediasListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
export class SelectMediasPageAction implements Action {
  readonly type = MediasListActionTypes.SELECT_PAGE;
  constructor() {}
}

export class UnselectMediasPageAction implements Action {
  readonly type = MediasListActionTypes.UNSELECT_PAGE;
  constructor() {}
}

export class ToggleMediaSelectionAction implements Action {
  readonly type = MediasListActionTypes.TOGGLE_SELECTION;
  /* payload is the user id */
  constructor(public payload?: number) {}
}

export class ClearMediasAction implements Action {
  readonly type = MediasListActionTypes.CLEAR;
  constructor() {}
}

export class ToggleMediaSelectionTagsAction implements Action { // tags to delete
  readonly type = MediasListActionTypes.TOGGLE_SELECTION_TAGS;
  constructor(public payload?: [TagsLists]) {}
}

<ng-select  #select
            [multiple]="true"
            [items]="tagsArray"
            (change)="changeTags($event)"
            placeholder="{{'NO_DATA_SELECTED' | translate:locale.language}}"
            class="scroll"
            bindLabel="name"
            (remove)="removedTags($event)"
            [(ngModel)]="selectedTags"
            (keyup)="onKeydownHandler($event)"
            [addTag]="addTagFn"
></ng-select>

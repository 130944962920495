import {Action} from '@ngrx/store';

import {Timezone} from '../models/timezone';
import {type} from '../utilities/type';

export const TimezonesActionTypes = {
  LOAD: type('[Timezones] Load'),
  LOAD_SUCCESS: type('[Timezones] Load Success'),
  LOAD_FAIL: type('[Timezones] Load Fail')
};

export class LoadTimezonesAction implements Action {
  readonly type = TimezonesActionTypes.LOAD;

  constructor() {}
}

export class LoadTimezonesSuccessAction implements Action {
  readonly type = TimezonesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Timezone[]) {}
}

export class LoadTimezonesFailAction implements Action {
  readonly type = TimezonesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

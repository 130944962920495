import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {BaseEffect} from '../../shared/effects/base.effect';
import {ArrayResponse} from '../../shared/models/array-response';
import {Media} from '../../shared/models/media';
import {SendBackResult} from '../../shared/models/send-back-result';
import * as rootSelectors from '../../shared/reducers';
import {LoadMediaToValidateFaildAction, LoadMediaToValidateSuccessAction, MediaToValidateListActionTypes} from '../actions/medias-to-validate-list';
import {DashboardService} from '../services/dashboard.service';

@Injectable()
export class MediasToValidateListEffect extends BaseEffect {
  private client$ = this.storeClient.select(rootSelectors.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$ = createEffect(
    () => {  return this.actions$.pipe(
    ofType(MediaToValidateListActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Load medias to validate action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.dashboardService.getMediasToValidate(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<Media>>) => new LoadMediaToValidateSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  protected handleUnhandledError(response: Response): Action { return new LoadMediaToValidateFaildAction(response.status); }

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private storeClient: Store<rootSelectors.AppState>,
              private dashboardService: DashboardService,
              router: Router) {
    super(router, translation);
  }
}

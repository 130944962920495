import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup} from '@angular/router';

import {RedirectComponent} from './redirect.component';
import {AppLayoutComponent, MustBeAuthenticatedGuard} from './shared';
import {RoleCanActivateGuard} from './shared/guards/role-can-activate.guard';

export function clientId(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
  const parts = [/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i];
  const posParams: { [key: string]: UrlSegment } = {};
  const consumed: UrlSegment[] = [];

  let currentIndex = 0;
  for (let i = 0; i < parts.length; ++i) {
    if (currentIndex >= segments.length) {
      return null;
    }
    const current = segments[currentIndex];

    const part = parts[i];
    if (!part.test(current.path)) {
      return null;
    }

    posParams['clientId'] = current;
    consumed.push(current);
    currentIndex++;
  }

  if (route.pathMatch === 'full' && (segmentGroup.hasChildren() || currentIndex < segments.length)) {
    return null;
  }

  return { consumed, posParams };
}



const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    // @ts-ignore
    component: AppLayoutComponent,
    matcher: clientId,
    children: [
      {
        path: '',
        children: [
          { path: 'dashboard', component: RedirectComponent, data: { redirectTo: 'dashboard' } },
          { path: 'myDashboard', component: RedirectComponent, data: { redirectTo: 'myDashboard' } },
          { path: 'media', component: RedirectComponent, data: { redirectTo: 'media' } },
          { path: 'campaign', component: RedirectComponent, data: { redirectTo: 'campaign' } },
          { path: 'templates-programs', component: RedirectComponent, data: { redirectTo: 'templates-programs' } },
          { path: 'subscription', component: RedirectComponent, data: { redirectTo: 'subscription' } },
          { path: 'publication', component: RedirectComponent, data: { redirectTo: 'publication' } }
        ]
      },
      {
        matcher: clientId,
        children: [
          {
            path: 'dashboard',
            data: { title: '' },
            // @ts-ignore
            loadChildren:  () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          { path: 'myDashboard',
            data: { title: '' },
            // @ts-ignore
            loadChildren:  () => import('./my-dashboard/my-dashboard.module').then(m => m.MyDashboardModule)
          },
          { path: 'media', canActivate: [RoleCanActivateGuard], data: { roles: 'APS_MEDIA', title: '' },
            // @ts-ignore
            loadChildren:  () => import('./medias/medias.module').then(m => m.MediaModule)
          },
          {
            path: 'campaign',
            canActivate: [RoleCanActivateGuard],
            data: { roles: 'APS_CAMPAIGN', title: '' },
            // @ts-ignore
            loadChildren:  () => import('./compaigns/campaigns.module').then(m => m.CampaignsModule)
          },
          {
            path: 'templates-programs',
            canActivate: [RoleCanActivateGuard],
            data: { roles: 'APS_PROGRAM', title: '' },
            // @ts-ignore
            loadChildren:  () => import('./templates-programs/templates-programs.module').then(m => m.TemplatesProgramsModule)
          },
          {
            path: 'subscription',
            data: { title: '' },
            // @ts-ignore
            loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule)
          },
          {
            path: 'publication',
            canActivate: [RoleCanActivateGuard],
            data: { roles: 'APS_PUBLISH', title: '' },
            // @ts-ignore
            loadChildren: () => import('./publications/publications.module').then(m => m.PublicationsModule)
          }
        ]
      }
    ],
    canActivate: [MustBeAuthenticatedGuard]
  }
];

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {}

import {Action} from '@ngrx/store';

import {Client} from '../models/client';
import {type} from '../utilities/type';

export const MyClientsActionTypes = {
  LOAD: type('[My Clients] Load'),
  LOAD_SUCCESS: type('[My Clients] Load Success'),
  LOAD_FAIL: type('[My Clients] Load Fail')
};

export class LoadMyClientsAction implements Action {
  readonly type = MyClientsActionTypes.LOAD;

  constructor() {}
}

export class LoadMyClientsSuccessAction implements Action {
  readonly type = MyClientsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Client[]) {}
}

export class LoadMyClientsFailAction implements Action {
  readonly type = MyClientsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

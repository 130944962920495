import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as m from 'moment';

export const moment = (format: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (Validators.required(control) != undefined) {
      return undefined;
    }

    const v = m(control.value, format, true);
    return v.isValid() ? undefined : { moment: true };
  };
};

import {Inject, Injectable} from '@angular/core';
import {L10N_LOCALE, L10nLocale, L10nTranslationService} from 'angular-l10n';
import {SetOptions} from 'eonasdan-bootstrap-datetimepicker';

import {localeToCulture} from '../utilities/locale-to-culture';

export interface DateTimeFormat {
  date: string;
  time: string;
  timeWithSeconds: string;
  timeWithTimezone?: string;
  timeWithSecondsAndTimezone?: string;
  dateTime: string;
  dateTimeWithTimezone?: string;
  dateTimeWithSeconds: string;
  dateTimeWithSecondsAndTimezone?: string;
}

@Injectable()
export class DateTimeFormatService {
  static get input(): DateTimeFormat {
    return { date: 'DD/MM/YYYY', time: 'HH:mm', timeWithSeconds: 'HH:mm:ss', dateTime: 'DD/MM/YYYY HH:mm', dateTimeWithSeconds: 'DD/MM/YYYY HH:mm:ss' };
  }

  static get display(): DateTimeFormat {
    return {
      date: 'L',
      time: 'LT',
      timeWithSeconds: 'LTS',
      timeWithTimezone: 'LT [GMT]Z',
      timeWithSecondsAndTimezone: 'LTS [GMT]Z',
      dateTime: 'L LT',
      dateTimeWithTimezone: 'L LT [GMT]Z',
      dateTimeWithSeconds: 'L LTS',
      dateTimeWithSecondsAndTimezone: 'L LTS [GMT]Z'
    };
  }

  constructor(private translation: L10nTranslationService, @Inject(L10N_LOCALE) public locale: L10nLocale) {}

  buildDateTimePickerOptions(options: SetOptions): SetOptions {
    const defaultOptions: SetOptions = {
      format: false,
      locale: localeToCulture(this.locale.language),
      useStrict: true,
      calendarWeeks: true,
      showTodayButton: true,
      showClose: true,
      tooltips: {
        today: this.translation.translate('DATETIME_PICKER_TODAY'),
        clear: this.translation.translate('DATETIME_PICKER_CLEAR'),
        close: this.translation.translate('DATETIME_PICKER_CLOSE'),
        selectMonth: this.translation.translate('DATETIME_PICKER_SELECT_MONTH'),
        prevMonth: this.translation.translate('DATETIME_PICKER_PREV_MONTH'),
        nextMonth: this.translation.translate('DATETIME_PICKER_NEXT_MONTH'),
        selectYear: this.translation.translate('DATETIME_PICKER_SELECT_YEAR'),
        prevYear: this.translation.translate('DATETIME_PICKER_PREV_YEAR'),
        nextYear: this.translation.translate('DATETIME_PICKER_NEXT_YEAR'),
        selectDecade: this.translation.translate('DATETIME_PICKER_SELECT_DECADE'),
        prevDecade: this.translation.translate('DATETIME_PICKER_PREV_DECADE'),
        nextDecade: this.translation.translate('DATETIME_PICKER_NEXT_DECADE'),
        prevCentury: this.translation.translate('DATETIME_PICKER_PREV_CENTURY'),
        nextCentury: this.translation.translate('DATETIME_PICKER_NEXT_CENTURY'),
        incrementHour: this.translation.translate('DATETIME_PICKER_INCREMENT_HOUR'),
        pickHour: this.translation.translate('DATETIME_PICKER_PICK_HOUR'),
        decrementHour: this.translation.translate('DATETIME_PICKER_DECREMENT_HOUR'),
        incrementMinute: this.translation.translate('DATETIME_PICKER_INCREMENT_MINUTE'),
        pickMinute: this.translation.translate('DATETIME_PICKER_PICK_MINUTE'),
        decrementMinute: this.translation.translate('DATETIME_PICKER_DECREMENT_MINUTE'),
        incrementSecond: this.translation.translate('DATETIME_PICKER_INCREMENT_SECOND'),
        pickSecond: this.translation.translate('DATETIME_PICKER_PICK_SECOND'),
        decrementSecond: this.translation.translate('DATETIME_PICKER_DECREMENT_SECOND')
      }
    };
    return Object.assign({}, defaultOptions, options);
  }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {UserContextActionTypes} from '../../shared';
import {AddExceptionAction, AddExceptionActionTypes} from '../actions/add-exception-opening';
import {
  CampaignLocalAction,
  campaignLocalActionTypes,
  CampaignLocalDeletedAction,
  CampaignLocalToggleSelectionAction,
  CampaignLocalUpdatedAction
} from '../actions/campaign-local';
import {CorporateListChangeAction, CorporateListChangeActionTypes} from '../actions/corporate-list-change';
import {GeocodeAddressActionTypes, GeocodeAddressSuccessAction} from '../actions/geocode-address';
import {LoadSubscriptionDetailSuccessAction, SubscriptionDetailActionTypes} from '../actions/subscription';
import {
  UpdateSubscriptionActionTypes,
  UpdateSubscriptionCulturesAction,
  UpdateSubscriptionOpeningHoursAction,
  UpdateSubscriptionProgramAction,
  UpdateSubscriptionTagsAction
} from '../actions/update-subscription';
import {GeocodedAddress} from '../models/geocoded-address';
import {SelectedSubs, Subscriptions} from '../models/subscriptions';

export interface SubscriptionsDetailState {
  value: Subscriptions;
  valueInit: Subscriptions;
  selectedSubLoc: SelectedSubs[];
  geocodedAddress: GeocodedAddress;
}

const initialState: SubscriptionsDetailState = {
  value: undefined,
  valueInit: undefined,
  selectedSubLoc: [],
  geocodedAddress: undefined

};

export function SubscriptionDetailReducer(state = initialState, action: Action): SubscriptionsDetailState {
  switch (action.type) {
    case SubscriptionDetailActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case SubscriptionDetailActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case GeocodeAddressActionTypes.GEOCODE_SUCCESS:
      return handleGeocodeSuccessAction(state, action);
    case GeocodeAddressActionTypes.GEOCODE_HTTP_ERROR:
    case GeocodeAddressActionTypes.GEOCODE_GENERIC_ERROR:
    case GeocodeAddressActionTypes.GEOCODE_OVER_QUERY_LIMIT:
    case GeocodeAddressActionTypes.GEOCODE_ZERO_RESULTS:
      return handleGeocodeErrorActions(state);
    case UpdateSubscriptionActionTypes.UPDATE_PROGRAM_TEMPLATE:
      return handleUpdateProgramActions(state, action);
    case UpdateSubscriptionActionTypes.UPDATE_CULTURES:
      return handleUpdateCulturesActions(state, action);
    case AddExceptionActionTypes.ADD_EXCEPTION:
      return handleUpdateExceptionActions(state, action);
    case UpdateSubscriptionActionTypes.UPDATE_TAGS:
      return handleUpdateTagsActions(state, action);
    case UpdateSubscriptionActionTypes.UPDATE_OPENING_HOURS:
      return handleUpdateOpeningHoursActions(state, action);
    case UpdateSubscriptionActionTypes.UPDATE_PLAY_OFFSET:
      return handleUpdatePlayOffsetActions(state, action);
    case CorporateListChangeActionTypes.CHANGE_CORPORATE_DIFFUSION:
      return handleUpdateCorporateListDiffussionActions(state, action);
    case UpdateSubscriptionActionTypes.UPDATE_CANCEL:
      return handleCancelAction(state);
    case campaignLocalActionTypes.CAMPAIGN_LOCAL:
      return handleCampaignLocalAction(state, action);
    case campaignLocalActionTypes.CAMPAIGN_LOCAL_UPDATE:
      return handleEditCampaignLocalAction(state, action);
    case campaignLocalActionTypes.CAMPAIGN_LOCAL_DELETE:
      return handleDeleteCampaignLocalAction(state, action);
    case campaignLocalActionTypes.CAMPAIGN_LOCAL_DELETE_ACTION:
      return handleDeleteCampaignLocalActionAction(state);

    case campaignLocalActionTypes.CAMPAIGN_LOCAL_TOGGLE_SELECTION:
      return handleToggleSelectionCampaignLocalAction(state, action);

    case campaignLocalActionTypes.CAMPAIGN_LOCAL_SELECT_PAGE:
      return handleSelectPageCampaignLocalAction(state);

    case campaignLocalActionTypes.CAMPAIGN_LOCAL_UNSELECT_PAGE:
      return handleUnSelectPageCampaignLocalAction(state);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: SubscriptionsDetailState, action: LoadSubscriptionDetailSuccessAction): SubscriptionsDetailState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleLoadErrorActions(state: SubscriptionsDetailState): SubscriptionsDetailState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}

function handleChangeClientAction(): SubscriptionsDetailState {
  return { value: undefined, geocodedAddress: undefined, valueInit: undefined, selectedSubLoc: [] };
}
function handleGeocodeSuccessAction(state: SubscriptionsDetailState, action: GeocodeAddressSuccessAction): SubscriptionsDetailState {
  const newState:
    SubscriptionsDetailState = { value: state.value, geocodedAddress: action.payload, valueInit: state.value, selectedSubLoc: state.selectedSubLoc };
  return newState;
}
function handleCancelAction(state: SubscriptionsDetailState): SubscriptionsDetailState {

  const options = { geocodedAddress: state.geocodedAddress, selectedSubLoc: state.selectedSubLoc };
  const values = { value: state.valueInit, valueInit: state.valueInit };
  const newState: SubscriptionsDetailState = Object.assign({}, options, values);
  return newState;
}

function handleGeocodeErrorActions(state: SubscriptionsDetailState): SubscriptionsDetailState {
  const newState:
    SubscriptionsDetailState = { value: state.value, geocodedAddress: undefined, valueInit: state.valueInit, selectedSubLoc: state.selectedSubLoc };
  return newState;
}
function handleUpdateProgramActions(state: SubscriptionsDetailState, action: UpdateSubscriptionProgramAction): SubscriptionsDetailState {

  const newState = _.clone(state);
  if (newState.value.programTemplate) {
    newState.value.programTemplate.id = action.payload.id;
    newState.value.programTemplate.name = action.payload.name;
  } else {
    newState.value.programTemplate = { id: action.payload.id, name: action.payload.name };
  }

  return newState;
}
function handleUpdateOpeningHoursActions(state: SubscriptionsDetailState, action: UpdateSubscriptionOpeningHoursAction): SubscriptionsDetailState {
  const close = '23:59'
  const newState = _.clone(state);
  const actionOpenH = _.clone(action.payload);
  console.log(actionOpenH);
  for (const openingHours of actionOpenH) {
    console.log(openingHours);
    if (openingHours.colse === '00:00') {
      openingHours.colse  = close;
    }
  }
  newState.value.openingHours = actionOpenH;
  console.log(newState.value.openingHours );
  return newState;
}
function handleUpdatePlayOffsetActions(state: SubscriptionsDetailState, action: UpdateSubscriptionOpeningHoursAction): SubscriptionsDetailState {

  const newState = _.clone(state);
  newState.value.playOffset = action.payload;

  return newState;
}
function handleUpdateCulturesActions(state: SubscriptionsDetailState, action: UpdateSubscriptionCulturesAction): SubscriptionsDetailState {

  const newState = _.clone(state);
  newState.value.cultures = action.payload;
  return newState;
}
function handleUpdateExceptionActions(state: SubscriptionsDetailState, action: AddExceptionAction): SubscriptionsDetailState {

  let openingHourExcpetions;
  const newState = _.clone(state);
  if (action.payload.length === 0) {
    newState.value.openingHourExcpetions = action.payload;
  }
  if (action.payload.length > 0
    && action.payload[action.payload.length - 1].startDate !== 'Invalid date'
    && action.payload[action.payload.length - 1].startTime !== 'Invalid date'
    && action.payload[action.payload.length - 1].endTime !== 'Invalid date'
  ) {
    openingHourExcpetions = _.uniqBy(action.payload, 'startDate');
    newState.value.openingHourExcpetions = _.clone(openingHourExcpetions);
  }
  return newState;
}

function handleUpdateTagsActions(state: SubscriptionsDetailState, action: UpdateSubscriptionTagsAction): SubscriptionsDetailState {
  const newState = _.clone(state);
  newState.value.tags = action.payload;
  return newState;
}

function handleUpdateCorporateListDiffussionActions(state: SubscriptionsDetailState, action: CorporateListChangeAction): SubscriptionsDetailState {

  const newState = _.clone(state);
  const id = _.findLastIndex(newState.value.corporateMedia, function(o) { return o.id === action.payload.id; });
  newState.value.corporateMedia[id].blacklisted = action.payload.blacklisted;
  newState.value.corporateMedia[id].blacklistChanged = true;

  return newState;
}
function handleCampaignLocalAction(state: SubscriptionsDetailState, action: CampaignLocalAction): SubscriptionsDetailState {
  let id = 0;
  const newState = _.clone(state);
  for (const entry of action.payload) {
    if (entry.id === -9999) {
      entry.id = id;
    }
    id = id - 1;
    newState.value.localSchedules.push(entry);
  }

  return newState;
}
function handleEditCampaignLocalAction(state: SubscriptionsDetailState, action: CampaignLocalUpdatedAction): SubscriptionsDetailState {
  const newState = _.clone(state);
  if (action.payload.id !== -9999) {
    const id = _.findLastIndex(newState.value.localSchedules, function(o) { return o.id === action.payload.id; });
    newState.value.localSchedules[id] = action.payload;
  } else {
    if (state.selectedSubLoc.length > 0) {
      for (const entry of state.selectedSubLoc) {
        const id = _.findLastIndex(newState.value.localSchedules, function(o) { return o.id === entry.id; });
        newState.value.localSchedules[id].validityPeriod = action.payload.validityPeriod;
      }
    }
  }

  return newState;
}

function handleDeleteCampaignLocalAction(state: SubscriptionsDetailState, action: CampaignLocalDeletedAction): SubscriptionsDetailState {
  const newState = _.clone(state);
  const id = _.findLastIndex(newState.value.localSchedules, function(o) { return o.id === action.payload.id; });
  newState.value.localSchedules[id] = action.payload;
  return newState;
}
function handleDeleteCampaignLocalActionAction(state: SubscriptionsDetailState): SubscriptionsDetailState {
  const newState = _.clone(state);
  if (state.selectedSubLoc.length > 0) {
    for (const entry of state.selectedSubLoc) {
      const id = _.findLastIndex(newState.value.localSchedules, function(o) { return o.id === entry.id; });
      newState.value.localSchedules[id].deleted = true;
    }
  }
  return newState;
}
function handleToggleSelectionCampaignLocalAction(state: SubscriptionsDetailState, action: CampaignLocalToggleSelectionAction): SubscriptionsDetailState {
  const newState = _.clone(state);

  const selectedIndex = _.findIndex(state.selectedSubLoc, ['id', action.payload]);
  let selectedLocCampaign: SelectedSubs[];
  if (selectedIndex > -1) {
    selectedLocCampaign = _.filter(state.selectedSubLoc, localSchedules => localSchedules.id !== action.payload);
  } else {
    const localCampaign = _.find(state.value.localSchedules, u => u.id === action.payload);
    const selectedLocalCampaigns: SelectedSubs = { id: localCampaign.id, name: localCampaign.name };
    selectedLocCampaign = [...state.selectedSubLoc, selectedLocalCampaigns];
  }
  newState.selectedSubLoc = selectedLocCampaign;
  return newState;
}

function handleSelectPageCampaignLocalAction(state: SubscriptionsDetailState): SubscriptionsDetailState {
  const newState = _.clone(state);
  newState.selectedSubLoc = _.map(state.value.localSchedules, localCampaign => ({ id: localCampaign.id, name: localCampaign.name }));
  return newState;
}

function handleUnSelectPageCampaignLocalAction(state: SubscriptionsDetailState): SubscriptionsDetailState {
  const newState = _.clone(state);
  newState.selectedSubLoc = [];
  return newState;
}

export const SubscriptionDetailSelectors = {
  value: (state: SubscriptionsDetailState) => _.get(state, 'value'),
  valueInit: (state: SubscriptionsDetailState) => _.get(state, 'valueInit'),
  geocodedAddress: (state: SubscriptionsDetailState) => _.get(state, 'geocodedAddress'),
  selectedSubLoc: (state: SubscriptionsDetailState) => _.get(state, 'selectedSubLoc', [])
};

import {Action} from '@ngrx/store';
import {type} from '../utilities/type';

export const LayoutActionTypes = {
  TOGGLE_SIDEBAR: type('[Layout] Toggle Sidebar'),
  SET_LANGUAGE: type('[Layout] Set Language')
};

export class ToggleSidebarAction implements Action {
  readonly type = LayoutActionTypes.TOGGLE_SIDEBAR;

  constructor() {}
}

export class SetLanguageAction implements Action {
  readonly type = LayoutActionTypes.SET_LANGUAGE;

  constructor(public payload?: string) {}
}

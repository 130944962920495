import {isAfter} from './is-after/validator';
import {isBefore} from './is-before/validator';
import {isBetween} from './is-between/validator';
import {isSameOrAfter} from './is-same-or-after/validator';
import {moment} from './moment/validator';

export const StoreverValidators = {
  moment,
  isAfter,
  isBefore,
  isSameOrAfter,
  isBetween
};

export class UUID {
  static uuid(): string {
    if (window.crypto != undefined && window.crypto.getRandomValues != undefined) {
      const buf = new Uint16Array(8);
      window.crypto.getRandomValues(buf);
      return (UUID.pad4(buf[0]) + UUID.pad4(buf[1]) + '-' + UUID.pad4(buf[2]) + '-' + UUID.pad4(buf[3]) + '-' + UUID.pad4(buf[4]) + '-' + UUID.pad4(buf[5]) +
              UUID.pad4(buf[6]) + UUID.pad4(buf[7]));
    } else {
      return (UUID.random4() + UUID.random4() + '-' + UUID.random4() + '-' + UUID.random4() + '-' + UUID.random4() + '-' + UUID.random4() + UUID.random4() +
              UUID.random4());
    }
  }

  private static pad4(num: number): string {
    let ret = num.toString(16);
    while (ret.length < 4) {
      ret = '0' + ret;
    }

    return ret;
  }

  private static random4(): string { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
}

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ScrollToTopService {
  private scrollToTopSource = new Subject<number>();
  public scrollToTop$: Observable<number> = this.scrollToTopSource.asObservable();

  scrollToTop(speed = 500): void { this.scrollToTopSource.next(speed); }
}

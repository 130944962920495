import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const AddTagsActionTypes = {
  ADD_TAGS: type('[Add Tags] Add Tags'),
  ADD_TAGS_SUCCESS: type('[Add Tags] Add Tags Success'),
  ADD_TAGS_SELF: type('[Add Tags] Add Tags Self'),
  ADD_TAGS_FAIL: type('[Add Tags] Add Tags Server Error')
};

export class AddTagsAction implements Action {
  readonly type = AddTagsActionTypes.ADD_TAGS;
  constructor(public payload?: any) {}
}

export class AddTagsSuccessAction implements Action {
  readonly type = AddTagsActionTypes.ADD_TAGS_SUCCESS;
  constructor() {}
}

export class AddTagsSelfAction implements Action {
  readonly type = AddTagsActionTypes.ADD_TAGS_SELF;
  constructor() {}
}

export class AddTagsFailAction implements Action {
  readonly type = AddTagsActionTypes.ADD_TAGS_FAIL;
  constructor(public payload?: number) {}
}

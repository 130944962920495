import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {BlockUIEvent} from '../models/block-ui-event';
import {BlockUIEventType} from '../models/block-ui-event-type.enum';

@Injectable()
export class BlockUIService {
  public events$: Observable<BlockUIEvent>;
  private eventsSource = new BehaviorSubject<BlockUIEvent>(new BlockUIEvent(BlockUIEventType.Hide));
  private xhrCount: { [key: string]: boolean } = {};

  constructor() { this.events$ = this.eventsSource.asObservable(); }

  show(): void { this.eventsSource.next(new BlockUIEvent(BlockUIEventType.Show)); }

  hide(): void { this.eventsSource.next(new BlockUIEvent(BlockUIEventType.Hide)); }

  startXHR(uuid: string): void {
    const prev = _.keys(this.xhrCount).length;
    this.xhrCount[uuid] = true;
    if (_.keys(this.xhrCount).length > 1 && prev === 1) {
      this.show();
      this.timer();
    }
  }

  endXHR(uuid: string): void {
    if (_.has(this.xhrCount, uuid)) {
      delete this.xhrCount[uuid];
      if (_.keys(this.xhrCount).length <= 1) {
        this.hide();
      }
    }
  }
  timer() {
    setTimeout(() => {
      if (_.keys(this.xhrCount).length > 0) {
        this.hide();
      }
    }, 10000);
  }
}

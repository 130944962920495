import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class InterceptorSpinnerService implements HttpInterceptor {

  private count = 0;
  private prevCount = 0;
  private msg: string;

  constructor(public blockUIService: BlockUIService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    if (req.method === 'GET') {
      this.count++;
    }

    if (this.count === 1) {
      this.prevCount++;
      this.blockUIService.endXHR('')
    }

    const handleObs: Observable<HttpEvent<any>> = next.handle(req);
    return handleObs.pipe(tap(event => ok = (event instanceof HttpResponse) ? 'succeed' : '', error => ok = 'succeed'),
                          // Log when response observable either completes or errors
                          finalize(() => {
                            const elapsed = Date.now() - started;
                            if (req.method === 'GET') {
                              this.count--;
                              this.msg = `${req.method} '${req.urlWithParams}' ${ok} in ${elapsed} ms. / ${this.count}`;
                            } else {
                              this.msg = `${req.method} '${req.urlWithParams}' ${ok} in ${elapsed} ms.`;
                            }
                            if (isDevMode()) {
                              // TODO: remove domain from logs.
                              console.log('[INTERCEPTOR]: ', this.msg);
                            }
                            if (this.count === 0) {
                              this.blockUIService.startXHR('')
                            }
                          }));
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, skipWhile, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {Publication} from '../../publications/models/publication';
import {BaseEffect} from '../../shared/effects/base.effect';
import {ArrayResponse} from '../../shared/models/array-response';
import {SendBackResult} from '../../shared/models/send-back-result';
import * as rootSelectors from '../../shared/reducers';
import * as fromRoot from '../../shared/reducers';
import {PublicationFaildAction, PublicationFailedListActionTypes, PublicationFailedSuccessAction} from '../actions/publication-failed-list';
import {DashboardService} from '../services/dashboard.service';

@Injectable()
export class PublicationsFailedListEffect extends BaseEffect {
  private client$ = this.storeClient.select(rootSelectors.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));
  private roles$ = this.storeClient.select(fromRoot.selectors.getMyRoles).pipe(filter(roles => !_.isEmpty(roles)));

  loadPublicationFailed$  = createEffect(
    () => {  return this.actions$.pipe(
    ofType(PublicationFailedListActionTypes.LOAD_FAILED),
    delayWhen(() => this.roles$),
    withLatestFrom(this.roles$),
    skipWhile(([action, roles]) => !_.find(roles, { 'code': 'APS_PUBLISH' })),
    delayWhen(() => this.client$),
    debug('Load Publication Last action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.dashboardService.getPublicationFailed(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<Publication>>) => new PublicationFailedSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  protected handleUnhandledError(response: Response): Action { return new PublicationFaildAction(response.status); }

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private storeClient: Store<rootSelectors.AppState>,
              private dashboardService: DashboardService,
              router: Router) {
    super(router, translation);
  }
}

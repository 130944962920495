import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadTagsListFailAction, LoadTagsListSuccessAction, TagsListActionTypes} from '../actions/tags';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {TagsList} from '../models/tags-list';
import * as fromRoot from '../reducers';
import {TagsListsService} from '../services/tags-lists.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class MediaTagsEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$ = createEffect(
    () => {  return this.actions$.pipe(
    ofType(TagsListActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Load languages action received;'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.tagsListsService.mediasTagsList(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<TagsList>>) => new LoadTagsListSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

 loadFail$ = createEffect(
   () => {  return this.actions$.pipe(ofType(TagsListActionTypes.LOAD_FAIL),
                                 debug('Load languages fail action received.'),
                                 map(() => error(this.translation.translate('LANGUAGES_LOAD_ERROR'),
                                   this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private tagsListsService: TagsListsService,
              private store: Store<fromRoot.AppState>,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadTagsListFailAction(response.status); }
}

import {Action} from '@ngrx/store';

import {DiscardableAlertMessage} from '../models/discardable-alert-message';
import {type} from '../utilities/type';
import {Counters} from '../models/counters';

export const CountersActionType = {
  COUNTERS_LOAD: type('[Counters] Load'),
  COUNTERS_LOAD_SUCCESS: type('[Counters] Load Success'),
  COUNTERS_LOAD_FAIL: type('[Counters] Load Fail'),
};

export class LoadCountersAction implements Action {
  readonly type = CountersActionType.COUNTERS_LOAD;

  constructor() {}
}

export class LoadCountersSuccessAction implements Action {
  readonly type = CountersActionType.COUNTERS_LOAD_SUCCESS;

  constructor(public payload?: Counters) {}
}

export class LoadCountersFailAction implements Action {
  readonly type = CountersActionType.COUNTERS_LOAD_FAIL;

  constructor(public payload?: number) {}
}


<ng-progress></ng-progress>
<p-toast position="top-right"></p-toast>
<div class="page-header navbar navbar-fixed-top">
  <div class="page-header-inner ">
    <div class="page-logo">
      <a *ngIf="brand$ | async as brand" [routerLink]="dashboardLink$ | async">
        <img width="80"
             [src]="'/assets/images/brands/' + brand.id + '/logo.png'"
             [alt]="brand.name"
             class="logo-default">
      </a>
      <div class="menu-toggler sidebar-toggler" (click)="toggleSidebar()">
      </div>
    </div>
    <span class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse"> </span>
    <div class="page-top">
      <div class="top-menu pull-left client-name">
        <h3>{{ 'SITE_TITLE' | translate:locale.language }}  </h3>
      </div>
      <div class="client-selector">
        <div dropdown class="btn-group" #clientDropdown [autoClose]="false" (onShown)="onShown()" [isDisabled]="!(canChangeClient$ | async)" >
          <button #clientDropdownToggle dropdownToggle type="button" class="btn btn-client dropdown-toggle" >
            <span>{{ clientName$ | async }}</span> <span *ngIf="canChangeClient$ | async" class="caret" style="margin-left: 5px;"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <div class="client-filter">
              <li role="menuitem">
                <input autofocus #clientDropdownFilter type="text" class="form-control" autocomplete="off"
                       [placeholder]="'CLIENT_SELECTOR_PLACEHOLDER' | translate:locale.language"
                       [(ngModel)]="currentClientId">
              </li>
            </div>
            <div class="client-list">
              <li *ngFor="let client of myClients$ | async | clientsFilter:currentClientId " role="menuitem">
                <a [href]="['/' + client.uuid + '/dashboard']" class="dropdown-item">{{client.name}}</a>
                <!--<span (click)="redirectClient(client.uuid)" class="dropdown-item">{{client.name}}</span>-->
              </li>
            </div>
          </ul>
        </div>
      </div>
      <storever-top-menu [userInfo]="userInfo$ | async"
                         [canImpersonate]="isInRole$('RUNAS') | async"
                         [impersonatedUser]="impersonatedUser$ | async"
                         [availableLanguages]="availableLanguages"
                         [currentLanguage]="locale.language"
                         (impersonate)="impersonate($event, impersonateUserDialog)"
                         (logout)="logout()"
                         (selectLanguage)="selectLanguage($event)"></storever-top-menu>
      <span class="top-menu pull-right">
        <storever-publish *ngIf="isInRole$('APS_PUBLISH') | async"
                          [roleProgram]="isInRole$('APS_PROGRAM') | async"
                          (published)="publishDialogBox(ConfirmationPublishModal)" >
        </storever-publish ></span>
    </div>
  </div>
</div>
<div class="clearfix"></div>
<div class="page-container">
  <div class="page-sidebar-wrapper">
    <div class="page-sidebar navbar-collapse collapse" style="z-index: 1000;">
      <ul class="page-sidebar-menu page-header-fixed page-sidebar-menu-hover-submenu"
          [class.page-sidebar-menu-closed]="sidebarClosed$ | async"
          data-keep-expanded="false"
          data-auto-scroll="true"
          data-slide-speed="200">
        <li class="nav-item start" [ngClass]="{'active open': isRouteActiveClassAdd(['/dashboard', '/myDashboard'])}">
          <a
            [routerLink]="dashboardLink$ | async" href="javascript:;" class="nav-link nav-toggle">
            <i class="icon-home"></i>
            <span class="title">{{ 'DASHBOARD' | translate:locale.language }}</span>
            <span [class.arrow]="!isRouteActive(['/dashboard'])" [class.selected]="isRouteActive(['/dashboard'])"></span>
          </a>
          <ul class="sub-menu"  *ngIf="(isStorever$ | async)  && !ifRunAs()">
            <li  class="nav-item" [ngClass]="{'active open': isRouteActiveClassAdd(['myDashboard'])}">
              <a [routerLink]="myDashboardLink$ | async" class="nav-link">
                <span class="title">{{ 'MY_DASHBOARD' | translate:locale.language }}</span>
              </a>
            </li>
          </ul>
        </li>
        <li [ngClass]="{'active open': isRouteActiveClassAdd(['/templates-programs'])}" *ngIf="isInRole$('APS_PROGRAM') | async"  class="nav-item" [routerLinkActive]="['active', 'open']">

          <a
            [routerLink]="templatePLink$ | async" class="nav-link nav-toggle">
            <span class="template-icon" style="font-size:24px"></span>
            <span class="title" translate>TEMPLATES_AND_PROGRAMS</span>
            <span [class.arrow]="!isRouteActive(['/templates-programs'])" [class.selected]="isRouteActive(['/templates-programs'])"></span>
          </a>
        </li>
        <li *ngIf="isInRole$('APS_MEDIA') | async"  class="nav-item" [routerLinkActive]="['active', 'open']" [ngClass]="{'active open': isRouteActiveClassAdd(['/media'])}">
          <a
            [routerLink]="mediasLink$ | async" class="nav-link nav-toggle">
            <span class="media-icon" style="font-size:24px"></span>
            <span class="title" translate>MEDIAS_LIST_TITLE</span>
            <span [class.arrow]="!isRouteActive(['/media'])"[class.selected]="isRouteActive(['/media'])"></span>
          </a>
        </li>
        <li class="nav-item" [ngClass]="{'active open': isRouteActiveClassAdd(['/campaign'])}"
            *ngIf="isInRole$('APS_CAMPAIGN') | async"  [routerLinkActive]="['active', 'open']">
          <a
            [routerLink]="campaignsLink$ | async" class="nav-link nav-toggle">
            <span class="campaign-icon" style="font-size:24px"></span>
            <span class="title" translate>CAMPAIGNS_LIST_TITLE</span>
            <span [class.arrow]="!isRouteActive(['/campaign'])" [class.selected]="isRouteActive(['/campaign'])"></span>
          </a>
        </li>

        <li class="nav-item" [ngClass]="{'active open': isRouteActiveClassAdd(['/subscription'])}">
          <a
            [routerLink]="subscriptionsLink$ | async" class="nav-link nav-toggle">
            <span class="subscription-icon" style="font-size:24px"></span>
            <span class="title" translate>EDIT_CAMPAIGN_SUBSCRIPTIONS</span>
            <span [class.arrow]="!isRouteActive(['/subscription'])" [class.selected]="isRouteActive(['/subscription'])"></span>
          </a>
        </li>
        <li *ngIf="isInRole$('APS_PUBLISH') | async"  class="nav-item" [ngClass]="{'active open': isRouteActiveClassAdd(['/publication'])}">
          <a
            [routerLink]="publicationsLink$ | async" class="nav-link nav-toggle">
            <span class="icon-cloud-upload fa-2x" ></span>
            <span class="title" translate>PUBLICATION_LIST_TITLE</span>
            <span [class.arrow]="!isRouteActive(['/publication'])" [class.selected]="isRouteActive(['/publication'])"></span>
          </a>
        </li>
        <li *ngIf="hasExternalLinks$ | async" class="nav-item">
          <a href="javascript:;" class="nav-link nav-toggle">
            <i class="icon-options-vertical"></i>
            <span class="title" translate>EXTERNAL_APPS</span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li *ngFor="let link of externalLinks$ | async" class="nav-item">
              <a [href]="link.url" target="_blank" rel="noreferrer noopener" class="nav-link">
                <span class="title">{{link.name}}</span>
              </a>
            </li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
  <div class="page-content-wrapper">
    <div class="page-content">
      <div class="alert-messages-wrapper">
        <div *ngFor="let msg of alerts$ | async | notDiscarded" class="alert alert-warning alert-dismissable">
          <button type="button" class="close" aria-label="Close" (click)="dismiss(msg.id)">
            <span aria-hidden="true">&times;</span>
          </button>
          <span [innerHTML]="msg.message | message:locale.language | nl2br"></span>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="page-footer">
  <div class="page-footer-inner">
    {{copyrightYear}} &copy; <a href="http://mcubedigital.com" target="_blank" rel="noreferrer noopener">Mcube</a>
    <storever-scroll-to-top [isVisible]="scrollToTopVisible"></storever-scroll-to-top>
  </div>
  <div class="page-footer-inner version">
    <span>v{{currentApplicationVersion}}</span>
  </div>
  <div id="container">
    <span><a href="https://portal.mcubedigital.com/privacy-policy" target="_blank" fragment="privacy-policy" title="" rel="license">{{'PRIVACY_POLICY' | translate:locale.language}}</a></span>
    <span><a href="https://portal.mcubedigital.com/terms-of-use"  target="_blank" fragment="terms-of-use" title="" rel="license">{{'TERMS_OF_USE' | translate:locale.language}}</a></span>
  </div>
</div>
<storever-block-ui></storever-block-ui>
<storever-impersonate-user #impersonateUserDialog
                           [users]="impersonableUsers$ | async"
                           (impersonate)="impersonateUser($event)"></storever-impersonate-user>
<storever-confirmation-publication-dialog #ConfirmationPublishModal
                                          [roleProgram]="isInRole$('APS_PROGRAM') | async"
                                          [rolePublication]="isInRole$('APS_PUBLISH') | async"
                                          [publishInfo]="publish$ | async"
                                          (Publish)="publishCommercial($event)"
                                          (Music)="publishMusic($event)"
                                          (PublishAndMusic)="publishCommercialAndMusic($event)">
</storever-confirmation-publication-dialog>

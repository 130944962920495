import * as _ from 'lodash';
import * as moment from 'moment';
import {isUndefined} from 'lodash';
export function serializeQueryString(obj: any): string {
  const qs: string[] = [];
  _.forOwn(obj, (value, key) => {
    if (!isUndefined(value) && value !== null && value !== '') {

      if (value instanceof Date) {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(moment(value).format('YYYY-MM-DDTHH:mm:ssZZ'))}`);
      } else if (value instanceof moment) {
        // @ts-ignore
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.format('YYYY-MM-DDTHH:mm:ssZZ'))}`);
      } else {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });
  if (qs.length > 0) {
    return '?' + qs.join('&');
  }

  return '';
}

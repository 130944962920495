import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'joinComplexe' })
export class JoinComplexePipe implements PipeTransform {
  transform(value: String[]): string {
    if (value) {
      let tab = '';

      for (const entry of value) {
        tab = tab + ' ' + entry['name'];
      }

      if (tab) {
        return tab;
      }
    }
    return null;
  }
}

/* tslint:disable */
export const localCn = {
  'de': '德语',
  'en': '英语',
  'es': '西班牙语',
  'fr': '法语',
  'it': '意大利语',
  'nl': '荷兰语',
  'cn': '中国',
  'LANG_DE': '德语',
  'LANG_EN': '英语',
  'LANG_ES': '西班牙语',
  'LANG_FR': '法语',
  'LANG_IT': '意大利语',
  'LANG_NL': '荷兰语',
  'LANG_CN': '中国',
  'IMPERSONATE_USER': '模拟用户',
  'LEAVE_IMPERSONATION': '退出模拟模式',
  'LOGOUT': '注销',
  'RUN_AS': '作为',
  'CLOSE': '关闭',
  'OPEN': '打开',
  'INTRO': '内',
  'EXTRO': '外',
  'IMPERSONATE_DIALOG': '模拟用户表对话框',
  'IMPERSONATE_DIALOG_TITLE': '模拟用户',
  'IMPERSONATE_DIALOG_EXPLAIN_P1': '使用此选项,您可以模拟其他用户浏览AD Manager, 此选项仅适用于管理员.',
  'IMPERSONATE_DIALOG_EXPLAIN_P2': '任何在 "模拟"下执行的操作都将已你的身份完成 .  "模拟" 只是模拟其他用户可以看到的内容.',
  'SHOW_X_ELEMENTS_START': '显示',
  'SHOW_X_ELEMENTS_END': '元素',
  'SEARCH': '查找',
  'NAME': '名字',
  'EMAIL': '邮箱',
  'ROLES_GROUP': '角色组',
  'IMPERSONATE': '模拟',
  'CANCEL': '取消',
  'DATE': '日期',
  'PAGINATION_INFO': '显示从 {{from}} 到 {{to}} 的元素 {{filteredLength}} 共()元素',
  'CLIENT_SELECTOR_PLACEHOLDER': '更改客户...',
  'DASHBOARD': '用户界面',
  'MY_DASHBOARD': '我的界面',
  'MY_DASHBOARD_PLACEHOLDER_NAME': '搜索我的名字',
  'MY_DASHBOARD_ROW_NAME': '名字',
  'MY_DASHBOARD_ROW_MEDIA_VALIDATE': '多媒体文件验证',
  'MY_DASHBOARD_ROW_FAILED_PUBLICATION': '发布失败',
  'MY_DASHBOARD_ROW_SUBSCRIPTION_TO_PUBLISH': '订阅发布',
  'MY_DASHBOARD_ROW_MUSIC_PROGRAM_TO_PUBLISH': '音乐频道发布',
  'MY_DASHBOARD_ROW_SUBSCRIPTION_WITHOUT_TEMPLATE': '无模板的订阅',
  'MY_DASHBOARD_ROW_PROGRAM_OUTDATED': '频道过期或即将过期',
  'MY_DASHBOARD_ROW_PUBLICATION_IN_PROGRESS': '发布中',
  'TOAST_ERROR_TITLE': '错误',
  'TOAST_SUCCESS_TITLE': '成功',
  'TOAST_INFO_TITLE': '信息',
  'TOAST_WARNING_TITLE': '警告',
  'GROUP_ROLES_LOAD_ERROR': '获取当前客户的组角色时发生错误.',
  'LANGUAGES_LOAD_ERROR': '加载可用语言时发生错误.',
  'MY_CLIENTS_LOAD_ERROR': '无法加载访问的客户端列表.',
  'GET_SET_ROW_FAILED_SIZE': '无法加载用户首选项.',
  'TIMEZONES_LOAD_ERROR': '加载可用时区时发生错误.',
  'USER_CONTEXT_IMPERSONATE_ERROR': '尝试模拟时发生错误 {{username}}.',
  'USER_CONTEXT_IMPERSONATE_SUCCESS': '用户模拟成功',
  'USER_CONTEXT_GET_ROLES_ERROR': '无法恢复你的客户角色 "{{clientName}}". 请联系你的管理员.',
  'AUTH_GET_USER_INFO_ERROR': '恢复你的用户信息时发生错误.',
  'ERROR_PAGE_NOT_FOUND': '404',
  'ERROR_PAGE_NOT_FOUND_TITLE': '休斯顿，我们有一个问题.',
  'ERROR_PAGE_NOT_FOUND_PARA': '您要查找的页面不存在.',
  'ERROR_PAGE_RETURN_HOME': '返回主页',
  'ERROR_PAGE_ACCESS_DENIED': '403',
  'ERROR_PAGE_ACCESS_DENIED_TITLE': '休斯顿，你们有一个问题.',
  'ERROR_PAGE_ACCESS_DENIED_PARA': '您无权访问该页面.',

  'ERROR_INVALID_ORDER_TYPE': '鍵入無效的命令：{{0}}',
  'ERROR_FIRST_PROGRAM_NOT_PERMANENT': '第一程序{{0}}是不永久',
  'ERROR_SUBSCRIPTIONS_REMAINS': '一些訂閱是{{0}}',
  'ERROR_PROGRAM_LIST_EMPTY': '空的程序列表{{0}}',
  'ERROR_NAME_EMPTY': '該名稱丟失',
  'ERROR_MUSIC_PUBLICATION_REQUIRED': '一個音樂出版需要',
  'ERROR_NO_SCHEDULE_FOUND': '未找到賽程',
  'ERROR_NO_DATA': '沒有數據',
  'ERROR_PROGRAM_NOT_FOUND': '程序{{0}}未找到',
  'ERROR_SUBSCRIPTION_NOT_ALLOWED': '訂閱{{0}}是不允許的',
  'ERROR_CULTURE_INVALID': '文化是無效的。',
  'ERROR_NO_TEMPLATE_LINKED': '沒有模板的約束。',
  'ERROR_DUPLICATE_DATE_OPENING_HOUR': '日期雙上的開放時間的限制：{{0}}',
  'ERROR_FAILED_REMOVE_BLACKLIST_MEDIA': '用戶“{{0}}”限制解除媒體失敗（ID：{{1}}）',
  'ERROR_FAILED_INSERT_BLACKLIST_MEDIA': '用戶“{{0}}插入限制媒體{{1}}到{{2}}訂閱失敗',
  'ERROR_DUPLICATE_DATE': '該日期已經存在：{{0}}',
  'ERROR_NO_CULTURE_FOUND': '沒有文化的發現{{0}}',
  'ERROR_COHERENCE_CULTURE': '在文化一致性錯誤',
  'ERROR_SCHEDULE_FOUND': '規劃發現{{0}}',
  'ERROR_FAILED_LINK_SUBSCRIPTION_TO_AUDIO_SCHEDULE': '用戶“{{0}}”鏈接到訂閱{{1}}到{{2}}音頻刨失敗',
  'ERROR_INVALID_SUBSCRIPTION': '無效預訂（UUID：{{0}}）',
  'ERROR_INVALID_SHARING_TYPE': '無效共享值：{{0}}',
  'ERROR_MEDIA_NOT_FOUND': '未找到媒體（ID：{{0}}）',
  'ERROR_MEDIA_NOT_VALID': '數字無效',
  'ERROR_INVARIANT_CULTURE_NOT_COMPATIBLE_WITH_OTHERS': '固定區域性不與其他文化兼容',
  'ERROR_DUPLICATE_NAME': '名重複為{{0}}（ID：{{1}}）',
  'ERROR_LINK_TAG_TO_MEDIA': '用戶“{{0}}”鏈接標記{{1}}到{{2}}媒體失敗',
  'ERROR_LINK_CULTURE_TO_MEDIA': '文化鏈接{{0}} {{1}}媒體失敗',
  'ERROR_FAILED_DELETE_MEDIA_RESTRICTION_FOR_SUBSCRIPTIONS': '用戶“{{0}}”去除限制souscriptino {{1}}到{{2}}媒體失敗',
  'ERROR_MEDIA_ALREADY_VALIDATED': '媒體已經驗證',
  'ERROR_MEDIA_NOT_CONVERTED': '數字轉化的',
  'ERROR_NO_TARGET_FOR_FILE_CONTENT': '沒有寫入目的地文件內容',
  'ERROR_FLOATING_REPETITION_LESS_THAN_ONE': '重複浮<1',
  'ERROR_TOO_MANY_MEDIA_JIT_OR_CLOSE': '媒體太{{0}}',
  'ERROR_MISMATCH_MEDIA_ENTRIES': '日曆包含音頻媒體{{0}}，而不是{{1}}',
  'ERROR_MISMATCH_LINK_ENTRIES': '訂閱鏈接包含{{0}}的條目，而不是{{1}}',
  'ERROR_LINK_TAG_TO_AUDIO_SCHEDULE': '用戶“{{0}}”標記{{1}}到{{2}}的音頻節目失敗',
  'ERROR_FILE_NOT_UPLOADED_IN_AZURE_BLOB': '文件沒有上傳到Azure的斑點',
  'ERROR_SECOND_CONNECTION_AZURE_FAILED': '第二次嘗試連接到Azure的斑點失敗',
  'ERROR_FILE_NOT_FOUND': '文件{{0}}未找到',
  'ERROR_INVALID_PARAMETER': '設置無效',
  'ERROR_ADDRESS_NAME_EMPTY': '地址的名稱丟失',
  'ERROR_ADDRESS_NAME_LENGTH_EXCEEDED': '超出地址的字符的最大數量',
  'ERROR_ADDRESS_LINE1_EMPTY': '地址行1失踪',
  'ERROR_ADDRESS_LINE1_LENGTH_EXCEEDED': '當超過第1行地址的最大字符數',
  'ERROR_ADDRESS_LINE2_LENGTH_EXCEEDED': '當超過第2行地址中的最大字符數',
  'ERROR_ADDRESS_LINE3_LENGTH_EXCEEDED': '當超過該地址線上3個字符的最大數目',
  'ERROR_ADDRESS_ZIP_LENGTH_EXCEEDED': '超出字符的郵政編碼的最大數量',
  'ERROR_ADDRESS_CITY_EMPTY': '這個城市缺少',
  'ERROR_ADDRESS_CITY_LENGTH_EXCEEDED': '超出字符為城市的最大數量',
  'ERROR_ADDRESS_COUNTRY_EMPTY': '該國缺少',
  'ERROR_ADDRESS_COUNTRY_LENGTH_EXCEEDED': '超出字符為國家的最大數量',
  'ERROR_INVALID_ID': '無效的ID',
  'ERROR_USER_AD_ACCOUNT_NOT_FOUND': '用戶{{0}}的AD帳戶未找到',
  'ERROR_USER_NOT_FOUND': '{{0}}用戶未找到',
  'ERROR_CLIENT_NOT_ALLOWED': '客戶未授權',
  'ERROR_ITEM_IS_DELETED': '{{0}}被移除',
  'ERROR_ITEM_IS_OFF': '{{0}}關閉',
  'ERROR_ITEM_IS_DEFAULT': '{{0}}是默認',
  'ERROR_SITE_NOT_ALLOWED': '未經授權的網站',
  'ERROR_USER_NOT_ALLOWED_FOR_ROLE': '{{0}}用戶無權{{1}}',
  'ERROR_INVALID_CLIENT': '客戶無效',
  'ERROR_INVALID_NAME': '不正確的名稱',
  'ERROR_USER_NOT_ALLOWED': '未經授權的用戶',
  'ERROR_USER_NOT_ALLOWED_TO_EDIT': '用戶不得改變',
  'ERROR_USER_NOT_ALLOWED_TO_VIEW': '該用戶無權觀看',
  'ERROR_NAME_MISSING': '該名稱丟失',
  'ERROR_CLIENT_NAME_ALREADY_EXIST': '客戶名稱“{{0}}”已存在',
  'ERROR_OFFICE_MISSING': '缺乏局',
  'ERROR_SALES_MISSING': '缺乏銷售',
  'ERROR_LOGIN_LIMIT_IS_REACHED': '達到連接限制',
  'ERROR_INVALID_PARENT': '家長無效',
  'ERROR_PRODUCER_INVALID': '生產者缺失或無效',
  'ERROR_PRODUCTS_MISSING': '未選擇任何產品',
  'ERROR_INVALID_CLIENT_PARAMETER': '不正確的客戶端設置',
  'ERROR_PLAYER_STILL_LINKED': '球員仍然束縛',
  'ERROR_USER_ALREADY_EXIST': '用戶“{{0}}”已存在',
  'ERROR_DEPARTMENT_MISSING': '系失踪',
  'ERROR_EMAIL_PHONE_MOBILE_SKYPE_MISSING': '電子郵件，電話，手機和Skype失踪',
  'ERROR_INVALID_TEMPLATE_NOT_SHARED': '型號無效：單戀',
  'ERROR_INVALID_TEMPLATE': '型號無效',
  'ERROR_GROUP_ALREADY_EXIST': '組{{0}}已存在',
  'ERROR_DEVICE_NOT_WITH_SUBSCRIPTION': '該設備不屬於訂閱',
  'ERROR_SET_PLAYER_DEFAULT_FAILED': '作為默認播放器的更新失敗',
  'ERROR_PLAYER_NOT_FOUND': '播放器{{0}}未找到',
  'ERROR_SITE_NAME_MISSING': '網站名稱缺失',
  'ERROR_SITE_NAME_LENGTH_EXCEEDED': '超出字符的網站名稱的最大數目（{{0}}字符）',
  'ERROR_EXTERNAL_SITE_LENGTH_EXCEEDED': '超出字符外部站點的最大數量（{{0}}字符）',
  'ERROR_DEFAULT_SITE_ADDRESS_MISSING': '默認的站點地址丟失',
  'ERROR_INVALID_USER': '無效的用戶',
  'ERROR_SITE_INVALID': '無效的網站',
  'ERROR_SUBSCRIPTION_INVALID': '認購無效',
  'ERROR_ORDER_ALREADY_OPENED_FOR_SUBSCRIPTION': '訂單已經打開此訂閱{{0}}',
  'ERROR_SUBSCRIPTION_NAME_MISSING': '訂閱名稱丟失',
  'ERROR_SUBSCRIPTION_LENGTH_EXCEEDED': '超出字符對於訂閱的最大數量（{{0}}字符）',
  'ERROR_PRODUCT_INVALID': '該產品是無效',
  'ERROR_STATUS_INVALID': '狀態無效',
  'ERROR_NAME_LENGTH_EXCEEDED': '超出字符的名稱的最大數目（{{0}}字符）',
  'ERROR_COMMENT_EMPTY': '評論是空的',
  'ERROR_INVALID_DEVICE': '無效裝置{{0}}',
  'ERROR_INVALID_DEVICE_MUST_BE_PRODUCT': '無效的設備模型{{0}}  - 必須產生{{1}}',
  'ERROR_INVALID_PLAYER': '玩家無效{{0}}',
  'ERROR_DEVICE_ALREADY_USED': '設備已經使用{{0}}',
  'ERROR_NOT_ACTIVE': '沒有活動',
  'ERROR_AVATAR_NOT_FOUND': '化身未找到用戶{{0}}',
  'ERROR_USER_EMAIL_NOT_FOUND': '{{0}}沒有找到',
  'ERROR_USER_CODE_NOT_FOUND': '代碼未找到',
  'ERROR_INVALID_PASSWORD': '密碼不正確',
  'ERROR_NO_ROLE_FOUND': '無角色FOUND',
  'ERROR_INVALID_GROUP_ROLE': '無效組角色',
  'ERROR_DELETE_HIMSELF': '它是不允許刪除自己',
  'ERROR_INVALID_ADDRESS_TYPE': '無法識別的地址類型：“{{0}}”（已知值：{{1}}）',
  'ERROR_EMAIL_MISSING': '電子郵件丟失',
  'ERROR_PHONE_MISSING': '電話，手機是空的',
  'ERROR_SCREENROTATION_INVALID': '屏幕旋轉無效：{{0}}（已知值：{{1}}）',
  'ERROR_SCHEDULE_NOT_FOUND': '沒有時間導致',

  'AUTH_LOGIN_GENERIC_ERROR': '尝试连接时出现问题. 请联系你的管理员.',
  'AUTH_LOGIN_WRONG_CREDENTIALS': '错误的邮件地址或密码.',
  'AUTH_LOGIN_BLOCKED': '你的账号已被锁定. 请联系你的管理员.',
  'AUTH_LOGIN_LEAKED': '此登录已被阻止,因为您的密码已在其他网站泄露. 请联系您的管理员.',
  'AUTH_LOGIN_SOCIAL_FIRST': '您无法使用未链接到现有本地帐户的社交帐户登录.',
  'AUTH_LOGIN_ACCESS_DENIED': '访问被拒绝. 请联系你的管理员.',
  'AUTH_LOGIN_INVALID_TOKEN': '无效令牌:',
  'AUTH_LOGIN_AUTO_TITLE': '请等待...',
  'AUTH_LOST_PASSWORD_TITLE': '忘记密码 ?',
  'AUTH_LOST_PASSWORD_PARA': '在下方输入你的电子邮件地址以重设密码.',
  'AUTH_LOST_PASSWORD_ERROR': '发送请求时出错.',
  'AUTH_LOST_PASSWORD_SUCCESS': '密码重置链接已发送到您的邮箱.',
  'AUTH_GOTO_LOGIN_PAGE': '点击此处转到登录页面',
  'AUTH_LOST_PASSWORD_EMAIL': '邮箱',
  'AUTH_LOST_PASSWORD_EMAIL_REQUIRED': '电子邮件是必需的.',
  'AUTH_LOST_PASSWORD_EMAIL_INVALID': '电子邮件无效.',
  'AUTH_LOST_PASSWORD_BACK': '返回',
  'AUTH_LOST_PASSWORD_SUBMIT': '提交',
  'AUTH_LOST_PASSWORD_SUBMITTING': '提交中...',
  'PAGE_SIZE_ALL': '所有',
  'SOCIAL_LOGIN_INTRO': '或者登录以',
  'LOGIN_TITLE': '登入',
  'CURRENT_ALERT_MESSAGES_ERROR': '加载当前警报消息时发生错误.',
  'EXTERNAL_APPS': '更多应用程序',
  'SITE_TITLE': 'AD Manager',
  'AUTH_LOGIN_PAGE_TITLE': 'AD Manager | 登录',
  'AUTH_LOST_PASSWORD_PAGE_TITLE': 'AD Manager | 忘记密码',
  'DASHBOARD_PAGE_TITLE': 'AD Manager | 界面',
  'ERROR_ACCESS_DENIED_PAGE_TITLE': 'AD Manager | 访问被拒绝',
  'ERROR_NOT_FOUND_PAGE_TITLE': 'AD Manager | 未找到',
  'MEDIAS_LIST_COLUMN_NAME': '名称',
  'MEDIAS_LIST_COLUMN_DURATION': '持续时间',
  'MEDIAS_LIST_COLUMN_TAG': '标签',
  'MEDIAS_LIST_COLUMN_CREATED': '创建',
  'MEDIAS_LIST_COLUMN_UPDATE': '更新',
  'MEDIAS_LIST_COLUMN_FILENAME': '文件名称',
  'MEDIAS_LIST_TITLE': '多媒体文件',
  'MEDIAS_LIST_CREATE': '创建',
  'MEDIAS_LIST_IMPORT': '导入',
  'MEDIAS_LIST_SELECT_PAGE': '选择页面',
  'MEDIAS_LIST_UNSELECT_PAGE': '取消选择页面',
  'MEDIAS_LIST_ACTIONS': '行动',
  'MEDIAS_LIST_COLUMN_STATUS': '状态',
  'MEDIA_ARCHIVED': '存档',
  'MEDIA_NOT_ARCHIVED': '未存档',
  'MEDIA_SHARING_PRIVATE': '私人',
  'MEDIA_SHARING_PUBLIC': '公开',
  'MEDIA_SHARING_USER_SHARING': '与用户分享',
  'MEDIA_SHARING_ADMIN': '管理',
  'EDIT_MEDIA_NAME': '名称',
  'EDIT_MEDIA_NAME_REQUIRED': '名称是必需的',
  'EDIT_MEDIA_LOCK': '锁定多媒体文件',
  'EDIT_MEDIA_LOCKED': '锁定',
  'EDIT_MEDIA_UNLOCKED': '解锁',
  'EDIT_MEDIA_SHARING': '共享',
  'EDIT_MEDIA_ARCHIVE': '归档',
  'EDIT_MEDIA_DURATION': '持续时间',
  'EDIT_MEDIA_FILENAME': '文件名称',
  'EDIT_MEDIA_COMMENTS': '评论',
  'EDIT_MEDIA_CREATE': '创建',
  'EDIT_MEDIA_CREATEBY': '创建者',
  'EDIT_MEDIA_LANGUAGE_REQUIRED': '语言是必需的',
  'EDIT_MEDIA_LANGUAGE_INVARIANT_AND_OTHER_NOT_PERMITTED': '不允许使用不变和其他文化',
  'EDIT_MEDIA_BY': '通过',
  'EDIT_MEDIA_UPDATED': '更新',
  'EDIT_MEDIA_UPDATEDBY': '更新者',
  'EDIT_MEDIA_TO_TIME_LESS_THAN_FROM': '时间结束需要大于',
  'EDIT_MEDIA_TIME_REQUIRED': '时间是必需的',
  'MEDIAS_LIST_ADD': '添加',
  'ADD_MEDIA_DIALOG_TITLE': '多媒体文件云',
  'ADD_MEDIA_DIALOG_TITLE2': '在此处上传您的文件',
  'ADD_MEDIA_DIALOG_DROPPED_FILES': '丢弃的文件',
  'EDIT_MEDIA_TITLE': '多媒体文件信息',
  'MEDIA_EDIT': '多媒体文件基本信息',
  'MEDIA_EDIT_CAMPAIGN_LINKS': '活动信息',
  'MEDIA_EDIT_BLACKLIST': '列入黑名单的订阅',
  'MEDIA_EDIT_BLACKLIST_BY': '被…列入黑名单',
  'EDIT_MEDIA_TAGS': '标签',
  'SAVE_CHANGES': '保存',
  'MEDIA__DELETE': '删除',
  'CAMPAIGNS_LIST_COLUMN_START': '开始日期',
  'CAMPAIGNS_LIST_COLUMN_END': '创建',
  'CAMPAIGNS_LIST_FILTER_COVERS_STARTDATE': '开始日期',
  'CAMPAIGNS_LIST_FILTER_COVERS_ENDDATE': '结束日期',
  'CAMPAIGNS_LIST_TITLE': '活动',
  'CAMPAIGNS_NEW_LIST_FILTER_STARTDATE': '创建自',
  'CAMPAIGNS_NEW_LIST_FILTER_ENDDATE': '更新为',
  'CAMPAIGNS_TYPE_FLOATING': '指定次数',
  'CAMPAIGNS_TYPE_JUST_IN_TIME': '指定时间',
  'CAMPAIGNS_TYPE_JINGLE': '音乐标识',
  'CAMPAIGNS_TYPE_CLOSE_MESSAGE_OPENING': '开店语音',
  'CAMPAIGNS_TYPE_CLOSE_MESSAGE_CLOSE': '闭店语音',
  'Floating': '指定次数',
  'JustInTime': '指定时间',
  'CloseMessage': '闭店语音',
  'Jingle': '音乐标识',
  'CAMPAIGNS_ORDER_ASCENDING': '递增排序',
  'CAMPAIGNS_ORDER_RANDOM': '随机排序',
  'EDIT_CAMPAIGN_START_DATE': '开始日期',
  'EDIT_CAMPAIGN_END_DATE': '创建到',
  'EDIT_CAMPAIGN_START_TIME': '从',
  'EDIT_CAMPAIGN_END_TIME': '至',
  'EDIT_CAMPAIGN_AT_TIME': '在',
  'EDIT_CAMPAIGN_SINGLE': '单次',
  'EDIT_CAMPAIGN_ALL': '全部',
  'EDIT_CAMPAIGN_MONDAY': '周一',
  'EDIT_CAMPAIGN_TUESDAY': '周二',
  'EDIT_CAMPAIGN_Wednesday': '周三',
  'EDIT_CAMPAIGN_Thursday': '周四',
  'EDIT_CAMPAIGN_Friday': '周五',
  'EDIT_CAMPAIGN_Saturday': '周六',
  'EDIT_CAMPAIGN_Sunday': '周日',
  'MONDAY': '周一',
  'TUESDAY': '周二',
  'WEDNESDAY': '周三',
  'THURSDAY': '周四',
  'FRIDAY': '周五',
  'SATURDAY': '周六',
  'SUNDAY': '周日',
  'EDIT_CAMPAIGN_JINGLE_INTRO_EXTRO': '内 / 外',
  'EDIT_CAMPAIGN_CLOSING_MESSAGE_OPEN_CLOSE': '打开 / 关闭',
  'CREATE_CAMPAIGN_DIALOG': '',
  'ADD_CAMPAIGN_DIALOG_TITLE': '创建一个新的活动',
  'ADD_CAMPAIGN_NAME': '名称',
  'ADD_CAMPAIGN_NAME_REQUIRED': '名称是必需的.',
  'ADD_CAMPAIGN_TYPE': '类型',
  'ADD_CAMPAIGN_TYPE_REQUIRED': '活动类型是必需的.',
  'EDIT_CAMPAIGN_TITLE': '活动',
  'CAMPAIGN_EDIT': '活动编辑',
  'CAMPAIGN_SAVE_SUCCESS': '活動保存成功',
  'EDIT_CAMPAIGN_MEDIAS': '多媒体文件',
  'EDIT_CAMPAIGN_SUBSCRIPTIONS': '订阅',
  'CAMPAIGN_MEDIAS_ASSIGNED': '关联多个多媒体文件',
  'CAMPAIGN_MEDIA_ASSIGNED': '关联单个多媒体文件',
  'CAMPAIGN_MEDIAS_ASSIGNED_TEXT': '从未分配的多媒体文件列表中拖动多媒体以将其分配给该活动.',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE': '关联所有多媒体文件',
  'CAMPAIGN_MEDIAS_ASSIGNED_EMPTY': '该活动无法访问任何多媒体.',
  'CAMPAIGN_MEDIAS_UNASSIGNED_FILTER': '未关联的多媒体文件',
  'CAMPAIGN_MEDIAS_ASSIGNED_FILTER': '关联的多媒体文件',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TEXT': '从指定的多媒体列表中拖动多媒体以从该活动中取消关联.',
  'CAMPAIGN_MEDIA_UNASSIGNED': '未关联的单个多媒体文件',
  'CAMPAIGN_MEDIAS_UNASSIGNED': '未关联的多个多媒体文件',
  'CAMPAIGN_MEDIAS_UNASSIGNED_EMPTY': '该活动关联了所有多媒体文件.',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOGGLE': '取消关联所有多媒体文件',
  'CAMPAIGN_MEDIA_ASSIGNED_TOGGLE': '取消关联单个多媒体文件',
  'CAMPAIGN_SUBSCRIPTION_ASSIGNED': '已关联单个订阅',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED': '已关联多个订阅',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TEXT': '从未关联的订阅列表中拖动订阅以将其分配给该活动.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TOGGLE': '关联所有订阅',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_EMPTY': '该活动无法访问任何订阅.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_FILTER': '未关联的多个订阅',
  'CAMPAIGN_SUBSCRIPTION_UNASSIGNED': '未关联的单个订阅',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED': '未关联的多个订阅',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TOGGLE<': '未关联的所有订阅',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_FILTER': '过滤关联的订阅',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TEXT': '从已关联的订阅列表中拖动订阅以从该广告中取消关联.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_EMPTY': '该活动包含所有订阅.',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TOGGLE': '未关联的所有订阅',
  'EDIT_CAMPAIGN_MODE': '选择模式',
  'MEDIA_IS_NOT_VALIDE': '多媒体文件无效',
  'EDIT_MEDIA_VALIDATEDBY': '经过验证',
  'EDIT_MEDIA_VALIDATED': '验证',
  'AUTH_LOGIN_TITLE': '登录',
  'EDIT_CAMPAIGN_REPETITION': '重复',
  'EDIT_CAMPAIGN_OFFSET': '抵消',
  'EDIT_CAMPAIGN_HOUR': '小时',
  'EDIT_CAMPAIGN_HOURS': '小时',
  'EDIT_CAMPAIGN_OPEN_CLOSE': '打开/关闭',
  'AUTH_LOGIN_ERROR_TITLE': '发生错误',
  'AUTH_LOGIN_ERROR_PARA': '登录时发生以下错误',
  'AUTH_LOGIN_GO_BACK_BUTTON': '返回登录页面',
  'MEDIAS_LIST_FILTER': '筛选',
  'MEDIAS_LIST_QUICK_SEARCH': '按多媒体文件名称搜索',
  'MEDIAS_LIST_FILTER_NAME': '名称',
  'MEDIAS_LIST_FILTER_FILENAME': '文件名称',
  'MEDIAS_LIST_FILTER_TAGS': '标签',
  'MEDIAS_LIST_FILTER_SHARING': '已共享',
  'MEDIAS_LIST_FILTER_ARCHIVED': '已存档',
  'MEDIAS_LIST_FILTER_VALIDATED': '已验证',
  'MEDIAS_LIST_FILTER_STARTDATE': '创建自',
  'MEDIAS_LIST_FILTER_ENDDATE': '创建至',
  'MEDIAS_LIST_FILTER_SEARCH': '搜索',
  'MEDIAS_LIST_FILTER_RESET': '重置',
  'MEDIA_REMOVE_FROM_LIST': '从列表中删除',
  'ADD_MEDIA_TOO_SMALL': '太小',
  'ADD_MEDIA_TOO_BIG': '太大',
  'CAMPAIGNS_LIST_FILTER': '筛选',
  'CAMPAIGNS_LIST_QUICK_SEARCH': '通过活动名称搜索',
  'CAMPAIGNS_LIST_FILTER_TYPE': '类型',
  'MEDIAS_LIST_FILTER_ISONLINE': '在线',
  'CAMPAIGNS_LIST_FILTER_STARTDATE': '开始日期创建',
  'CAMPAIGNS_LIST_FILTER_ENDDATE': '结束日期创建',
  'CAMPAIGNS_LIST_FILTER_COVER_STARTDATE': '开始日期活动',
  'CAMPAIGNS_LIST_FILTER_COVER_ENDDATE': '结束日期活动',
  'CAMPAIGN_ADVENCE_FILTER_MEDIAS_DIALOG_TITLE': '多媒体文件高级搜索',
  'CAMPAIGN_MEDIAS_FILTER_TAG': '标签',
  'CAMPAIGN_MEDIAS_FILTER_CULTURES_REQUIRED': '文化是必需的.',
  'CAMPAIGN_MEDIAS_FILTER_SEARCH': '搜索',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOTAL': '合计',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOTAL': '合计',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE_FILTER': '关联过滤多媒体',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ADDRESS': '地址',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_CITY': '城市',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_COUNTRY': '国家',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ZIP': '邮编',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_SITE_NUM': '外部ID',
  'CAMPAIGN_ADVANCE_FILTER_SUBSCRIPTIONS_DIALOG_TITLE': '订阅高级搜索',
  'EDIT_CAMPAIGN_ARCHIVE': '已存档',
  'MEDIAS_LIST_DELETE': '删除',
  'MEDIAS_LIST_ARCHIVE': '存档',
  'CONFIRM_LOCK_BLACKLIST_MEDIAS_DIALOG': '锁定多媒体文件黑名单',
  'CONFIRM_LOCK_BLACKLIST_MEDIAS_DIALOG_TITLE': '是',
  'REVOKE_LOCK_BLACKLIST_MEDIAS_DIALOG_TITLE': '否',
  'CONFIRM_DELETE_MEDIAS_DIALOG': '删除确认',
  'CONFIRM_DELETE_MEDIAS_DIALOG_TITLE': '删除确认',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT': '删除所有',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT_ONLY': '仅删除未计划多媒体文件',
  'CONFIRM_DELETE_MEDIAS_DIALOG_SINGLE': '你确定要删除此多媒体文件吗',
  'CONFIRM_DELETE_MEDIAS_DIALOG_MULTI': '你确定要删除这些多媒体文件吗',
  'CONFIRM_DELETE_CAMPAIGN_DIALOG_SINGLE': '你确定要删除次活动吗',
  'CONFIRM_DELETE_CAMPAIGN_DIALOG_MULTI': '你确定要删除这些活动吗',
  'CONFIRM_DELETE_MEDIAS_USED_MEDIAS_TITLE': '这些多媒体文件被用于以下活动',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG': '存档确认',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE1': '你想要',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE2': '这',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE3': '选定的多媒体文件',
  'CONFIRM_ARCHIVE_CAMPAIGN_DIALOG_MESSAGE3': '选定的活动',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_ACCEPT': '档案',
  'EDIT_CAMPAIGN_INFO': '活动信息',
  'CAMPAIGN_UPDATED': '更新',
  'CAMPAIGN_UPDATED_BY': '更新者',
  'CAMPAIGN_CREATED': '创建',
  'CAMPAIGN_CREATED_BY': '创建者',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_UNACCEPT': '取消存档',
  'DUPLICATION_CAMPAIGN_TITLE': '复制活动',
  'DUPLICATION_CAMPAIGN_LANG': '复制语言',
  'DUPLICATION_CAMPAIGN_SITES': '复制订阅',
  'DUPLICATION_CAMPAIGN_MEDIAS': '复制多媒体文件',
  'DUPLICATION_CAMPAIGN_DATA': '复制活动标签',
  'DUPLICATION_BTN_SUBMIT': '复制',
  'DUPLICATION_BTN': '复制',
  'TEMPLATES_PROGRAMS': '模板和频道.',
  'TEMPLATES_PROGRAMS_LOAD_ERROR': '加载模板和频道时发生错误.',
  'TEMPLATES_PROGRAMS_TITLE': '模板和频道',
  'TEMPLATES_PROGRAMS_TEMPLATES_TITLE': '模板',
  'TEMPLATES_PROGRAMS_PROGRAMS_TITLE': '频道',
  'TEMPLATES_PROGRAMS_EDIT_BUTTON': '编辑',
  'TEMPLATES_PROGRAMS_DELETE_TEMPLATE_BUTTON': '删除',
  'TEMPLATES_PROGRAMS_DELETE_PROGRAM_BUTTON': '删除',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_TITLE': '频道详情',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME': '名称',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_IS24H': '是24H频道吗?',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM': '24h频道',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE': '开始日期',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE': '创建到',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE2': '结束日期',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_UPDATE_BUTTON': '更新',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_CANCEL_BUTTON': '取消',
  'TEMPLATES_PROGRAMS_SAVE_BUTTON': '保存',
  'TEMPLATES_PROGRAMS_SAVING_BUTTON': '保存中...',
  'TEMPLATES_PROGRAMS_CANCEL_BUTTON': '取消',
  'DATETIME_PICKER_TODAY': '跳转至今天',
  'DATETIME_PICKER_CLEAR': '清空选择',
  'DATETIME_PICKER_CLOSE': '关闭选择器',
  'DATETIME_PICKER_SELECT_MONTH': '选择月份',
  'DATETIME_PICKER_PREV_MONTH': '前一个月',
  'DATETIME_PICKER_NEXT_MONTH': '下一个月',
  'DATETIME_PICKER_SELECT_YEAR': '选择年份r',
  'DATETIME_PICKER_PREV_YEAR': '前一年',
  'DATETIME_PICKER_NEXT_YEAR': '下一年',
  'DATETIME_PICKER_SELECT_DECADE': '选择十年',
  'DATETIME_PICKER_PREV_DECADE': '上一个十年',
  'DATETIME_PICKER_NEXT_DECADE': '下一个十年',
  'DATETIME_PICKER_PREV_CENTURY': '上个世纪',
  'DATETIME_PICKER_NEXT_CENTURY': '下个世纪',
  'DATETIME_PICKER_INCREMENT_HOUR': '增加小时',
  'DATETIME_PICKER_PICK_HOUR': '选择小时',
  'DATETIME_PICKER_DECREMENT_HOUR': '减少小时',
  'DATETIME_PICKER_INCREMENT_MINUTE': '增加分钟',
  'DATETIME_PICKER_PICK_MINUTE': '选择分钟',
  'DATETIME_PICKER_DECREMENT_MINUTE': '减少分钟',
  'DATETIME_PICKER_INCREMENT_SECOND': '增加秒',
  'DATETIME_PICKER_PICK_SECOND': '选择秒',
  'DATETIME_PICKER_DECREMENT_SECOND': '减少秒',
  'DATE_TIME_PLACEHOLDER': 'DD/MM/YYYY HH:mm',
  'DATE_PLACEHOLDER': 'DD/MM/YYYY',
  'TIME_PLACEHOLDER': 'HH:mm',
  'TEMPLATES_PROGRAMS_TEMPLATE_DIALOG_TITLE': '模板详情',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME': '名称',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_CANCEL_BUTTON': '取消',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_SAVE_BUTTON': '保存',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_REQUIRED': '模板名称是必需的.',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_MAX_LENGTH': '模板名称不能超过50个字符.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_REQUIRED': '频道名称是必需的.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_MAX_LENGTH': '频道名称不能超过50个字符.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM_REQUIRED': '24H频道是必需的.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_REQUIRED': '开始日期是必需的.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_MOMENT': '开始日期必需是有效日期 (格式为 DD/MM/YYYY).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_REQUIRED': '结束日期是必需的.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_MOMENT': '结束日期必需是有效日期 (格式为 DD/MM/YYYY).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_AFTER': '结束日期必须在开始日期之后.',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_DIALOG_TITLE': '添加频道',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME_REQUIRED': '频道是必需的.',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_SAVE_BUTTON': '保存',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_CANCEL_BUTTON': '取消',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_TITLE': '确认删除模板',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_TITLE': '确认删除频道',
  'TEMPLATES_PROGRAMS_CANCEL_DELETE_TEMPLATE_BUTTON': '不，不要删除它',
  'TEMPLATES_PROGRAMS_CANCEL_UNASSIGN_PROGRAM_BUTTON': '不，不要删除它',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_BUTTON': '是的，删除它',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_BUTTON': '是的，删除它',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_MESSAGE': '你确定要删除模板 "{{name}}"?',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_MESSAGE': '你确定要取消关联该频道 "{{programName}}" 从模板 "{{templateName}}"?',
  'PROGRAMS_LOAD_ERROR': '加载频道时发生服务器错误.',
  'PROGRAMS_24H_LOAD_ERROR': '加载24H频道时发生服务器错误.',
  'TEMPLATES_PROGRAMS_SAVE_SUCCESS': '模板和频道已成功保存.',
  'TEMPLATES_PROGRAMS_SAVE_VALIDATION': '保存模板和频道时发生验证错误.',
  'TEMPLATES_PROGRAMS_SAVE_FAIL': '保存模板和程序时发生服务器错误.',
  'DELETE_CONFIRMATION_MEDIAS': '你确定要删除此多媒体',
  'DELETE_CONFIRMATION_CAMPAIGNS': '你确定要删除此活动 ?',
  'SUBSCRIPTION_LIST_TITLE': '站点',
  'COMMANDS_FILTER': '筛选',
  'PUBLICATION_LIST_TITLE': '发布',
  'PUBLICATIONS_LIST_COLUMN_NAME': '名称',
  'PUBLICATIONS_LIST_COLUMN_TYPE': '类型',
  'PUBLICATIONS_LIST_COLUMN_START_DATE': '创建自',
  'PUBLICATIONS_LIST_COLUMN_END_DATE': '创建至',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_CD': 'CD',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_SD': 'SD',
  'PUBLICATIONS_LIST_COLUMN_SUBSCRIPTIONS_AFFECTED': '受影响订阅',
  'PUBLICATIONS_LIST_COLUMN__SUBSCRIPTIONS_FAILED': '订阅失败',
  'PUBLICATIONS_LIST_COLUMN__CREATED': '创建',
  'PUBLICATIONS_LIST_COLUMN_CREATEDBY': '创建者',
  'PUBLICATIONS_LIST_COLUMN_STATUS': '状态',
  'No': '否',
  'Yes': '是',
  'FILTER_RESET': '重置',
  'CITY': '城市',
  'COUNTRY': '国家',
  'UNSAVED_CHANGES': '您有未保存的更改.  您确定要离开此页面吗 ?',
  'SUBSCRIPTION_DETAILS_INFO_PAGE_TITLE': '订阅详细信息页面',
  'PUBLICATIONS_LIST_QUICK_SEARCH': '创建自',
  'PUBLICATION_MUSIC': '音乐',
  'PUBLICATION_PUB': '广告',
  'SUBSCRIPTION_HOURS': '订阅时间',
  'CONFIRM_PUBLISH_PROGRAM_DIALOG_TITLE': '频道发布确认直到',
  'CONFIRM_PUBLISH_PUBLISH_DIALOG_TITLE': '广告发布确认',
  'CONFIRM_PUBLISH_PROGRAM_PUBLISH_DIALOG_TITLE': '频道和广告发布确认直到',
  'CONFIRM_PUBLISH_DIALOG_ACCEPT': '发布直到',
  'CONFIRM_PUBLISH_DIALOG_ACCEPT1': '发布',
  'PUBLICATION_DETAILS_INFO': '信息',
  'PUBLICATION_DETAILS_LIST': '详情',
  'PUBLICATIONS_GENERAL_INFO': '基本信息',
  'PUBLICATION_NAME': '名称',
  'PUBLICATIONS_TYPE': '类型',
  'PUBLICATIONS_TYPE_MUSIC': '音乐',
  'PUBLICATIONS_TYPE_COMMERCIAL': '广告',
  'PUBLICATION_START_DATE': '创建自',
  'PUBLICATION_END_DATE': '创建至',
  'PUBLICATION_NUMBER_OF_CD': 'cd号码',
  'PUBLICATION_NUMBER_OF_SD': 'sd号码',
  'PUBLICATIONS_STATUS': '状态',
  'PROGRESS': '进行中',
  'READY': '准备',
  'FAILED': '失败',
  'PUBLICATION_NUMBER_OF_SUBSCRIPTION': '受影响订阅',
  'PUBLICATION_NUMBER_OF_FAILED_PUBLICATION': '发布失败',
  'PUBLICATION_CREATEDBY': '创建者',
  'PUBLICATION_SUBCRIPTION_NAME': '订阅名称',
  'PUBLICATION_CITY': '城市',
  'PUBLICATION_COUNTRY': '国家',
  'PUBLICATION_EXT_ID': '外部ID',
  'PUBLICATIONS_LIST_FILTERD': '通过订阅名称搜索',
  'PUBLICATION_STATUS': '发布状态',
  'STATUS': '状态',
  'TRANSFERT': '转移类型',
  'PUBLICATION_FILTER_SUBSCRIPTION_NAME': '订阅名称',
  'PULICATION_FILTER_SUBSCRIPTION_EXT_ID': '外部ID',
  'PUBLICATIONS_LIST_FILTER_START_DATE': '创建自',
  'PUBLICATIONS_LIST_FILTER_END_DATE': '创建至',
  'PUBLICATION_FILTER_SUBSCRIPTION_CITY': '城市',
  'PUBLICATION_FILTER_SUBSCRIPTION_COUNTRY': '国家',
  'PUBLICATION_FILTER_SUBSCRIPTION_PRODUCT': '产品',
  'PUBLICATION_CANCEL_CD_SD': '取消 SD/CD',
  'PUBLICATION_CD': 'CD',
  'PUBLICATION_SD': 'SD',
  'PUBLICATION_CANCEL_SD': '取消 SD',
  'PUBLICATION_CANCEL_CD': '取消 CD',
  'PUBLICATION_CANCEL_MORE_INFO': '更多信息',
  'PUBLICATION_MORE_INFO': '活动发布详情',
  'AUDIO_SCHEDULED_MESSAGES_LOAD_ERROR': '加载音频计划语音时发生错误.',
  'AUDIO_SCHEDULED_MESSAGES_FILTER': '筛选',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY': '日',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_SEARCH': '搜索',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_RESET': '重置',
  'AUDIO_SCHEDULED_MESSAGES_ANNOUNCEMENT': '类型',
  'AUDIO_SCHEDULED_MESSAGES_CAMPAIGN': '活动',
  'AUDIO_SCHEDULED_MESSAGE_START_DATE': '从 (date)',
  'AUDIO_SCHEDULED_MESSAGE_END_DATE': '至 (date)',
  'AUDIO_SCHEDULED_MESSAGE_START_TIME': '从 (time)',
  'AUDIO_SCHEDULED_MESSAGE_END_TIME': '至 (time)',
  'AUDIO_SCHEDULED_MESSAGE_MONDAY': 'M',
  'AUDIO_SCHEDULED_MESSAGE_TUESDAY': 'T',
  'AUDIO_SCHEDULED_MESSAGE_WEDNESDAY': 'W',
  'AUDIO_SCHEDULED_MESSAGE_THURSDAY': 'T',
  'AUDIO_SCHEDULED_MESSAGE_FRIDAY': 'F',
  'AUDIO_SCHEDULED_MESSAGE_SATURDAY': 'S',
  'AUDIO_SCHEDULED_MESSAGE_SUNDAY': 'S',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_NAME': '名称',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_DURATION': '持续时间',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_PLAY': '播放',
  'PLAYER_AUDIO_SCHEDULED_MESSAGES_TITLE': '计划的语音',
  'CANCEL_CONFIRMATION': '你确定要取消 ?',
  'CANCEL_CONFIRMATION_BUTTON': '不,不要取消',
  'CANCEL_CANCEL_CONFIRMATION_BUTTON': '是的，取消它',
  'TOPUBLISH': '需要发布',
  'PUBLISHED': '已发布',
  'OPENING_HOURS': '营业时间',
  'SUBSCRIPTION_EDIT': '编辑',
  'SUBSCRIPTIONS_LIST_TAG': '标签',
  'CULTURES': '语言',
  'SUBSCRIPTIONS_LIST_OPENING_HOURS': '营业时间',
  'SUBSCRIPTIONS_LIST_PROGRAM': '频道',
  'TAGS_SUBSCRIPTION_DIALOG_TITLE': '添加标签到你选择的订阅',
  'DELETE_TAGS_SUBSCRIPTION_DIALOG_TITLE': '删除所选订阅的标签',
  'TAGS_MEDIA_DIALOG_TITLE': '为你选择的多媒体文件添加标签',
  'DELETE_TAGS_MEDIA_DIALOG_TITLE': '从所选多媒体文件中删除要删除的标签',
  'TAGS_CAMPAIGN_DIALOG_TITLE': '为你选择的活动添加标签',
  'DELETE_TAGS_CAMPAIGN_DIALOG_TITLE': '删除所选活动的标签',
  'CULTURES_MEDIA_DIALOG_TITLE': '为你选择的多媒体文件添加语言',
  'OPENING_HOURS_SUBSCRIPTION_DIALOG_TITLE': '为你选择的订阅设置营业时间',
  'PROGRAM_CHANGE_SUBSCRIPTION_DIALOG_TITLE': '关联你选择的订阅到模板&频道',
  'PROGRAMS': '模板&频道',
  'APPLY': '应用',
  'PLAYER_NAME': '播放器名称',
  'LAST_MODIFICATION': '上次修改',
  'MODEL': '型号',
  'NO_PROGRAM': '没有选择频道',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME': '频道',
  'CAMPAIGNS_LIST_PAGE_TITLE': 'AD Manager | 活动',
  'CAMPAIGNS_DETAILS_INFO_PAGE_TITLE': 'AD Manager  | 活动 | 详情',
  'MEDIAS_LIST_PAGE_TITLE': 'AD Manager  | 多媒体文件',
  'MEDIAS_DETAILS_INFO_PAGE_TITLE': 'AD Manager  | 多媒体文件 | 详情',
  'PUBLICATIONS_LIST_PAGE_TITLE': 'AD Manager  | 发布',
  'PUBLICATION_DETAILS_INFO_PAGE_TITLE': 'AD Manager | 发布 | 详情',
  'SUBSCRIPTIONS_LIST_PAGE_TITLE': 'AD Manager  | 订阅',
  'SUBSCRIPTIONS_DETAILS_INFO_PAGE_TITLE': 'AD anager  | 订阅| 详情',
  'TEMPLATES_PROGRAMS_INFO_PAGE_TITLE': 'AD Manager  | 模板&频道',
  'MEDIAS_LIST_VALIDATE': '验证多媒体文件',
  'MEDIA_CONVERSION_STATUS': '转换中',
  'MEDIA_CONVERSION_FAILED': '转换失败',
  'NOT_FOUND': '未找到',
  'TAGS_NOT_SELECTED': '没有选择标签',
  'CULTURES_NOT_SELECTED': '没有选择语言',
  'PENDING_PUBLICATIONS': '待定的发布',
  'PUBLICATION_SIGNALR_TITLE\'': '发布',
  'NEW_MEDIA_IS_AVAILABLE': '有新多媒体文件可供选择',
  'NEW_MEDIA_IS_AVAILABLE_TITLE': '多媒体文件信息',
  'PUBLICATION_COMPLETED': '发布完成',
  'PUBLICATION_FAILED': '发布失败',
  'PUBLICATION_DELIVERY_FAILED': '发布交付失败',
  'ALL_PROGRAMS': '所有频道',
  'CONFIRM_PUBLISH_CD': 'CD',
  'CONFIRM_PUBLISH_SD': 'SD',
  'SUBSCRIPTION_LIST_QUICK_SEARCH': '按订阅名称搜索',
  'DELETE_USED_TEMPLATES': '你只能删除未被使用的模板',
  'SUBSCRIPTION_SAVE_SUCCESS': '订阅已成功保存.',
  'SUBSCRIPTION_SAVE_VALIDATION_ERROR': '日期 或者/和 时间 错误',
  'SUBSCRIPTION_NOT_FOUND': '保存订阅时发生服务器错误.',
  'CHANGE_PROGRAM_TEMPLATE_SUCCESS': '添加新模板&频道到你选择的订阅',
  'CHANGE_PROGRAM_TEMPLATE_ERROR': '模板&频道链接错误',
  'MEDIAS_VALIDATE_SUCCESS': '多媒体文件已经成功验证.',
  'MEDIAS_VALIDATE_FAIL': '保存多媒体文件时发生验证错误.',
  'SAVE_ONLY_FULL_TEMPLATES': '仅保存带有频道的模板',
  'MEDIAS_DELETE_SUCCESS': '多媒体文件已被成功删除.',
  'MEDIAS_DELETE_ERROR': '删除媒体文件时发生错误.',
  'ADD_TAGS_SUCCESS': '添加标签到您选择的多媒体文件',
  'ADD_TAGS_ERROR': '添加标签时发生错误.',
  'ADD_OPENING_HOURS_ERROR': '更新营业时间时发生错误.',
  'MEDIAS_ARCHIVE_SUCCESS': '多媒体文件已成功存档.',
  'ADD_OPENING_HOURS_SUCCESS': '营业时间更新成功.',
  'MEDIAS_ARCHIVE_ERROR': '存档多媒体文件时发生错误.',
  'CAMPAIGN_REMOVE_SUCCESS': '活动已成功删除.',
  'CAMPAIGN_REMOVE_SELF_ERROR': '删除活动时发生错误.',
  'CAMPAIGN_REMOVE_ERROR': '删除活动时发生错误.',
  'ADD_TAGS_CAMPAIGN_SUCCESS': '为所选活动添加标签',
  'ADD_TAGS_CAMPAIGN_ERROR': '添加标签时发生错误.',
  'DELETE_TAGS_SUCCESS': '删除所选活动的标签',
  'DELETE_TAGS_ERROR': '删除标签时发生错误.',
  'CAMPAIGNS_ARCHIVE_SUCCESS': '该活动已成功存档.',
  'CAMPAIGNS_ARCHIVE_ERROR': '存档标签时发生错误.',
  'ADD_TAGS_SUBSCRIPTION_SUCCESS': '为你选择的订阅添加标签',
  'ADD_TAGS_SUBSCRIPTION_ERROR': '添加标签时发生错误.',
  'MEDIAS_LIST_FILTER_ONLINE': '在线',
  'CONTENT': '内容',
  'CAMPAIGN_LIST': '活动列表',
  'PUBLICATION_LIST': '发布列表',
  'EDIT_CAMPAIGN_PUBLICATION_INFO': '发布信息',
  'EXCEPTION_SUBSCRIPTION_DIALOG_TITLE': '例外营业时间管理',
  'ADD_EXCEPTION_GENERATE_SUCCESS': '为你选择的订阅添加营业时间',
  'ADD_EXCEPTION_GENERATE_ERROR': '添加营业时间时发生错误',
  'SAVE_DUPLICATED_ERROR': '存在重复的名称',
  'PLACING': '位置',
  'MEDIA_TO_VALIDATE': '单个需验证多媒体文件',
  'MEDIAS_TO_VALIDATE': '个媒体文件待确定',
  'GO_TO_VALIDATE_MEDIA': '已验证多媒体文件',
  'GO_TO_PUBLICATION': '发布',
  'GO_TO_CAMPAIGN': '活动',
  'GO_TO_SUBSCRIPTION': '订阅',
  'LAST_CAMPAIGN': '上个无效活动',
  'LAST_CAMPAIGNS': '之前无效活动',
  'LAST_PUBLICATION': '上次发布 ',
  'LAST_PUBLICATIONS': '之前发布 ',
  'FAILED_PUBLICATION': '失败的发布',
  'FAILED_PUBLICATIONS': '失败的发布',
  'SITE_WITH_NO_TEMPLATE': '个站点未关联模板',
  'ACTIVITY_FEED': '活动资源',
  'DELETED': '(deleted)',
  'ENDED': '结束',
  'DASHBOARD_ACTIVITY_FEED_TITLE': '近期活动',
  'PROGRAM_NO_PLANNING': '频道播放计划已结束.',
  'DASHBOARD_ACTIVITY_FEED_ERROR': '加载活动资源时发生错误.',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULE': '{{userName}} 创建 <strong>时间表</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULE': '{{userName}} 更新 <strong>时间表</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULE': '{{userName}} 删除 <strong>时间表</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULES': '{{userName}} 创建 <strong>时间表</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULES': '{{userName}} 更新 <strong>时间表</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULES': '{{userName}} 删除 <strong>时间表</strong>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTION': '{{userName}} 创建 <strong>订阅</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTION': '{{userName}} 更新 <strong>订阅</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTION': '{{userName}} 删除 <strong>订阅</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTIONS': '{{userName}} 创建 <strong>订阅</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTIONS': '{{userName}} 更新 <strong>订阅</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTIONS': '{{userName}} 删除 <strong>订阅</strong>',
  'DASHBOARD_ACTIVITY_FEED_PUBLISH_SUBSCRIPTIONS': '出版物已由 <strong> {{userName}} </strong> ',
  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIA': '{{userName}} 创建 <strong>多媒体文件</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIA': '{{userName}} 更新 <strong>多媒体文件</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIA': '{{userName}} 删除 <strong>多媒体文件</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIA': '{{userName}} 验证 <strong>多媒体文件</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIAS': '{{userName}} 创建 <strong>多媒体文件</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIAS': '{{userName}} 更新 <strong>多媒体文件</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIAS': '{{userName}} 删除 <strong>多媒体文件</strong>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIAS': '{{userName}} 验证 <strong>多媒体文件</strong>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATE': '{{userName}} 创建 <strong>频道 模板</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATE': '{{userName}} 更新 <strong>频道 模板</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATE': '{{userName}} 删除 <strong>频道 模板</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATES': '{{userName}} 创建 <strong>频道 模板</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATES': '{{userName}} 更新 <strong>频道 模板</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATES': '{{userName}} 删除 <strong>频道 模板</strong>',
  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTION': '{{userName}} 链接 <strong>频道至订阅</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTIONS': '{{userName}} 链接 <strong>频道至订阅</strong>',
  'DASHBOARD_ACTIVITY_FEED_NO_DATA': '这里没有数据显示.',
  'MEDIAS_LIST_FILTER_UPDATE': '日期更新',
  'CORPORATE_LIST': '企业列表',
  'MEDIAS_LIST_COLUMN_DIFFUSION': '传播',
  'LOCAL_CAMPAIGN_EDIT': '编辑',
  'LOCAL_CAMPAIGN_REMOVE': '移除',
  'LOCAL_CAMPAIGN_LIST': '本地活动',
  'ADD_MEDIA_IN_YOUR_LOCAL_CAMPAIGN': '添加多媒体文件',
  'MEDIA_LOCK_STATUS': '此多媒体文件被锁定',
  'VALIDATION_LIST_COLUMN_NAME': '  名称语言标签 ',
  'MEDIAS_LIST_LOCK_STATUS': '传播锁定',
  'MEDIAS_LIST_BLACKLIST_STATUS': '黑名单包含一些订阅',
  'MY_PROFILE': '我的个人资料',
  'CONFIRMATION': '确认',
  'END_PUBLICATION': '结束计划',
  'PUBLICATION_PROGRAM_WARNING': '警告',
  'PUBLICATION_PROGRAM_READY': '准备',
  'PUBLICATION_PROGRAM_ERROR': '出错',
  'PUBLICATION_COMPLETED_WITH_WARNING': '发布完成',
  'UPLOAD_TITLE': '拖动文件到此处上传',
  'PUBLICATION_STARTED': '开始发布',
  'DEFAULT_TITLE': 'AD Manager',
  'PUBLICATION_SIGNALR_TITLE': '发布',
  'PRIVACY_POLICY': '隐私政策',
  'TERMS_OF_USE': '使用条款',
  'IMPORT_FROM': '导入自',
  'IMPORT_CAMPAIGNS_FROM': '将活动导入你选择的订阅',
  'CLONE_FROM': '克隆自',
  'STEP': '步',
  'NEXT': '下一页',
  'COPY': '导入',
  'PREVIOUS': '上一页',
  'FIRST': '第一页',
  'LAST': '最后页',
  'CLONED_DATA_RESUME': '克隆的数据恢复',
  'IMPORT_CAMPAIGNS_FROM_ERROR': '导入活动引起错误.',
  'SELECTED_SUBSCRIPTIONS_TO_CLONE': '选择的订阅.',
  'SELECTED_CAMPAIGNS_TO_CLONE': '克隆选择活动',
  'CAMPAIGNS_INVALID': '无效活动',
  'NO_PLAYER': '无播放器',
  'ONLINE_PLAYER': '在线',
  'OFFLINE_PLAYER': '离线',
  'PUBLICATION_COUNT_MESSAGE': '你有',
  'PUBLICATION_COUNT_MESSAGE_ONE': '有待发布',
  'SPLIT_BY_CULTURES': '按语言分割',
  'PUBLICATION_ALL': '发布所有',
  'ACTION_LIST_TAG_DELETE': '删除标签',
  'LAST_PUBLISHED_PLANNING': '上传已更新计划',
  'PUBLICATION_CULTURES_ERROR': '语言错误',
  'PUBLICATION_COUNT_ERROR': '计数警告',
  'CURRENT_CULTURES': '现有语言',
  'EXPECTED_CULTURES': '预期的语言',
  'VALIDATE_ALL': '验证所有',
  'TEMPLATES_AND_PROGRAMS': '模板和频道',
  'NO_DATA_SELECTED': '未选择数据',
  'SOME_COMMENTS': '一些评论',
  'CAMPAIGN_MEDIA_COUNT': '活动多媒体文件统计',
  'SAVE': '保存',
  'EXTRA_PLAY': '玩',
  'CHANGE_SUBSCRIPTION': '更改订阅',
  'FILTER_BY_NAME': '按名称过滤',
  'END_DATE': '结束日期',
  'MINUTES': '分钟',
  'NO_LANGUAGE_SELECTED': '未选择语言',
  'NO_TAGS_SELECTED': '未选择标签',
  'NO_CULTURES_SELECTED': '未选择语言',
  'FIVE': '五',
  'TEN': '十',
  'TWENTY': '二十',
  'FIFTY': '五十',
  'HUNDRED': '百',
  'ON_LINE': '线上',
  'DATE_VALIDITY': '日期有效性',
  'ADD_MEDIA_SUCCESS': '添加多媒体文件成功',
  'EMPTY_TEXT': '这里没有要显示的数据',
  'MEDIA_SAVE_SUCCESS': '多媒体文件保存成功',
  'FIRMWARE': '固件',
  'ARCHIVE_RELATED_MEDIA': '存档相关媒体',
  'TEMPLATE_PROGRAM_DUPLICATE_NAME': '名稱已存在',
  'SUBSCRIPTION_SAVE_ERROR': '订阅保存错误',
  'MEDIA_UPLOAD_INFO_TITLE': '信息',
  'MEDIA_UPLOAD_INFO_FILES_SUPPORTED': '支持文件 :',
  'MEDIA_UPLOAD_INFO_FILES_MAX_SIZE': '文件最大大小 : ',
  'MEDIA_UPLOAD_INFO_FILES_MAX_UPLOAD': '最多上传文件 :',
  'MEDIAS_LIST_FILTER_EXPIRED': '已到期',
  'MUSIC_MANAGER_LINK': '音乐管理器链接',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION': '强制更新',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION_LISA_PLAYERS': '丽莎球员',
  'PUBLICATION_DIALOG_TOTAL_PLAYERS': '总玩家数: ',
  'PUBLICATION_DIALOG_ONLINE_PLAYERS': '在线玩家数: ',
  'PUBLICATION_DIALOG_OFFLINE_PLAYERS': '离线玩家数: '
}

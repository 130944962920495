import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

const availableLocales = moment.locales();

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date|moment.Moment, defaultLocale: string, omitSuffix?: boolean): string {
    const localeMoment = moment(value);
    if (_.indexOf(availableLocales, defaultLocale) >= 0) {
      localeMoment.locale(defaultLocale);
    }

    return localeMoment.fromNow(omitSuffix);
  }
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {Media} from '../../shared/models/media';

export const MediaDetailsActionType = {
  LOAD: type('[Media] Load'),
  LOAD_SUCCESS: type('[Media] Load Success'),
  LOAD_FAIL: type('[Media] Load Server Error')
};

export class LoadMediaDetailsAction implements Action {
  readonly type = MediaDetailsActionType.LOAD;

  constructor(public payload?: string) {}
}

export class LoadMediaDetailsSuccessAction implements Action {
  readonly type = MediaDetailsActionType.LOAD_SUCCESS;

  constructor(public payload?: Media) {}
}

export class LoadMediaDetailsFailAction implements Action {
  readonly type = MediaDetailsActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import {Subscription} from 'rxjs';

import {type} from '../../shared';
import {Campaign} from '../../shared/models/campaign';
import {Media} from '../../shared/models/media';

export const CampaignDetailsActionType = {
  LOAD: type('[Campaign] Load'),
  LOAD_SUCCESS: type('[Campaign] Load Success'),
  LOAD_FAIL: type('[Campaign] Load Server Error'),
  ADD_MEDIA: type('[Campaign] Add media'),
  ADD_MEDIA_SUCCESS: type('[Campaign] Add media SUCCESS'),
  ADD_MEDIA_FAIL: type('[Campaign] Add media FAIL'),
  REMOVE_MEDIA: type('[Campaign] Remove media'),
  REMOVE_MEDIA_SUCCESS: type('[Campaign] Remove media SUCCESS'),
  REMOVE_MEDIA_FAIL: type('[Campaign] Remove media FAIL'),
  ADD_SUBSCRIPTION: type('[Campaign] Add subscription'),
  ADD_SUBSCRIPTION_SUCCESS: type('[Campaign] Add subscription SUCCESS'),
  ADD_SUBSCRIPTION_FAIL: type('[Campaign] Add subscription FAIL'),
  REMOVE_SUBSCRIPTION: type('[Campaign] Remove subscription'),
  REMOVE_SUBSCRIPTION_SUCCESS: type('[Campaign] Remove subscription SUCCESS'),
  REMOVE_SUBSCRIPTION_FAIL: type('[Campaign] Remove subscription FAIL'),
  ORDER_MEDIA: type('[Campaign] Order media'),
  ORDER_MEDIA_SUCCESS: type('[Campaign] Order media SUCCESS'),
  ORDER_MEDIA_FAIL: type('[Campaign] Order media FAIL')
};

export class LoadCampaignDetailsAction implements Action {
  readonly type = CampaignDetailsActionType.LOAD;
  /* payload is the user id */
  constructor(public payload?: string) {}
}
export class LoadCampaignDetailsSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.LOAD_SUCCESS;
  constructor(public payload?: Campaign) {}
}
export class LoadCampaignDetailsFailAction implements Action {
  readonly type = CampaignDetailsActionType.LOAD_FAIL;
  constructor(public payload?: number) {}
}

export class OrderMediaAction implements Action {
  readonly type = CampaignDetailsActionType.ORDER_MEDIA;
  constructor(public payload: Media[]) {}
}
export class OrderMediaSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.ORDER_MEDIA_SUCCESS;
  constructor(public payload: string) {}
}
export class OrderMediaFailAction implements Action {
  readonly type = CampaignDetailsActionType.ORDER_MEDIA_FAIL;
  constructor(public payload: string) {}
}

export class AddMediaAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_MEDIA;
  constructor(public payload: Media) {}
}
export class AddMediaSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_MEDIA_SUCCESS;
  constructor(public payload: string) {}
}
export class AddMediaFailAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_MEDIA_FAIL;
  constructor(public payload: string) {}
}

export class RemoveMediaAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_MEDIA;
  constructor(public payload: number[]) {}
}
export class RemoveMediaSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_MEDIA_SUCCESS;
  constructor(public payload: string) {}
}
export class RemoveMediaFailAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_MEDIA_FAIL;
  constructor(public payload: string) {}
}

export class AddSubscriptionAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_SUBSCRIPTION;
  constructor(public payload: Subscription) {}
}
export class AddSubscriptionSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_SUBSCRIPTION_SUCCESS;
  constructor(public payload: string) {}
}
export class AddSubscriptionFailAction implements Action {
  readonly type = CampaignDetailsActionType.ADD_SUBSCRIPTION_FAIL;
  constructor(public payload: string) {}
}

export class RemoveSubscriptionAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_SUBSCRIPTION;
  constructor(public payload: number) {}
}
export class RemoveSubscriptionSuccessAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_SUBSCRIPTION_SUCCESS;
  constructor(public payload: string) {}
}
export class RemoveSubscriptionFailAction implements Action {
  readonly type = CampaignDetailsActionType.REMOVE_SUBSCRIPTION_FAIL;
  constructor(public payload: string) {}
}

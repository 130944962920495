import {Action} from '@ngrx/store';

import {Client} from '../models/client';
import {type} from '../utilities/type';

export const CurrentClientActionTypes = {
  LOAD: type('[Current Client] Load'),
  LOAD_SUCCESS: type('[Current Client] Load Success'),
  LOAD_FAIL: type('[Current Client] Load Fail'),
  LOAD_PREFERENCES_ROW_SIZE: type('[Current Client] Load preferences row size'),
};

export class LoadCurrentClientAction implements Action {
  readonly type = CurrentClientActionTypes.LOAD;
  constructor(public payload?: string) {}
}

export class LoadCurrentClientSuccessAction implements Action {
  readonly type = CurrentClientActionTypes.LOAD_SUCCESS;
  constructor(public payload?: Client) {}
}

export class LoadCurrentClientFailAction implements Action {
  readonly type = CurrentClientActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

export class LoadCurrentPreferencesRowSizeAction implements Action {
  readonly type = CurrentClientActionTypes.LOAD_PREFERENCES_ROW_SIZE;
  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {CorporateMedia} from '../../shared/models/media';

export const CorporateListChangeActionTypes = {
  CHANGE_CORPORATE_DIFFUSION: type('[Corporate List] DIFFUSION'),
  CHANGE_CORPORATE_DIFFUSION_SUCCESS: type('[Corporate List] DIFFUSION Success'),
  CHANGE_CORPORATE_DIFFUSION_FAIL: type('[Corporate List] DIFFUSION Error')
};

export class CorporateListChangeAction implements Action {
  readonly type = CorporateListChangeActionTypes.CHANGE_CORPORATE_DIFFUSION;

  constructor(public payload?: CorporateMedia) {}
}

export class CorporateListChangeSuccessAction implements Action {
  readonly type = CorporateListChangeActionTypes.CHANGE_CORPORATE_DIFFUSION_SUCCESS;

  constructor() {}
}

export class CorporateListChangeFailAction implements Action {
  readonly type = CorporateListChangeActionTypes.CHANGE_CORPORATE_DIFFUSION_FAIL;

  constructor(public payload?: number) {}
}

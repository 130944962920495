import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {L10nIntlModule, L10nLoader, L10nTranslationModule} from 'angular-l10n';
import * as _ from 'lodash';
import {SignalRConfiguration, SignalRModule} from '@henkkelder/ng2-signalr';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {ErrorModule} from './error/error.module';
import {initL10n, l10nConfig} from './i10n-config';
import {MediasService} from './medias/services/medias.service';
import {RedirectComponent} from './redirect.component';
import {AppLayoutComponent, reducers, RouterStateUrl, SharedModule} from './shared';
import * as sharedEffects from './shared/effects';
import {PreferencesEffect} from './shared/effects/preferences.effect';
import {HnResolver} from './shared/services/hn-resolver.service';
import {CounterEffect} from './shared/effects';

//
// export class CustomOption extends ToastOptions {
//   animate = 'flyRight'; // you can override any options available
//   newestOnTop = false;
//   showCloseButton = true;
//   toastLife = 10000;
//   // dismiss = 'click';
// }

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data: mergeRouteData(routerState.root)
    };
  }
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
  if (!route) {
    return {};
  }

  const currentParams = getter(route);
  const primaryChild = _.find(route.children, c => c.outlet === 'primary') || route.firstChild;
  return Object.assign({}, currentParams, mergeRouteParams(primaryChild, getter));
}

function mergeRouteData(route: ActivatedRouteSnapshot): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = _.find(route.children, c => c.outlet === 'primary') || route.firstChild;
  return Object.assign({}, currentData, mergeRouteData(primaryChild));
}

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'notifications';
  if ('https://localhost:4301' === window.location.origin || 'https://localhost:4201' === window.location.origin ||
      'https://aps.staging.musicmatic.com' === window.location.origin || 'https://admanager.staging.musicmatic.com' === window.location.origin) {
    c.url = 'https://storeverapi-bo.staging.musicmatic.com';
  } else if ('https://admanager-qa.storever.com' === window.location.origin) {
    c.url = 'https://api-qa.storever.com';
  } else if ('https://admanager.storever.com' === window.location.origin) {
    c.url = 'https://api.storever.com';
  } else if ('https://admanager-qa.mcubedigital.com' === window.location.origin) {
    c.url = 'https://api-qa.mcubedigital.com';
  } else if ('https://admanager.mcubedigital.com' === window.location.origin) {
    c.url = 'https://api.mcubedigital.com';
  }
  c.logging = true;
  return c;
}

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SignalRModule.forRoot(createConfig),
    EffectsModule.forRoot([
      sharedEffects.MyClientsEffect,
      sharedEffects.UserInfoEffect,
      sharedEffects.UserContextClientEffect,
      sharedEffects.UserContextImpersonationEffect,
      sharedEffects.UserContextRolesEffect,
      sharedEffects.ImpersonableUsersEffect,
      sharedEffects.LanguagesEffect,
      sharedEffects.MediaTagsEffect,
      sharedEffects.TimezonesEffect,
      sharedEffects.PublishEffect,
      sharedEffects.AddPublishedEffect,
      sharedEffects.AddPublishedProgramEffect,
      sharedEffects.AlertsEffect,
      sharedEffects.GroupRolesEffect,
      sharedEffects.ExternalLinksEffect,
      sharedEffects.CampaignTagsEffect,
      sharedEffects.RouterEffects,
      sharedEffects.ToastsEffect,
      sharedEffects.CurrentClientEffect,
      sharedEffects.MediasListEffect,
      sharedEffects.PreferencesEffect,
      sharedEffects.CounterEffect
    ]),
    ErrorModule,
    HttpClientModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    SortableModule.forRoot(),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    SharedModule.forRoot(),
    L10nTranslationModule.forRoot(l10nConfig),
    L10nIntlModule,
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
    StoreRouterConnectingModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastModule,

  ],
  providers: [
    MediasService,
    HnResolver,
    Title,
    MessageService,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: APP_INITIALIZER, useFactory: initL10n, deps: [L10nLoader], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}

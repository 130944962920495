import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Publication} from '../../publications/models/publication';
import {PublicationLastSuccessAction, PublicationListActionTypes} from '../actions/publication-list';

export interface PublicationLastState {
  array: Publication[];
  count: number;
}
const initialState: PublicationLastState = {
  array: [],
  count: 0,
};

export function publicationLastReducer(state = initialState, action: Action): PublicationLastState {
  switch (action.type) {
    case PublicationListActionTypes.LOAD_LAST_SUCCESS:
      return handleLoadLastSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadLastSuccessAction(action: PublicationLastSuccessAction): PublicationLastState {
  const newState: PublicationLastState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const PublicationLastSelectors = {
  array: (state: PublicationLastState) => _.get(state, 'array', []),
  count: (state: PublicationLastState) => _.get(state, 'count', 0)
};

import {Action} from '@ngrx/store';

import {PublishProgramForm} from '../models/publish';
import {type} from '../utilities/type';

export const AddPublishedProgramActionTypes = {
  ADD: type('[Published Prog] Add'),
  ADD_SUCCESS: type('[Published Prog] Add Success'),
  ADD_VALIDATION_FAIL: type('[Published Prog] Add Validation Fail'),
  ADD_FAIL: type('[Published Prog] Add Fail')
};

export class AddPublishedProgramAction implements Action {
  readonly type = AddPublishedProgramActionTypes.ADD;

  constructor(public payload?: PublishProgramForm) {}
}

export class AddPublishedProgramSuccessAction implements Action {
  readonly type = AddPublishedProgramActionTypes.ADD_SUCCESS;

  constructor(public payload?: number) {}
}

export class AddPublishedProgramValidationFailAction implements Action {
  readonly type = AddPublishedProgramActionTypes.ADD_VALIDATION_FAIL;

  constructor() {}
}

export class AddPublishedProgramFailAction implements Action {
  readonly type = AddPublishedProgramActionTypes.ADD_FAIL;

  constructor(public payload?: number) {}
}

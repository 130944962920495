import {Action} from '@ngrx/store';

import {Campaign, type} from '../../shared';

export const campaignLocalActionTypes = {
  CAMPAIGN_LOCAL: type('[Campaign local] Edit'),
  CAMPAIGN_LOCAL_SUCCESS: type('[Campaign local] Edit Success'),
  CAMPAIGN_LOCAL_UPDATE: type('[Campaign local] Edit UPDATE'),
  CAMPAIGN_LOCAL_DELETE: type('[Campaign local] Edit DELETE'),
  CAMPAIGN_LOCAL_DELETE_ACTION: type('[Campaign local] Edit DELETE Action'),
  CAMPAIGN_LOCAL_SELF: type('[Campaign local] Edit Self'),
  CAMPAIGN_LOCAL_FAIL: type('[Campaign local] Edit Server Error'),
  CAMPAIGN_LOCAL_TOGGLE_SELECTION: type('[Campaign local] Edit Toggle Selection'),
  CAMPAIGN_LOCAL_SELECT_PAGE: type('[Campaign local] Edit Page Select'),
  CAMPAIGN_LOCAL_UNSELECT_PAGE: type('[Campaign local] Edit Page UnSelect'),
};

export class CampaignLocalAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL;

  constructor(public payload?: Array<any>) {}
}
export class CampaignLocalUpdatedAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_UPDATE;

  constructor(public payload?: Campaign) {}
}
export class CampaignLocalDeletedAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_DELETE;

  constructor(public payload?: Campaign) {}
}
export class CampaignLocalDeletedListAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_DELETE_ACTION;

  constructor() {}
}

export class CampaignLocalSuccessAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_SUCCESS;

  constructor() {}
}

export class CampaignLocalSelfAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_SELF;

  constructor() {}
}

export class CampaignLocalFailAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_FAIL;

  constructor(public payload?: number) {}
}
export class CampaignLocalToggleSelectionAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_TOGGLE_SELECTION;

  constructor(public payload?: number) {}
}
export class CampaignLocalSelectPageAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_SELECT_PAGE;

  constructor(public payload?: number) {}
}
export class CampaignLocalUnSelectPageAction implements Action {
  readonly type = campaignLocalActionTypes.CAMPAIGN_LOCAL_UNSELECT_PAGE;

  constructor(public payload?: number) {}
}

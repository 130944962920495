import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const DeleteMediaActionTypes = {
  DELETE: type('[Media] Delete'),
  DELETE_SUCCESS: type('[Media] Delete Success'),
  DELETE_SELF: type('[Media] Delete Self'),
  DELETE_FAIL: type('[Media] Delete Fail')
};

export class DeleteMediaAction implements Action {
  readonly type = DeleteMediaActionTypes.DELETE;

  constructor() {}
}

export class DeleteMediaSuccessAction implements Action {
  readonly type = DeleteMediaActionTypes.DELETE_SUCCESS;

  constructor() {}
}

export class DeleteMediaSelfAction implements Action {
  readonly type = DeleteMediaActionTypes.DELETE_SELF;

  constructor() {}
}

export class DeleteMediaFailAction implements Action {
  readonly type = DeleteMediaActionTypes.DELETE_FAIL;

  constructor(public payload?: number) {}
}

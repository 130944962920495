import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Subscriptions} from '../../shared/models/subscriptions';

export const SubscriptionsListActionTypes = {
  TOGGLE_SEARCH: type('[Campaign Subscriptions] Toggle Search Form'),
  QUICK_SEARCH: type('[Campaign Subscriptions] Quick Search'),
  SEARCH: type('[Campaign Subscriptions] Search'),
  CHANGE_PAGE_SIZE: type('[Campaign Subscriptions] Change Page Size'),
  PAGE_TO: type('[Campaign Subscriptions] Page To'),
  ORDER_BY: type('[Campaign Subscriptions] Order By'),
  LOAD: type('[Campaign Subscriptions] Load'),
  LOAD_SUCCESS: type('[Campaign Subscriptions] Load Success'),
  LOAD_FAIL: type('[Campaign Subscriptions] Load Fail')
};

export class ChangeSubscriptionsPageSizeAction implements Action {
  readonly type = SubscriptionsListActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageSubscriptionsToAction implements Action {
  readonly type = SubscriptionsListActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderSubscriptionsByAction implements Action {
  readonly type = SubscriptionsListActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD;

  constructor() {}
}

export class LoadSubscriptionsSuccessAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Subscriptions>) {}
}

export class LoadSubscriptionsFailAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse} from '../models/array-response';
import {ExternalLink} from '../models/external-link';
import {SendBackResult} from '../models/send-back-result';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class ExternalLinksService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'ExternalLinksService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(clientId: string): Observable<SendBackResult<ArrayResponse<ExternalLink>>> {
    return this.get<ArrayResponse<ExternalLink>>(`/clients/${clientId}/applications/allowed`);
  }
}

import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {Publish, PublishForm, PublishProgramForm} from '../../models/publish';
import {Store} from '@ngrx/store';
import * as fromPublications from '../../../publications/reducers';
import {Observable} from 'rxjs';
import {Publication} from '../../../publications/models/publication';
import {Counters} from '../../models/counters';
import * as fromShared from '../../../shared/reducers';
import {LoadCountersAction} from '../../actions/counters';

@Component({
  selector: 'storever-confirmation-publication-dialog',
  templateUrl: './confirmation-publication-dialog.component.html',
  styleUrls: ['./confirmation-publication-dialog.component.scss']
})
export class ConfirmationPublicationDialogComponent implements OnInit, OnChanges {
  @ViewChild('ConfirmationPublishModal') modal: ModalDirective;
  @Output() Publish = new EventEmitter<PublishForm>();
  @Output() Music = new EventEmitter<PublishProgramForm>();
  @Output() PublishAndMusic = new EventEmitter<any>();
  @Input() publishInfo: Publish;
  @Input() roleProgram;
  @Input() rolePublication;
  publishInfoForm: UntypedFormGroup;
  counter$: Observable<Counters>;
  counter: Counters;
  programsInfo = [];
  programsInfoLength: number;
  publish: Publish;
  pageSize = 5;
  page = 1;
  sdStatus: any;
  cdStatus: any;
  canPublish = false;
  constructor(fb: UntypedFormBuilder,
              @Inject(L10N_LOCALE) public locale: L10nLocale ,
              private store: Store<fromPublications.AppState>,
              private storeShared: Store<fromShared.AppState>) {
    this.publishInfoForm = fb.group({ name: [undefined, Validators.required], cd: false, sd: false , forcedUpdate: false  });
    this.counter$ = this.storeShared.select(fromShared.selectors.getCounters);
  }

  ngOnInit() {
    this.store.dispatch(new LoadCountersAction())
    this.counter$.subscribe(data => {
      if (data) {
        this.counter = data;
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
    }
    if (this.publishInfo) {
      this.sdStatus = this.publishInfo.sd;
      this.cdStatus = this.publishInfo.cd;

      if (this.sdStatus === 'checked') {
        this.publishInfoForm.patchValue({ 'sd': true })
      }
      if (this.cdStatus === 'checked') {
        this.publishInfoForm.patchValue({ 'cd': true })
      }
    }

    if (this.publishInfo && this.publishInfo.programsInfo) {
      this.programsInfoLength = this.publishInfo.programsInfo.length;

      this.updateArray();
      this.publish = this.publishInfo;
      this.canPublish = !this.publish.canPublish;
    }
  }

  open(): void { this.modal.show(); }

  close(): void { this.modal.hide(); }

  pageChanged($event) {
    this.page = $event;
    this.updateArray();
  }

  updateArray() {
    this.programsInfo = [];
    for (let iter = (this.page * this.pageSize) - this.pageSize; iter < this.page * this.pageSize; iter++) {
      if (iter < this.programsInfoLength) {
        this.programsInfo.push(this.publishInfo.programsInfo[iter]);
      }
    }
  }

  pageSizeChanged($event) {
    if ($event === -1) {
      this.pageSize = this.publishInfo.programsInfo.length;
    } else {
      if ($event > this.programsInfoLength) {
        this.pageSize = this.publishInfo.programsInfo.length;
      } else {
        this.pageSize = $event;
      }
    }
    this.updateArray();
  }
  applyCommercial(form: PublishForm): void {
    this.Publish.emit(form);
    this.modal.hide();
    this.reset();
  }
  applyMusic(): void {
    this.Music.emit({ endDate: this.publish.programEnd });
    this.modal.hide();
    this.reset();
  }
  applyCommercialAndMusic(form: PublishForm): void {
    this.PublishAndMusic.emit(Object.assign({ endDate: this.publish.programEnd }, form));
    this.modal.hide();
    this.reset();
  }
  reset() { this.publishInfoForm.reset(); }
}


  <div bsModal #AddMediasModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" translate>ADD_MEDIA_DIALOG_TITLE</h4>
      </div>
      <div *ngIf="(showAsPopup == undefined)" class="modal-body" >
        <storever-drop-zone (close)="close($event)" (addMedia)="AddMedia($event)"></storever-drop-zone>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="close($event)" translate>CLOSE</button>
      </div>
    </div>
  </div>
</div>

import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {DataTableColumnCellDirective} from './datatable-column-cell.directive';
import {DataTableColumnHeaderDirective} from './datatable-column-header.directive';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'storever-datatable-column' })
export class DataTableColumnDirective {
  @Input() name = '';
  @Input() prop: string;
  @Input() sortable = false;
  @Input() sortableProp: string;
  @Input() className: string;

  @ContentChild(DataTableColumnCellDirective, { read: TemplateRef }) cellTemplate: TemplateRef<any>;

  @ContentChild(DataTableColumnHeaderDirective, { read: TemplateRef }) headerTemplate: TemplateRef<any>;
}

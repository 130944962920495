import {ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {Campaign} from '../../shared/models/campaign';
import {Media} from '../../shared/models/media';
import {MediasFilterForm} from '../../shared/models/medias-filter-form';
import {SelectedMediasValidate} from '../../shared/models/selected-medias';
import * as fromRoot from '../../shared/reducers';
import {MediasListSelectors} from '../../shared/reducers/medias-list';
import {UsedMedias} from '../models/used-medias';

import {campaignReducer, CampaignSelectors, CampaignState} from './campaign-link-media';
import {mediaDetailsReducer, MediaDetailsSelectors, MediaDetailsState} from './media-details';
import {mediasValidateListReducer, MediasValidateListSelectors, MediasValidateListState} from './media-list-validate';
import {usedMediasReducer, UsedMediasSelectors, UsedMediasState} from './used-medias';

export interface MediaState {
  campaign: CampaignState;
  media: MediaDetailsState;
  UsedMedias: UsedMediasState;
  mediasValidate: MediasValidateListState;
}

export interface AppState extends fromRoot.AppState {
  media: MediaState;
}

export const reducers: ActionReducerMap<MediaState> = {

  media: mediaDetailsReducer,
  campaign: campaignReducer,
  UsedMedias: usedMediasReducer,
  mediasValidate: mediasValidateListReducer
};

const getMediaState = createFeatureSelector('media');

const rootSelectors = {
  media: createSelector(getMediaState, (state: MediaState) => _.get(state, 'media')),
  campaign: createSelector(getMediaState, (state: MediaState) => _.get(state, 'campaign')),
  usedMedias: createSelector(getMediaState, (state: MediaState) => _.get(state, 'UsedMedias')),
  mediasValidate: createSelector(getMediaState, (state: MediaState) => _.get(state, 'mediasValidate')),
};

export interface Selectors {
  getCampaign: any;
  getMediaDetails: any;
  getUsedMedias: any;

  getValidateShowFilter: any;
  getValidateQuickSearch: any;
  getValidateFilter: any;
  getValidatePage: any;
  getValidatePageSize: any;
  getValidateOrderBy: any;
  getValidateMedia: any;
  getValidateMediaCount: any;
  getValidateSelectedMedias: any;
}

export const selectors: Selectors = {

  getMediaDetails: createSelector(rootSelectors.media, MediaDetailsSelectors.value),
  getCampaign: createSelector(rootSelectors.campaign, CampaignSelectors.array),
  getUsedMedias: createSelector(rootSelectors.usedMedias, UsedMediasSelectors.array),

  getValidateShowFilter: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.ValidateshowFilter),
  getValidateQuickSearch: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.ValidatequickSearch),
  getValidateFilter: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.Validatefilter),
  getValidatePage: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.Validatepage),
  getValidatePageSize: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.ValidatepageSize),
  getValidateOrderBy: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.ValidateorderBy),
  getValidateMedia: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.Validatearray),
  getValidateMediaCount: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.Validatecount),
  getValidateSelectedMedias: createSelector(rootSelectors.mediasValidate, MediasValidateListSelectors.ValidateselectedMedias)
};

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MessageService} from 'primeng/api';
import {of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {CustomToastAction, ErrorToastAction, InfoToastAction, SuccessToastAction, ToastsAction, ToastsMethodCall, WarningToastAction} from '../actions/toasts';

@Injectable()
export class ToastsEffect {
  // @Effect({ dispatch: false })
  // @ts-ignore
  error$: any = createEffect((): any => { return this.actions$.pipe(ofType(ToastsAction.ERROR),
                              map((action: ErrorToastAction) => action.payload),
                              switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                              { severity: 'error', summary: payload.title, detail: payload.message, sticky: true, closable: true }))))}, { dispatch: false });

  // @Effect({ dispatch: false })
  // @ts-ignore
  info$: any = createEffect((): any => { return this.actions$.pipe(ofType(ToastsAction.INFO),
                             map((action: InfoToastAction) => action.payload),
                             switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                             { severity: 'info', summary: payload.title, detail: payload.message, closable: true, life: 2000 }))))}, { dispatch: false });

  // @Effect({ dispatch: false })
  // @ts-ignore
  success$: any = createEffect((): any => { return this.actions$.pipe(ofType(ToastsAction.SUCCESS),
                                map((action: SuccessToastAction) => action.payload),
                                switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                { severity: 'success', summary: payload.title, detail: payload.message, closable: true, life: 2000 }))))}, { dispatch: false });

  // @Effect({ dispatch: false })
  // @ts-ignore
  warning$: any = createEffect((): any => { return this.actions$.pipe(ofType(ToastsAction.WARNING),
                                map((action: WarningToastAction) => action.payload),
                                switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                { severity: 'warn', summary: payload.title, detail: payload.message, closable: true, life: 2000 }))))}, { dispatch: false });

  // @Effect({ dispatch: false })
  // custom$ = this.actions$.ofType(ToastsAction.CUSTOM),
  //             .map((action: CustomToastAction) => action.payload),
  //             .do((payload: ToastsMethodCall) => this.toasts.custom(payload.message, payload.title, payload.options));

  // @Effect({ dispatch: false })
  clearAll$: any = createEffect((): any => { return this.actions$.pipe(ofType(ToastsAction.CLEAR_ALL),
    tap(() => this.messageService.clear()))}, { dispatch: false });

  constructor(private actions$: Actions, private messageService: MessageService) {}
}

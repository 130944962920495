import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Subscriptions} from '../../subscription/models/subscriptions';
import {Program} from '../../templates-programs/models/program';
import {ProgramNoPlaningActionTypes, ProgramNoPlanningSuccessAction} from '../actions/program-no-planning';
import {SiteWithNoTemplateListActionTypes, SiteWithNoTemplateSuccessAction} from '../actions/site-with-no-template-list';

export interface ProgramNoPlanningState {
  array: Program[];
  count: number;
}
const initialState: ProgramNoPlanningState = {
  array: [],
  count: 0,
};

export function programNoPlanningReducer(state = initialState, action: Action): ProgramNoPlanningState {
  switch (action.type) {
    case ProgramNoPlaningActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(action: ProgramNoPlanningSuccessAction): ProgramNoPlanningState {
  const newState: ProgramNoPlanningState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const ProgramNoPlanningSelectors = {
  array: (state: ProgramNoPlanningState) => _.get(state, 'array', []),
  count: (state: ProgramNoPlanningState) => _.get(state, 'count', 0)
};

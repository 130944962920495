import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';

import {AppSettings} from '../../models/app-settings';

@Component({
  selector: 'storever-audio-player-simple',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPlayerComponent implements OnChanges {
  @Input() fileName: string;
  @Input() appSettings: AppSettings;
  @Input() playing: boolean;
  @Output() togglePlay = new EventEmitter<string>();
  @ViewChild('audioPlayer') audioPlayer: ElementRef;

  get ready(): boolean { return !_.isEmpty(this.fileName); }

  get mp3Url(): string {
    if (_.isEmpty(this.fileName)) {
      return undefined;
    }
    return `${this.appSettings.player.url}${this.fileName}.mp3`;
  }

  get oggUrl(): string {
    if (_.isEmpty(this.fileName)) {
      return undefined;
    }
    return `${this.appSettings.player.url}${this.fileName}.ogg`;
  }

  constructor(private cdr: ChangeDetectorRef, @Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'playing')) {
      const change = _.get(changes, 'playing');
      const value: boolean = change.currentValue;
      if (this.audioPlayer && this.audioPlayer.nativeElement) {
        if (value) {
          (<HTMLAudioElement>this.audioPlayer.nativeElement).play();
        } else {
          (<HTMLAudioElement>this.audioPlayer.nativeElement).pause();
        }
      }
    }
  }

  playCompleted(): void {
    this.cdr.markForCheck();
    this.togglePlay.emit(this.fileName);
  }

  togglePlayClicked(): void { this.togglePlay.emit(this.fileName); }
}

  <div bsModal #deleteMediaModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" translate>CONFIRMATION</h4>
      </div>
      <div *ngIf="selectedMedias" class="modal-body">
        <span>{{ selectedMedias.length | i18nPlural:messageMapping | translate:locale.language }}</span>
         <br/><br/>
        <div class="list-group-container">
          <p-accordion>
            <span *ngIf="UMedias.length > 0" translate>CONFIRM_DELETE_MEDIAS_USED_MEDIAS_TITLE</span><br/><br/>
            <span class="" *ngFor="let usedMedia of UMedias">
                <p-accordionTab header="{{usedMedia.name}}">
                  <ul class="" *ngFor="let children of usedMedia.children"><li><i class="fa fa-shopping-basket"></i>{{children.name}}</li></ul>
                </p-accordionTab>
            </span>
          </p-accordion>
          </div>
      </div>
      <div class="modal-footer">
         <button *ngIf="UMedias.length > 0"   type="button" class="btn btn-danger" (click)="acceptOnly()">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ 'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT_ONLY' | translate:locale.language }}
        </button>
        <button type="button" class="btn btn-danger" (click)="accept()">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          {{ 'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT' | translate:locale.language }}
        </button>
        <button type="button" class="btn btn-default" (click)="close()" translate>{{ 'CANCEL' | translate:locale.language }}</button>
      </div>
    </div>
  </div>
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthLayoutComponent} from '../shared';

import {CallbackComponent} from './pages/callback/callback.component';
import {LoginComponent} from './pages/login/login.component';
import {LostPasswordComponent} from './pages/lost-password/lost-password.component';

const routes: Routes = [{
  path: '',
  component: AuthLayoutComponent,
  children: [
    { path: 'login', component: LoginComponent, data: { title: 'AUTH_LOGIN_PAGE_TITLE' } },
    { path: 'callback', component: CallbackComponent, data: { title: 'AUTH_LOGIN_PAGE_TITLE' } },
    { path: 'lost-password', component: LostPasswordComponent, data: { title: 'AUTH_LOST_PASSWORD_PAGE_TITLE' } }
  ]
}];

@NgModule({ imports: [RouterModule.forChild(routes)], exports: [RouterModule] })
export class AuthRoutingModule {}

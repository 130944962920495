import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {EditCampaignForm} from '../models/edit-campaign-form';

export const SaveCampaignActionTypes = {
  SAVE: type('[campaign] Save'),
  SAVE_ALL: type('[campaign] Save All'),
  SAVE_ALL_SUCCESS: type('[campaign] Save All Success'),
  SAVE_SUCCESS: type('[campaign] Save Success'),
  SAVE_VALIDATION_FAIL: type('[campaign] Save Validation Fail'),
  SAVE_NOT_FOUND: type('[campaign] Save Not Found'),
  SAVE_FAIL: type('[campaign] Save Fail'),
  RESET_CAMPAIGN: type('[campaign] Reset campaign')
};

export class ResetCampaignAction implements Action {
  readonly type = SaveCampaignActionTypes.RESET_CAMPAIGN;
  constructor() {}
}

export class SaveCampaignAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE;
  constructor(public payload?: EditCampaignForm) {}
}

export class SaveCampaignAllAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_ALL;
  constructor(public payload?: number) {}
}

export class SaveCampaignAllSuccessAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_ALL_SUCCESS;
  constructor() {}
}

export class SaveCampaignSuccessAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_SUCCESS;
  constructor() {}
}

export class SaveCampaignValidationFailAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_VALIDATION_FAIL;
  constructor() {}
}

export class SaveCampaignNotFoundAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_NOT_FOUND;
  constructor() {}
}

export class SaveCampaignFailAction implements Action {
  readonly type = SaveCampaignActionTypes.SAVE_FAIL;
  constructor(public payload?: number) {}
}

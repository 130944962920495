import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {ImpersonableUsersActionTypes, LoadImpersonableUsersFailAction, LoadImpersonableUsersSuccessAction} from '../actions/impersonable-users';
import {NoOpAction} from '../actions/noop';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {ImpersonableUserVM} from '../models/impersonable-user.vm';
import {SendBackResult} from '../models/send-back-result';
import {User} from '../models/user';
import * as fromShared from '../reducers';
import * as fromRoot from '../reducers';
import {ImpersonableUsersService} from '../services/impersonable-users.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class ImpersonableUsersEffect extends BaseEffect {
  load$  = createEffect(
    () => {  return this.actions$.pipe(
    ofType(ImpersonableUsersActionTypes.LOAD),
    debug('Load impersonable users action received.'),
    withLatestFrom(this.store.select(fromShared.selectors.getCurrentClient)),
    switchMap(([action, client]) => this.impersonableUsersService.getList(client.uuid)
                                      .pipe(map((result: SendBackResult<ArrayResponse<User>>) => _.map(result.data.array, mapUserToImpersonableUserVM)),
                                            map((payload: ImpersonableUserVM[]) => new LoadImpersonableUsersSuccessAction(payload)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(ImpersonableUsersActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving impersonable users.'),
                                 withLatestFrom(this.store.select(fromRoot.selectors.getCurrentClient)),
                                 map(([action, client]) => error(this.translation.translate('USER_CONTEXT_GET_IMPERSONABLE_ERROR', { clientName: client.name }),
                                                                 this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromRoot.AppState>,
              translation: L10nTranslationService,
              private impersonableUsersService: ImpersonableUsersService,
              router: Router) {
    super(router, translation);
  }

  protected handleAccessDenied(): Action { return new NoOpAction(); }

  protected handleUnhandledError(response: Response): Action { return new LoadImpersonableUsersFailAction(response.status); }
}

function mapUserToImpersonableUserVM(user: User): ImpersonableUserVM {
  return { uuid: user.uuid, name: user.name, email: user.email, groupRoleName: _.get(user, 'groupRole.name', 'N/A') };
}

import {Action} from '@ngrx/store';

import {ArrayResponse} from '../models/array-response';
import {TagsList} from '../models/tags-list';
import {type} from '../utilities/type';

export const CampaignTagsListActionTypes = {
  LOAD: type('[CampaignTagsList] Load'),
  LOAD_SUCCESS: type('[CampaignTagsList] Load Success'),
  LOAD_FAIL: type('[CampaignTagsList] Load Fail')
};

export class LoadCampaignTagsListAction implements Action {
  readonly type = CampaignTagsListActionTypes.LOAD;

  constructor() {}
}

export class LoadCampaignTagsListSuccessAction implements Action {
  readonly type = CampaignTagsListActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<TagsList>) {}
}

export class LoadCampaignTagsListFailAction implements Action {
  readonly type = CampaignTagsListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

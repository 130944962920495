import {Action} from '@ngrx/store';

import {type} from '../';
import {Publication} from '../../publications/models/publication';
import {ArrayResponse} from '../models/array-response';

export const PublicationSimpleListActionType = {
  LOAD: type('[Publication Simple List] Load'),
  LOAD_SUCCESS: type('[Publication Simple List] Load Success'),
  LOAD_FAIL: type('[Publication Simple List] Load Server Error'),
  LOAD_NOT_FOUND: type('[Publication Simple List] Load NOT FOUND')
};

export class LoadPublicationSimpleListAction implements Action {
  readonly type = PublicationSimpleListActionType.LOAD;

  constructor(public payload?: string) {}
}

export class LoadPublicationSimpleListSuccessAction implements Action {
  readonly type = PublicationSimpleListActionType.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Publication>) {}
}
export class LoadPublicationSimpleListNotFoundAction implements Action {
  readonly type = PublicationSimpleListActionType.LOAD_NOT_FOUND;

  constructor(public payload?: Publication) {}
}

export class LoadPublicationSimpleListFailAction implements Action {
  readonly type = PublicationSimpleListActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import {RETURN_URL} from '../consts';
import {tokenNotExpired} from '../utilities/token-not-expired';

@Injectable()
export class MustBeAuthenticatedGuard  {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (next) {
    }
    if (tokenNotExpired()) {
      return true;
    }
    if (state.url !== '/login' && state.url !== '/dashboard') {
      localStorage.setItem(RETURN_URL, state.url);
    }
    this.router.navigate(['/login']);
    return false;
  }
}

import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';

import {Role} from '../models/role';
import * as fromRoot from '../reducers';

@Injectable()
export class RoleCanActivateGuard  {
  private roles$: Observable<Role[]>;

  constructor(store: Store<fromRoot.AppState>) { this.roles$ = store.select(fromRoot.selectors.getMyRoles); }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state) {
    }
    return this.roles$.pipe(skipWhile(data => !data.length), map(data => { return this.isRoleExist(data, route.data['roles']); }));
  }

  isRoleExist(roles: Role[], code: string): boolean {
    if (_.findIndex(roles, ['code', code]) !== -1) {
      return true;
    } else {
      return false;
    }
  }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {GroupRolesActionTypes, LoadGroupRolesSuccessAction} from '../actions/group-roles';
import {UserContextActionTypes} from '../actions/user-context';
import {GroupRole} from '../models/group-role';

export interface GroupRolesState {
  array: GroupRole[];
}

const initialState: GroupRolesState = {
  array: []
};

export function groupRolesReducer(state = initialState, action: Action): GroupRolesState {
  switch (action.type) {
    case GroupRolesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case GroupRolesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: GroupRolesState, action: LoadGroupRolesSuccessAction): GroupRolesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: GroupRolesState): GroupRolesState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): GroupRolesState {
  return { array: [] };
}

export const groupRolesSelectors = {
  array: (state: GroupRolesState) => _.get(state, 'array', [])
};

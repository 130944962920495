import {Action} from '@ngrx/store';

import {TagsLists} from '../../medias/models/tags-list';
import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {SubscriptionFilter} from '../models/subscription-filter';
import {Subscriptions} from '../models/subscriptions';

export const SubscriptionsListActionTypes = {
  TOGGLE_SEARCH: type('[Subscriptions] Toggle Search Form'),
  QUICK_SEARCH: type('[ Subscriptions] Quick Search'),
  SEARCH: type('[ Subscriptions] Search'),
  CHANGE_PAGE_SIZE: type('[ Subscriptions] Change Page Size'),
  PAGE_TO: type('[ Subscriptions] Page To'),
  ORDER_BY: type('[ Subscriptions] Order By'),
  LOAD: type('[ Subscriptions] Load'),
  LOAD_SUCCESS: type('[ Subscriptions] Load Success'),
  LOAD_FAIL: type('[ Subscriptions] Load Fail'),
  SELECT_PAGE: type('[ Subscriptions] Select Page'),
  UNSELECT_PAGE: type('[ Subscriptions] Unselect Page'),
  TOGGLE_SELECTION: type('[ Subscriptions] Toggle Selection'),
  TOGGLE_SELECTION_TAGS: type('[ Subscriptions] Toggle Selection Tags'),
  CLEAR: type('[Subscriptions] Clear Data')
};

export class ChangeSubscriptionsPageSizeAction implements Action {
  readonly type = SubscriptionsListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageSubscriptionsToAction implements Action {
  readonly type = SubscriptionsListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderSubscriptionsByAction implements Action {
  readonly type = SubscriptionsListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD;
  constructor() {}
}

export class ToggleSearchSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class SearchSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.SEARCH;
  constructor(public payload?: SubscriptionFilter) {}
}

export class QuickSearchSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.QUICK_SEARCH;
  constructor(public payload?: string) {}
}
export class LoadSubscriptionsSuccessAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Subscriptions>) {}
}

export class LoadSubscriptionsFailAction implements Action {
  readonly type = SubscriptionsListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}
export class SelectSubscriptionsPageAction implements Action {
  readonly type = SubscriptionsListActionTypes.SELECT_PAGE;
  constructor() {}
}

export class UnselectSubscriptionsPageAction implements Action {
  readonly type = SubscriptionsListActionTypes.UNSELECT_PAGE;
  constructor() {}
}
export class ToggleSubscriptionsSelectionAction implements Action {
  readonly type = SubscriptionsListActionTypes.TOGGLE_SELECTION;
  /* payload is the user id */
  constructor(public payload?: number) {}
}
export class ClearSubscriptionsAction implements Action {
  readonly type = SubscriptionsListActionTypes.CLEAR;
  constructor() {}
}

export class ToggleSubscriptionsSelectionTagsAction implements Action { // tags to delete
  readonly type = SubscriptionsListActionTypes.TOGGLE_SELECTION_TAGS;
  constructor(public payload?: [TagsLists]) {}
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CampaignSimpleListActionType, LoadCampaignSimpleListSuccessAction} from '../../shared/actions/campaign-simple-list';
import {Campaign} from '../../shared/models/campaign';

export interface CampaignState {
  array: Campaign[];
}

const initialState: CampaignState = {
  array: [],
};

export function campaignSimpleListReducer(state = initialState, action: Action): CampaignState {
  switch (action.type) {
    case CampaignSimpleListActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    case CampaignSimpleListActionType.LOAD_NOT_FOUND:
    case CampaignSimpleListActionType.LOAD_FAIL:
      return handleLoadErrorActions();

    default:
      return state;
  }
}

function handleLoadSuccessAction(action: LoadCampaignSimpleListSuccessAction): CampaignState {
  const newState: CampaignState = { array: action.payload.array };

  return newState;
}

function handleLoadErrorActions(): CampaignState {
  const newState: CampaignState = { array: [] };
  return newState;
}

export const CampaignSelectors = {
  array: (state: CampaignState) => _.get(state, 'array', [])
};

import {Action} from '@ngrx/store';
import {type} from '../../shared';

export const DeleteCampaignActionTypes = {
  DELETE: type('[Campaign] Delete'),
  DELETE_SUCCESS: type('[Campaign] Delete Success'),
  DELETE_SELF: type('[Campaign] Delete Self'),
  DELETE_FAIL: type('[Campaign] Delete Fail')
};

export class DeleteCampaignAction implements Action {
  readonly type = DeleteCampaignActionTypes.DELETE;

  constructor() {}
}

export class DeleteCampaignSuccessAction implements Action {
  readonly type = DeleteCampaignActionTypes.DELETE_SUCCESS;

  constructor() {}
}

export class DeleteCampaignSelfAction implements Action {
  readonly type = DeleteCampaignActionTypes.DELETE_SELF;

  constructor() {}
}

export class DeleteCampaignFailAction implements Action {
  readonly type = DeleteCampaignActionTypes.DELETE_FAIL;

  constructor(public payload?: number) {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {AddMediaFailAction, AddMediaSuccessAction} from '../../../medias/actions/add-media';
import {LoadMediasAction} from '../../../shared/actions/medias-list';
import * as fromRoot from '../../../shared/reducers';
import {MediaService} from '../../services/media.service';

@Component({
  selector: 'storever-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DropZoneComponent implements OnDestroy, OnInit {
  public files: Array<any> = [];
  public filesStatus: Array<any> = [];
  public upload = true;
  @Output() addMedia: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  /** A template reference to the native file input element. */
  @ViewChild('fileInput') _fileInput: ElementRef;

  private subscription$: Subscription;
  private cptFile = 0;
  typesMedia = ['mp3', 'wav'];
  accept = '*';
  maxFileSize = 1073741824; // 2 Go
  multiple = true;
  _isHovered: boolean;
  clientUuid: any;
  sub$: Subscription;

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    this.preventDefault(evt);
    this.handleFileDrop(evt.dataTransfer.files);
  }

  @HostListener('dragover', ['$event'])
  _onDragOver(event) {
    this.preventDefault(event);
    this._isHovered = true;
  }

  @HostListener('dragleave')
  _onDragLeave() {
    this._isHovered = false;
  }

  /** Show the native OS file explorer to select files. */
  @HostListener('click')
  _onClick() {
    this.showFileSelector();
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale,
              private cdr: ChangeDetectorRef,
              private mediaService: MediaService,
              private store: Store<fromRoot.AppState>) {
    this.sub$ =
      this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client))).subscribe(client => this.clientUuid = client.uuid);
  }
  ngOnInit() {
    this.upload = true;
    this.files = [];
    this.filesStatus = [];
    this.processServiceUploaded();
  }
  processServiceUploaded() {
    this.subscription$ = this.mediaService.getMessage().subscribe(message => {
      const idToDelete = message.text.replace('idsenddata=', '');
      this.files = this.files.filter(item => {
        if (item.id === +idToDelete && item.valid && item.error === 0) {
          item.upload = true;
        }
        return item;
      });
      this.cdr.markForCheck();
    });
  }

  sizeOfmediaAccepted(size: number): number {
    if (size < 1024) {
      return -1
    } else if (size > 1073741824) { // 2go
      return 1
    } else {
      return 0;
    }
  }

  isAcceptedMedia(type: string): boolean { return type === 'audio/mp3' || type === 'audio/wav' || type === 'audio/mpeg'; }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this.upload = false;
    this.files = [];
    this.sub$.unsubscribe();
  }
  _onFilesSelected(event) {
    console.log('_onFilesSelected')
    const files: FileList = event.target.files;
    this.handleFileDrop(files);

    // Reset the native file input element to allow selecting the same file again
    this._fileInput.nativeElement.value = '';

    // fix(#32): Prevent the default event behaviour which caused the change event to emit twice.
    this.preventDefault(event);
  }
  showFileSelector() { (this._fileInput.nativeElement as HTMLInputElement).click(); }
  private handleFileDrop(files: FileList) {
    console.log('handleFileDrop')
    const result = this.parseFileList(files, this.accept, this.maxFileSize, this.multiple);

    const delta = this.filesStatus.length;
    if (Number(files.length) < 35) {
      for (let i = 0; i < result.addedFiles.length; i++) {
        const index = delta + i;
        if (this.isAcceptedMedia(result.addedFiles[i].type)) {
          if (this.sizeOfmediaAccepted(result.addedFiles[i].size) !== 0) {
            this.filesStatus.push(
              { id: index + 1, file: result.addedFiles[i].name, upload: false, valid: true, error: this.sizeOfmediaAccepted(result.addedFiles[i].size) });
            this.store.dispatch(new AddMediaFailAction());
          } else {
            this.files.push(result.addedFiles[i]);
            // tslint-disable-next-line
            const currentFileStatus = { id: index + 1, file: result.addedFiles[i].name, upload: false, valid: true, error: 0 };
            this.filesStatus.push(currentFileStatus);
            this.readFile(result.addedFiles[i]).then(fileContents => {
              // Put this string in a request body to upload it to an API.
              this.mediaService
                .uploadFile(
                  this.clientUuid,
                  {
                    name: result.addedFiles[i].name,
                    content: fileContents.toString().substring(fileContents.toString().indexOf('base64,') + 'base64,'.length, fileContents.toString().length),
                    type: result.addedFiles[i].type
                  },
                  index + 1)
                .subscribe(
                  () => {
                    currentFileStatus.upload = true;
                    this.cdr.markForCheck();
                    this.store.dispatch(new AddMediaSuccessAction());
                    this.store.dispatch(new LoadMediasAction());
                  },
                  error => {
                    this.store.dispatch(new AddMediaFailAction());
                    console.error(error);
                  });
            });
          }
        } else {
          this.filesStatus.push({ id: index + 1, file: result.addedFiles[i].name, upload: false, valid: false, error: 2 });
          this.store.dispatch(new AddMediaFailAction());
        }
      }
    } else {
      this.store.dispatch(new AddMediaFailAction());
    }
  }

  private parseFileList(files: FileList, accept: string, maxFileSize: number, multiple: boolean): FileSelectResult {
    const addedFiles: File[] = [];
    const rejectedFiles: RejectedFile[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);

      if (!this.isAccepted(file, accept)) {
        this.rejectFile(rejectedFiles, file, 'type');
        continue;
      }

      if (maxFileSize && file.size > maxFileSize) {
        this.rejectFile(rejectedFiles, file, 'size');
        continue;
      }

      if (!multiple && addedFiles.length >= 1) {
        this.rejectFile(rejectedFiles, file, 'no_multiple');
        continue;
      }

      addedFiles.push(file);
    }

    const result: FileSelectResult = { addedFiles, rejectedFiles };

    return result;
  }

  private isAccepted(file: File, accept: string): boolean {

    if (accept === '*') {
      return true;
    }

    const acceptFiletypes = accept.split(',').map(it => it.toLowerCase().trim());
    const filetype = file.type.toLowerCase();
    const filename = file.name.toLowerCase();

    const matchedFileType = acceptFiletypes.find(acceptFiletype => {
      // check for wildcard mimetype (e.g. image/*)
      if (acceptFiletype.endsWith('/*')) {
        return filetype.split('/')[0] === acceptFiletype.split('/')[0];
      }

      // check for file extension (e.g. .csv)
      if (acceptFiletype.startsWith('.')) {
        return filename.endsWith(acceptFiletype);
      }

      // check for exact mimetype match (e.g. image/jpeg)
      return acceptFiletype === filetype;
    });

    return !!matchedFileType;
  }

  private rejectFile(rejectedFiles: RejectedFile[], file: File, reason: RejectReason) {

    const rejectedFile = file as RejectedFile;
    rejectedFile.reason = reason;

    rejectedFiles.push(rejectedFile);
  }
  private async readFile(file: File): Promise<string|ArrayBuffer> {
    return new Promise<string|ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => { return resolve((e.target as FileReader).result); };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }
  private preventDefault(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}

export interface FileSelectResult {

  /** The added files, emitted in the filesAdded event. */
  addedFiles: File[];

  /** The rejected files, emitted in the filesRejected event. */
  rejectedFiles: RejectedFile[];
}

export interface RejectedFile extends File {

  /** The reason the file was rejected. */
  reason?: RejectReason;
}

export type RejectReason = 'type'|'size'|'no_multiple';

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppSettingsService, ArrayResponse, BACKGROUND_XHR_PARAM, BaseRestService, BlockUIService, SendBackResult, serializeQueryString} from '../../shared';
import {Publication, PublicationCancelForm, PublicationFilterForm, PublicationSubscription} from '../models/publication';
import {Counters} from '../../shared/models/counters';

@Injectable({ providedIn: 'root' })
export class PublicationsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'CampaignsService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(clientId: string, filter: PublicationFilterForm): Observable<SendBackResult<ArrayResponse<Publication>>> {
    return this.get<ArrayResponse<Publication>>(`/clients/${clientId}/audio/publications${serializeQueryString(filter)}`);
  }
  getListSub(clientId: string, filter: any, publicationId: string): Observable<SendBackResult<ArrayResponse<PublicationSubscription>>> {
    return this.get<ArrayResponse<PublicationSubscription>>(`/clients/${clientId}/audio/publications/${publicationId}/details${serializeQueryString(filter)}`);
  }
  getDetailsPublicationSub(clientId: string, publicationId: string): Observable<SendBackResult<PublicationSubscription>> {
    return this.get<PublicationSubscription>(`/clients/${clientId}/audio/publications/${publicationId}`);
  }
  cancel(clientId: string, form: PublicationCancelForm, publicationId: number): Observable<SendBackResult<Response>> {
    return this.post(`/clients/${clientId}/audio/publications/cancel/${publicationId}`, form);
  }
  getStatsSlow(clientId: string): Observable<SendBackResult<Counters>> {
    return this.get<Counters>(`/clients/${clientId}/players-report/stats-heavy?${BACKGROUND_XHR_PARAM}`);
  }
  reTryPublication(clientId: string, packageId: string): Observable<SendBackResult<{}>> { return this.post<{}>(`/RequeuePackage/${packageId}`, null); }
}

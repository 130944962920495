import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {TagsLists} from '../../medias/models/tags-list';
import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {Campaign} from '../../shared/models/campaign';
import {
  CampaignsListActionTypes,
  ChangeCampaignsPageSizeAction,
  LoadCampaignsSuccessAction,
  OrderCampaignsByAction,
  PageCampaignsToAction,
  QuickSearchCampaignsAction,
  SearchCampaignsAction,
  ToggleCampaignSelectionAction,
  ToggleCampaignsSelectionTagsAction,
  ToggleSearchCampaignsAction
} from '../actions/campaigns-list';
import {CampaignsFilterForm} from '../models/campaigns-filter-form';
import {SelectedCampaigns} from '../models/selected-campaigns';

export interface CampaignsListState {
  showFilter: boolean;
  quickSearch: string;
  filter: CampaignsFilterForm;
  page: number;
  pageSize: number;
  orderBy: string;
  array: Campaign[];
  count: number;
  downloading: boolean;
  selectedCampaigns: SelectedCampaigns[];
  selectedTagsList: TagsLists[] // tags to delete
}

const initialState: CampaignsListState = {
  showFilter: false,
  quickSearch: undefined,
  filter: undefined,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: 'updated descending',
  array: [],
  count: 0,
  downloading: false,
  selectedCampaigns: [],
  selectedTagsList: [] // tags to delete
};

export function CampaignsListReducer(state = initialState, action: Action): CampaignsListState {
  switch (action.type) {
    case CampaignsListActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case CampaignsListActionTypes.QUICK_SEARCH:
      return handleQuickSearchAction(state, action);
    case CampaignsListActionTypes.SEARCH:
      return handleSearchAction(state, action);
    case CampaignsListActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case CampaignsListActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case CampaignsListActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case CampaignsListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CampaignsListActionTypes.LOAD_VALIDATION_FAIL:
    case CampaignsListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case CampaignsListActionTypes.SELECT_PAGE:
      return handleSelectPageAction(state);
    case CampaignsListActionTypes.UNSELECT_PAGE:
      return handleUnselectPageAction(state);
    case CampaignsListActionTypes.TOGGLE_SELECTION:
      return handleToggleCampaignSelectionAction(state, action);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case CampaignsListActionTypes.CLEAR:
      return handleClearAction(state);
    case CampaignsListActionTypes.TOGGLE_SELECTION_TAGS:
      return handleToogleTagsListToDelete(state, action);
    default:
      return state;
  }
}

function getTagsSelected(state: CampaignsListState, action: ToggleCampaignsSelectionTagsAction) { // tags to delete
  let selectedTagsList: TagsLists[];
  for (const actionPayload of action.payload) {
    const selectedIndex = _.findIndex(state.selectedTagsList, ['id', actionPayload.id]);
    if (selectedIndex > -1) {
      selectedTagsList = _.filter(state.selectedTagsList, selectedTags => selectedTags.id !== actionPayload.id);
    } else {
      selectedTagsList = [...state.selectedTagsList, actionPayload];
    }
  }
  return selectedTagsList;
}

function getCampaignsTagsListFromState(state: CampaignsListState): TagsLists[] { // tags to delete
  const selectedTags: TagsLists[] = [];
  for (const elem of state.array) {
    if (elem.tags) {
      selectedTags.push({ id: elem.id, tagsList: elem.tags })
    }
  }
  return selectedTags;
}

function handleClearAction(state: CampaignsListState): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleSelectPageAction(state: CampaignsListState): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: _.union(state.selectedCampaigns, _.map(state.array, campaign => ({ id: campaign.id, name: campaign.name, archive: campaign.archive }))),
    selectedTagsList: getCampaignsTagsListFromState(state) // tags to delete
  };
  return newState;
}
function handleUnselectPageAction(state: CampaignsListState): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: [],
    selectedTagsList: []
  };
  return newState;
}
function handleToggleSearchAction(state: CampaignsListState, action: ToggleSearchCampaignsAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: action.payload,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleQuickSearchAction(state: CampaignsListState, action: QuickSearchCampaignsAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: action.payload,
    filter: state.filter,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: [],
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleSearchAction(state: CampaignsListState, action: SearchCampaignsAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: action.payload,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: [],
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handlePageToAction(state: CampaignsListState, action: PageCampaignsToAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangePageSizeAction(state: CampaignsListState, action: ChangeCampaignsPageSizeAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: 1,
    pageSize: action.payload,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleOrderByAction(state: CampaignsListState, action: OrderCampaignsByAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: action.payload,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadSuccessAction(state: CampaignsListState, action: LoadCampaignsSuccessAction): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadErrorActions(state: CampaignsListState): CampaignsListState {
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleToggleCampaignSelectionAction(state: CampaignsListState, action: ToggleCampaignSelectionAction): CampaignsListState {
  const selectedIndex = _.findIndex(state.selectedCampaigns, ['id', action.payload]);
  let selectedCampaigns: SelectedCampaigns[];
  if (selectedIndex > -1) {
    selectedCampaigns = _.filter(state.selectedCampaigns, sc => sc.id !== action.payload);
  } else {
    const campaign = _.find(state.array, u => u.id === action.payload);
    const selectedCampaign: SelectedCampaigns = { id: campaign.id, name: campaign.name, archive: campaign.archive };
    selectedCampaigns = [...state.selectedCampaigns, selectedCampaign];
  }

  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: false,
    selectedCampaigns: selectedCampaigns,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangeClientAction(): CampaignsListState {
  return {
    showFilter: false,
    quickSearch: undefined,
    filter: undefined,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: undefined,
    array: [],
    count: 0,
    downloading: false,
    selectedCampaigns: [],
    selectedTagsList: []
  };
}

function handleToogleTagsListToDelete(state: CampaignsListState, action: ToggleCampaignsSelectionTagsAction): CampaignsListState { // tags to delete
  const newState: CampaignsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedCampaigns: state.selectedCampaigns,
    selectedTagsList: getTagsSelected(state, action)
  };
  return newState;
}

export const CampaignsListSelectors = {
  showFilter: (state: CampaignsListState) => _.get(state, 'showFilter', false),
  quickSearch: (state: CampaignsListState) => _.get(state, 'quickSearch'),
  filter: (state: CampaignsListState) => _.get(state, 'filter'),
  page: (state: CampaignsListState) => _.get(state, 'page', 1),
  pageSize: (state: CampaignsListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  orderBy: (state: CampaignsListState) => _.get(state, 'orderBy'),
  array: (state: CampaignsListState) => _.get(state, 'array', []),
  count: (state: CampaignsListState) => _.get(state, 'count', 0),
  downloading: (state: CampaignsListState) => _.get(state, 'downloading', false),
  selectedCampaigns: (state: CampaignsListState) => _.get(state, 'selectedCampaigns', []),
  selectedTags: (state: CampaignsListState) => _.get(state, 'selectedTagsList', [])

};

import {Action} from '@ngrx/store';
import {type} from '../utilities/type';
export const PlayLogsActionTypes = {
  TOGGLE_PLAY: type('[Play Logs] Toggle Play Audio')
};

export class TogglePlayLogsPlayAction implements Action {
  readonly type = PlayLogsActionTypes.TOGGLE_PLAY;

  constructor(public payload?: string) {}
}

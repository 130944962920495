import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, convertToParamMap} from '@angular/router';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import {Observable, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {AddMediaAction} from '../../medias/actions/add-media';
import {AddTagsAction} from '../../medias/actions/add-tags';
import {ArchiveMediasAction} from '../../medias/actions/archive-medias';
import {DeleteMediasAction} from '../../medias/actions/delete-medias';
import {DeleteTagsAction} from '../../medias/actions/delete-tags';
import {SearchMediasValidateAction} from '../../medias/actions/media-list-validate';
import {UsedMediasAction} from '../../medias/actions/usedMedias';
import {AddMediaForm} from '../../medias/models/add-media';
import {TagsLists} from '../../medias/models/tags-list';
import {UsedMedias} from '../../medias/models/used-medias';
import * as fromMedia from '../../medias/reducers/index';
import {DownloadMediasAction} from '../actions/download-medias';
import {
  ChangeMediasPageSizeAction,
  ClearMediasAction,
  LoadMediasAction,
  OrderMediasByAction,
  PageMediasToAction,
  QuickSearchMediasAction,
  SearchMediasAction,
  SelectMediasPageAction,
  ToggleMediaSelectionAction,
  ToggleMediaSelectionTagsAction,
  ToggleSearchMediasAction,
  UnselectMediasPageAction
} from '../actions/medias-list';
import {TogglePlayLogsPlayAction} from '../actions/play-logs';
import {LoadTagsListAction} from '../actions/tags';
import {GetRowSizeAction, SetRowSizeAction} from '../actions/user-preferences';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {MediaFilterComponent} from '../filter/media-filter/media-filter.component';
import {LoadLanguagesAction, replace} from '../index';
import {AppSettings} from '../models/app-settings';
import {Language} from '../models/language';
import {Media} from '../models/media';
import {MediasFilterForm} from '../models/medias-filter-form';
import {Role} from '../models/role';
import {SelectedMedias} from '../models/selected-medias';
import {TagsList} from '../models/tags-list';
import * as fromRoot from '../reducers/index';
import {AppSettingsService} from '../services/app-settings.service';

@Component({ selector: 'storever-media-list', templateUrl: './media-list.component.html', styleUrls: ['./media-list.component.scss'] })
export class MediaListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() showAsPopup: boolean;
  showFilter$: Observable<boolean>;
  quickSearch$: Observable<string>;
  filter$: Observable<MediasFilterForm>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  orderBy$: Observable<string>;
  media$: Observable<Media[]>;
  count$: Observable<number>;
  MVcount$: Observable<number>;
  usedMedia$: Observable<UsedMedias[]>;
  roles$: Observable<Role[]>;
  downloading$: Observable<boolean>;
  languages$: Observable<Language[]>;
  selectedMedia$: Observable<SelectedMedias[]>;
  isActionsDisabled$: Observable<boolean>;
  tagsList$: Observable<TagsList[]>;
  appSettings$: Observable<AppSettings>;
  validate = true;
  data;
  dataMedias: Media[];
  @ViewChild('filterMedia') filterMedia: MediaFilterComponent;
  validateMedia: number;
  private sub: Subscription;
  toDeleteTags: Subject<boolean>;                     // tags to delete
  getSelectedTagsFromMedia$: Observable<TagsLists[]>; // tags to delete

  constructor(private store: Store<fromRoot.AppState>,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private appSettingsService: AppSettingsService,
              private activatedRoute: ActivatedRoute) {
    this.storeSelect();
    this.toDeleteTags = new Subject<boolean>(); // tags to delete
  }

  storeDispatch() {
    this.store.dispatch(new LoadTagsListAction());
    this.store.dispatch(new LoadLanguagesAction());
    this.store.dispatch(new ClearMediasAction());
    this.store.dispatch(new SearchMediasValidateAction({ validated: false, convertedOnly: 'true' }));
    this.sub = this.activatedRoute.queryParams.pipe(distinctUntilChanged((x, y) => _.isEqual(x, y))).subscribe(query => {
      if (_.isEmpty(query)) {
        this.store.dispatch(new GetRowSizeAction('medias'));

        if (this.showAsPopup) {
          this.store.dispatch(replace([], { $page: 1, archived: false, validated: true }));
        } else {
          this.store.dispatch(replace([], { $page: 1, archived: false }));
        }
      } else {
        this.store.dispatch(new LoadMediasAction());
      }
    });
  }
  storeSelect() {
    const query$ = this.store.select(fromRoot.selectors.getRouterState).pipe(filter(state => !_.isEmpty(state)), map(state => state.queryParams));
    this.page$ = query$.pipe(map(convertToParamMap), map(query => parseInt(query.get('$page') || '1', 10)));
    this.pageSize$ = query$.pipe(map(convertToParamMap), map(query => parseInt(query.get('$length'), 10) || DEFAULT_PAGE_SIZE));
    this.orderBy$ = query$.pipe(map(convertToParamMap), map(query => query.get('$orderBy')));
    this.quickSearch$ = query$.pipe(map(convertToParamMap), map(query => query.get('quickSearch')));
    this.filter$ = query$.pipe(map(query => Object.assign(query)));
    this.orderBy$ = query$.pipe(map(convertToParamMap), map(query => query.get('$orderBy')));

    this.selectedMedia$ = this.store.select(fromRoot.selectors.getSelectedMedias);
    this.isActionsDisabled$ = this.selectedMedia$.pipe(map(selectedMedias => _.isEmpty(selectedMedias)));
    this.roles$ = this.store.select(fromRoot.selectors.getMyRoles);
    this.showFilter$ = this.store.select(fromRoot.selectors.getShowFilter);
    this.count$ = this.store.select(fromRoot.selectors.getMediaCount);
    this.downloading$ = this.store.select(fromRoot.selectors.getDownloading);
    this.languages$ = this.store.select(fromRoot.selectors.getLanguages);
    this.tagsList$ = this.store.select(fromRoot.selectors.getTags);
    this.usedMedia$ = this.store.select(fromMedia.selectors.getUsedMedias);
    this.media$ = this.store.select(fromRoot.selectors.getMedia);
    this.MVcount$ = this.store.select(fromMedia.selectors.getValidateMediaCount);
    this.MVcount$.subscribe(data => (data ? this.validateMedia = data : null), err => console.log('err', err));
    this.getSelectedTagsFromMedia$ = this.store.select(fromRoot.selectors.getSelectedTagsList);
  }
  ngAfterViewInit() {
    //  this.filterMedia.resetFilter();
  }

  ngOnInit() {
    // this.store.dispatch(replace([], { $page: 1, $length: DEFAULT_PAGE_SIZE, archived: false }));
    this.storeDispatch();
    this.disabledActionButton(this.media$);
    this.appSettings$ = this.appSettingsService.settings$;
    this.languages$.subscribe(data => (data ? (this.data = data) : null));
    this.media$.subscribe(data => (data ? this.dataMedias = data : null));
    this.store.dispatch(new SearchMediasValidateAction({ validated: false, convertedOnly: 'true' }));
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  disabledActionButton(value): void {
    // TODO: adapt this solution and remove the setTimeout !!!
    value.subscribe(data => {
      if (!!data[0]) {
        this.store.dispatch(new ToggleMediaSelectionAction(data[0].id));
        setTimeout(() => { this.store.dispatch(new ToggleMediaSelectionAction(data[0].id)); }, 300);
      }
    });
  }
  notValid(value) {
    if (value === null) {
      this.validate = false;
    } else {
      this.validate = true;
    }
  }
  applyFilter(form: MediasFilterForm): void {
    console.log('applyFilter' , form)
    this.store.dispatch(new SearchMediasAction(form));
  }
  isInRole$(role: string): Observable<boolean> { return this.roles$.pipe(map(roles => _.findIndex(roles, ['code', role]) > -1)); }

  applyQuickFilter(query: string): void { this.store.dispatch(new QuickSearchMediasAction(query)); }

  toggleSearchForm(show: boolean): void { this.store.dispatch(new ToggleSearchMediasAction(show)); }
  selectPage(): void {
    // this.getAllTagsFromMedia(this.dataMedias); // tags to delete
    this.store.dispatch(new SelectMediasPageAction());
    this.store.dispatch(new UsedMediasAction());
  }
  unselectPage(): void { this.store.dispatch(new UnselectMediasPageAction()); }
  pageChanged(page: number): void { this.store.dispatch(new PageMediasToAction(page)); }
  pageSizeChanged(pageSize: number): void {
    this.store.dispatch(new SetRowSizeAction({ name: 'medias', value: pageSize }));
    // this.store.dispatch(new ChangeMediasPageSizeAction(pageSize));
  }
  orderByChanged(orderBy: string): void { this.store.dispatch(new OrderMediasByAction(orderBy)); }
  downloadCSV(): void { this.store.dispatch(new DownloadMediasAction()); }
  showModal($event: MouseEvent, modal: any): void {
    this.toDeleteTags.next(false);
    $event.preventDefault();
    modal.open();
  }
  showModalDelete($event: MouseEvent, modal: any): void {
    if (modal.UMedias) {
      this.store.dispatch(new UsedMediasAction());
    }
    $event.preventDefault();
    modal.open();
  }
  deleteNotUsedMedias(form: any) {
    this.store.dispatch(new UnselectMediasPageAction());
    if (form.length > 0) {
      for (const entry of form) {
        this.store.dispatch(new ToggleMediaSelectionAction(entry.id));
      }
      this.store.dispatch(new DeleteMediasAction());
    }
  }
  isSelected$(mediaId: number): Observable<boolean> {
    return this.selectedMedia$.pipe(map(selectedMedias => _.findIndex(selectedMedias, ['id', mediaId]) > -1));
  }

  archiveSelectedMedias(archive: boolean): void {
    this.store.dispatch(new ArchiveMediasAction(archive));
    this.unselectPage();
  }
  deleteSelectedMedias(): void {
    this.store.dispatch(new DeleteMediasAction());
    this.unselectPage();
  }
  AddMedia(file: AddMediaForm): void { this.store.dispatch(new AddMediaAction(file)); }
  addTagsListSelected(tagsList: Object[]): void {
    this.store.dispatch(new AddTagsAction(tagsList));
    this.store.dispatch(new LoadTagsListAction());
    this.unselectPage();
  }
  isPlaying$(fileName: string): Observable<boolean> {
    return this.store.select(fromRoot.selectors.getPlayLogsPlaying).pipe(map(playing => fileName === playing));
  }

  togglePlay(fileName: string): void { this.store.dispatch(new TogglePlayLogsPlayAction(fileName)); }
  showModalTagsToDelete($event: MouseEvent, modal: any): void { // tags to delete
    this.toDeleteTags.next(true);
    $event.preventDefault();
    modal.open();
  }
  deleteTagsListSelected(tagsList: Object[]): void { // tags to delete
    console.log(tagsList)
    this.store.dispatch(new DeleteTagsAction(tagsList));
    this.store.dispatch(new LoadTagsListAction());
    this.unselectPage();
  }
  toggleMediaSelection(mediaId: number, tags: TagsList[]): void { // tags to delete
    this.store.dispatch(new ToggleMediaSelectionAction(mediaId));
    if (tags && tags.length) {
      this.store.dispatch(new ToggleMediaSelectionTagsAction([{ id: mediaId, tagsList: tags }]));
    }
  }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UsedMediasActionTypes, UsedMediasSuccessAction} from '../actions/usedMedias';
import {UsedMedias} from '../models/used-medias';

export interface UsedMediasState {
  array: UsedMedias[];
}

const initialState: UsedMediasState = {
  array: []
};

export function usedMediasReducer(state = initialState, action: Action): UsedMediasState {
  switch (action.type) {
    case UsedMediasActionTypes.USED_SUCCESS:
      return handleLoadSuccessAction(action);
    case UsedMediasActionTypes.USED_NOT_FOUND:
    case UsedMediasActionTypes.USED_FAIL:
      return handleLoadErrorActions();

    default:
      return state;
  }
}

function handleLoadSuccessAction(action: UsedMediasSuccessAction): UsedMediasState {
  const newState: UsedMediasState = { array: action.payload.array };

  return newState;
}

function handleLoadErrorActions(): UsedMediasState {
  const newState: UsedMediasState = { array: [] };
  return newState;
}

export const UsedMediasSelectors = {
  array: (state: UsedMediasState) => _.get(state, 'array', [])
};

import {ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {Publication} from '../../publications/models/publication';
import {Campaign} from '../../shared/models/campaign';
import * as fromRoot from '../../shared/reducers';
import {MediasListSelectors} from '../../shared/reducers/medias-list';
import {Countries} from '../models/countries';
import {GeocodedAddress} from '../models/geocoded-address';
import {SelectedSubscriptions} from '../models/selected-subscriptions';
import {SubscriptionFilter} from '../models/subscription-filter';
import {SelectedSubs, Subscriptions} from '../models/subscriptions';
import {TagsList} from '../models/tags-list';
import {TemplatesPrograms} from '../models/templates-programs';

import {CampaignSelectors, campaignSimpleListReducer, CampaignState} from './campaign-simple-list';
import {CountriesDetailSelectors, CountriesListReducer, CountriesListState} from './countries';
import {PublicationSelectors, publicationSimpleListReducer, PublicationState} from './publication-simple-list';
import {SubscriptionDetailReducer, SubscriptionDetailSelectors, SubscriptionsDetailState} from './subscription';
import {SubscriptionsListReducer, SubscriptionsListSelectors, SubscriptionsListState} from './subscriptions';
import {tagsReducer, tagsSelectors, TagsState} from './taglist';
import {templatesProgramsReducer, templatesProgramsSelectors, TemplatesProgramsState} from './templates-programs';

export interface SubscriptionsState {
  campaign: CampaignState;
  publication: PublicationState;
  subscriptions: SubscriptionsListState;
  subscription: SubscriptionsDetailState;
  tags: TagsState;
  countries: CountriesListState;
  templatesPrograms: TemplatesProgramsState;
}

export interface AppState extends fromRoot.AppState {
  campaign: CampaignState;
  publication: PublicationState;
  subscriptions: SubscriptionsState;
  subscription: SubscriptionsDetailState;
  tags: TagsState;
  countries: CountriesListState;
  templatesPrograms: TemplatesProgramsState;
}

export const reducers: ActionReducerMap<SubscriptionsState> = {

  campaign: campaignSimpleListReducer,
  publication: publicationSimpleListReducer,
  subscriptions: SubscriptionsListReducer,
  subscription: SubscriptionDetailReducer,
  tags: tagsReducer,
  countries: CountriesListReducer,
  templatesPrograms: templatesProgramsReducer
};

const getCampaignsState = createFeatureSelector('subscriptions');
const rootSelectors = {
  subscriptions: createSelector(getCampaignsState, (state: SubscriptionsState) => _.get(state, 'subscriptions')),
  tags: createSelector(getCampaignsState, (state: TagsState) => _.get(state, 'tags')),
  countries: createSelector(getCampaignsState, (state: TagsState) => _.get(state, 'countries')),
  programs: createSelector(getCampaignsState, (state: TemplatesProgramsState) => _.get(state, 'templatesPrograms')),
  subscription: createSelector(getCampaignsState, (state: SubscriptionsState) => _.get(state, 'subscription')),
  campaign: createSelector(getCampaignsState, (state: SubscriptionsState) => _.get(state, 'campaign')),
  publication: createSelector(getCampaignsState, (state: PublicationState) => _.get(state, 'publication')),

};

export interface Selectors {
  getShowFilter: any;
  getQuickSearch: any;
  getFilter: any;
  getPage: any;
  getPageSize: any;
  getOrderBy: any;
  getCount: any;
  getSubscriptions: any;
  getSubscription: any;
  getGeocodedAddress: any;
  getTags: any;
  getCountries: any;
  getPrograms: any;
  getSelectedSubscriptions: any;
  getCampaign: any;
  getPublication: any;
  getSelectedLocCampaign: any;
  getSelectedTagsList: any;
}

export const selectors: Selectors = {
  getShowFilter: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.showFilter),
  getQuickSearch: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.quickSearch),
  getFilter: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.filter),
  getPage: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.page),
  getPageSize: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.pageSize),
  getOrderBy: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.orderBy),
  getCount: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.count),
  getSubscriptions: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.array),
  getSubscription: createSelector(rootSelectors.subscription, SubscriptionDetailSelectors.value),
  getGeocodedAddress: createSelector(rootSelectors.subscription, SubscriptionDetailSelectors.geocodedAddress),
  getTags: createSelector(rootSelectors.tags, tagsSelectors.array),
  getCountries: createSelector(rootSelectors.countries, CountriesDetailSelectors.array),
  getPrograms: createSelector(rootSelectors.programs, templatesProgramsSelectors.value),
  getSelectedSubscriptions: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.selectedSubscriptions),
  getCampaign: createSelector(rootSelectors.campaign, CampaignSelectors.array),
  getPublication: createSelector(rootSelectors.publication, PublicationSelectors.array),
  getSelectedLocCampaign: createSelector(rootSelectors.subscription, SubscriptionDetailSelectors.selectedSubLoc),
  // @ts-ignore
  getSelectedTagsList: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.selectedTags)

};

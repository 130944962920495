import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {TagsList} from '../models/tags-list';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class TagsListsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'TagsListsService'; }

  protected get isAnonymous(): boolean { return false; }

  mediasTagsList(clientId: string): Observable<SendBackResult<ArrayResponse<TagsList>>> {
    return this.get<ArrayResponse<TagsList>>(`/clients/${clientId}/audio/Tags/Media/Commercials`);
  }
  CampaignsTagsList(clientId: string): Observable<SendBackResult<ArrayResponse<TagsList>>> {
    return this.get<ArrayResponse<TagsList>>(`/clients/${clientId}/audio/Tags/Schedules/Commercials`);
  }
}

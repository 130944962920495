import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {Subscriptions} from '../../shared/models/subscriptions';
import {
  ChangeSubscriptionsPageSizeAction,
  LoadSubscriptionsSuccessAction,
  OrderSubscriptionsByAction,
  PageSubscriptionsToAction,
  SubscriptionsListActionTypes
} from '../actions/campaign-subscriptions';
import {SelectedSubscriptions} from '../models/selected-subscriptions';

export interface SubscriptionsListState {
  page: number;
  pageSize: number;
  orderBy: string;
  array: Subscriptions[];
  count: number;

  selectedSubscriptions: SelectedSubscriptions[];
}

const initialState: SubscriptionsListState = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: undefined,
  array: [],
  count: 0,
  selectedSubscriptions: []
};

export function SubscriptionsListReducer(state = initialState, action: Action): SubscriptionsListState {
  switch (action.type) {
    case SubscriptionsListActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case SubscriptionsListActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case SubscriptionsListActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case SubscriptionsListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case SubscriptionsListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);

    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}
function handlePageToAction(state: SubscriptionsListState, action: PageSubscriptionsToAction): SubscriptionsListState {
  return {
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions
  };
}

function handleChangePageSizeAction(state: SubscriptionsListState, action: ChangeSubscriptionsPageSizeAction): SubscriptionsListState {
  return {
    page: 1,
    pageSize: action.payload,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions
  };
}

function handleOrderByAction(state: SubscriptionsListState, action: OrderSubscriptionsByAction): SubscriptionsListState {
  return {
    page: state.page,
    pageSize: state.pageSize,
    orderBy: action.payload,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions
  };
}

function handleLoadSuccessAction(state: SubscriptionsListState, action: LoadSubscriptionsSuccessAction): SubscriptionsListState {
  return {
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    selectedSubscriptions: state.selectedSubscriptions
  };
}

function handleLoadErrorActions(state: SubscriptionsListState): SubscriptionsListState {
  return { page: state.page, pageSize: state.pageSize, orderBy: state.orderBy, array: [], count: 0, selectedSubscriptions: state.selectedSubscriptions };
}

function handleChangeClientAction(): SubscriptionsListState {
  return { page: 1, pageSize: DEFAULT_PAGE_SIZE, orderBy: undefined, array: [], count: 0, selectedSubscriptions: [] };
}

export const SubscriptionsListSelectors = {
  page: (state: SubscriptionsListState) => _.get(state, 'page', 1),
  pageSize: (state: SubscriptionsListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  orderBy: (state: SubscriptionsListState) => _.get(state, 'orderBy'),
  array: (state: SubscriptionsListState) => _.get(state, 'array', []),
  count: (state: SubscriptionsListState) => _.get(state, 'count', 0),
  selectedSubscriptions: (state: SubscriptionsListState) => _.get(state, 'selectedSubscriptions', [])
};

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AlertMessage} from '../models/alert-message';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class AlertsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'SharedAlertsService'; }

  protected get isAnonymous(): boolean { return false; }

  protected get showBlockUI(): boolean { return false; }

  getCurrentMessages(clientId: string): Observable<SendBackResult<ArrayResponse<AlertMessage>>> {
    return this.get<ArrayResponse<AlertMessage>>(`/clients/${clientId}/alert-messages`);
  }
}

import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';

import {LayoutType} from '../../models/layout-type.enum';
import {LayoutService} from '../../services/layout.service';

@Component({
  selector: 'storever-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorLayoutComponent implements OnInit {
  constructor(private layoutService: LayoutService, @Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() { this.layoutService.setLayout(LayoutType.Error); }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {Media} from '../../shared/models/media';
import {SelectedMedias} from '../../shared/models/selected-medias';
import {ChangeMediasPageSizeAction, LoadMediasSuccessAction, MediasListActionTypes, OrderMediasByAction, PageMediasToAction} from '../actions/campaign-medias';

export interface MediasListState {
  page: number;
  pageSize: number;
  orderBy: string;
  array: Media[];
  count: number;

  selectedMedias: SelectedMedias[];
}

const initialState: MediasListState = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: undefined,
  array: [],
  count: 0,
  selectedMedias: []
};

export function MediasListReducer(state = initialState, action: Action): MediasListState {
  switch (action.type) {
    case MediasListActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case MediasListActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case MediasListActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case MediasListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case MediasListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);

    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handlePageToAction(state: MediasListState, action: PageMediasToAction): MediasListState {
  return {
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMedias: state.selectedMedias
  };
}

function handleChangePageSizeAction(state: MediasListState, action: ChangeMediasPageSizeAction): MediasListState {
  return { page: 1, pageSize: action.payload, orderBy: state.orderBy, array: state.array, count: state.count, selectedMedias: state.selectedMedias };
}

function handleOrderByAction(state: MediasListState, action: OrderMediasByAction): MediasListState {
  return { page: state.page, pageSize: state.pageSize, orderBy: action.payload, array: state.array, count: state.count, selectedMedias: state.selectedMedias };
}

function handleLoadSuccessAction(state: MediasListState, action: LoadMediasSuccessAction): MediasListState {
  return {
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    selectedMedias: state.selectedMedias
  };
}

function handleLoadErrorActions(state: MediasListState): MediasListState {
  return { page: state.page, pageSize: state.pageSize, orderBy: state.orderBy, array: [], count: 0, selectedMedias: state.selectedMedias };
}

function handleChangeClientAction(): MediasListState {
  return { page: 1, pageSize: DEFAULT_PAGE_SIZE, orderBy: undefined, array: [], count: 0, selectedMedias: [] };
}

export const MediasListSelectors = {
  page: (state: MediasListState) => _.get(state, 'page', 1),
  pageSize: (state: MediasListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  orderBy: (state: MediasListState) => _.get(state, 'orderBy'),
  array: (state: MediasListState) => _.get(state, 'array', []),
  count: (state: MediasListState) => _.get(state, 'count', 0),
  selectedMedias: (state: MediasListState) => _.get(state, 'selectedMedias', [])
};

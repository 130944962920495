import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Publication} from '../../publications/models/publication';

import {PublicationFailedListActionTypes, PublicationFailedSuccessAction} from '../actions/publication-failed-list';

export interface PublicationFailedState {
  array: Publication[];
  count: number;
}
const initialState: PublicationFailedState = {
  array: [],
  count: 0,
};

export function publicationFailedReducer(state = initialState, action: Action): PublicationFailedState {
  switch (action.type) {
    case PublicationFailedListActionTypes.LOAD_FAILED_SUCCESS:
      return handleLoadFailedSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadFailedSuccessAction(action: PublicationFailedSuccessAction): PublicationFailedState {
  const newState: PublicationFailedState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const PublicationFailedSelectors = {
  array: (state: PublicationFailedState) => _.get(state, 'array', []),
  count: (state: PublicationFailedState) => _.get(state, 'count', 0)
};

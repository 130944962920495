import {Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {Subscription} from 'rxjs';

import {debug} from '../../../../rxjs-operators';
import {SendBackResultCode} from '../../../shared';
import {LostPasswordForm} from '../../models/lost-password-form';
import {AnonymousUserService} from '../../services/anonymous-user.service';

@Component({ selector: 'storever-lost-password', templateUrl: './lost-password.component.html', styleUrls: ['./lost-password.component.scss'] })
export class LostPasswordComponent implements OnDestroy {
  lostPasswordForm: UntypedFormGroup;
  submitting = false;
  resultCode: SendBackResultCode;
  private sub: Subscription;

  constructor(private userService: AnonymousUserService, fb: UntypedFormBuilder, @Inject(L10N_LOCALE) public locale: L10nLocale) {
    this.lostPasswordForm = fb.group({ email: [undefined, Validators.compose([Validators.required, Validators.email])] });
  }

  ngOnDestroy() {
    // super.cancelParamSubscriptions();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  submitForm(form: LostPasswordForm): void {
    this.submitting = true;
    this.sub = this.userService.lostPassword(form.email)
                 .pipe(debug('Lost password result'))
                 .subscribe(
                   res => {
                     this.submitting = false;
                     this.resultCode = res.statusCode;
                   },
                   () => {
                     this.submitting = false;
                     this.resultCode = SendBackResultCode.Error;
                   });
  }

  tryAgain($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.submitting = false;
    this.resultCode = undefined;
  }
}

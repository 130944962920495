import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Publish, PublishForm, PublishProgramForm} from '../models/publish';
import {SendBackResult} from '../models/send-back-result';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class PublishService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'PublishService'; }

  protected get isAnonymous(): boolean { return false; }

  getCount(clientId: string): Observable<SendBackResult<Publish>> { return this.get<Publish>(`/clients/${clientId}/audio/publications/pendings/count`); }

  publish(clientId: string, form: PublishForm): Observable<SendBackResult<any>> {
    return this.post<any>(`/clients/${clientId}/audio/publications/publish-commercial`, form);
  }
  publishProgram(clientId: string, form: PublishProgramForm): Observable<SendBackResult<any>> {
    return this.post<any>(`/clients/${clientId}/audio/publications/publish-music`, form);
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppSettingsService, ArrayResponse, BaseRestService, BlockUIService, SendBackResult, serializeQueryString} from '../../shared/index';
import {Media} from '../../shared/models/media';
import {MediasFilter} from '../../shared/models/medias-filter';
import {EditMediaForm} from '../models/edit-media-form';
import {UsedMedias} from '../models/used-medias';

@Injectable({ providedIn: 'root' })
export class MediasService extends BaseRestService {
  private subject = new Subject<any>();

  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'MediasService'; }
  protected get isAnonymous(): boolean { return false; }

  get saveAsProxyUrl$(): Observable<string> { return this.appSettingsService.settings$.pipe(map(appSettings => `${appSettings.api.url}/savefile`)); }

  getList(clientId: string, filter: MediasFilter): Observable<SendBackResult<ArrayResponse<Media>>> {
    return this.get<ArrayResponse<Media>>(`/clients/${clientId}/audio/media/commercials${serializeQueryString(filter)}`);
  }

  getListValidate(clientId: string, filter: MediasFilter): Observable<SendBackResult<ArrayResponse<Media>>> {
    return this.get<ArrayResponse<Media>>(`/clients/${clientId}/audio/media/commercials${serializeQueryString(filter)}`);
  }

  downloadCSV(clientId: string): Observable<string> {
    return this.download(`/clients/${clientId}/audio/media/commercials/all?output=csv`).pipe(map(res => res.text()));
  }

  archive(clientId: string, archive: object): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/archive`, archive);
  }

  deleteList(clientId: string, deletes: object): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/delete`, deletes);
  }

  load_used_medias(clientId: string, campaignList: object): Observable<SendBackResult<ArrayResponse<UsedMedias>>> {
    return this.post<ArrayResponse<UsedMedias>>(`/clients/${clientId}/audio/media/commercials/used`, campaignList);
  }

  addTags(clientId: string, tagList: object): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/tag`, tagList);
  }

  deleteTags(clientId: string, tagList: any): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/deleteTag`, tagList);
  }

  validate(clientId: string, media: EditMediaForm): Observable<SendBackResult<{}>> {
    return this.patch<{}>(`/clients/${clientId}/audio/media/commercials/${media.id}/valid`, media);
  }

  validateError(resp: any): void { this.subject.next({ resp }); }

  validateOk(resp: any): void { this.subject.next({ resp }); }

  getResponse(): Observable<any> { return this.subject.asObservable(); }

  getClose() { this.subject.complete(); }
}

<main>
  <div class="cloud-icon">
    <i class="fa fa-cloud-upload">&nbsp;</i>
  </div>
  <h2 class="title" translate>{{ 'ADD_MEDIA_DIALOG_TITLE2' | translate:locale.language }} (<span *ngFor="let typeMedia of typesMedia">{{ typeMedia }},</span>...)</h2>
  <input #fileInput type="file" id="id" accept=".mp3,.wav" (change)="_onFilesSelected($event)" multiple>
</main>
<h2 class="title-margin" *ngIf="filesStatus.length" translate>ADD_MEDIA_DIALOG_DROPPED_FILES</h2>
  <ul class="container-files">
    <li *ngFor="let file of filesStatus">
         <div class="loading">
           <span  class="sr-only" translate>LOADING</span>
           <!-- valid files -->
           <div *ngIf="file.valid" class="contain-elem">
             <h4 class="file-id">{{ file.id }} - </h4>
             <h5 class="file-name">{{ file.file }}</h5>
             <!-- not upload yet -->
             <i *ngIf="!file.upload && file.error===0" class="fa fa-spinner fa-pulse" style="color:black"></i>
             <!-- uploaded succesfully -->
             <i *ngIf="file.error===0 && file.upload"  class="fa fa-check" style="color:green" aria-hidden="true"></i>
             <!-- too small -->
             <span *ngIf="file.error===-1 && !file.upload" class="size-content" >
               <h4 class="file-size">{{ file.size }} {{ 'ADD_MEDIA_TOO_SMALL' | translate:locale.language }}</h4>
               <i class="fa fa-leaf" aria-hidden="true"></i>
             </span>
             <!-- too big -->
             <span *ngIf="file.error===1 && !file.upload" class="size-content" >
               <h4 class="file-size">{{ file.size }} {{ 'ADD_MEDIA_TOO_BIG' | translate:locale.language }}</h4>
               <i class="fa fa-leaf" aria-hidden="true"></i>
             </span>
           </div>

           <!-- INvalid files -->
           <div *ngIf="!file.upload && !file.valid && file.error===2" class="contain-elem">
             <h4 class="file-id">{{ file.id }} - </h4>
             <h5 class="file-name">{{ file.name }}</h5>
             <span class="fa-stack not-media">
                <i class="fa fa-music fa-stack-1x"></i>
                <i class="fa fa-ban fa-stack-2x text-danger"></i>
             </span>
           </div>
         </div>
    </li>
  </ul>
<div class="alert alert-info" style="margin-left:20px;margin-right:20px;">
  <h4><i class="fa fa-info-circle"></i> <span translate>MEDIA_UPLOAD_INFO_TITLE</span></h4>
  <strong translate>MEDIA_UPLOAD_INFO_FILES_SUPPORTED</strong> mp3, wav <br>
  <strong translate>MEDIA_UPLOAD_INFO_FILES_MAX_SIZE </strong>2 Go <br>
  <strong translate>MEDIA_UPLOAD_INFO_FILES_MAX_UPLOAD </strong>34
</div>

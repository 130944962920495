import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {Media} from '../../shared/models/media';
import {MediasFilterForm} from '../../shared/models/medias-filter-form';
import {SelectedMediasValidate} from '../../shared/models/selected-medias';
import {
  ChangeMediasValidatePageSizeAction,
  LoadMediasValidateSuccessAction,
  MediasListValidateActionTypes,
  OrderMediasValidateByAction,
  PageMediasValidateToAction,
  QuickSearchMediasValidateAction,
  SearchMediasValidateAction,
  ToggleMediasValidateSelectionAction,
  ToggleSearchMediasValidateAction
} from '../actions/media-list-validate';
import {UpdateCulturesAction, UpdateCulturesActionTypes} from '../actions/update-cultures-validate';
import {UpdateTagsAction, UpdateTagsActionTypes} from '../actions/update-tags-validate';
import {valitadedMediaUpdateActionTypes} from '../actions/validated-media-updated';

export interface MediasValidateListState {
  showFilter: boolean;
  quickSearch: string;
  filter: MediasFilterForm;
  page: number;
  pageSize: number;
  orderBy: string;
  array: Media[];
  count: number;
  selectedMediasValidate: SelectedMediasValidate[];
}
const defaultFilter: MediasFilterForm = {
  isDefault: true
};
const initialState: MediasValidateListState = {
  showFilter: false,
  quickSearch: undefined,
  filter: defaultFilter,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: 'updated descending',
  array: [],
  count: 0,
  selectedMediasValidate: []
};

export function mediasValidateListReducer(state = initialState, action: Action): MediasValidateListState {
  switch (action.type) {
    case MediasListValidateActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case MediasListValidateActionTypes.QUICK_SEARCH:
      return handleQuickSearchAction(state, action);
    case MediasListValidateActionTypes.SEARCH:
      return handleSearchAction(state, action);
    case MediasListValidateActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case MediasListValidateActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case MediasListValidateActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case MediasListValidateActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case valitadedMediaUpdateActionTypes.UPDATE_SUCCESS:
      return handleLoadStoreSuccessAction(state);
    case MediasListValidateActionTypes.LOAD_VALIDATION_FAIL:
    case MediasListValidateActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case MediasListValidateActionTypes.SELECT_PAGE:
      return handleSelectPageAction(state);
    case MediasListValidateActionTypes.UNSELECT_PAGE:
      return handleUnselectPageAction(state);
    case MediasListValidateActionTypes.TOGGLE_SELECTION:
      return handleToggleMediaSelectionAction(state, action);
    case UpdateCulturesActionTypes.UPDATE_CULTURES:
      return handleUpdateCultureSelectionAction(state, action);
    case UpdateTagsActionTypes.UPDATE_TAGS:
      return handleUpdateTagsSelectionAction(state, action);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}
function handleUpdateCultureSelectionAction(state: MediasValidateListState, action: UpdateCulturesAction): MediasValidateListState {
  const newState = _.clone(state);
  for (const entry of newState.array) {
    entry.cultures = action.payload;
  }
  return newState;
}
function handleUpdateTagsSelectionAction(state: MediasValidateListState, action: UpdateTagsAction): MediasValidateListState {
  const newState = _.clone(state);
  for (const entry of newState.array) {
    entry.tags = action.payload;
  }
  return newState;
}
function handleSelectPageAction(state: MediasValidateListState): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate:
      _.map(state.array, media => ({ id: media.id, name: media.name, archive: media.archive, cultures: media.cultures, tags: media.tags }))
  };
  return newState;
}
function handleUnselectPageAction(state: MediasValidateListState): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: []
  };
  return newState;
}
function handleToggleSearchAction(state: MediasValidateListState, action: ToggleSearchMediasValidateAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: action.payload,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };

  return newState;
}

function handleQuickSearchAction(state: MediasValidateListState, action: QuickSearchMediasValidateAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: action.payload,
    filter: _.isEmpty(action.payload) ? defaultFilter : undefined,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };

  return newState;
}

function handleSearchAction(state: MediasValidateListState, action: SearchMediasValidateAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: undefined,
    filter: action.payload,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handlePageToAction(state: MediasValidateListState, action: PageMediasValidateToAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handleChangePageSizeAction(state: MediasValidateListState, action: ChangeMediasValidatePageSizeAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: 1,
    pageSize: action.payload,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handleOrderByAction(state: MediasValidateListState, action: OrderMediasValidateByAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: action.payload,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handleLoadSuccessAction(state: MediasValidateListState, action: LoadMediasValidateSuccessAction): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}
function handleLoadStoreSuccessAction(state: MediasValidateListState): MediasValidateListState {

  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handleLoadErrorActions(state: MediasValidateListState): MediasValidateListState {
  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    selectedMediasValidate: state.selectedMediasValidate
  };
  return newState;
}

function handleChangeClientAction(): MediasValidateListState {
  return {
    showFilter: false,
    quickSearch: undefined,
    filter: undefined,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: undefined,
    array: [],
    count: 0,
    selectedMediasValidate: []
  };
}
function handleToggleMediaSelectionAction(state: MediasValidateListState, action: ToggleMediasValidateSelectionAction): MediasValidateListState {
  const selectedIndex = _.findIndex(state.selectedMediasValidate, ['id', action.payload]);
  let selectedMediasValidate: SelectedMediasValidate[];
  if (selectedIndex > -1) {
    selectedMediasValidate = _.filter(state.selectedMediasValidate, selectedMediasValidates => selectedMediasValidates.id !== action.payload);
  } else {
    const media = _.find(state.array, u => u.id === action.payload);
    const selectedMediasValidates:
      SelectedMediasValidate = { id: media.id, name: media.name, cultures: media.cultures, tags: media.tags, archive: media.archive };
    selectedMediasValidate = [...state.selectedMediasValidate, selectedMediasValidates];
  }

  const newState: MediasValidateListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedMediasValidate: selectedMediasValidate
  };
  return newState;
}
export const MediasValidateListSelectors = {
  ValidateshowFilter: (state: MediasValidateListState) => _.get(state, 'showFilter', false),
  ValidatequickSearch: (state: MediasValidateListState) => _.get(state, 'quickSearch'),
  Validatefilter: (state: MediasValidateListState) => _.get(state, 'filter'),
  Validatepage: (state: MediasValidateListState) => _.get(state, 'page', 1),
  ValidatepageSize: (state: MediasValidateListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  ValidateorderBy: (state: MediasValidateListState) => _.get(state, 'orderBy'),
  Validatearray: (state: MediasValidateListState) => _.get(state, 'array', []),
  Validatecount: (state: MediasValidateListState) => _.get(state, 'count', 0),
  ValidateselectedMedias: (state: MediasValidateListState) => _.get(state, 'selectedMediasValidate', [])
};

import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value: number, includeMs = false): string {
    const duration = moment.duration(value);
    const hours = duration.get('hours');
    const minutes = duration.get('minutes');
    const seconds = duration.get('seconds');
    const milliseconds = duration.get('milliseconds');
    if (hours > 0) {
      return `${hours}:${this.leadingZeros(minutes, 2)}:${this.leadingZeros(seconds, 2)}` + (includeMs ? `.${this.leadingZeros(milliseconds, 3)}` : '');
    }

    if (minutes > 0) {
      return `${minutes}:${this.leadingZeros(seconds, 2)}` + (includeMs ? `.${this.leadingZeros(milliseconds, 3)}` : '');
    }

    if (seconds > 0) {
      return `00:${this.leadingZeros(seconds, 2)}` + (includeMs ? `.${this.leadingZeros(milliseconds, 3)}` : '');
    } else {
      return includeMs ? `${milliseconds} ms` : '00:00';
    }
  }

  private leadingZeros(value: number, digits: number): string {
    let str = value.toString(10);
    while (str.length < digits) {
      str = '0' + str;
    }

    return str;
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, mergeMap, skipWhile, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadPublishFailAction, LoadPublishSuccessAction, PublishActionTypes} from '../actions/publish';
import {error} from '../actions/toasts';
import {Publish} from '../models/publish';
import {SendBackResult} from '../models/send-back-result';
import * as fromRoot from '../reducers';
import {PublishService} from '../services/publish.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class PublishEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));
  private roles$ = this.store.select(fromRoot.selectors.getMyRoles).pipe(filter(roles => !_.isEmpty(roles)));
  load$ = createEffect(
    () => {  return this.actions$.pipe(ofType(PublishActionTypes.LOAD),
                             debug('Load publish roles action received;'),
                             delayWhen(() => this.roles$),
                             withLatestFrom(this.roles$),
                             skipWhile(([action, roles]) => !_.find(roles, { 'code': 'APS_PUBLISH' })),
                             delayWhen(() => this.client$),
                             debug('Load publish action received;'),
                             withLatestFrom(this.client$),
                             mergeMap(([action, client]) => this.publishService.getCount(client.uuid)
                                                              .pipe(map((payload: SendBackResult<Publish>) => new LoadPublishSuccessAction(payload.data)),
                                                                    catchError((res: Response) => this.catchResponseError(res)))))});

  // loadFail$ = createEffect(
      //     () => {  return  this.actions$.pipe(ofType(PublishActionTypes.LOAD_FAIL),
  //                                debug('Load publish fail action received.'),
  //                                map(() => error(this.translation.translate('PUBLISH_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private store: Store<fromRoot.AppState>,
              private publishService: PublishService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadPublishFailAction(response.status); }
}

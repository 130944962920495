import {Action} from '@ngrx/store';

import {DiscardableAlertMessage} from '../models/discardable-alert-message';
import {type} from '../utilities/type';

export const AlertsActionType = {
  LOAD: type('[Alerts] Load'),
  LOAD_SUCCESS: type('[Alerts] Load Success'),
  LOAD_FAIL: type('[Alerts] Load Fail'),
  DISCARD: type('[Alerts] Discard Alert')
};

export class LoadAlertsAction implements Action {
  readonly type = AlertsActionType.LOAD;

  constructor() {}
}

export class LoadAlertsSuccessAction implements Action {
  readonly type = AlertsActionType.LOAD_SUCCESS;

  constructor(public payload?: DiscardableAlertMessage[]) {}
}

export class LoadAlertsFailAction implements Action {
  readonly type = AlertsActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class DiscardAlertAction implements Action {
  readonly type = AlertsActionType.DISCARD;

  constructor(public payload?: number) {}
}

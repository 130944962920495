<h3 class="font-brand" translate>AUTH_LOGIN_TITLE</h3>
<div *ngIf="!errorMessage; else showErrorMessage" class="form-actions">
  <img src="/assets/images/loading.svg" alt="Loading...">
  <h4 translate>AUTH_LOGIN_AUTO_TITLE</h4>
</div>
<ng-template #showErrorMessage>
  <div class="form-actions">
    <h4 translate>AUTH_LOGIN_ERROR_TITLE</h4>
    <p translate>AUTH_LOGIN_ERROR_PARA</p>
    <pre>{{errorMessage}}</pre>
    <button type="button" class="btn brand full-width" (click)="backToLogin()" translate>AUTH_LOGIN_GO_BACK_BUTTON</button>
  </div>
</ng-template>

import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {SelectedMedias} from '../../../shared/models/selected-medias';

@Component({
  selector: 'storever-media-confirm-archive-dialog',
  templateUrl: './confirm-archive-dialog.component.html',
  styleUrls: ['./confirm-archive-dialog.component.scss']
})
export class ConfirmMediaArchiveDialogComponent implements OnChanges {
  @ViewChild('archiveMediaModal') modal: ModalDirective;
  @Input() selectedMedias: SelectedMedias[] = [];
  @Output() confirm = new EventEmitter<boolean>();
  archive: boolean;

  messageMapping: { [k: string]: string } = { '=1': 'CONFIRM_DELETE_MEDIAS_DIALOG_SINGLE', other: 'CONFIRM_DELETE_MEDIAS_DIALOG_MULTI' };

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    for (const entry of this.selectedMedias) {
      this.archive = entry.archive;
    }
  }

  open(): void { this.modal.show(); }

  close(): void { this.modal.hide(); }

  accept(): void {
    this.confirm.emit(true);
    this.modal.hide();
  }

  Unaccept(): void {
    this.confirm.emit(false);
    this.modal.hide();
  }
}

import {Action} from '@ngrx/store';

export interface ToastsMethodCall {
  message: string;
  title?: string;
  options?: any;
}

export const ToastsAction = {
  ERROR: '[Toasts] Error',
  INFO: '[Toasts] Info',
  SUCCESS: '[Toasts] Success',
  WARNING: '[Toasts] Warning',
  CUSTOM: '[Toasts] Custom',
  CLEAR_ALL: '[Toasts] Clear All'
};

export class ErrorToastAction implements Action {
  readonly type = ToastsAction.ERROR;

  constructor(public payload: ToastsMethodCall) {}
}

export class InfoToastAction implements Action {
  readonly type = ToastsAction.INFO;

  constructor(public payload: ToastsMethodCall) {}
}

export class SuccessToastAction implements Action {
  readonly type = ToastsAction.SUCCESS;

  constructor(public payload: ToastsMethodCall) {}
}

export class WarningToastAction implements Action {
  readonly type = ToastsAction.WARNING;

  constructor(public payload: ToastsMethodCall) {}
}

export class CustomToastAction implements Action {
  readonly type = ToastsAction.CUSTOM;

  constructor(public payload: ToastsMethodCall) {}
}

export class ClearAllToastsAction implements Action {
  readonly type = ToastsAction.CLEAR_ALL;

  constructor() {}
}

export function error(message: string, title?: string, options?: any): ErrorToastAction {
  options = { animate: 'flyRight', newestOnTop: false, showCloseButton: true, dismiss: 'click' };
  const payload: ToastsMethodCall = { message, title, options };
  return new ErrorToastAction(payload);
}

export function info(message: string, title?: string, options?: any): InfoToastAction {
  const payload: ToastsMethodCall = { message, title, options };
  return new InfoToastAction(payload);
}

export function success(message: string, title?: string, options?: any): SuccessToastAction {
  const payload: ToastsMethodCall = { message, title, options };
  return new SuccessToastAction(payload);
}

export function warning(message: string, title?: string, options?: any): WarningToastAction {
  const payload: ToastsMethodCall = { message, title, options };
  return new WarningToastAction(payload);
}

export function custom(message: string, title?: string, options?: any): CustomToastAction {
  const payload: ToastsMethodCall = { message, title, options };
  return new CustomToastAction(payload);
}

export function clearAll(): ClearAllToastsAction {
  return new ClearAllToastsAction();
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Publication} from '../../publications/models/publication';
import {LoadPublicationSimpleListSuccessAction, PublicationSimpleListActionType} from '../../shared/actions/publication-simple-list';

export interface PublicationState {
  array: Publication[];
}

const initialState: PublicationState = {
  array: [],
};

export function publicationSimpleListReducer(state = initialState, action: Action): PublicationState {
  switch (action.type) {
    case PublicationSimpleListActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    case PublicationSimpleListActionType.LOAD_NOT_FOUND:
    case PublicationSimpleListActionType.LOAD_FAIL:
      return handleLoadErrorActions();

    default:
      return state;
  }
}

function handleLoadSuccessAction(action: LoadPublicationSimpleListSuccessAction): PublicationState {
  const newState: PublicationState = { array: action.payload.array };

  return newState;
}

function handleLoadErrorActions(): PublicationState {
  const newState: PublicationState = { array: [] };
  return newState;
}

export const PublicationSelectors = {
  array: (state: PublicationState) => _.get(state, 'array', [])
};

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadPublishSuccessAction, PublishActionTypes} from '../actions/publish';
import {Publish} from '../models/publish';

export interface PublishState {
  array: Publish;
}

const initialState: PublishState = {
  array: null
};

export function publishReducer(state = initialState, action: Action): PublishState {
  switch (action.type) {
    case PublishActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case PublishActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: PublishState, action: LoadPublishSuccessAction): PublishState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: PublishState): PublishState {
  const newState = _.clone(state);
  newState.array = null;
  return newState;
}

export const publishSelectors = {
  publish: (state: PublishState) => _.get(state, 'array')
};

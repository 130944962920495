<span *ngIf="ready">
  <audio #audioPlayer preload="none" (ended)="playCompleted()">
    <source [src]="mp3Url" type="audio/mpeg">
    <source [src]="oggUrl" type="audio/ogg">
    {{'AUDIO_PLAYER_NOT_SUPPORTED' | translate:locale.language}}
  </audio>
  <!--<i class="fa" aria-hidden="true"-->
     <!--[class.fa-play]="!playing"-->
     <!--[class.fa-stop]="playing"-->
     <!--(click)="togglePlayClicked()"></i>-->

    <i class="fa" aria-hidden="true"
       [class.fa-play]="audioPlayer.paused"
       [class.fa-stop]="!audioPlayer.paused"
       (click)="audioPlayer.paused ? audioPlayer.play() : audioPlayer.pause()"></i>
</span>

import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'paginate' })
export class PaginatePipe implements PipeTransform {
  transform(value: any[], currentPage = 1, pageSize = 10): any[] {
    if (!value) {
      return value;
    }

    if (+pageSize === -1) {
      return value;
    }

    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize - 1;
    return _.slice(value, start, end);
  }
}

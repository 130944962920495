import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {DataTableEmptyTemplateDirective} from './datatable-empty-template.directive';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'storever-datatable-empty' })
export class DataTableEmptyDirective {
  @Input() className: string;

  @ContentChild(DataTableEmptyTemplateDirective, { read: TemplateRef }) template: TemplateRef<any>;
}

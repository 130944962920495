import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Media} from '../../shared/models/media';
import {LoadMediaToValidateSuccessAction, MediaToValidateListActionTypes} from '../actions/medias-to-validate-list';

export interface MediaToValidateState {
  array: Media[];
  count: number;
}
const initialState: MediaToValidateState = {
  array: [],
  count: 0,
};

export function mediaToValidateReducer(state = initialState, action: Action): MediaToValidateState {
  switch (action.type) {
    case MediaToValidateListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(action: LoadMediaToValidateSuccessAction): MediaToValidateState {
  const newState: MediaToValidateState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const MediasToValidateSelectors = {
  array: (state: MediaToValidateState) => _.get(state, 'array', []),
  count: (state: MediaToValidateState) => _.get(state, 'count', 0)
};

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {Campaign} from '../../shared/models/campaign';

export const CampaignLastActionTypes = {
  CAMPAIGN_LOAD: type('[Campaign] last Load'),
  CAMPAIGN_LOAD_SUCCESS: type('[Campaign] last Load Success'),
  CAMPAIGN_LOAD_FAIL: type('[Campaign] last Load Fail'),
};

export class CampaignLastLoadAction implements Action {
  readonly type = CampaignLastActionTypes.CAMPAIGN_LOAD;
  constructor() {}
}

export class CampaignLastSuccessAction implements Action {
  readonly type = CampaignLastActionTypes.CAMPAIGN_LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Campaign>) {}
}

export class CampaignLastFaildAction implements Action {
  readonly type = CampaignLastActionTypes.CAMPAIGN_LOAD_FAIL;
  constructor(public payload?: number) {}
}

<div id="dashboard">

  <!-- Site WITH NO TEMPLATE -->
  <div *ngIf="isInRole$('APS_PROGRAM') | async" class="elem-container" [style.order]="siteWithNoTemplateOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="template-icon" style="font-size:16px; margin-right: 5px;"></span>
        <span class="caption-subject font-dark bold uppercase" >{{ siteWithNoTemplateData.length?siteWithNoTemplateData.length:'' }} {{ 'SITE_WITH_NO_TEMPLATE' | translate:locale.language}}</span>
      </div>
      <button type="button" pButton
              (click)="navigateTo('subscription', 0, ['programTemplateId', -1])"
              icon="fas fa-external-link-alt"
              [popover]="'GO_TO_SUBSCRIPTION' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>
    </div>
    <p-table  *ngIf="siteWithNoTemplateData.length" [value]="siteWithNoTemplateData" selectionMode="single" [responsive]="true"  [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN_NAME' | translate:locale.language }}</th>
          <th>{{ 'MEDIAS_LIST_COLUMN_STATUS' | translate:locale.language }}</th>
          <th>{{ 'MEDIAS_LIST_CREATE'| translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr (click)="navigateTo('subscription', rowData.id)" style="cursor: pointer">
          <td >{{rowData.name}}</td>
          <td>
          <i *ngIf="rowData.toPublish === true"
             class="icon-cloud-upload"
             style="color:red"
             aria-hidden="true"
             [popover]="'TOPUBLISH' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <i *ngIf="rowData.toPublish === false"
             class="icon-cloud-upload"
             style="color:green"
             aria-hidden="true"
             [popover]="'PUBLISHED' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <i *ngIf="rowData.player"
             class="fa fa-hdd-o fa-2x"
             aria-hidden="true"
             [popover]="rowData.player.deviceID"
             triggers="mouseenter:mouseleave"></i>
          </td>

          <td>{{ rowData.created | date:'d/M/yyyy' }}</td>
        </tr>
        </ng-template>
    </p-table>
    <p *ngIf="!siteWithNoTemplateData.length && !spinnerTemplate">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerTemplate" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- PUBLICATION FAILED -->
  <div *ngIf="isInRole$('APS_PUBLISH') | async" class="elem-container" [style.order]="publicationFailedOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="icon-cloud-upload" style="margin-right: 5px;"></span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="publicationFailedData.length<2">{{ publicationFailedData.length?publicationFailedData.length:'' }} {{ 'FAILED_PUBLICATION' | translate:locale.language}}</span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="publicationFailedData.length>1">{{ publicationFailedData.length }} {{ 'FAILED_PUBLICATIONS' | translate:locale.language}}</span>
      </div>
      <button type="button" pButton
              (click)="navigateTo('publication', 0, ['status', -1])"
              icon="fas fa-external-link-alt"
              [popover]="'GO_TO_PUBLICATION' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>
    </div>
    <p-table  *ngIf="publicationFailedData.length" [value]="publicationFailedData" selectionMode="single" [responsive]="true" [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'EDIT_MEDIA_NAME' | translate:locale.language }}</th>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN_STATUS' | translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr (click)="navigateTo('publication', rowData.id)" style="cursor: pointer">
            <td>
              <i *ngIf="rowData.type === 'PublicationPackage_Audio'"
                 class="fa fa-music"
                 aria-hidden="true"
                 [popover]="'PUBLICATION_MUSIC' | translate:locale.language"
                 triggers="mouseenter:mouseleave"></i>
              <i *ngIf="rowData.type === 'PublicationPackage_Audio_Commercial'"
                 class="fa fa-bullhorn"
                 aria-hidden="true"
                 [popover]="'PUBLICATION_PUB' | translate:locale.language"
                 triggers="mouseenter:mouseleave"></i>
              <span>{{ rowData.name }}</span>
             </td>
             <td>
                <span *ngIf="rowData.status === 0"
                      class="label label-sm label-warning"
                      aria-hidden="true">{{"PROGRESS"|translate:locale.language}}</span>
                  <span *ngIf="rowData.status === 1 "
                        class="label label-sm label-info"
                        aria-hidden="true">{{"READY"|translate:locale.language}}</span>
                  <span *ngIf="rowData.status === -1 "
                        class="label label-sm label-danger"
                        aria-hidden="true">{{"FAILED"|translate:locale.language}}</span>
           </td>
        </tr>
      </ng-template>
    </p-table>
    <p *ngIf="!publicationFailedData.length && !spinnerPublicationFailed">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerPublicationFailed" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- LAST PUBLICATION -->
  <div *ngIf="isInRole$('APS_PUBLISH') | async" class="elem-container" [style.order]="publicationLastOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="icon-cloud-upload" style="margin-right: 5px;" ></span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="publicationLastData.length<2">{{ publicationLastData.length?publicationLastData.length:'' }} {{ 'LAST_PUBLICATION' | translate:locale.language }}</span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="publicationLastData.length>1">{{ publicationLastData.length }} {{ 'LAST_PUBLICATIONS' | translate:locale.language }}</span>
      </div>
      <button type="button"
              pButton
              (click)="navigateTo('publication', 0)"
              icon="fas fa-external-link-alt"
              [popover]="'LAST_PUBLICATIONS' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>
    </div>
    <p-table  *ngIf="publicationLastData.length" [value]="publicationLastData" selectionMode="single" [responsive]="true" [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'EDIT_MEDIA_NAME' | translate:locale.language }}</th>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN_STATUS' | translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr (click)="navigateTo('publication', rowData.id)" style="cursor: pointer">
          <td>
          <i *ngIf="rowData.type === 'PublicationPackage_Audio'"
             class="fa fa-music"
             aria-hidden="true"
             [popover]="'PUBLICATION_MUSIC' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <i *ngIf="rowData.type === 'PublicationPackage_Audio_Commercial'"
             class="fa fa-bullhorn"
             aria-hidden="true"
             [popover]="'PUBLICATION_PUB' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <span>{{ rowData.name }}</span>
          </td>
          <td>
        <span *ngIf="rowData.status === 0"
              class="label label-sm label-warning"
              aria-hidden="true">{{"PROGRESS"|translate:locale.language}}</span>
          <span *ngIf="rowData.status === 1 "
                class="label label-sm label-info"
                aria-hidden="true">{{"READY"|translate:locale.language}}</span>
          <span *ngIf="rowData.status === -1 "
                class="label label-sm label-danger"
                aria-hidden="true">{{"FAILED"|translate:locale.language}}</span>
          </td>
          </tr>
        </ng-template>
    </p-table>
    <p *ngIf="!publicationLastData.length && !spinnerPublicationLast">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerPublicationLast" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- MEDIA TO VALIDATE -->
  <div *ngIf="isInRole$('APS_MUSTVALIDMEDIA') | async" class="elem-container" [style.order]="mediaToValidateOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="media-icon" style="font-size:16px"></span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="mediaToValidateData.length<2">{{ mediaToValidateData.length?mediaToValidateData.length:'' }} {{ 'MEDIA_TO_VALIDATE' | translate:locale.language}}</span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="mediaToValidateData.length>1">{{ mediaToValidateData.length }} {{ 'MEDIAS_TO_VALIDATE' | translate:locale.language}}</span>
      </div>
      <button type="button"
              pButton
              (click)="navigateTo('media/validate', 0)"
              icon="fas fa-external-link-alt"
              [popover]="'MEDIAS_TO_VALIDATE' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>

    </div>
    <p-table *ngIf="mediaToValidateData.length" [value]="mediaToValidateData" selectionMode="single" [rows]="5" [paginator]="false"  [responsive]="true" [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN_NAME' | translate:locale.language }}</th>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN__CREATED' | translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr>
          <td> <span>{{ rowData.name }}</span></td>
          <td>{{ rowData.created | date:'d/M/yyyy' }} </td>
        </tr>
      </ng-template>
    </p-table>
    <p *ngIf="!mediaToValidateData.length && !spinnerMedia">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerMedia" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- activity feed -->
  <div  class="elem-container  sizeDouble" [style.padding]="0">
    <div class="portlet light">
      <div class="portlet-title" align="left" style="justify-content: left !important">
        <div class="caption">
          <i class="icon-clock font-dark"></i>
          <span class="caption-subject font-dark bold uppercase">{{ activityFeedData.length?activityFeedData.length:'' }} {{ 'DASHBOARD_ACTIVITY_FEED_TITLE' | translate:locale.language }}</span>
        </div>
      </div>
      <div class="portlet-body" *ngIf="activityFeedData.length">
        <div class="activity-feed overFlow">
          <ul *ngIf="activityFeedData; else noData" class="feeds">
            <li *ngFor="let activity of activityFeedData" [class.failed]="activity.isFailed">
              <div class="col1">
                <div class="cont">
                  <div [ngSwitch]="activity.name" class="cont-col1">
                    <div *ngSwitchCase="'Delete'" class="label label-sm label-danger">
                      <i class="fa fa-trash"></i>
                    </div>
                    <div *ngSwitchCase="'Update'" class="label label-sm label-warning">
                      <i class="fa fa-pencil"></i>
                    </div>
                    <div *ngSwitchCase="'Create'" class="label label-sm label-success">
                      <i class="fa fa-plus"></i>
                    </div>
                    <div *ngSwitchDefault class="label label-sm label-info">
                      <i class="fa fa-info"></i>
                    </div>
                  </div>
                  <div class="cont-col2" style="display: flex;">
                    <div class="desc" (click)="activity.extra?.isDeleted || navigateTo(activity?.objectType, activity?.objectIds[0])"
                         [innerHTML]="getTranslationKey(activity) | translate:locale.language:{ userName: activity?.userName, count: activity?.objectIds?.length, name: activity?.extra?.name, uuid: activity?.extra?.uuid, id: activity?.id }">
                    </div>
                    <span *ngIf="activity.extra?.isDeleted" style="color: red;"> {{ 'DELETED' | translate:locale.language }} </span>
                  </div>
                </div>
              </div>
              <div class="col2">
                      <span [popover]="activity.created |  date:'dd/MM/yyyy HH:mm:ss'"
                            triggers="mouseenter:mouseleave">{{ activity.created | timeAgo:locale.language }}</span>
              </div>
            </li>
          </ul>
          <ng-template #noData>
            <div class="no-data" translate>DASHBOARD_ACTIVITY_FEED_NO_DATA</div>
          </ng-template>
        </div>
      </div>
    </div>
    <p *ngIf="!activityFeedData.length && !spinnerActivityFeed">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerActivityFeed" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- INVALID CAMPAIGN -->
  <div *ngIf="isInRole$('APS_CAMPAIGN') | async" class="elem-container" [style.order]="campaignLastOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="campaign-icon" style="font-size:16px;margin-right: 5px;"></span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="campaignLastData.length<2">{{ 'LAST_CAMPAIGN' | translate:locale.language}}</span>
        <span class="caption-subject font-dark bold uppercase" *ngIf="campaignLastData.length>1">{{ 'LAST_CAMPAIGNS' | translate:locale.language}}</span>
      </div>
      <button type="button"
              pButton
              style="float:left"
              (click)="navigateTo('campaign', 0)"
              icon="fas fa-external-link-alt"
              [popover]="'LAST_CAMPAIGNS' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>
    </div>
    <p-table  *ngIf="campaignLastData.length" [value]="campaignLastData" selectionMode="single" [responsive]="true" [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'PUBLICATIONS_LIST_COLUMN_NAME' | translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr (click)="navigateTo('campaign', rowData.id)" style="cursor: pointer">
          <td>
          <i *ngIf="rowData.subscriptionCount === 0 || rowData.mediaCount === 0" class="fa fa-exclamation-circle fa-2x"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_INVALID' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <i *ngIf="rowData.placing === 'Floating'" class="fa fa-life-ring fa-2x"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_TYPE_FLOATING' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>

          <i *ngIf="rowData.placing === 'JustInTime'" class="fal fa-clock fa-lg"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_TYPE_JUST_IN_TIME' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>

          <i *ngIf="rowData.placing === 'Jingle'" class="fa fa-bell fa-2x"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_TYPE_JINGLE' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>

          <i *ngIf="rowData.placing === 'CloseMessage' && rowData.positioning === 'Single' " class="fad fa-sun fa-2x"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_TYPE_CLOSE_MESSAGE_OPENING' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <i *ngIf="rowData.placing === 'CloseMessage' && rowData.positioning === 'Last' " class="fal fa-moon fa-lg"
             aria-hidden="true"
             [popover]="'CAMPAIGNS_TYPE_CLOSE_MESSAGE_CLOSE' | translate:locale.language"
             triggers="mouseenter:mouseleave"></i>
          <span>{{ rowData.name }}</span>
          </td>
        </tr>
        </ng-template>
    </p-table>
    <p *ngIf="!campaignLastData.length && !spinnerCampaignLast">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerCampaignLast" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

  <!-- Program no planning -->
  <div *ngIf="isInRole$('APS_PROGRAM') | async" class="elem-container" [style.order]="programNoPlanningOrder">
    <div class="portlet-title">
      <div class="caption">
        <span class="template-icon" style="font-size:16px"></span>
        <span class="caption-subject font-dark bold uppercase" >{{ programNoPlanningData.length?programNoPlanningData.length:'' }} {{ 'PROGRAM_NO_PLANNING' | translate:locale.language}}</span>
      </div>
      <button type="button" pButton
              (click)="navigateTo('templates-programs', 0)"
              icon="fas fa-external-link-alt"
              [popover]="'TEMPLATES_PROGRAMS' | translate:locale.language"
              triggers="mouseenter:mouseleave"
      ></button>
    </div>
    <p-table *ngIf="programNoPlanningData.length" [value]="programNoPlanningData" selectionMode="single" [responsive]="true" [tableStyle]="{ 'font-size': '1.3rem' }">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>{{ 'NAME' | translate:locale.language }}</th>
          <th>{{ 'ENDED' | translate:locale.language }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData >
        <tr>
          <td> {{ rowData.program.name }}</td>
          <td>
            <span *ngIf="!isDatePlanning(rowData.lastPublishedPlanning, rowData.endDate); else notEndedBlock" style="color:red">
              {{ rowData.lastPublishedPlanning | date:'d/M/yyyy' }}
            </span>
            <span #notEndedBlock>{{ rowData.lastPublishedPlanning | date:'d/M/yyyy' }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p *ngIf="!programNoPlanningData.length && !spinnerProgramNoPlanning">{{ "DASHBOARD_ACTIVITY_FEED_NO_DATA" | translate:locale.language }}</p>
    <span *ngIf="spinnerProgramNoPlanning" class="col-sm-offset-5">
      <span class="fa-4x" >
          <i class="fa fa-spinner fa-spin"></i>
      </span>
    </span>
  </div>

</div>




<div class="top-menu">
  <ul class="nav navbar-nav">
    <li class="dropdown dropdown-user" dropdown>
      <a  class="dropdown-toggle" dropdownToggle data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
        <span class="badge"  style="z-index: 1000" *ngIf="0 < commercialCount">{{ commercialCount }}</span>
        <span class="icon-cloud-upload fa-2x" style="color:#C0CDDC"></span>
      </a>
      <ul class="dropdown-menu dropdown-menu-default" *ngIf="0 < commercialCount">
      <!--<ul class="dropdown-menu dropdown-menu-default" >-->
        <li class="external"><h5 ><span >{{ 'PUBLICATION_COUNT_MESSAGE' | translate:lang}}</span>
          <span> {{ commercialCount }} </span>
          <span> {{ 'PUBLICATION_COUNT_MESSAGE_ONE' | translate:lang}}</span></h5></li>
        <li >
          <button type="button" class="btn btn-block btn-danger"  (click)="showModal($event, ConfirmationPublishModal)" [disabled]="isRunning">
            <span class="icon-cloud-upload" style="font-size:20px"></span>
            <strong>{{ 'PUBLICATION_ALL' | translate:lang}}</strong>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</div>
<div class="top-menu" *ngIf="roleProgram">
  <ul class="nav navbar-nav">
    <li class="dropdown dropdown-user" dropdown>
      <a  class="dropdown-toggle" dropdownToggle data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
        <span class="badge"  style="z-index: 1000" *ngIf="0 < programCount">{{ programCount }}</span>
        <span class="fa fa-music fa-2x" style="color:#C0CDDC"></span>
      </a>
      <ul class="dropdown-menu dropdown-menu-default" *ngIf="0 < programCount">
      <!--<ul class="dropdown-menu dropdown-menu-default" >-->
        <li class="external"><h5><span >{{ 'PUBLICATION_COUNT_MESSAGE' | translate:lang}}</span>
          <span>  {{ programCount }} </span>
          <span> {{ 'PUBLICATION_COUNT_MESSAGE_ONE' | translate:lang}}</span></h5></li>
        <li >
          <button type="button" class="btn btn-block btn-danger" (click)="showModal($event, ConfirmationPublishModal)" [disabled]="isRunning">
            <span class="fa fa-music" style="font-size:20px"></span>
            <strong>{{ 'PUBLICATION_ALL' | translate:lang}}</strong>
          </button>
        </li>
      </ul>
    </li>
  </ul>
</div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {BlockUIEvent} from '../../models/block-ui-event';
import {BlockUIEventType} from '../../models/block-ui-event-type.enum';
import {BlockUIService} from '../../services/block-ui.service';

@Component({
  selector: 'storever-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockUIComponent implements OnInit, OnDestroy {
  @Input() isRunning = false;

  private sub: any;

  constructor(private service: BlockUIService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.sub = this.service.events$.subscribe((event: BlockUIEvent) => {
      switch (event.type) {
        case BlockUIEventType.Hide:
          this.isRunning = false;
          this.cdr.markForCheck();
          break;
        case BlockUIEventType.Show:
          this.isRunning = true;
          this.cdr.markForCheck();
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() { this.sub.unsubscribe(); }
}

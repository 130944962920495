import {Action} from '@ngrx/store';

import {Publish} from '../models/publish';
import {type} from '../utilities/type';

export const PublishActionTypes = {
  LOAD: type('[Publish] Load'),
  LOAD_SUCCESS: type('[Publish] Load Success'),
  LOAD_FAIL: type('[Publish] Load Fail')
};

export class LoadPublishAction implements Action {
  readonly type = PublishActionTypes.LOAD;

  constructor() {}
}

export class LoadPublishSuccessAction implements Action {
  readonly type = PublishActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Publish) {}
}

export class LoadPublishFailAction implements Action {
  readonly type = PublishActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {TagsLists} from '../../medias/models/tags-list';
import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {MediasListActionTypes, ToggleMediaSelectionTagsAction} from '../../shared/actions/medias-list';
import {MediasListState} from '../../shared/reducers/medias-list';
import {
  ChangeSubscriptionsPageSizeAction,
  LoadSubscriptionsSuccessAction,
  OrderSubscriptionsByAction,
  PageSubscriptionsToAction,
  QuickSearchSubscriptionsAction,
  SearchSubscriptionsAction,
  SubscriptionsListActionTypes,
  ToggleSearchSubscriptionsAction,
  ToggleSubscriptionsSelectionAction,
  ToggleSubscriptionsSelectionTagsAction
} from '../actions/subscriptions';
import {SelectedSubscriptions} from '../models/selected-subscriptions';
import {SubscriptionFilter} from '../models/subscription-filter';
import {Subscriptions} from '../models/subscriptions';

export interface SubscriptionsListState {
  showFilter: boolean;
  quickSearch: string;
  filter: SubscriptionFilter;
  page: number;
  pageSize: number;
  orderBy: string;
  array: Subscriptions[];
  count: number;
  selectedSubscriptions: SelectedSubscriptions[];
  selectedTagsList: TagsLists[] // tags to delete
}

const initialState: SubscriptionsListState = {
  showFilter: false,
  quickSearch: undefined,
  filter: undefined,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: 'updated descending',
  array: [],
  count: 0,
  selectedSubscriptions: [],
  selectedTagsList: [] // tags to delete
};

export function SubscriptionsListReducer(state = initialState, action: Action): SubscriptionsListState {
  switch (action.type) {
    case SubscriptionsListActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case SubscriptionsListActionTypes.QUICK_SEARCH:
      return handleQuickSearchAction(state, action);
    case SubscriptionsListActionTypes.SEARCH:
      return handleSearchAction(state, action);
    case SubscriptionsListActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case SubscriptionsListActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case SubscriptionsListActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case SubscriptionsListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case SubscriptionsListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case SubscriptionsListActionTypes.SELECT_PAGE:
      return handleSelectPageAction(state);
    case SubscriptionsListActionTypes.UNSELECT_PAGE:
      return handleUnselectPageAction(state);
    case SubscriptionsListActionTypes.TOGGLE_SELECTION:
      return handleToggleSubscriptionSelectionAction(state, action);
    case SubscriptionsListActionTypes.CLEAR:
      return handleClearAction(state);
    case SubscriptionsListActionTypes.TOGGLE_SELECTION_TAGS:
      return handleToogleTagsListToDelete(state, action);
    default:
      return state;
  }
}

function getTagsSelected(state: SubscriptionsListState, action: ToggleSubscriptionsSelectionTagsAction) { // tags to delete
  let selectedTagsList: TagsLists[];
  for (const actionPayload of action.payload) {
    const selectedIndex = _.findIndex(state.selectedTagsList, ['id', actionPayload.id]);
    if (selectedIndex > -1) {
      selectedTagsList = _.filter(state.selectedTagsList, selectedTags => selectedTags.id !== actionPayload.id);
    } else {
      selectedTagsList = [...state.selectedTagsList, actionPayload];
    }
  }
  return selectedTagsList;
}

function getSubscriptionsTagsListFromState(state: SubscriptionsListState): TagsLists[] { // tags to delete
  const selectedTags: TagsLists[] = [];
  for (const elem of state.array) {
    if (elem.tags) {
      selectedTags.push({ id: elem.id, tagsList: elem.tags })
    }
  }
  return selectedTags;
}

function handleClearAction(state: SubscriptionsListState): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleSelectPageAction(state: SubscriptionsListState): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedTagsList: getSubscriptionsTagsListFromState(state), // tags to delete
    selectedSubscriptions:
      _.union(state.selectedSubscriptions,
              _.map(state.array,
                    subscription => ({ id: subscription.id, name: subscription.name, productID: subscription.productID, cultures: subscription.cultures })))
  };
  return newState;
}
function handleUnselectPageAction(state: SubscriptionsListState): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: [],
    selectedTagsList: []
  };
  return newState;
}
function handleToggleSearchAction(state: SubscriptionsListState, action: ToggleSearchSubscriptionsAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: action.payload,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleQuickSearchAction(state: SubscriptionsListState, action: QuickSearchSubscriptionsAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: action.payload,
    filter: state.filter,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleSearchAction(state: SubscriptionsListState, action: SearchSubscriptionsAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: action.payload,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handlePageToAction(state: SubscriptionsListState, action: PageSubscriptionsToAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangePageSizeAction(state: SubscriptionsListState, action: ChangeSubscriptionsPageSizeAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: 1,
    pageSize: action.payload,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleOrderByAction(state: SubscriptionsListState, action: OrderSubscriptionsByAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: action.payload,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadSuccessAction(state: SubscriptionsListState, action: LoadSubscriptionsSuccessAction): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadErrorActions(state: SubscriptionsListState): SubscriptionsListState {
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleToggleSubscriptionSelectionAction(state: SubscriptionsListState, action: ToggleSubscriptionsSelectionAction): SubscriptionsListState {
  const selectedIndex = _.findIndex(state.selectedSubscriptions, ['id', action.payload]);
  let selectedSubscriptions: SelectedSubscriptions[];
  if (selectedIndex > -1) {
    selectedSubscriptions = _.filter(state.selectedSubscriptions, sc => sc.id !== action.payload);
  } else {
    const subscriptions = _.find(state.array, u => u.id === action.payload);
    const selectedSubscription:
      SelectedSubscriptions = { id: subscriptions.id, name: subscriptions.name, productID: subscriptions.productID, cultures: subscriptions.cultures };
    selectedSubscriptions = [...state.selectedSubscriptions, selectedSubscription];
  }

  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: selectedSubscriptions,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangeClientAction(): SubscriptionsListState {
  return {
    showFilter: false,
    quickSearch: undefined,
    filter: undefined,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: undefined,
    array: [],
    count: 0,
    selectedSubscriptions: [],
    selectedTagsList: []
  };
}

function handleToogleTagsListToDelete(state: SubscriptionsListState, action: ToggleSubscriptionsSelectionTagsAction): SubscriptionsListState { // tags to delete
  const newState: SubscriptionsListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    selectedSubscriptions: state.selectedSubscriptions,
    selectedTagsList: getTagsSelected(state, action)
  };
  return newState;
}

export const SubscriptionsListSelectors = {
  showFilter: (state: SubscriptionsListState) => _.get(state, 'showFilter', false),
  quickSearch: (state: SubscriptionsListState) => _.get(state, 'quickSearch'),
  filter: (state: SubscriptionsListState) => _.get(state, 'filter'),
  page: (state: SubscriptionsListState) => _.get(state, 'page', 1),
  pageSize: (state: SubscriptionsListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  orderBy: (state: SubscriptionsListState) => _.get(state, 'orderBy'),
  array: (state: SubscriptionsListState) => _.get(state, 'array', []),
  count: (state: SubscriptionsListState) => _.get(state, 'count', 0),
  selectedSubscriptions: (state: SubscriptionsListState) => _.get(state, 'selectedSubscriptions', []),
  selectedTags: (state: MediasListState) => _.get(state, 'selectedTagsList', [])
};

import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {UserContextActionTypes} from '../../shared';
import {CountriesListActionTypes, LoadCountriesSuccessAction} from '../actions/countries';
import {Countries} from '../models/countries';

export interface CountriesListState {
  array: Countries[];
}

const initialState: CountriesListState = {
  array: []
};

export function CountriesListReducer(state = initialState, action: Action): CountriesListState {
  switch (action.type) {
    case CountriesListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CountriesListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: CountriesListState, action: LoadCountriesSuccessAction): CountriesListState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadErrorActions(state: CountriesListState): CountriesListState {
  const newState = _.clone(state);
  newState.array = undefined;
  return newState;
}

function handleChangeClientAction(): CountriesListState {
  return { array: undefined };
}

export const CountriesDetailSelectors = {
  array: (state: CountriesListState) => _.get(state, 'array'),
};

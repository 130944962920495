import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {ImpersonableUserVM} from '../models/impersonable-user.vm';

@Pipe({ name: 'impersonableUsersFilterCount' })
export class ImpersonableUsersFilterCountPipe implements PipeTransform {
  transform(value: ImpersonableUserVM[], filter: string): number {
    if (!value) {
      return 0;
    }

    filter = _.trim(filter);
    if (_.isEmpty(filter)) {
      return value.length;
    }

    const lowerFilter = _.toLower(filter);
    return _
      .filter(value,
              user => {
                return (_.toLower(user.name).indexOf(lowerFilter) > -1 || _.toLower(user.email).indexOf(lowerFilter) > -1 ||
                        _.toLower(user.groupRoleName).indexOf(lowerFilter) > -1);
              })
      .length;
  }
}

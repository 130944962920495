<div class="portlet light">
  <div class="portlet-title">
    <div class="caption">
      <div class="caption-subject bold uppercase" translate><span class="media-icon" style="font-size:16px"></span> MEDIAS_LIST_TITLE</div>
    </div>
    <div class="actions">
      <span *ngIf="(showAsPopup == undefined) && (isInRole$('APS_UPLOAD') | async)" >
        <span class="btn btn-circle btn-default" (click)="showModal($event, AddMediasModal)">
          <i class="fa fa-upload" aria-hidden="true"></i>
          {{ 'MEDIAS_LIST_ADD' | translate:locale.language }}
        </span>
      </span>
      <span *ngIf="(isInRole$('APS_MUSTVALIDMEDIA') | async) && (showAsPopup == undefined) " >
        <span [routerLink]="['validate']" class="btn btn-circle btn-default" >
          <i class="fa fa-check" aria-hidden="true"></i>
          {{ 'MEDIAS_LIST_VALIDATE' | translate:locale.language }} <span *ngIf="validateMedia">({{ validateMedia}})</span>
        </span>
      </span>
    </div>
  </div>
  <div class="portlet-body">
    <storever-filter-media
                     #filterMedia
                     [languages]="languages$ | async"
                     [languages$]="languages$"
                     [tagsList]="tagsList$ | async"
                     [showSearchForm]="showFilter$ | async"
                     [filter]="filter$ | async"
                     [simpleForm]="(false) || (showAsPopup !== undefined)"
                     (search)="applyFilter($event)"
                     (toggle)="toggleSearchForm($event)">
    </storever-filter-media>
    <div class="table-responsive">
      <storever-datatable [striped]="true"
                          [hover]="true"
                          [data]="media$ | async"
                          [count]="count$ | async"
                          [currentPage]="page$ | async"
                          [itemsPerPage]="pageSize$ | async"
                          [orderBy]="orderBy$ | async"
                          (pageChange)="pageChanged($event)"
                          (pageSizeChange)="pageSizeChanged($event)"
                          (orderByChange)="orderByChanged($event)">

        <storever-datatable-actions>
          <ng-template storeverDatatableActionsTemplate>
            <button type="button" class="btn btn-outline btn-sm brand" (click)="selectPage()" translate>MEDIAS_LIST_SELECT_PAGE</button>
            <button type="button" class="btn btn-outline btn-sm brand" (click)="unselectPage()" translate>MEDIAS_LIST_UNSELECT_PAGE</button>
            <span *ngIf="showAsPopup == undefined" >
              <div class="btn-group" dropdown>
                <button type="button" class="btn btn-outline btn-sm brand dropdown-toggle" [disabled]="isActionsDisabled$ | async" dropdownToggle>
                  {{ 'MEDIAS_LIST_ACTIONS' | translate:locale.language }}
                  <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" [attr.aria-label]="'MEDIAS_LIST_ACTIONS' | translate:locale.language">
                  <li>
                    <a  href="#" (click)="showModal($event, SubListTagModal)">
                      <i class="fa fa-tags" aria-hidden="true"></i>
                      {{ 'SUBSCRIPTIONS_LIST_TAG' | translate:locale.language }}
                    </a>
                  </li>
                  <li>
                    <a href="#" (click)="showModal($event, confirmArchive)">
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                      {{ 'MEDIAS_LIST_ARCHIVE' | translate:locale.language }}
                    </a>
                  </li>
                   <li>
                    <a  href="#" (click)="showModalTagsToDelete($event, SubListTagModalDelete)">
                      <i class="fa fa-tags" aria-hidden="true"></i>
                      {{ 'ACTION_LIST_TAG_DELETE' | translate:locale.language }}
                    </a>
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a href="#" (click)="showModalDelete($event, confirmDelete)">
                      <i class="fa-solid fa-trash" aria-hidden="true"></i>
                      {{ 'MEDIAS_LIST_DELETE' | translate:locale.language }}
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </ng-template>
        </storever-datatable-actions>

        <storever-datatable-column [prop]="''">
          <ng-template let-row="row" storeverDatatableColumnCell>
          {{notValid(row.validated)}}
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="''"
                                   [prop]="'id'">
          <ng-template let-row="row" let-value="value" storeverDatatableColumnCell>
            <input #selection
                   type="checkbox"
                   [checked]="isSelected$(value) | async"
                   (change)="toggleMediaSelection(value, row.tags)"
                   >
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="''"
                                   [prop]="'fileName'"
                                   [className]="'media-name'">
          <ng-template let-value="value" storeverDatatableColumnCell>
            <storever-audio-player-simple
              [playing]="isPlaying$(value) | async"
              (togglePlay)="togglePlay($event)"
              [fileName]="value"
              [appSettings]="appSettings$ | async"
            ></storever-audio-player-simple>
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_NAME' | translate:locale.language"
                                   [prop]="'name'"
                                   [sortable]="true"
                                   [className]="'media-name'" >
          <ng-template let-row="row" let-value="value" storeverDatatableColumnCell>
            <a  *ngIf="validate" [routerLink]="['../','media',row.id]">{{value}}</a>
            <span  *ngIf="!validate">{{value}}</span>
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'CULTURES' | translate:locale.language"
                                   [prop]="'cultures'"
                                   [className]="'media-cultures'">
          <ng-template let-value="value" storeverDatatableColumnCell>
            <span [popover]="value | join:data" triggers="mouseenter:mouseleave">{{ value | join }}</span>
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_DURATION' | translate:locale.language"
                                   [prop]="'duration'"
                                   [sortable]="true">
          <ng-template let-value="value" storeverDatatableColumnCell>
            {{ value | duration }}
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_TAG' | translate:locale.language"
                                   [prop]="'tags'"
                                   [className]="'media-tags'">
          <ng-template let-value="value" storeverDatatableColumnCell>
            <span [popover]="value | joinComplexe" triggers="mouseenter:mouseleave">
              <span *ngFor="let valueTag of value">
                <span class="tags" >{{ valueTag.name }}</span>
              </span>
            </span>
          </ng-template>
          <!--<ng-template let-value="value" storeverDatatableColumnCell>-->
            <!--{{ value | joinComplexe }}-->
          <!--</ng-template>-->
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_STATUS' | translate:locale.language"
                                   [prop]="''">
          <ng-template let-row="row" storeverDatatableColumnCell>
            <div *ngIf="row.archive; then isArchive"></div>
            <ng-template #isArchive>
              <i class="fa fa-eye-slash fa-fw fa-2x"
                 aria-hidden="true"
                 [popover]="'MEDIA_ARCHIVED' | translate:locale.language"
                 triggers="mouseenter:mouseleave"></i>
            </ng-template>
            <i *ngIf="row.convertionStatus === 0"
               class="fa fa-cogs"
               aria-hidden="true"
               [popover]="'MEDIA_CONVERSION_STATUS' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.convertionStatus === -1"
               class="fa fa-exclamation-circle"
               aria-hidden="true"
               [popover]="'MEDIA_CONVERSION_FAILED' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.sharing === 'None'"
               class="fa fa-user-times"
               aria-hidden="true"
               [popover]="'MEDIA_SHARING_PRIVATE' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.sharing === 'Private'"
               class="fa fa-user "
               aria-hidden="true"
               [popover]="'MEDIA_SHARING_PRIVATE' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.sharing === 'Public'"
               class="fa fa-users"
               aria-hidden="true"
               [popover]="'MEDIA_SHARING_PUBLIC' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.sharing === 'User'"
               class="fa fa-user-secret"
               aria-hidden="true"
               [popover]="'MEDIA_SHARING_USER_SHARING' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.sharing === 'Admin'"
               class="fa fa-user-plus"
               aria-hidden="true"
               [popover]="'MEDIA_SHARING_ADMIN' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.validated === null"
               class="fa-solid fa-rectangle-xmark"
               aria-hidden="true"
               [popover]="'MEDIA_IS_NOT_VALIDE' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.isOnline === true"
               class="fa fa-play"
               aria-hidden="true"
               [popover]="'CAMPAIGNS_ONLINE' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.lock"  class="fa fa-lock"
               aria-hidden="true"
               [popover]="'MEDIAS_LIST_LOCK_STATUS' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
            <i *ngIf="row.blacklist && row.blacklist.length" class="fa fa-flag"
               aria-hidden="true"
               [popover]="'MEDIAS_LIST_BLACKLIST_STATUS' | translate:locale.language"
               triggers="mouseenter:mouseleave"></i>
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_CREATED' | translate:locale.language"
                                   [prop]="'created'"
                                   [sortable]="true">
          <ng-template let-value="value" storeverDatatableColumnCell>
            {{ value | date: 'dd/MM/yyyy' }}
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_UPDATE' | translate:locale.language"
                                   [prop]="'updated'"
                                   [sortable]="true">
          <ng-template let-value="value" storeverDatatableColumnCell>
            {{ value | date: 'dd/MM/yyyy' }}
          </ng-template>
        </storever-datatable-column>
        <storever-datatable-column [name]="'MEDIAS_LIST_COLUMN_FILENAME' | translate:locale.language"
                                   [prop]="'fileName'"
                                   [sortable]="true">

        </storever-datatable-column>
      </storever-datatable>
    </div>
  </div>
</div>

<storever-add-medias-dialog  #AddMediasModal [showAsPopup]="showAsPopup" (addMedia)="AddMedia($event)"></storever-add-medias-dialog>

<storever-media-confirm-delete-dialog #confirmDelete
                                [selectedMedias]="selectedMedia$ | async"
                                [usedMedias]="usedMedia$ | async"
                                (confirm)="deleteSelectedMedias()"
                                (deleteNotUsed)="deleteNotUsedMedias($event)">
</storever-media-confirm-delete-dialog>
<storever-media-confirm-archive-dialog #confirmArchive
                                 [selectedMedias]="selectedMedia$ | async"
                                 (confirm)="archiveSelectedMedias($event)"
></storever-media-confirm-archive-dialog>
<storever-media-tags-action-list-dialog #SubListTagModal
                                  [tagsList]="tagsList$ | async"
                                  [toDeleteTags]="toDeleteTags"
                                  (confirm)="addTagsListSelected($event)"
></storever-media-tags-action-list-dialog>
<storever-media-tags-action-list-dialog #SubListTagModalDelete
                                        [tagsList]="getSelectedTagsFromMedia$ | async"
                                        [toDeleteTags]="toDeleteTags"
                                        (confirm)="deleteTagsListSelected($event)"
></storever-media-tags-action-list-dialog>

import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const AddMediaActionTypes = {
  ADD_MEDIA: type('[Media] Add'),
  ADD_MEDIA_SUCCESS: type('[Media] Add Success'),
  ADD_MEDIA_FAIL: type('[Media] Add Fail')
};

export class AddMediaAction implements Action {
  readonly type = AddMediaActionTypes.ADD_MEDIA;
  constructor(public payload?: any) {}
}

export class AddMediaSuccessAction implements Action {
  readonly type = AddMediaActionTypes.ADD_MEDIA_SUCCESS;
  constructor(public payload?: any) {}
}

export class AddMediaFailAction implements Action {
  readonly type = AddMediaActionTypes.ADD_MEDIA_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Campaign} from '../../shared/models/campaign';
import {CampaignLastActionTypes, CampaignLastSuccessAction} from '../actions/campaign-list';

export interface CampaignLastState {
  array: Campaign[];
  count: number;
}
const initialState: CampaignLastState = {
  array: [],
  count: 0,
};

export function campaignLastReducer(state = initialState, action: Action): CampaignLastState {
  switch (action.type) {
    case CampaignLastActionTypes.CAMPAIGN_LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(action: CampaignLastSuccessAction): CampaignLastState {
  const newState: CampaignLastState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const CampaignLastSelectors = {
  array: (state: CampaignLastState) => _.get(state, 'array', []),
  count: (state: CampaignLastState) => _.get(state, 'count', 0)
};

import {isDevMode} from '@angular/core';
import * as moment from 'moment';

export abstract class BaseService {
  protected log(message?: any, ...optionalParams: any[]): void {
    if (isDevMode()) {
      console.log(`LOG [${this.getTimestamp()}] ${message}`, optionalParams);
    }
  }

  protected warn(message?: any, ...optionalParams: any[]): void { console.warn(`WARN [${this.getTimestamp()}] ${message}`, optionalParams); }

  protected error(message?: any, ...optionalParams: any[]): void { console.error(`ERROR [${this.getTimestamp()}] ${message}`, optionalParams); }

  private getTimestamp(): string {
    const now = moment();
    return now.format('DD/MM/YYYY HH:mm:ss.SSS');
  }
}

import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {BroadcastEventListener, SignalR} from '@henkkelder/ng2-signalr';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

import {AddPublishedAction, Campaign, Client, ErrorToastAction, InfoToastAction, LoadPublishAction, SuccessToastAction, WarningToastAction} from '../../';
import * as fromCampaigns from '../../../compaigns/reducers';
import {LoadPublicationsAction} from '../../../publications/actions/publications-list';
import {LoadSubscriptionsAction} from '../../../subscription/actions/subscriptions';
import {Subscriptions} from '../../../subscription/models/subscriptions';
import * as fromSubscription from '../../../subscription/reducers';
import {LoadMediasAction} from '../../actions/medias-list';
import {LoadPublicationSimpleListAction} from '../../actions/publication-simple-list';
import {Publish} from '../../models/publish';
import * as fromRoot from '../../reducers';
import {AppState, selectors} from '../../reducers';
import {MessageService} from 'primeng/api';

@Component({ selector: 'storever-publish', templateUrl: './publish.component.html', styleUrls: ['./publish.component.scss'] })
export class PublishComponent implements OnInit {
  publish$: Observable<Publish>;
  client$: Observable<Client>;
  @Output() published = new EventEmitter<any>();
  @Input() roleProgram;
  subscription: any;
  campaign$: Observable<Campaign>;
  subscription$: Observable<Subscriptions>;
  options: any;

  isRunning: any;
  public commercialCount: number;
  public programCount: number;
  public name: string;

  publishForm: UntypedFormGroup;
  constructor(private store: Store<AppState>,
              private _signalR: SignalR,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private messageService: MessageService,
              private translation: L10nTranslationService,
              private storeCampaign: Store<fromCampaigns.AppState>,
              private storeSub: Store<fromSubscription.AppState>,
              fb: UntypedFormBuilder) {
    this.publishForm = fb.group({ name: [], cd: false, sd: false });
    this.publish$ = this.store.select(selectors.getPublish);
    this.client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));
    this.options = { animate: 'flyRight', newestOnTop: false, showCloseButton: true, dismiss: 'click' };
  }
  ngOnInit() {
    this.publish$.subscribe(data => (data ? (this.commercialCount = data.commercialCount) : null), err => console.log('err', err));
    this.publish$.subscribe(data => (data ? (this.programCount = data.programCount) : null), err => console.log('err', err));
    this.publish$.subscribe(data => (data ? (this.isRunning = data.isRunning) : null), err => console.log('err', err));
    this.updateSignalr();
  }

  updateSignalr() {
    this._signalR.connect().then((c) => {
      this.client$.subscribe(data => (c.invoke('subscribe_aps', data.name).then((datas: string[]) => {
                               if (!!datas) {
                                 console.log(datas)
                               }
                             }),
                                      c.invoke('subscribe_aps_Admin', data.name).then((datas: string[]) => {
                                        if (!!datas) {
                                          console.log(datas)
                                        }
                                      }),
                                      this.store.dispatch(new LoadPublishAction())));
      const newPublicationRequired$ = new BroadcastEventListener<any>('newPublicationRequired');
      c.listen(newPublicationRequired$);

      newPublicationRequired$.subscribe(() => {
        // refrech nuage
        this.store.dispatch(new LoadPublishAction());
      });

      const newAudioPublicationRequired$ = new BroadcastEventListener<any>('newAudioPublicationRequired');
      c.listen(newAudioPublicationRequired$);

      newAudioPublicationRequired$.subscribe(() => {
        // refrech nuage
        this.store.dispatch(new LoadPublishAction());
      });

      const publicationStarted$ = new BroadcastEventListener<any>('publicationStarted');
      c.listen(publicationStarted$);

      publicationStarted$.subscribe(() => {
        this.store.dispatch(new SuccessToastAction(
          { message: this.translation.translate('PUBLICATION_STARTED'), title: this.translation.translate('PUBLICATION_SIGNALR_TITLE') }));
        this.store.dispatch(new LoadPublishAction());
      });

      const localUserPublicationWaiting$ = new BroadcastEventListener<any>('localUserPublicationWaiting');
      c.listen(localUserPublicationWaiting$);
      localUserPublicationWaiting$.subscribe(() => {
        this.store.dispatch(new WarningToastAction(
          { message: this.translation.translate('PENDING_PUBLICATIONS'), title: this.translation.translate('PUBLICATION_SIGNALR_TITLE') }));
      });

      const newMediaAvailable$ = new BroadcastEventListener<any>('newMediaAvailable');
      c.listen(newMediaAvailable$);
      newMediaAvailable$.subscribe(() => {
        this.store.dispatch(new InfoToastAction(
          { message: this.translation.translate('NEW_MEDIA_IS_AVAILABLE'), title: this.translation.translate('NEW_MEDIA_IS_AVAILABLE_TITLE') }));
      });

      const newMediaConvertionComplete$ = new BroadcastEventListener<any>('newMediaConvertionComplete');
      c.listen(newMediaConvertionComplete$);
      newMediaConvertionComplete$.subscribe(() => { this.store.dispatch(new LoadMediasAction()); });

      const publicationCompleted$ = new BroadcastEventListener<any>('publicationCompleted');
      c.listen(publicationCompleted$);
      publicationCompleted$.subscribe((data) => {
        data = JSON.parse(data);
        this.store.dispatch(new LoadPublishAction());
        this.store.dispatch(new LoadPublicationsAction());
        this.publicationUpdated();
        this.store.dispatch(new InfoToastAction(
          { message: 'Client: ' + data.clientName + ' Publication: ' + data.packageName, title: this.translation.translate('PUBLICATION_COMPLETED') }));
      });

      const publicationFailed$ = new BroadcastEventListener<any>('publicationFailed');
      c.listen(publicationFailed$);

      publicationFailed$.subscribe((data) => {
        data = JSON.parse(data);
        this.store.dispatch(new LoadPublishAction());
        this.store.dispatch(new LoadPublicationsAction());
        this.store.dispatch(new LoadSubscriptionsAction());
        this.publicationUpdated();
        this.store.dispatch(new ErrorToastAction(
          { message: 'Client: ' + data.clientName + ' Publication: ' + data.packageName, title: this.translation.translate('PUBLICATION_FAILED') }));
      });

      const publicationCompletedWithWarning$ = new BroadcastEventListener<any>('publicationCompletedWithWarning');
      c.listen(publicationCompletedWithWarning$);
      publicationFailed$.subscribe((data) => {
        data = JSON.parse(data);
        this.store.dispatch(new LoadPublishAction());
        this.store.dispatch(new LoadPublicationsAction());
        this.publicationUpdated();
        this.store.dispatch(new WarningToastAction({
          message: 'Client: ' + data.clientName + ' Publication: ' + data.packageName + ' Subscription: ' + data.warncount,
          title: this.translation.translate('PUBLICATION_COMPLETED_WITH_WARNING')
        }));
      });

      const publicationDeliveryFailed$ = new BroadcastEventListener<any>('publicationDeliveryFailed');
      c.listen(publicationDeliveryFailed$);
      publicationDeliveryFailed$.subscribe(() => {
        this.store.dispatch(new LoadPublishAction());
        this.publicationUpdated();
        this.store.dispatch(new ErrorToastAction(
          { message: this.translation.translate('PUBLICATION_DELIVERY_FAILED'), title: this.translation.translate('PUBLICATION_SIGNALR_TITLE') }));
      });
    });
  }
  publish(): void {
    this.store.dispatch(new AddPublishedAction());
    this.publish$ = this.store.select(selectors.getPublish);
    // this.count = 0;
    this.publish$ = null;
  }
  showModal(modal: any): void { this.published.emit(modal); }

  publicationUpdated() {

    this.campaign$ = this.storeCampaign.select(fromCampaigns.selectors.getCampaignDetails);
    this.campaign$.subscribe(data => (data ? this.storeCampaign.dispatch(new LoadPublicationSimpleListAction(String(data.id))) : null));

    this.subscription$ = this.storeSub.select(fromSubscription.selectors.getSubscription);
    this.subscription$.subscribe(data => (data ? this.storeSub.dispatch(new LoadPublicationSimpleListAction(String(data.id))) : null));
  }
}

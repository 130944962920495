  <div bsModal #SubListTagModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
         <h4 class="modal-title" translate><i class="fa fa-tags"></i>
           <span *ngIf="(toDeleteTags | async); else addTagsTitle"> {{ 'DELETE_TAGS_MEDIA_DIALOG_TITLE' | translate:locale.language}}</span>
           <ng-template #addTagsTitle>
             <span> {{ 'TAGS_MEDIA_DIALOG_TITLE' | translate:locale.language}}</span>
           </ng-template>
         </h4>
      </div>
      <div class="modal-body">
        <div class="col-md-2 col-sm-12"> <label class="control-label" translate>{{ 'EDIT_MEDIA_TAGS' | translate:locale.language}}</label></div>
        <div class="col-md-10 col-sm-12">
          <div>
            <storever-select-tags
              #selectTags
              [tagsList]="tagsList"
              [toDeleteTags]="toDeleteTags | async"
              (saveTags)="submitFromChild($event)">
            </storever-select-tags>
          </div>
        </div>
      </div>
      <br><br>
      <div class="modal-footer">
        <button type="submit" class="btn btn-info" (click)="accept()" translate>SAVE</button>
        <button type="button" class="btn btn-default" (click)="close($event)" translate>{{ 'CANCEL' | translate:locale.language }}</button>
      </div>
    </div>
  </div>
</div>

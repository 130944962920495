import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {fade, slide} from '../../';
import {SelectCulturesComponent} from '../../components/select-cultures/select-cultures.component';
import {SelectTagsComponent} from '../../components/select-tags/select-tags.component';
import {Language} from '../../models/language';
import {MediasFilterForm} from '../../models/medias-filter-form';
import {TagsList} from '../../models/tags-list';
import {DateTimeFormatService} from '../../services/date-time-format.service';

@Component({
  selector: 'storever-filter-media',
  templateUrl: './media-filter.component.html',
  styleUrls: ['./media-filter.component.scss'],
  animations: [fade, slide],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() languages: Language[];
  @Input() languages$: Observable<Language[]>;
  @Input() showSearchForm = false;
  @Input() filter: MediasFilterForm;
  @Input() simpleForm: boolean; // used for campaign media edit simple form vue
  @Output() search = new EventEmitter<MediasFilterForm>();
  @Output() toggle = new EventEmitter<boolean>();
  searchForm: UntypedFormGroup;
  quickSearchForm: UntypedFormGroup;
  public langS: Array<any> = [];
  public lg: Array<any> = [];
  startDateOptions: any;
  endDateOptions: any;
  private subscription: Subscription;
  // tags
  injectTags: TagsList[] = [];

  _tagsList: TagsList[];
  selectTagsList: any[] = [];
  selectCulturesList: any[] = [];

  @Input('tagsList')
  set allowTagsList(value: TagsList[]) {
    this._tagsList = value;
  }

  get allowTagsList(): TagsList[] { return this._tagsList; }

  @ViewChild('selectTags') selectTags: SelectTagsComponent;
  @ViewChild('selectCultures') selectCultures: SelectCulturesComponent;

  constructor(fb: UntypedFormBuilder,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              dateTimeFormatService: DateTimeFormatService,
              private cdr: ChangeDetectorRef) {
    this.searchForm = fb.group(
      { name: [], tags: [], sharing: null, isOnline: null, archived: null, validated: null, startDate: [null], endDate: [null], cultures: [], filename: [] });
    this.quickSearchForm = fb.group({ name: [] });
    this.startDateOptions = dateTimeFormatService.buildDateTimePickerOptions({ format: DateTimeFormatService.input.date, useCurrent: false });
    this.endDateOptions = dateTimeFormatService.buildDateTimePickerOptions({ format: DateTimeFormatService.input.date, useCurrent: false });
  }
  ngOnInit() {
    this.subscription = this.quickSearchForm.get('name').valueChanges.pipe(debounceTime(700)).subscribe(newName => {
      this.searchForm.get('name').setValue(newName);
      if (newName && newName.length) {
        this.search.emit({ name: newName });
      } else {
        this.search.emit(undefined);
      }
    });
  }
  ngOnDestroy() { this.subscription.unsubscribe(); }
  ngOnChanges(changes: SimpleChanges) {
    this.languagesPushNameFromCode();
    if (_.has(changes, 'filter')) {
      const value = this.getCurrentValueFromFilter(changes);
      if (value) {
        this.changeFilterTags(value);
        this.changeFilterCultures(value);
        this.formPatchValue(value);
      }
    }
    if (_.has(changes, 'allowTagsList')) {
      if (this.selectTags) {
        this.selectTags.tagsList = changes.allowTagsList.currentValue;
        this.selectTags.tagsArray = _.map(changes.allowTagsList.currentValue, 'name');
        this.cdr.markForCheck();
        console.log(this.selectTags.tagsList);
      }
    }
  }
  getCurrentValueFromFilter(changes: SimpleChanges): MediasFilterForm {
    const change = _.get(changes, 'filter');
    return change.currentValue;
  }
  changeFilterTags(value: MediasFilterForm): void {
    this.injectTags = [];
    if (value.tags) {
      this.addInTagsFromFilter(value.tags.split(','));
    }
  }
  changeFilterCultures(value: MediasFilterForm): void {
    if (value.cultures) {
      for (const entry of value.cultures.split(',')) {
        if (this.langS.indexOf(entry) === -1) {
          const str = entry;
          for (const entry2 of this.languages) {
            if (entry2.code === str) {
              this.langS.push(entry2.name);
            }
          }
        }
      }
      this.langS = _.uniq(this.langS);
    }
  }
  languagesPushNameFromCode() {
    if (this.languages) {
      this.lg = [];
      for (const entry of this.languages) {
        if (entry.code) {
          this.lg.push(entry.name);
        }
      }
    }
  }
  formPatchValue(value: MediasFilterForm): void {
    this.searchForm.patchValue({
      name: value.name,
      tags: value.tags,
      sharing: this.initSharing(value.sharing),
      archived: this.booleanAdapte(value.archived),
      validated: this.booleanAdapte(value.validated),
      isOnline: this.booleanAdapte(value.isOnline),
      startDate: value.startDate ? moment(value.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
      endDate: value.endDate ? moment(value.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
      cultures: this.langS,
      filename: value.filename
    });
  }
  toggleSearchForm($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.toggle.emit(!this.showSearchForm);
  }
  showResetQuickSearch(): boolean { return !_.isEmpty(this.quickSearchForm.get('name').value); }
  resetQuickSearch($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.quickSearchForm.reset();
    this.search.emit(undefined);
  }
  applyFilter(form: MediasFilterForm): void {
    console.log('applyFilter' , form)
    console.log(this.selectTagsList);
   // if (this.selectTagsList && this.selectTagsList.length > 0) {
      form.tags = this.objectToArrayString(this.selectTagsList).join();
   // }

    form.sharing = this.trasformSharing(form.sharing);
  //  if (this.selectCulturesList && this.selectCulturesList.length > 0) {
      form.cultures = this.transformLang(this.convertCultures(this.selectCulturesList));
  //  }
    form.startDate = this.transformeDate(form.startDate);
    form.endDate = this.transformeDate(form.endDate);
    this.search.emit(form);
  }
  booleanAdapte(tristate: any) {
    if (tristate !== null && typeof tristate !== 'undefined') {
      tristate = JSON.parse(tristate);
    } else {
      tristate = null
    }
    return tristate
  }
  trasformSharing(sharing) {
    if (sharing !== false && sharing !== true) {
      sharing = null;
    } else {
      sharing = sharing ? 'Admin' : 'Public';
    }
    return sharing;
  }
  initSharing(sharing) {
    if (sharing !== null && typeof sharing !== 'undefined') {
      if (sharing === 'Admin') {
        sharing = true;
      }
      if (sharing === 'Public') {
        sharing = false;
      }
    } else {
      sharing = null
    }
    return sharing;
  }
  transformeDate(date) {
    if (date && moment(date, 'DD/MM/YYYY').isValid()) {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
    } else {
      return null;
    }
  }
  resetFilter($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.reset();
    this.search.emit(undefined);
  }

  public transformLang(cultureList) {
    let langSub = '';
    for (const entry of cultureList) {
      if (cultureList.length > 1) {
        langSub = langSub + ',' + entry;
      } else {
        langSub = entry;
      }
    }
    return langSub;
  }
  reset() {
    this.searchForm.reset();
    this.langS = [];
    if (this.selectCultures) {
     // this.selectCultures.select.active = [];
    }
    if (this.selectTags) {
     // this.selectTags.select.active = [];
    }
  }
  // tags
  private objectToArrayString(val: any[]): Array<string> {
    const resp = [];
   if (val) {
     for (const prop of val) {
       resp.push(prop);
     }
   }

    return resp;
  }
  public saveTags(tags: any): void {
    if (tags && tags[0].tag) {
      for (const prop of tags) {
        this.selectTagsList.push(prop.name);
      }
    } else {
      this.selectTagsList = tags;
    }
  }
  addInTagsFromFilter(tags: string[]): void {
    for (const tag of tags) {
      this.injectTags.push({ id: tag, text: tag });
    }
    this.injectTags = _.uniqBy(this.injectTags, 'text')
  }
  saveCultures(cultures: any) {
    this.selectCulturesList = cultures;
  }
  private convertCultures(tagsActive: Array<any>): Array<String> {
    const culturesListArrayToSend = [];
    tagsActive.forEach(cultures => {
      this.selectCultures.culturesList.forEach(culturesName => {
        if (culturesName.name === cultures) {
          culturesListArrayToSend.push(culturesName.code);
        }
      });
    });
    return culturesListArrayToSend;
  }
  public submitFromChild(): void {}
}

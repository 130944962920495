import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import * as moment from 'moment';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {DiscardAlertAction, LoadAlertsAction} from '../../actions/alerts';
import {LoadImpersonableUsersAction} from '../../actions/impersonable-users';
import {ToggleSidebarAction} from '../../actions/layout';
import {LoadMyClientsAction} from '../../actions/my-clients';
import {AddPublishedProgramAction} from '../../actions/publish-program';
import {AddPublishedAction} from '../../actions/published';
import {StartImpersonationAction, StopImpersonationAction} from '../../actions/user-context';
import {LoadUserInfoAction, LogoutAction} from '../../actions/user-info';
import {CUSTOM_CLAIMS_IS_STOREVER, IMPERSONATION_TOKEN, SCROLL_OFFSET} from '../../consts';
import {BrandSettings} from '../../models/app-settings';
import {Auth0UserInfo} from '../../models/auth0-user-info';
import {Client} from '../../models/client';
import {DiscardableAlertMessage} from '../../models/discardable-alert-message';
import {ExternalLink} from '../../models/external-link';
import {ImpersonableUserVM} from '../../models/impersonable-user.vm';
import {ImpersonateUser} from '../../models/impersonate-user';
import {LayoutType} from '../../models/layout-type.enum';
import {Publish, PublishForm, PublishProgramForm} from '../../models/publish';
import {Role} from '../../models/role';
import * as fromShared from '../../reducers';
import {selectors} from '../../reducers';
import {AppSettingsService} from '../../services/app-settings.service';
import {LayoutService} from '../../services/layout.service';
import {LoadCountersAction} from '../../actions/counters';

@Component({
 // standalone: true,
 // imports: [SharedModule, AppModule],
  selector: 'storever-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent implements OnInit {
  sidebarClosed$: Observable<boolean>;
  canChangeClient$: Observable<boolean>;
  myClients$: Observable<Client[]>;
  clientName$: Observable<any>;
  publish$: Observable<Publish>;
  userInfo$: Observable<Auth0UserInfo>;
  impersonableUsers$: Observable<ImpersonableUserVM[]>;
  impersonatedUser$: Observable<ImpersonateUser>;
  roles$: Observable<Role[]>;
  brand$: Observable<BrandSettings>;
  alerts$: Observable<DiscardableAlertMessage[]>;
  externalLinks$: Observable<ExternalLink[]>;
  isStorever$: Observable<boolean>;
  scrollToTopVisible = false;
  copyrightYear: number;
  availableLanguages: any;
  currentClientId: string; // this is only used for display and shouldn't be used to know which client has been selected
  dashboardLink$: Observable<any[]>;
  myDashboardLink$: Observable<any[]>;
  mediasLink$: Observable<any[]>;
  campaignsLink$: Observable<any[]>;
  templatePLink$: Observable<any[]>;
  subscriptionsLink$: Observable<any[]>;
  publicationsLink$: Observable<any[]>;
  clientId: string;
  currentApplicationVersion: string;
  @ViewChild('clientDropdown', { read: BsDropdownDirective }) clientDropdown: BsDropdownDirective;
  @ViewChild('clientDropdownToggle', { read: ElementRef }) clientDropdownToggle: ElementRef;
  @ViewChild('clientDropdownFilter', { read: ElementRef }) clientDropdownFilter: ElementRef;
  @ViewChild('clientDropdownFiltered', { read: ElementRef }) clientDropdownFiltered: ElementRef;

  get hasExternalLinks$(): Observable<boolean> { return this.externalLinks$.pipe(map(links => links.length > 0)); }

  constructor(
    private store: Store<fromShared.AppState>,
    private layoutService: LayoutService,
    private router: Router,
    appSettingsService: AppSettingsService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
  ) {
    this.isStorever$ =
      this.store.select(fromShared.selectors.getUserInfo).pipe(filter(userInfo => userInfo != undefined), map(userInfo => userInfo[CUSTOM_CLAIMS_IS_STOREVER]));
    this.sidebarClosed$ = this.store.select(fromShared.selectors.getSidebarClosed);
    this.myClients$ = this.store.select(fromShared.selectors.getMyClients);
    this.publish$ = this.store.select(selectors.getPublish);
    this.canChangeClient$ = this.myClients$.pipe(filter(clients => clients != undefined), map(clients => clients.length > 1));
    this.clientName$ = this.store.select(fromShared.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)), map(client => client.name));
    this.userInfo$ = this.store.select(fromShared.selectors.getUserInfo);
    this.impersonableUsers$ = this.store.select(fromShared.selectors.getImpersonableUsers);
    this.impersonatedUser$ = this.store.select(fromShared.selectors.getImpersonateUser);
    this.roles$ = this.store.select(fromShared.selectors.getMyRoles);
    this.brand$ = appSettingsService.settings$.pipe(map(appSettings => appSettings.brand));
    this.alerts$ = this.store.select(fromShared.selectors.getAlerts);
    this.copyrightYear = moment().year();
    this.availableLanguages = this.l10nConfig.schema;
    this.externalLinks$ = this.store.select(fromShared.selectors.getExternalLinks);
    this.dashboardLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'dashboard']));
    this.myDashboardLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'myDashboard']));
    this.campaignsLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'campaign']));
    this.mediasLink$ = this.store.select(fromShared.selectors.getCurrentClient)
                         .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'media']));
    this.templatePLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'templates-programs']));
    this.subscriptionsLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'subscription']));
    this.publicationsLink$ =
      this.store.select(fromShared.selectors.getCurrentClient)
        .pipe(filter(client => client != undefined), tap(client => (this.clientId = client.uuid)), map(client => ['/', client.uuid, 'publication']));
  }

  ngOnInit() {
    console.log('---------------------------' , 'AppLayoutComponent')
    this.layoutService.setLayout(LayoutType.Full);
    this.store.dispatch(new LoadUserInfoAction());
    this.store.dispatch(new LoadMyClientsAction());
    this.store.dispatch(new LoadAlertsAction());
    this.store.dispatch(new LoadCountersAction())
    console.log('---------------------------' , 'AppLayoutComponent')
    setInterval(() => this.store.dispatch(new LoadAlertsAction()), 300000);
    this.currentApplicationVersion = environment.appVersion;
    this.cache();
  }
  cache(): void {

    if (!localStorage.getItem('buildVersion') || localStorage.getItem('buildVersion').replace(/"/g, '') !== this.currentApplicationVersion) {
      localStorage.setItem('buildVersion', JSON.stringify(this.currentApplicationVersion));
      // @ts-ignore
      window.location.reload(true);
    }
  }

  toggleSidebar(): void {
    this.store.dispatch(new ToggleSidebarAction());
    this.layoutService.toggleSidebar();
  }

  selectLanguage(language: any): void { this.translation.setLocale(language); }

  isInRole$(role: string): Observable<boolean> { return this.roles$.pipe(map(roles => _.findIndex(roles, ['code', role]) > -1)); }

  isRouteActive(commands: any[]): boolean { return this.router.isActive(this.router.createUrlTree(commands), false); }

  isRouteActiveClassAdd(commands: string[]): boolean {
    let find = false;
    commands.forEach(value => {
      if (this.router.url.includes(value)) {
        find = true;
      }
    });
    return find;
  }
  impersonate(action: 'Enter'|'Leave', modal: any): void {
    console.log(action , modal)
    if (action === 'Enter') {
      this.store.dispatch(new LoadImpersonableUsersAction());
      modal.open();
    } else {
      this.store.dispatch(new StopImpersonationAction());
    }
  }

  publishDialogBox(modal: any): void { modal.open('ConfirmationPublishModal'); }

  impersonateUser(user: ImpersonateUser): void { this.store.dispatch(new StartImpersonationAction(user)); }
  publishCommercial(form: PublishForm): void { this.store.dispatch(new AddPublishedAction(form)); }
  publishMusic(form: PublishProgramForm): void { this.store.dispatch(new AddPublishedProgramAction(form)); }
  publishCommercialAndMusic(form: any): void { this.store.dispatch(new AddPublishedProgramAction(form)); }

  logout(): void {
    const homePath = this.router.serializeUrl(this.router.createUrlTree(['']));
    const returnTo = `${location.protocol}//${location.host}${homePath}`;
    this.store.dispatch(new LogoutAction(returnTo));
  }

  dismiss(alertMessageId: number): void { this.store.dispatch(new DiscardAlertAction(alertMessageId)); }

  onShown(): void {
    // if (this.clientDropdownFilter) {
      setTimeout(() => this.clientDropdownFilter.nativeElement.focus(), 100);
  //  }
  }
  ifRunAs() {
    if (sessionStorage.getItem(IMPERSONATION_TOKEN)) {
      return true;
    } else {
      return false;
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    const scrollFromTop: number = window.pageYOffset || document.documentElement.scrollTop || document.querySelector('body').scrollTop;
    this.scrollToTopVisible = scrollFromTop > SCROLL_OFFSET;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event: MouseEvent): void {
    if (this.clientDropdown.isOpen && $event.button !== 2 && !this.clientDropdownToggle.nativeElement.contains($event.target) &&
        !this.clientDropdownFilter.nativeElement.contains($event.target)) {
      this.clientDropdown.hide();
    }
  }

  @HostListener('keyup.esc')
  onEsc(): void {
    if (this.clientDropdown.isOpen) {
      this.clientDropdown.hide();
    }
  }
  addInputEnter() {
    console.log(this.clientDropdownFiltered, this.currentClientId)
    this.clientDropdownFiltered.nativeElement.click();
  }
  redirectClient(clientUuid) {
    this.router.navigate(['/', clientUuid, 'dashboard'], { queryParams: {} })
      .then(
        nav => {
          // console.log(nav, this.router); // true if navigation is successful
        },
        err => {
          // console.log(err) // when there's an error
        });
  }
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {Countries} from '../models/countries';

export const CountriesListActionTypes = {
  LOAD: type('[ Countries] Load'),
  LOAD_SUCCESS: type('[ Countries] Load Success'),
  LOAD_FAIL: type('[ Countries] Load Fail')
};

export class LoadCountriesAction implements Action {
  readonly type = CountriesListActionTypes.LOAD;
  constructor() {}
}

export class LoadCountriesSuccessAction implements Action {
  readonly type = CountriesListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: Countries[]) {}
}

export class LoadCountriesFailAction implements Action {
  readonly type = CountriesListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

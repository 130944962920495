import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {Action, Store} from '@ngrx/store';
import * as _ from 'lodash';
import {of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadCurrentClientAction} from '../actions/current-client';
import {LoadExternalLinksAction} from '../actions/external-links';
import {ChangeClientAction, LoadRolesAction, UserContextActionTypes} from '../actions/user-context';
import {RouterStateUrl} from '../models/router-state-url';
import * as fromRoot from '../reducers'

  @Injectable() export class UserContextClientEffect {

    roles$ = this.store.select(fromRoot.selectors.getMyRoles);

    changeClient$ = createEffect(
      () => {  return this.actions$.pipe(ofType(UserContextActionTypes.CHANGE_CLIENT),
                                                 debug('Change client action received'),
                                                 switchMap((action: ChangeClientAction) => of<Action>(
                                                             new LoadCurrentClientAction(action.payload.clientId),
                                                             new LoadRolesAction(action.payload.clientId),
                                                             new LoadExternalLinksAction(action.payload.clientId),
                                                             // this.can()
                                                             )))});

    changeClientByUrl$ = createEffect(
      () => {  return this.actions$.pipe(ofType(ROUTER_NAVIGATION),
                         debug('Change client by url action received'),
                         map((action: RouterNavigationAction<RouterStateUrl>) => _.get(action.payload.routerState.params, 'clientId')),
                         filter(clientId => !_.isEmpty(clientId)),
                         distinctUntilChanged(),
                         map(clientId => new ChangeClientAction({clientId, redirect: false})))});
    // private can() {
    //   this.roles$.subscribe(data => this.storeDispatch(data), err => console.log('err', err));
    //   return new LoadActivityFeedAction();
    // }
    //
    // storeDispatch(data) {
    //   this.store.dispatch(new LoadActivityFeedAction());
    //   if (_.findIndex(data, ['code', 'APS_PROGRAM']) !== -1) {
    //     this.store.dispatch(new SiteWithNoTemplateAction());
    //   }
    //   if (_.findIndex(data, ['code', 'APS_CAMPAIGN']) !== -1) {
    //     this.store.dispatch(new CampaignLastLoadAction());
    //   }
    //   if (_.findIndex(data, ['code', 'APS_PUBLISH']) !== -1) {
    //     this.store.dispatch(new PublicationLastAction());
    //     this.store.dispatch(new PublicationFailedAction());
    //   }
    //   if (_.findIndex(data, ['code', 'APS_MUSTVALIDMEDIA']) !== -1) {
    //     this.store.dispatch(new LoadMediaToValidateAction());
    //   }
    // }

    constructor(private actions$: Actions, private store: Store<fromRoot.AppState>) {}
  }

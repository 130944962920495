import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {L10nTranslationModule} from 'angular-l10n';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {AccordionModule} from 'primeng/accordion';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToastModule} from 'primeng/toast';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';

import {AddMediasDialogComponent} from '../medias/components/add-medias-dialog/add-medias-dialog.component';
import {ConfirmMediaArchiveDialogComponent} from '../medias/components/confirm-archive-dialog/confirm-archive-dialog.component';
import {ConfirmMediaDeleteDialogComponent} from '../medias/components/confirm-delete-dialog/confirm-delete-dialog.component';
import {DropZoneComponent} from '../medias/components/drop-zone/drop-zone.component';
import {TagsMediaActionListDialogComponent} from '../medias/components/tags-action-list-dialog/tags-action-list-dialog.component';

import {AudioPlayerComponent} from './components/audio-player/audio-player.component';
import {BlockUIComponent} from './components/block-ui/block-ui.component';
import {CampaignSimpleListComponent} from './components/campaign-simple-list/campaign-simple-list.component';
import {ConfirmationPublicationDialogComponent} from './components/confirmation-publication-dialog/confirmation-publication-dialog.component';
import {DataTableComponent} from './components/datatable/datatable.component';
import {ConfirmNewComponent} from './components/dialog-component/dialog.component';
import {EditCampaignComponent} from './components/edit-campaign/edit-campaign.component';
import {ImpersonateUserComponent} from './components/impersonate-user/impersonate-user.component';
import {PublicationSimpleListComponent} from './components/publication-simple-list/publication-simple-list.component';
import {PublishComponent} from './components/publish/publish.component';
import {ScrollToTopComponent} from './components/scroll-to-top/scroll-to-top.component';
import {SelectCulturesComponent} from './components/select-cultures/select-cultures.component';
import {SelectTagsComponent} from './components/select-tags/select-tags.component';
import {SendBackResultComponent} from './components/send-back-result/send-back-result.component';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {DataTableActionsTemplateDirective} from './directives/datatable-actions-template.directive';
import {DataTableActionsDirective} from './directives/datatable-actions.directive';
import {DataTableColumnCellDirective} from './directives/datatable-column-cell.directive';
import {DataTableColumnHeaderDirective} from './directives/datatable-column-header.directive';
import {DataTableColumnDirective} from './directives/datatable-column.directive';
import {DataTableDetailsTemplateDirective} from './directives/datatable-details-template.directive';
import {DataTableDetailsDirective} from './directives/datatable-details.directive';
import {DataTableEmptyTemplateDirective} from './directives/datatable-empty-template.directive';
import {DataTableEmptyDirective} from './directives/datatable-empty.directive';
import {DateTimePickerDirective} from './directives/date-time-picker.directive';
import {InputLimitToDirective} from './directives/input-limit-to.directive';
import {MediaFilterComponent} from './filter/media-filter/media-filter.component';
import {SubscriptionFilterComponent} from './filter/subscription-filter/subscription-filter.component';
import {MustBeAuthenticatedGuard} from './guards/must-be-authenticated.guard';
import {RoleCanActivateGuard} from './guards/role-can-activate.guard';
import {MediaListComponent} from './list/media-list.component';
import {AppLayoutComponent} from './pages/app-layout/app-layout.component';
import {AuthLayoutComponent} from './pages/auth-layout/auth-layout.component';
import {ErrorLayoutComponent} from './pages/error-layout/error-layout.component';
import {AvatarPipe} from './pipes/avatar.pipe';
import {ClientsFilterPipe} from './pipes/clients-filter.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {ImpersonableUsersFilterCountPipe} from './pipes/impersonable-users-filter-count.pipe';
import {ImpersonableUsersFilterPipe} from './pipes/impersonable-users-filter.pipe';
import {JoinComplexePipe} from './pipes/join-complexe.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {KeysPipe} from './pipes/key.pipe';
import {MessagePipe} from './pipes/message.pipe';
import {MomentPipe} from './pipes/moment.pipe';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {NotDiscardedPipe} from './pipes/not-discarded.pipe';
import {PaginatePipe} from './pipes/paginate.pipe';
import {TernaryPipe} from './pipes/ternary.pipe';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {AlertsService} from './services/alerts.service';
import {AppSettingsService} from './services/app-settings.service';
import {Auth0AuthenticationService} from './services/auth0-authentication.service';
import {BlockUIService} from './services/block-ui.service';
import {BodyService} from './services/body.service';
import {CanDeactivateFormGuardService} from './services/can-deactivate-form-guard.service';
import {ClientsService} from './services/clients.service';
import {DateTimeFormatService} from './services/date-time-format.service';
import {ExternalLinksService} from './services/external-links.service';
import {GroupRolesService} from './services/group-roles.service';
import {ImpersonableUsersService} from './services/impersonable-users.service';
import {InterceptorSpinnerService} from './services/interceptor-spinner.service';
import {LanguagesService} from './services/languages.service';
import {LayoutService} from './services/layout.service';
import {MyClientsService} from './services/my-clients.service';
import {PublishService} from './services/publish.service';
import {ScrollToTopService} from './services/scroll-to-top.service';
import {TagsListsService} from './services/tags-lists.service';
import {TimezonesService} from './services/timezones.service';
import {UserContextService} from './services/user-context.service';
import {IsAfterValidatorDirective} from './validators/is-after/directive';
import {IsBeforeValidatorDirective} from './validators/is-before/directive';
import {IsBetweenValidatorDirective} from './validators/is-between/directive';
import {IsSameOrAfterValidatorDirective} from './validators/is-same-or-after/directive';
import {MomentValidatorDirective} from './validators/moment/directive';
import {ConfirmationService} from 'primeng/api';
import {NgSelectModule} from '@ng-select/ng-select';

export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: InterceptorSpinnerService, multi: true},
];

@NgModule({
  imports: [
    BsDropdownModule,
    ConfirmDialogModule,
    CommonModule,
    FormsModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    NgProgressModule,
    NgSelectModule,
    L10nTranslationModule,
    TypeaheadModule,
    InputSwitchModule,
    TriStateCheckboxModule,
    AccordionModule,
    ToastModule,
    NgProgressHttpModule,
    NgProgressModule.withConfig({spinnerPosition: 'right', color: '#0080ff'}),
    // BootstrapModalModule
    // BootstrapModalModule.forRoot({ container: document.body })
  ],
    exports: [
        // Components
        BlockUIComponent,
        DataTableComponent,
        ImpersonateUserComponent,
        PublishComponent,
        AudioPlayerComponent,
        ScrollToTopComponent,
        SendBackResultComponent,
        TopMenuComponent,
        SelectTagsComponent,
        SelectCulturesComponent,
        MediaFilterComponent,
        DropZoneComponent,
        AddMediasDialogComponent,
        ConfirmMediaDeleteDialogComponent,
        ConfirmMediaArchiveDialogComponent,
        TagsMediaActionListDialogComponent,
        DurationPipe,
        JoinComplexePipe,
        SubscriptionFilterComponent,
        // Directives
        DataTableActionsDirective,
        DataTableActionsTemplateDirective,
        DataTableColumnDirective,
        DataTableColumnCellDirective,
        DataTableColumnHeaderDirective,
        DataTableDetailsDirective,
        DataTableDetailsTemplateDirective,
        DataTableEmptyDirective,
        DataTableEmptyTemplateDirective,
        DateTimePickerDirective,
        CampaignSimpleListComponent,
        PublicationSimpleListComponent,
        InputLimitToDirective,
        // Pages
        AppLayoutComponent,
        AuthLayoutComponent,
        ErrorLayoutComponent,
        // Pipes
        AvatarPipe,
        ImpersonableUsersFilterPipe,
        ImpersonableUsersFilterCountPipe,
        JoinPipe,
        MomentPipe,
        PaginatePipe,
        KeysPipe,
        TernaryPipe,
        TimeAgoPipe,
        // Validators
        IsAfterValidatorDirective,
        IsBeforeValidatorDirective,
        IsBetweenValidatorDirective,
        IsSameOrAfterValidatorDirective,
        MomentValidatorDirective,
        // Modules
        ConfirmDialogModule,
        TriStateCheckboxModule,
        AccordionModule,
        ConfirmNewComponent,
        MediaListComponent,
        EditCampaignComponent,
        ToastModule
    ],
    declarations: [
        // Components
        BlockUIComponent,
        DataTableComponent,
        ImpersonateUserComponent,
        PublishComponent,
        AudioPlayerComponent,
        ScrollToTopComponent,
        SendBackResultComponent,
        TopMenuComponent,
        MediaFilterComponent,
        DurationPipe,
        JoinComplexePipe,
        DropZoneComponent,
        AddMediasDialogComponent,
        ConfirmMediaDeleteDialogComponent,
        ConfirmMediaArchiveDialogComponent,
        TagsMediaActionListDialogComponent,
        SubscriptionFilterComponent,
        // Directives
        DataTableActionsDirective,
        DataTableActionsTemplateDirective,
        DataTableColumnDirective,
        DataTableColumnCellDirective,
        DataTableColumnHeaderDirective,
        DataTableDetailsDirective,
        DataTableDetailsTemplateDirective,
        DataTableEmptyDirective,
        DataTableEmptyTemplateDirective,
        DateTimePickerDirective,
        InputLimitToDirective,
        // Pages
        AppLayoutComponent,
        AuthLayoutComponent,
        ErrorLayoutComponent,
        CampaignSimpleListComponent,
        PublicationSimpleListComponent,
        // Pipes
        AvatarPipe,
        ImpersonableUsersFilterPipe,
        ImpersonableUsersFilterCountPipe,
        JoinPipe,
        MessagePipe,
        MomentPipe,
        Nl2brPipe,
        NotDiscardedPipe,
        PaginatePipe,
        KeysPipe,
        TernaryPipe,
        TimeAgoPipe,
        ClientsFilterPipe,
        // Validators
        IsAfterValidatorDirective,
        IsBeforeValidatorDirective,
        IsBetweenValidatorDirective,
        IsSameOrAfterValidatorDirective,
        MomentValidatorDirective,
        SelectTagsComponent,
        ConfirmNewComponent,
        ConfirmationPublicationDialogComponent,
        SelectCulturesComponent,
        MediaListComponent,
        EditCampaignComponent
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Guards
        CanDeactivateFormGuardService,
        MustBeAuthenticatedGuard,
        RoleCanActivateGuard,
        // Services
        AlertsService,
        AppSettingsService,
        Auth0AuthenticationService,
        BlockUIService,
        BodyService,
        // { provide: BrowserXhr, useClass: ProgressBrowserXhr },
        DateTimeFormatService,
        ExternalLinksService,
        GroupRolesService,
        httpInterceptorProviders,
        ImpersonableUsersService,
        LanguagesService,
        LayoutService,
        MyClientsService,
        PublishService,
        ScrollToTopService,
        TagsListsService,
        TimezonesService,
        // { provide: ToastOptions, useClass: ToastOptionsService },
        UserContextService,
        ConfirmationService,
        ClientsService
      ]
    };
  }
}

<div *ngIf="brand$ | async as brand" class="logo">
  <img [src]="'/assets/images/brands/' + brand.id + '/logo.png'"
       width="80"
       [alt]="brand.name">
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
<div class="copyright">
  {{copyrightYear}} &copy;
  <a *ngIf="brand$ | async as brand" [href]="brand.url" target="_blank" rel="noopener noreferrer">{{brand.name}}</a>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {Client} from '../models/client';

@Pipe({ name: 'clientsFilter' })
export class ClientsFilterPipe implements PipeTransform {
  transform(value: Client[], filter: string): Client[] {
    if (_.isEmpty(value) || _.isEmpty(filter)) {
      return value;
    }

    // escape the filter so it can be used as a RegExp
    const filterRegex = filter.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
    // create the RegExp to search the clients name
    const regex = new RegExp(filterRegex, 'i');
    // filter the clients
    return _.filter(value, c => regex.test(c.name));
  }
}

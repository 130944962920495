import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Media} from '../../shared/models/media';
import {MediasFilterForm} from '../../shared/models/medias-filter-form';

export const MediasListValidateActionTypes = {
  TOGGLE_SEARCH: type('[Medias validate] Toggle Search Form'),
  QUICK_SEARCH: type('[Medias validate] Quick Search'),
  SEARCH: type('[Medias validate] Search'),
  CHANGE_PAGE_SIZE: type('[[Medias validate] Change Page Size'),
  PAGE_TO: type('[Medias validate] Page To'),
  ORDER_BY: type('[Medias validate] Order By'),
  LOAD: type('[Medias validate] Load'),
  LOAD_SUCCESS: type('[Medias validate] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Medias validate] Load Validation Fail'),
  LOAD_FAIL: type('[Medias validate] Load Fail'),
  SELECT_PAGE: type('[Medias validate] Select Page'),
  UNSELECT_PAGE: type('[Medias validate] Unselect Page'),
  TOGGLE_SELECTION: type('[Medias validate] Toggle Selection')
};

export class ToggleSearchMediasValidateAction implements Action {
  readonly type = MediasListValidateActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class QuickSearchMediasValidateAction implements Action {
  readonly type = MediasListValidateActionTypes.QUICK_SEARCH;

  constructor(public payload?: string) {}
}

export class SearchMediasValidateAction implements Action {
  readonly type = MediasListValidateActionTypes.SEARCH;

  constructor(public payload?: MediasFilterForm) {}
}

export class ChangeMediasValidatePageSizeAction implements Action {
  readonly type = MediasListValidateActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageMediasValidateToAction implements Action {
  readonly type = MediasListValidateActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderMediasValidateByAction implements Action {
  readonly type = MediasListValidateActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadMediasValidateAction implements Action {
  readonly type = MediasListValidateActionTypes.LOAD;

  constructor() {}
}

export class LoadMediasValidateSuccessAction implements Action {
  readonly type = MediasListValidateActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Media>) {}
}
export class LoadMediasValidateValidationFailAction implements Action {
  readonly type = MediasListValidateActionTypes.LOAD_VALIDATION_FAIL;

  constructor() {}
}

export class LoadMediasValidateFailAction implements Action {
  readonly type = MediasListValidateActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
export class SelectMediasValidatePageAction implements Action {
  readonly type = MediasListValidateActionTypes.SELECT_PAGE;

  constructor() {}
}

export class UnselectMediasValidatePageAction implements Action {
  readonly type = MediasListValidateActionTypes.UNSELECT_PAGE;

  constructor() {}
}

export class ToggleMediasValidateSelectionAction implements Action {
  readonly type = MediasListValidateActionTypes.TOGGLE_SELECTION;

  /* payload is the user id */
  constructor(public payload?: number) {}
}

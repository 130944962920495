import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {BaseEffect} from '../../shared/effects/base.effect';
import {ArrayResponse} from '../../shared/models/array-response';
import {Campaign} from '../../shared/models/campaign';
import {SendBackResult} from '../../shared/models/send-back-result';
import * as rootSelectors from '../../shared/reducers';
import {CampaignLastActionTypes, CampaignLastFaildAction, CampaignLastSuccessAction} from '../actions/campaign-list';
import * as fromCampaigns from '../reducers';
import {DashboardService} from '../services/dashboard.service';

@Injectable()
export class CampaignListEffect extends BaseEffect {
  private client$ = this.store.select(rootSelectors.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));
  private roles$ = this.store.select(rootSelectors.selectors.getMyRoles).pipe(filter(roles => !_.isEmpty(roles)));

  load$ = createEffect(
    () => {  return this.actions$.pipe(
    ofType(CampaignLastActionTypes.CAMPAIGN_LOAD),
    // .delayWhen(() => this.roles$)
    // .withLatestFrom(this.roles$)
    // .skipWhile(roles => !_.find(roles, { 'code': 'APS_PROGRAM' }))
    delayWhen(() => this.client$),
    debug('Load campaign last action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.dashboardService.getLastCampaign(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<Campaign>>) => new CampaignLastSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  protected handleUnhandledError(response: Response): Action { return new CampaignLastFaildAction(response.status); }

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private store: Store<fromCampaigns.AppState>,
              private dashboardService: DashboardService,
              router: Router) {
    super(router, translation);
  }
}

import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';

import {GoAction, ReplaceAction, RouterActions} from '../actions/router';

@Injectable()
export class RouterEffects {

  // @Effect({ dispatch: false })
  // @ts-ignore
  navigate$ = createEffect(() => {  return this.actions$.pipe(ofType(RouterActions.GO),
                                 map((action: GoAction) => action.payload),
                                 tap(({ path, query: queryParams, extras }) =>
                                   this.router.navigate(path, { queryParams, ...extras })))}, { dispatch: false });

  // @Effect({ dispatch: false })
  // @ts-ignore
  replace$ = createEffect(() => {  return this.actions$.pipe(ofType(RouterActions.REPLACE),
                                map((action: ReplaceAction) => action.payload),
                                tap(({ path, query: queryParams, extras }) =>
                                  this.router.navigate(path, { queryParams, ...extras, replaceUrl: true })))}, { dispatch: false });

  // @Effect({ dispatch: false })
  navigateBack$ = createEffect(
    () => {  return this.actions$.pipe(ofType(RouterActions.BACK), tap(() => this.location.back()))}, { dispatch: false });

  // @Effect({ dispatch: false })
  navigateForward$ = createEffect(
    () => {  return this.actions$.pipe(ofType(RouterActions.FORWARD), tap(() => this.location.forward()))}, { dispatch: false });

  constructor(private actions$: Actions, private router: Router, private location: Location) {}
}

import './rxjs-operators';
import 'eonasdan-bootstrap-datetimepicker';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Clear the session storage before bootstrapping the application.
// This will clear any impersonation token or login error message stored in session storage.
// sessionStorage.clear();
platformBrowserDynamic().bootstrapModule(AppModule);

import {Action} from '@ngrx/store';

import {TagsLists} from '../../medias/models/tags-list';
import {ArrayResponse, type} from '../../shared';
import {Campaign} from '../../shared/models/campaign';
import {SubscriptionsListActionTypes} from '../../subscription/actions/subscriptions';
import {CampaignsFilterForm} from '../models/campaigns-filter-form';

export const CampaignsListActionTypes = {
  TOGGLE_SEARCH: type('[Campaigns] Toggle Search Form'),
  QUICK_SEARCH: type('[Campaigns] Quick Search'),
  SEARCH: type('[Campaigns] Search'),
  CHANGE_PAGE_SIZE: type('[Campaigns] Change Page Size'),
  PAGE_TO: type('[Campaigns] Page To'),
  ORDER_BY: type('[Campaigns] Order By'),
  LOAD: type('[Campaigns] Load'),
  LOAD_SUCCESS: type('[Campaigns] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Campaigns] Load Validation Fail'),
  LOAD_FAIL: type('[Campaigns] Load Fail'),
  SELECT_PAGE: type('[Campaigns] Select Page'),
  UNSELECT_PAGE: type('[Campaigns] Unselect Page'),
  TOGGLE_SELECTION: type('[Campaigns] Toggle Selection'),
  TOGGLE_SELECTION_TAGS: type('[Campaigns] Toggle Selection Tags'),
  CLEAR: type('[Campaigns] Clear Data')
};

export class ToggleSearchCampaignsAction implements Action {
  readonly type = CampaignsListActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class QuickSearchCampaignsAction implements Action {
  readonly type = CampaignsListActionTypes.QUICK_SEARCH;
  constructor(public payload?: string) {}
}

export class SearchCampaignsAction implements Action {
  readonly type = CampaignsListActionTypes.SEARCH;
  constructor(public payload?: CampaignsFilterForm) {}
}

export class ChangeCampaignsPageSizeAction implements Action {
  readonly type = CampaignsListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageCampaignsToAction implements Action {
  readonly type = CampaignsListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderCampaignsByAction implements Action {
  readonly type = CampaignsListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadCampaignsAction implements Action {
  readonly type = CampaignsListActionTypes.LOAD;
  constructor() {}
}

export class LoadCampaignsSuccessAction implements Action {
  readonly type = CampaignsListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Campaign>) {}
}

export class LoadCampaignsValidationFailAction implements Action {
  readonly type = CampaignsListActionTypes.LOAD_VALIDATION_FAIL;
  constructor() {}
}

export class LoadCampaignsFailAction implements Action {
  readonly type = CampaignsListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}
export class SelectCampaignsPageAction implements Action {
  readonly type = CampaignsListActionTypes.SELECT_PAGE;
  constructor() {}
}

export class UnselectCampaignsPageAction implements Action {
  readonly type = CampaignsListActionTypes.UNSELECT_PAGE;
  constructor() {}
}
export class ToggleCampaignSelectionAction implements Action {
  readonly type = CampaignsListActionTypes.TOGGLE_SELECTION;
  /* payload is the user id */
  constructor(public payload?: number) {}
}
export class ClearCampaignAction implements Action {
  readonly type = CampaignsListActionTypes.CLEAR;
  constructor() {}
}

export class ToggleCampaignsSelectionTagsAction implements Action { // tags to delete
  readonly type = CampaignsListActionTypes.TOGGLE_SELECTION_TAGS;
  constructor(public payload?: [TagsLists]) {}
}

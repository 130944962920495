import {Action} from '@ngrx/store';

import {type} from '../utilities/type';

export const NoOpActionTypes = {
  NOOP: type('[Generic] No Operation')
};

export class NoOpAction implements Action {
  readonly type = NoOpActionTypes.NOOP;

  constructor() {}
}

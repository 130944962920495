import {Injectable} from '@angular/core';
import {convertToParamMap, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import { BaseEffect, error, SendBackResult } from '../index';
import * as fromRoot from '../reducers';
import * as fromCampaigns from '../../publications/reducers';
import {PublicationsService} from '../../publications/services/publications.service';
import {Counters} from '../models/counters';
import {CountersActionType, LoadCountersSuccessAction} from '../actions/counters';

@Injectable()
export class CounterEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$ = createEffect(
    () => {  return this.actions$.pipe(ofType(CountersActionType.COUNTERS_LOAD),
      delayWhen(() => this.client$),
      debug('Load advanced counters action received.'),
      withLatestFrom(this.client$),
      switchMap(([action, client]) => this.publicationsService.getStatsSlow(client.uuid)
        .pipe(map((payload: SendBackResult<Counters>) => new LoadCountersSuccessAction(payload.data)),
          catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(CountersActionType.COUNTERS_LOAD_FAIL),
      debug('A server error occurred while loading advanced counters.'),
      map(() => error(this.translation.translate('DASHBOARD_ADVANCED_COUNTERS_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});


  constructor(private actions$: Actions,
              private store: Store<fromCampaigns.AppState>,
              translation: L10nTranslationService,
              private publicationsService: PublicationsService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return undefined; }


}

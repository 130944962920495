import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {DateTimeFormatService, DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {PlayLogsActionTypes, TogglePlayLogsPlayAction} from '../actions/play-logs';

export interface PlayLogsState {
  playing: string;
}

const initialState: PlayLogsState = {
  playing: undefined
};

export function playLogsReducer(state = initialState, action: Action): PlayLogsState {
  switch (action.type) {
    case PlayLogsActionTypes.TOGGLE_PLAY:
      return handleTogglePlayAction(state, action);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleTogglePlayAction(state: PlayLogsState, action: TogglePlayLogsPlayAction): PlayLogsState {
  if (state.playing === action.payload) {
    return {...state, playing: undefined };
  } else {
    return {...state, playing: action.payload };
  }
}

function handleChangeClientAction(): PlayLogsState {
  return { playing: undefined };
}

export const playLogsSelectors = {
  playing: (state: PlayLogsState) => _.get(state, 'playing')
};

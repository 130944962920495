import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {Subscriptions} from '../models/subscriptions';

export const UpdateSubscriptionActionTypes = {
  UPDATE_PROGRAM_TEMPLATE: type('[Subscription Update] Update Program'),
  UPDATE_CULTURES: type('[Subscription Update] Update Cultures'),
  UPDATE_TAGS: type('[Subscription Update] Update TAGS'),
  UPDATE_OPENING_HOURS: type('[Subscription Update] Update Opening Hours'),
  UPDATE_PLAY_OFFSET: type('[Subscription Update] Update play offset'),
  UPDATE_CANCEL: type('[Subscription Update] Update cancel')
};

export class UpdateSubscriptionProgramAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_PROGRAM_TEMPLATE;

  constructor(public payload?: Subscriptions) {}
}

export class UpdateSubscriptionCulturesAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_CULTURES;

  constructor(public payload?: string[]) {}
}

export class UpdateSubscriptionTagsAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_TAGS;

  constructor(public payload?: any) {}
}

export class UpdateSubscriptionOpeningHoursAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_OPENING_HOURS;

  constructor(public payload?: any) {}
}
export class UpdateSubscriptionPlayOffsetAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_PLAY_OFFSET;

  constructor(public payload?: any) {}
}
export class UpdateSubscriptionCancelAction implements Action {
  readonly type = UpdateSubscriptionActionTypes.UPDATE_CANCEL;

  constructor() {}
}

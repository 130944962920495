import {Action} from '@ngrx/store';

import {ArrayResponse} from '../../shared/models/array-response';
import {type} from '../../shared/utilities/type';
import {TagsList} from '../models/tags-list';

export const TagsListActionTypes = {
  LOAD: type('[Subscription TagsList] Load'),
  LOAD_SUCCESS: type('[Subscription TagsList] Load Success'),
  LOAD_FAIL: type('[Subscription TagsList] Load Fail')
};

export class LoadTagsListAction implements Action {
  readonly type = TagsListActionTypes.LOAD;

  constructor() {}
}

export class LoadTagsListSuccessAction implements Action {
  readonly type = TagsListActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<TagsList>) {}
}

export class LoadTagsListFailAction implements Action {
  readonly type = TagsListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';

import {type} from '../../shared';
import {Media} from '../../shared/models/media';

export const valitadedMediaUpdateActionTypes = {
  UPDATE: type('[Update valMedia] Update'),
  UPDATE_SUCCESS: type('[Update valMedia] Update Success'),
  UPDATE_SELF: type('[Update valMedia] Update Self'),
  UPDATE_FAIL: type('[Update valMedia] Update Server Error')
};

export class ValidatedMediaUpdateAction implements Action {
  readonly type = valitadedMediaUpdateActionTypes.UPDATE;

  constructor() {}
}

export class ValidatedMediaUpdateSuccessAction implements Action {
  readonly type = valitadedMediaUpdateActionTypes.UPDATE_SUCCESS;

  constructor(public payload?: Observable<Media[]>) {}
}

export class ValidatedMediaUpdateSelfAction implements Action {
  readonly type = valitadedMediaUpdateActionTypes.UPDATE_SELF;

  constructor() {}
}

export class ValidatedMediaUpdateFailAction implements Action {
  readonly type = valitadedMediaUpdateActionTypes.UPDATE_FAIL;

  constructor(public payload?: number) {}
}

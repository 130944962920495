import {Injectable} from '@angular/core';
import {convertToParamMap, Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {ArrayResponse, cleanObject, error, go, SendBackResult} from '../';
import {debug} from '../../../rxjs-operators';
import {MediasService} from '../../medias/services/medias.service';
import {
  ChangeMediasPageSizeAction,
  LoadMediasFailAction,
  LoadMediasSuccessAction,
  LoadMediasValidationFailAction,
  MediasListActionTypes,
  OrderMediasByAction,
  PageMediasToAction,
  QuickSearchMediasAction,
  SearchMediasAction
} from '../actions/medias-list';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {Media} from '../models/media';
import * as fromRoot from '../reducers/index';
import {ClientsService} from '../services/clients.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class MediasListEffect extends BaseEffect {
  private routerState$ = this.store.select(fromRoot.selectors.getRouterState).pipe(filter(state => !_.isEmpty(state)));

  pageTo$  = createEffect(
    () => {  return this.actions$.pipe(ofType<PageMediasToAction>(MediasListActionTypes.PAGE_TO),
                               withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
                               map(([action, query]) => Object.assign({}, query, { $page: action.payload })),
                               map(query => go([], query)))});

  changePageSize$  = createEffect(
    () => {  return this.actions$.pipe(ofType<ChangeMediasPageSizeAction>(MediasListActionTypes.CHANGE_PAGE_SIZE),
                                       withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
                                       // .do(([action, client]) => this.clientsService.setRowSize(client.uuid, action))
                                       map(([action, query]) => Object.assign({}, query, { $length: action.payload })),
                                       map(query => go([], query)))});

  orderBy$  = createEffect(
    () => {  return this.actions$.pipe(ofType<OrderMediasByAction>(MediasListActionTypes.ORDER_BY),
                                withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
                                map(([action, query]) => Object.assign({}, query, { $orderBy: action.payload })),
                                map(query => go([], query)))});

  quickSearch$  = createEffect(
    () => {  return this.actions$.pipe(ofType<QuickSearchMediasAction>(MediasListActionTypes.QUICK_SEARCH),
                                    withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
                                    map(([action, query]) => Object.assign({}, query, { quickSearch: action.payload, $page: 1 })),
                                    map(query => cleanObject(query, ['$page', '$length', '$orderBy', 'quickSearch'], true)),
                                    map(query => go([], query)))});

  search$  = createEffect(    () => {  return this.actions$.pipe(ofType<SearchMediasAction>(MediasListActionTypes.SEARCH),
                       withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
                       map(([action, query]) => Object.assign({}, cleanObject(query, ['$page', '$length', '$orderBy'], true), action.payload, { $page: 1 })),
                       map(query => cleanObject(query, ['quickSearch'], false)),
                       map(query => go([], query)))});

  load$  = createEffect( () => {  return this.actions$.pipe( ofType(MediasListActionTypes.LOAD),
    delayWhen(() => this.routerState$),
    debug('Load medias list action received.'),
    withLatestFrom(this.routerState$.pipe(map(state => state.queryParams))),
    map(([action, query]) => Object.assign({}, { $page: 1, $length: DEFAULT_PAGE_SIZE, $orderBy: 'updated descending' }, query)),
    withLatestFrom(this.routerState$.pipe(map(state => state.params), map(convertToParamMap), map(params => params.get('clientId')))),
    switchMap(([filters, clientId]) => this.mediasService.getList(clientId, this.test(filters))
                                         .pipe(map((payload: SendBackResult<ArrayResponse<Media>>) => new LoadMediasSuccessAction(payload.data)),
                                               catchError((res: Response) => this.catchResponseError(res)))))});

  loadValidationFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(MediasListActionTypes.LOAD_VALIDATION_FAIL),
                       debug('User made a typo in search filter.'),
                       map(() => error(this.translation.translate('MEDIAS_LOAD_BAD_REQUEST_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(MediasListActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving medias list.'),
                                 map(() => error(this.translation.translate('MEDIAS_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromRoot.AppState>,
              translation: L10nTranslationService,
              private mediasService: MediasService,
              private clientsService: ClientsService,
              router: Router) {
    super(router, translation);
  }

  protected handleBadRequest(): Action {
    error(this.translation.translate('MEDIAS_NAME_DUPLICATE_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'));
    return new LoadMediasValidationFailAction();
  }

  protected handleUnhandledError(response: Response): Action { return new LoadMediasFailAction(response.status); }
  test(filterr) { return filterr; }
}

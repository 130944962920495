import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../index';
import {Counters} from '../models/counters';
import {CountersActionType, LoadCountersSuccessAction} from '../actions/counters';

export interface CountersState {
  value: Counters;
}

const initialState: CountersState = {
  value: undefined
};

export function countersReducer(state = initialState, action: Action): CountersState {
  switch (action.type) {
    case CountersActionType.COUNTERS_LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CountersActionType.COUNTERS_LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction(state);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: CountersState, action: LoadCountersSuccessAction): CountersState {
  const newState: CountersState = {...state, value: action.payload };
  return newState;
}

function handleLoadFailAction(state: CountersState): CountersState {
  const newState: CountersState = {...state, value: undefined };
  return newState;
}

function handleChangeClientAction(state: CountersState): CountersState {
  const newState: CountersState = {...state, value: undefined };
  return newState;
}

export const countersSelectors = {
  value: (state: CountersState) => _.get(state, 'value')
};

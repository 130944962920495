import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LanguagesActionTypes, LoadLanguagesSuccessAction} from '../actions/languages';
import {Language} from '../models/language';

export interface LanguagesState {
  array: Language[];
}

const initialState: LanguagesState = {
  array: []
};

export function languagesReducer(state = initialState, action: Action): LanguagesState {
  switch (action.type) {
    case LanguagesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case LanguagesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: LanguagesState, action: LoadLanguagesSuccessAction): LanguagesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: LanguagesState): LanguagesState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const languagesSelectors = {
  array: (state: LanguagesState) => _.get(state, 'array', [])
};

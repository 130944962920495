import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Publication} from '../../publications/models/publication';
import {serializeQueryString} from '../../shared';
import {Activity} from '../../shared/models/activity';
import {ArrayResponse} from '../../shared/models/array-response';
import {Campaign} from '../../shared/models/campaign';
import {Media} from '../../shared/models/media';
import {SendBackResult} from '../../shared/models/send-back-result';
import {AppSettingsService} from '../../shared/services/app-settings.service';
import {BaseRestService} from '../../shared/services/base-rest.service';
import {BlockUIService} from '../../shared/services/block-ui.service';
import {Subscriptions} from '../../subscription/models/subscriptions';
import {Program} from '../../templates-programs/models/program';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseRestService {

  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'DashboardService'; }

  protected get isAnonymous(): boolean { return false; }

  protected get showBlockUI(): boolean { return false; }

  getMediasToValidate(clientId: string): Observable<SendBackResult<ArrayResponse<Media>>> {
    return this.get<ArrayResponse<Media>>(
      `/clients/${clientId}/audio/media/commercials${serializeQueryString({ $page: 1, $length: 5, $orderBy: 'id descending' })}&validated=false`);
  };

  getPublicationFailed(clientId: string): Observable<SendBackResult<ArrayResponse<Publication>>> {
    return this.get<ArrayResponse<Publication>>(`/clients/${clientId}/audio/publications?status=-1`);
  };

  getPublicationLast(clientId: string): Observable<SendBackResult<ArrayResponse<Publication>>> {
    return this.get<ArrayResponse<Publication>>(
      `/clients/${clientId}/audio/publications${serializeQueryString({ $page: 1, $length: 5, $orderBy: 'id descending' })}&status=ready`);
  };

  getLastCampaign(clientId: string): Observable<SendBackResult<ArrayResponse<Campaign>>> {
    return this.get<ArrayResponse<Campaign>>(`/clients/${clientId}/audio/Schedules/commercials${
      serializeQueryString({ $page: 1, $length: 5, $orderBy: 'updated descending' })}&archive=false&subscriptionCount=false&mediaCount=false`);
  }

  siteWithNoTemplateAssociated(clientId: string): Observable<SendBackResult<ArrayResponse<Subscriptions>>> {
    return this.get<ArrayResponse<Subscriptions>>(
      `/clients/${clientId}/audio/subscriptions${serializeQueryString({ $page: 1, $orderBy: 'updated descending' })}&programTemplateId=-1`);
  }

  activityFeed(clientId: string): Observable<SendBackResult<ArrayResponse<Activity>>> {
    return this.get<ArrayResponse<Activity>>(`/clients/${clientId}/user-activities`);
  }

  getProgramNoPlanning(clientId: string): Observable<SendBackResult<ArrayResponse<Program>>> {
    return this.get<ArrayResponse<Program>>(`/clients/${clientId}/audio/ProgramTemplates/Programs`);
  };
}

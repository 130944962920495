import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as m from 'moment';

export const isSameOrAfter = (after: AbstractControl|m.Moment, format: string): ValidatorFn => {
  let subscribe = false;
  return (control: AbstractControl): ValidationErrors => {
    if (!subscribe && after instanceof AbstractControl) {
      subscribe = true;
      after.valueChanges.subscribe(() => { control.updateValueAndValidity(); });
    }

    if (Validators.required(control) != undefined) {
      return undefined;
    }

    if (after instanceof AbstractControl && Validators.required(after) != undefined) {
      return undefined;
    }

    const x = m(control.value, format, true);
    const y = getValue(after, format);
    return x.isSameOrAfter(y) ? undefined : { isSameOrAfter: true };
  };
};

function getValue(controlOrMoment: AbstractControl|m.Moment, format: string): m.Moment {
  if (controlOrMoment instanceof AbstractControl) {
    return m(controlOrMoment.value, format, true);
  }

  return controlOrMoment;
}

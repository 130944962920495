import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {Activity} from '../../shared/models/activity';
import {ActivityFeedActionTypes, LoadActivityFeedSuccessAction} from '../actions/activity-feed';

export interface ActivityFeedState {
  array: Activity[];
}

const initialState: ActivityFeedState = {
  array: []
};

export function activityFeedReducer(state = initialState, action: Action): ActivityFeedState {
  switch (action.type) {
    case ActivityFeedActionTypes.LOAD:
      return handleLoadAction();
    case ActivityFeedActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ActivityFeedActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): ActivityFeedState {
  return initialState;
}

function handleLoadSuccessAction(state: ActivityFeedState, action: LoadActivityFeedSuccessAction): ActivityFeedState {
  const newState: ActivityFeedState = {...state, array: action.payload.array };
  return newState;
}

function handleLoadFailAction(state: ActivityFeedState): ActivityFeedState {
  const newState: ActivityFeedState = {...state, array: [] };
  return newState;
}

function handleChangeClientAction(): ActivityFeedState {
  return initialState;
}

export const activityFeedSelectors = {
  array: (state: ActivityFeedState) => _.get(state, 'array', [])
};

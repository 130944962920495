import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppSettingsService, BaseRestService, BlockUIService, SendBackResult} from '../../shared';

@Injectable({ providedIn: 'root' })
export class AnonymousUserService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'AnonymousUserService'; }

  protected get isAnonymous(): boolean { return true; }

  lostPassword(email: string): Observable<SendBackResult<{}>> { return this.post(`/users/lost-password`, { email }); }
}

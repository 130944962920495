<div bsModal #ConfirmationPublishModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" >
    <form class="form-horizontal" [formGroup]="publishInfoForm" novalidate>
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" *ngIf="publishInfo?.programCount > 0 && publishInfo?.commercialCount===0"  ><span translate>CONFIRM_PUBLISH_PROGRAM_DIALOG_TITLE</span>  {{publish?.programEnd | date: 'dd/MM/yyyy'}}</h4>
          <h4 class="modal-title" *ngIf="publishInfo?.programCount === 0 && publishInfo?.commercialCount > 0"><span translate>CONFIRM_PUBLISH_PUBLISH_DIALOG_TITLE</span> </h4>
          <h4 class="modal-title" *ngIf="publishInfo?.programCount > 0 && publishInfo?.commercialCount > 0" ><span translate>CONFIRM_PUBLISH_PROGRAM_PUBLISH_DIALOG_TITLE </span> {{publish?.programEnd | date: 'dd/MM/yyyy'}}</h4>

        </div>
        <div class="modal-body">
          <div class="" >
            <div class="row" align="center">
              <div class="row"  *ngIf="counter">
                <div class="col-sm-4" *ngIf="counter.audio">
                  <label  translate>PUBLICATION_DIALOG_TOTAL_PLAYERS</label>  <strong> {{counter.audio}}</strong>
                </div>
                <div class="col-sm-4" *ngIf="counter.audioOnline">
                  <i class="fa fa-wifi fa-lg fa-fw ng-star-inserted"></i>
                  <label  translate>PUBLICATION_DIALOG_ONLINE_PLAYERS </label><strong> {{counter.audioOnline}}</strong>
                </div>
                <div class="col-sm-4" *ngIf="counter.audioOffline">>
                  <span class="fa-stack fa-lg" style="height: 1em;">
                    <i class="fa fa-wifi fa-stack-1x"></i>
                    <i class="fa fa-ban fa-stack-2x text-danger"></i>
                  </span> <label  translate>PUBLICATION_DIALOG_OFFLINE_PLAYERS</label> <strong> {{counter.audioOffline}}</strong>
                </div>
              </div><br>
               <div class="row" >
                <div class="col-sm-1">
                  <label class="col-sm-3 margin-top" translate>MEDIAS_LIST_FILTER_NAME</label>
                </div>
                <div class="col-sm-5">
                  <input id="mediaName" type="text" class="form-control" formControlName="name">
                </div>

               <div *ngIf="cdStatus !== 'none'" class="margin-top">
                <div class="col-sm-offset-1 col-sm-1"  >
                  <label class="label " translate>CONFIRM_PUBLISH_CD</label>
                </div>
                <div class="col-sm-2" >
                  <p-inputSwitch [formControlName]="'cd'"></p-inputSwitch>
                  <span style="margin-left: 7px ;  bottom: 5px;  position: relative;">
                <span *ngIf="publishInfoForm.get('cd').value=== true" class="switch-on">{{ 'YES' | translate:locale.language }}</span>
                <span *ngIf="publishInfoForm.get('cd').value=== false" class="switch-off">{{ 'NO' | translate:locale.language }}</span>
              </span>
                </div>
               </div>
               <div *ngIf="sdStatus !== 'none'" class="margin-top">
                <div class="col-sm-offset-1 col-sm-1"  >
                  <label  class="label" translate>CONFIRM_PUBLISH_SD </label>
                </div>
                <div class="col-sm-2 " >
                  <p-inputSwitch [formControlName]="'sd'"  [disabled]="sdStatus === 'checked'"></p-inputSwitch>
                <span style="margin-left: 7px ;  bottom: 5px;  position: relative;">
                  <span *ngIf="publishInfoForm.get('sd').value=== true" class="switch-on">{{ 'YES' | translate:locale.language }}</span>
                  <span *ngIf="publishInfoForm.get('sd').value=== false" class="switch-off">{{ 'NO' | translate:locale.language }}</span>
                </span>
                </div>
               </div>
              </div>
            </div>
            <br>
            <div class="row"  *ngIf="publishInfo && publishInfo.displayForceUpdate">
              <div class="col-sm-4">
                <label class="margin-top" translate>
                  PUBLICATION_DIALOG_FORCED_PUBLICATION
                </label> {{publishInfo.lisaCount}}
                <label class="margin-top" translate>
                  PUBLICATION_DIALOG_FORCED_PUBLICATION_LISA_PLAYERS
                </label>
              </div>
              <div class="col-sm-5">
                <input id="forcedPublication" type="checkbox" class="form-control" formControlName="forcedUpdate">
              </div>
            </div>
            <hr style="margin: 25px -50px 25px -50px" *ngIf="roleProgram && publishInfo?.programCount > 0">
            <div class="row"  *ngIf="roleProgram && publishInfo?.programCount > 0" align="center" >
              <!--<div class="row"><h5 class="pull-left">Program</h5><br></div>-->

              <!--<div class="row">-->
                <!--<div class="col-sm-3">-->
                  <!--<label class="" translate><strong>END_PUBLICATION</strong></label>-->
                <!--</div>-->
                <!--<div class="col-sm-2">-->
                  <!--<label class="" translate><strong>{{publish?.programEnd | date: 'dd/MM/yyyy'}}</strong></label>-->
                <!--</div>-->
              <!--</div>-->
              <!--<br>-->
              <div class="row">
                 <div class="table-responsive">
                      <storever-datatable *ngIf="programsInfo"
                                          [striped]="true"
                                          [hover]="true"
                                          [data]="programsInfo"
                                          [count]="programsInfoLength"
                                          [currentPage]="page"
                                          [itemsPerPage]="pageSize"
                                          (pageChange)="pageChanged($event)"
                                          (pageSizeChange)="pageSizeChanged($event)">



                        <storever-datatable-column [name]="'NAME' | translate:locale.language " [prop]="'name'">
                          <ng-template let-row="row" let-value="value" storeverDatatableColumnCell>
                            {{ value}}
                          </ng-template>
                        </storever-datatable-column>

                        <storever-datatable-column [name]="'DATE' | translate:locale.language " [prop]="'endDate'">
                          <ng-template let-value="value" storeverDatatableColumnCell>
                            {{ value | date: 'dd/MM/yyyy'}}
                          </ng-template>
                        </storever-datatable-column>

                        <storever-datatable-column [name]="'STATUS' | translate:locale.language " [prop]="'status'">
                          <ng-template let-value="value" storeverDatatableColumnCell >
                            <span class="label label-sm label-warning" *ngIf="value ===0"  translate>PUBLICATION_PROGRAM_WARNING</span>
                            <span class="label label-sm label-success" *ngIf="value ===1" translate>PUBLICATION_PROGRAM_READY</span>
                            <span class="label label-sm label-danger" *ngIf="value ===-1" translate>PUBLICATION_PROGRAM_ERROR</span>
                          </ng-template>
                        </storever-datatable-column>
                      </storever-datatable>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        <div class="modal-footer" style="max-height: 500px">
          <span *ngIf="roleProgram && publishInfo?.programCount > 0 && publishInfo?.commercialCount===0" >
          <button type="submit" class="btn btn-success"  [disabled]="!publishInfoForm.get('name').dirty || canPublish" (click)="applyCommercialAndMusic(publishInfoForm.value)">
            {{ 'CONFIRM_PUBLISH_DIALOG_ACCEPT' | translate:locale.language  }} {{publish?.programEnd | date: 'dd/MM/yyyy'}}
          </button>
          </span>
          <span *ngIf="publishInfo?.programCount === 0 && publishInfo?.commercialCount > 0" >
          <button type="submit" class="btn btn-success"  [disabled]="!publishInfoForm.get('name').dirty || canPublish" (click)="applyCommercial(publishInfoForm.value)">
            {{ 'CONFIRM_PUBLISH_DIALOG_ACCEPT1' | translate:locale.language  }}
          </button>
          </span>
          <span *ngIf="publishInfo?.programCount > 0 && publishInfo?.commercialCount > 0" >
          <button type="submit" class="btn btn-success"  [disabled]="!publishInfoForm.get('name').dirty || canPublish" (click)="applyCommercialAndMusic(publishInfoForm.value)">
            {{ 'CONFIRM_PUBLISH_DIALOG_ACCEPT' | translate:locale.language  }} {{publish?.programEnd | date: 'dd/MM/yyyy'}}
          </button>
          </span>
          <button type="button" class="btn btn-default" (click)="close()" translate>{{ 'CANCEL' | translate:locale.language  }}</button>
        </div>
      </div>
    </form>

  </div>
</div>



import {Action} from '@ngrx/store';
import {ImpersonateUser} from '../models/impersonate-user';
import {Role} from '../models/role';
import {type} from '../utilities/type';

export const UserContextActionTypes = {
  LOAD_ROLES: type('[User Context] Load Roles'),
  LOAD_ROLES_SUCCESS: type('[User Context] Load Roles Success'),
  LOAD_ROLES_FAIL: type('[User Context] Load Roles Fail'),
  CHANGE_CLIENT: type('[User Context] Change Client'),
  START_IMPERSONATION: type('[User Context] Start Impersonation'),
  START_IMPERSONATION_SUCCESS: type('[User Context] Start Impersonation Success'),
  START_IMPERSONATION_FAIL: type('[User Context] Start Impersonation Server Error'),
  STOP_IMPERSONATION: type('[User Context] Stop Impersonation'),
  CLEAR_IMPERSONATION: type('[User Context] Clear Impersonation')
};

export class LoadRolesAction implements Action {
  readonly type = UserContextActionTypes.LOAD_ROLES;
  /* payload is the clientId */
  constructor(public payload?: string) {}
}

export class LoadRolesSuccessAction implements Action {
  readonly type = UserContextActionTypes.LOAD_ROLES_SUCCESS;
  constructor(public payload?: Role[]) {}
}

export class LoadRolesServerErrorAction implements Action {
  readonly type = UserContextActionTypes.LOAD_ROLES_FAIL;
  constructor(public payload?: number) {}
}

export class ChangeClientAction implements Action {
  readonly type = UserContextActionTypes.CHANGE_CLIENT;
  constructor(public payload?: { clientId: string; redirect: boolean }) {}
}

export class StartImpersonationAction implements Action {
  readonly type = UserContextActionTypes.START_IMPERSONATION;
  constructor(public payload?: ImpersonateUser) {}
}

export class StartImpersonationSuccessAction implements Action {
  readonly type = UserContextActionTypes.START_IMPERSONATION_SUCCESS;
  constructor(public payload?: ImpersonateUser) {}
}

export class StartImpersonationServerErrorAction implements Action {
  readonly type = UserContextActionTypes.START_IMPERSONATION_FAIL;
  constructor(public payload?: number) {}
}

export class StopImpersonationAction implements Action {
  readonly type = UserContextActionTypes.STOP_IMPERSONATION;
  constructor() {}
}

export class ClearImpersonationAction implements Action {
  readonly type = UserContextActionTypes.CLEAR_IMPERSONATION;
  constructor() {}
}

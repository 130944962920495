import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {GeocodedAddress} from '../models/geocoded-address';

export const GeocodeAddressActionTypes = {

  GEOCODE: type('[Subscription Loc] Geocode Address'),
  GEOCODE_SUCCESS: type('[Subscription Loc] Geocode Address Success'),
  GEOCODE_HTTP_ERROR: type('[Subscription Loc] Geocode HTTP Error'),
  GEOCODE_GENERIC_ERROR: type('[Subscription Loc] Geocode Address Generic Error'),
  GEOCODE_OVER_QUERY_LIMIT: type('[Subscription Loc] Geocode Address Over Query Limit'),
  GEOCODE_RETRY: type('[Subscription Loc] Geocode Address Retry'),
  GEOCODE_ZERO_RESULTS: type('[Subscription Loc] Geocode Address Zero Result')
  /* GEOCODE: type('[Player] Geocode Address'),
  GEOCODE_SUCCESS: type('[Player] Geocode Address Success'),
  GEOCODE_HTTP_ERROR: type('[Player] Geocode HTTP Error'),
  GEOCODE_GENERIC_ERROR: type('[Player] Geocode Address Generic Error'),
  GEOCODE_OVER_QUERY_LIMIT: type('[Player] Geocode Address Over Query Limit'),
  GEOCODE_RETRY: type('[Player] Geocode Address Retry'),
  GEOCODE_ZERO_RESULTS: type('[Player] Geocode Address Zero Result')*/

};

export class GeocodeAddressAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE;

  constructor() {}
}

export class GeocodeAddressSuccessAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_SUCCESS;

  constructor(public payload?: GeocodedAddress) {}
}

export class GeocodeAddressHttpErrorAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_HTTP_ERROR;

  constructor(public payload?: number) {}
}

export class GeocodeAddressGenericErrorAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_GENERIC_ERROR;

  constructor() {}
}

export class GeocodeAddressOverQueryLimitAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_OVER_QUERY_LIMIT;

  constructor() {}
}

export class GeocodeAddressRetryAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_RETRY;

  constructor() {}
}

export class GeocodeAddressZeroResultsAction implements Action {
  readonly type = GeocodeAddressActionTypes.GEOCODE_ZERO_RESULTS;

  constructor() {}
}

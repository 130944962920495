import {Injectable} from '@angular/core';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {combineLatest, Observable, Observer} from 'rxjs';

import {ConfirmNewComponent} from '../components/dialog-component/dialog.component';
import {L10nTranslationService} from 'angular-l10n';

export interface ComponentDeactivable {
  canDeactivate: () => boolean;
  isModified: boolean;
}

@Injectable()
export class CanDeactivateFormGuardService  {
  bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService ,private translation: L10nTranslationService,) {}

  canDeactivate(component: ComponentDeactivable): boolean { return component.canDeactivate ? this.actionConfirm(component) : true; }
  actionConfirm(component: any): boolean {

    if (component.canDeactivate()) {
      return true;
    } else {
      return Observable.create((observer: Observer<boolean>) => {
        const text = this.translation.translate('UNSAVED_CHANGES');
        if (confirm(text) === true) {
          observer.next(true);
          observer.complete();
        } else {
          observer.next(false);
          //                        observer.complete();
        }
        // const initialState = { title: 'CONFIRMATION', message: 'UNSAVED_CHANGES', deleteBtn: false };
        // this.bsModalRef = this.modalService.show(ConfirmNewComponent, { initialState });
        //
        // const _combine = combineLatest(
        //                    this.modalService.onHide,
        //
        //                    )
        //                    .subscribe(() => {
        //                      if (this.bsModalRef.content.isConfirmed) {
        //                        observer.next(true);
        //                        observer.complete();
        //                      } else {
        //                        observer.next(false);
        //                        observer.complete();
        //                      }
        //                    });
      });
    }
  }
}

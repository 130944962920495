import {ChangeDetectorRef, Component, ContentChild, ContentChildren, EventEmitter, Inject, Input, NO_ERRORS_SCHEMA, Output, QueryList} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';

import {DEFAULT_PAGE_SIZE} from '../../consts';
import {DataTableActionsDirective} from '../../directives/datatable-actions.directive';
import {DataTableColumnDirective} from '../../directives/datatable-column.directive';
import {DataTableDetailsDirective} from '../../directives/datatable-details.directive';
import {DataTableEmptyDirective} from '../../directives/datatable-empty.directive';

@Component({ selector: 'storever-datatable', templateUrl: './datatable.component.html',
             styleUrls: ['./datatable.component.scss'] })
export class DataTableComponent {
  @Input() striped = false;
  @Input() bordered = false;
  @Input() hover = false;
  @Input() condensed = false;
  @Input() disablePagination = false;
  @Input() additionalClassName: string;
  @Input() data: any[] = [];
  @Input() count = 0;
  @Input() currentPage = 1;
  @Input() itemsPerPage = DEFAULT_PAGE_SIZE;
  @Input() orderBy: string;
  @Input() emptyText = 'EMPTY_TEXT';

  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() orderByChange = new EventEmitter<string>();

  @ContentChildren(DataTableColumnDirective) columns: QueryList<DataTableColumnDirective>;
  @ContentChild(DataTableActionsDirective) actions: DataTableActionsDirective;
  @ContentChild(DataTableDetailsDirective) details: DataTableDetailsDirective;
  @ContentChild(DataTableEmptyDirective) empty: DataTableEmptyDirective;

  get pageInfo(): { from: number; to: number; filteredLength: number } {
    if (this.count > 0) {
      if (this.itemsPerPage > 0) {
        const offset = this.currentPage - 1;
        const start = offset * this.itemsPerPage + 1;
        const end = Math.min(this.currentPage * this.itemsPerPage, this.count);
        return { from: start, to: end, filteredLength: this.count };
      } else {
        return { from: 1, to: this.count, filteredLength: this.count };
      }
    } else {
      return { from: 0, to: 0, filteredLength: 0 };
    }
  }

  private selectedRow: any;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  readProperty(row: any, propertyName: string): any { return _.isEmpty(propertyName) ? undefined : _.get(row, propertyName); }
  pageSizeChanged() { this.pageSizeChange.emit(+this.itemsPerPage); }
  gotoPage({ page }) {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }
  toggleSort(columnName: string, sortable: boolean) {
    if (!sortable) {
      return;
    }
    if (_.startsWith(this.orderBy, columnName)) {
      const descending: boolean = /\sdescending$/.test(this.orderBy);
      if (descending) {
        this.orderBy = columnName;
      } else {
        this.orderBy = `${columnName} descending`;
      }
    } else {
      this.orderBy = columnName;
    }
    this.orderByChange.emit(this.orderBy);
  }
  isSortedAsc(columnName: string): boolean { return _.isEqual(this.orderBy, columnName); }
  isSortedDesc(columnName: string): boolean { return _.isEqual(this.orderBy, `${columnName} descending`); }
  isSelected(row: any): boolean { return _.isEqual(this.selectedRow, row); }
  toggleRowSelection(row: any): void {
    if (this.isSelected(row)) {
      this.selectedRow = undefined;
    } else {
      this.selectedRow = row;
    }
  }
}

import {Action} from '@ngrx/store';
import {ArrayResponse, SendBackResult, type} from '../../shared';
import {Publication, PublicationFilterForm} from '../models/publication';

export const PublicationsListActionTypes = {
  TOGGLE_SEARCH: type('[Publications] Toggle Search Form'),
  QUICK_SEARCH: type('[Publications] Quick Search'),
  SEARCH: type('[Publications] Search'),
  CHANGE_PAGE_SIZE: type('[Publications] Change Page Size'),
  PAGE_TO: type('[Publications] Page To'),
  ORDER_BY: type('[Publications] Order By'),
  LOAD: type('[Publications] Load'),
  LOAD_SUCCESS: type('[Publications] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Publications] Load Validation Fail'),
  LOAD_FAIL: type('[Publications] Load Fail'),
  CLEAR: type('[Publications] Clear Data'),
  RE_TRY_PUBLICATION: type('[Publications] Re try'),
  RE_TRY_PUBLICATION_SUCCESS: type('[Publications] Re try Success'),
};

export class ToggleSearchPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class QuickSearchPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.QUICK_SEARCH;

  constructor(public payload?: string) {}
}

export class SearchPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.SEARCH;

  constructor(public payload?: PublicationFilterForm) {}
}

export class ChangePublicationsPageSizeAction implements Action {
  readonly type = PublicationsListActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PagePublicationsToAction implements Action {
  readonly type = PublicationsListActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderPublicationsByAction implements Action {
  readonly type = PublicationsListActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.LOAD;

  constructor() {}
}

export class LoadPublicationsSuccessAction implements Action {
  readonly type = PublicationsListActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Publication>) {}
}
export class ReTryPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.RE_TRY_PUBLICATION;

  constructor(public payload?: string) {}
}

export class RetryPublicationsSuccessAction implements Action {
  readonly type = PublicationsListActionTypes.RE_TRY_PUBLICATION_SUCCESS;

  constructor() {}
}
export class LoadPublicationsValidationFailAction implements Action {
  readonly type = PublicationsListActionTypes.LOAD_VALIDATION_FAIL;

  constructor() {}
}

export class LoadPublicationsFailAction implements Action {
  readonly type = PublicationsListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
export class ClearPublicationsAction implements Action {
  readonly type = PublicationsListActionTypes.CLEAR;

  constructor() {}
}

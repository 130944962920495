import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

import {ScrollToTopService} from '../../services/scroll-to-top.service';

@Component({
  selector: 'storever-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [trigger('stateChanged',
                       [
                         state('show', style({ opacity: 1, transform: 'scale(1.0)' })),
                         state('hide', style({ opacity: 0, transform: 'scale(0.0)' })),
                         transition('* => *', animate('500ms'))
                       ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnChanges, OnDestroy {
  @Input() isVisible = false;
  state = 'hide';

  private sub: Subscription;

  constructor(scrollToTopService: ScrollToTopService) {
    this.sub = scrollToTopService.scrollToTop$.subscribe(speed => { this.scrollToTop(speed); });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'isVisible')) {
      const change = _.get(changes, 'isVisible');
      this.state = change.currentValue ? 'show' : 'hide';
    }
  }

  ngOnDestroy() { this.sub.unsubscribe(); }

  scrollToTop(speed = 500): void {
    const current = document.documentElement.scrollTop || document.body.scrollTop;
    const target = document.body.getBoundingClientRect().top; // or -current since it's also the body
    this.animate(t => { return window.scrollTo(0, current + target * t); }, speed, t => --t * t * t + 1)();
  }

  private animate(callback: any, duration = 300, easing: (x: any) => any = x => x) {
    let animation: FrameRequestCallback;
    const start = new Date().getTime();
    return (animation = function() {
      let t = easing((new Date().getTime() - start) / duration);
      if (t <= 1) {
        window.requestAnimationFrame(animation);
      } else {
        t = 1;
      }
      return callback(t);
    });
  }
}

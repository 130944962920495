import * as _ from 'lodash';

export function cleanObject(obj: any, props: string[], keep: boolean): any {
  if (!obj) {
    throw new Error('Object is required.');
  }

  if (!props) {
    throw new Error('Props array is required.');
  }

  const result = _.clone(obj);
  Object.keys(obj).forEach(prop => {
    if (props.indexOf(prop) > -1) {
      if (!keep) {
        delete result[prop];
      }
    } else {
      if (keep) {
        delete result[prop];
      }
    }
  });
  return result;
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../';
import {ToggleMediasValidateSelectionAction} from '../../medias/actions/media-list-validate';
import {TagsList, TagsLists} from '../../medias/models/tags-list';
import {MediasValidateListState} from '../../medias/reducers/media-list-validate';
import {DownloadMediasActionTypes} from '../actions/download-medias';
import {
  ChangeMediasPageSizeAction,
  LoadMediasSuccessAction,
  MediasListActionTypes,
  OrderMediasByAction,
  PageMediasToAction,
  QuickSearchMediasAction,
  SearchMediasAction,
  ToggleMediaSelectionAction,
  ToggleMediaSelectionTagsAction,
  ToggleSearchMediasAction
} from '../actions/medias-list';
import {PlayLogsActionTypes, TogglePlayLogsPlayAction} from '../actions/play-logs';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {Media} from '../models/media';
import {MediasFilterForm} from '../models/medias-filter-form';
import {SelectedMedias, SelectedMediasValidate} from '../models/selected-medias';

export interface MediasListState {
  showFilter: boolean;
  quickSearch: string;
  filter: MediasFilterForm;
  page: number;
  pageSize: number;
  orderBy: string;
  array: Media[];
  count: number;
  downloading: boolean;
  selectedMedias: SelectedMedias[];
  selectedTagsList: TagsLists[] // tags to delete
}
const defaultFilter: MediasFilterForm = {
  isDefault: true
};

const initialState: MediasListState = {
  showFilter: false,
  quickSearch: undefined,
  filter: defaultFilter,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: 'updated descending',
  array: [],
  count: 0,
  downloading: false,
  selectedMedias: [],
  selectedTagsList: []
};

export function mediasListReducer(state = initialState, action: Action): MediasListState {
  switch (action.type) {
    case MediasListActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case MediasListActionTypes.QUICK_SEARCH:
      return handleQuickSearchAction(state, action);
    case MediasListActionTypes.SEARCH:
      return handleSearchAction(state, action);
    case MediasListActionTypes.PAGE_TO:
      return handlePageToAction(state, action);
    case MediasListActionTypes.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case MediasListActionTypes.ORDER_BY:
      return handleOrderByAction(state, action);
    case MediasListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case MediasListActionTypes.LOAD_VALIDATION_FAIL:
    case MediasListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case DownloadMediasActionTypes.DOWNLOAD_START:
      return handleStartDownloadAction(state);
    case MediasListActionTypes.SELECT_PAGE:
      return handleSelectPageAction(state);
    case MediasListActionTypes.UNSELECT_PAGE:
      return handleUnselectPageAction(state);
    case MediasListActionTypes.TOGGLE_SELECTION:
      return handleToggleMediaSelectionAction(state, action);
    case DownloadMediasActionTypes.DOWNLOAD_SUCCESS:
    case DownloadMediasActionTypes.DOWNLOAD_VALIDATION_FAIL:
    case DownloadMediasActionTypes.DOWNLOAD_FAIL:
      return handleEndDownloadActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case MediasListActionTypes.CLEAR:
      return handleClearAction(state);
    case MediasListActionTypes.TOGGLE_SELECTION_TAGS:
      return handleToogleTagsListToDelete(state, action);
    default:
      return state;
  }
}

function getTagsSelected(state: MediasListState, action: ToggleMediaSelectionTagsAction) {
  let selectedTagsList: TagsLists[];
  for (const actionPayload of action.payload) {
    const selectedIndex = _.findIndex(state.selectedTagsList, ['id', actionPayload.id]);
    if (selectedIndex > -1) {
      selectedTagsList = _.filter(state.selectedTagsList, selectedTags => selectedTags.id !== actionPayload.id);
    } else {
      selectedTagsList = [...state.selectedTagsList, actionPayload];
    }
  }
  return selectedTagsList;
} // tags to delete

function getMediasTagsListFromState(state: MediasListState): TagsLists[] {
  const selectedTags: TagsLists[] = [];
  for (const elem of state.array) {
    if (elem.tags) {
      selectedTags.push({ id: elem.id, tagsList: elem.tags })
    }
  }
  return selectedTags;
} // tags to delete

function handleClearAction(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleSelectPageAction(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: _.union(state.selectedMedias, _.map(state.array, media => ({ id: media.id, name: media.name, archive: media.archive }))),
    selectedTagsList: getMediasTagsListFromState(state) // tags to delete
  };
  return newState;
}
function handleUnselectPageAction(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    filter: state.filter,
    quickSearch: state.quickSearch,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: [],
    selectedTagsList: []
  };
  return newState;
}

function handleToggleSearchAction(state: MediasListState, action: ToggleSearchMediasAction): MediasListState {
  const newState: MediasListState = {
    showFilter: action.payload,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };

  return newState;
}

function handleQuickSearchAction(state: MediasListState, action: QuickSearchMediasAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: action.payload,
    filter: _.isEmpty(action.payload) ? defaultFilter : undefined,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedTagsList: state.selectedTagsList,
    selectedMedias: []
  };

  return newState;
}

function handleSearchAction(state: MediasListState, action: SearchMediasAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: undefined,
    filter: action.payload,
    page: 1,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: [],
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handlePageToAction(state: MediasListState, action: PageMediasToAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: action.payload,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangePageSizeAction(state: MediasListState, action: ChangeMediasPageSizeAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: 1,
    pageSize: action.payload,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleOrderByAction(state: MediasListState, action: OrderMediasByAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: action.payload,
    array: state.array,
    count: state.count,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadSuccessAction(state: MediasListState, action: LoadMediasSuccessAction): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: action.payload.array,
    count: action.payload.count,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleLoadErrorActions(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: [],
    count: 0,
    downloading: state.downloading,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleStartDownloadAction(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: true,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleEndDownloadActions(state: MediasListState): MediasListState {
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: false,
    selectedMedias: state.selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleChangeClientAction(): MediasListState {
  return {
    showFilter: false,
    quickSearch: undefined,
    filter: undefined,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: undefined,
    array: [],
    count: 0,
    downloading: false,
    selectedMedias: [],
    selectedTagsList: []
  };
}

function handleToggleMediaSelectionAction(state: MediasListState, action: ToggleMediaSelectionAction): MediasListState {
  const selectedIndex = _.findIndex(state.selectedMedias, ['id', action.payload]);
  let selectedMedias: SelectedMedias[];
  if (selectedIndex > -1) {
    selectedMedias = _.filter(state.selectedMedias, selectedMedia => selectedMedia.id !== action.payload);
  } else {
    const media = _.find(state.array, u => u.id === action.payload);
    const selectedMedia: SelectedMedias = { id: media.id, name: media.name, archive: media.archive };
    selectedMedias = [...state.selectedMedias, selectedMedia];
  }

  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: false,
    selectedMedias: selectedMedias,
    selectedTagsList: state.selectedTagsList
  };
  return newState;
}

function handleToogleTagsListToDelete(state: MediasListState, action: ToggleMediaSelectionTagsAction): MediasListState { // tags to delete
  const newState: MediasListState = {
    showFilter: state.showFilter,
    quickSearch: state.quickSearch,
    filter: state.filter,
    page: state.page,
    pageSize: state.pageSize,
    orderBy: state.orderBy,
    array: state.array,
    count: state.count,
    downloading: false,
    selectedMedias: state.selectedMedias,
    selectedTagsList: getTagsSelected(state, action)
  };
  return newState;
}

export const MediasListSelectors = {
  showFilter: (state: MediasListState) => _.get(state, 'showFilter', false),
  quickSearch: (state: MediasListState) => _.get(state, 'quickSearch'),
  filter: (state: MediasListState) => _.get(state, 'filter'),
  page: (state: MediasListState) => _.get(state, 'page', 1),
  pageSize: (state: MediasListState) => _.get(state, 'pageSize', DEFAULT_PAGE_SIZE),
  orderBy: (state: MediasListState) => _.get(state, 'orderBy'),
  array: (state: MediasListState) => _.get(state, 'array', []),
  count: (state: MediasListState) => _.get(state, 'count', 0),
  downloading: (state: MediasListState) => _.get(state, 'downloading', false),
  selectedMedias: (state: MediasListState) => _.get(state, 'selectedMedias', []),
  selectedTags: (state: MediasListState) => _.get(state, 'selectedTagsList', [])
};

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {UsedMedias} from '../models/used-medias';

export const UsedMediasActionTypes = {
  USED: type('[UsedMedias] used'),
  USED_SUCCESS: type('[UsedMedias] used Success'),
  USED_NOT_FOUND: type('[UsedMedias] used Not Found'),
  USED_FAIL: type('[UsedMedias] used Fail')
};

export class UsedMediasAction implements Action {
  readonly type = UsedMediasActionTypes.USED;

  constructor(public payload?: string) {}
}

export class UsedMediasSuccessAction implements Action {
  readonly type = UsedMediasActionTypes.USED_SUCCESS;

  constructor(public payload?: ArrayResponse<UsedMedias>) {}
}

export class UsedMediasNotFoundAction implements Action {
  readonly type = UsedMediasActionTypes.USED_NOT_FOUND;

  constructor(public payload?: UsedMedias) {}
}

export class UsedMediasFailAction implements Action {
  readonly type = UsedMediasActionTypes.USED_FAIL;

  constructor(public payload?: number) {}
}

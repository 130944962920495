import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Media} from '../../shared/models/media';

export const MediasListActionTypes = {
  TOGGLE_SEARCH: type('[Campaign Medias] Toggle Search Form'),
  QUICK_SEARCH: type('[Campaign Medias] Quick Search'),
  SEARCH: type('[Campaign Medias] Search'),
  CHANGE_PAGE_SIZE: type('[Campaign Medias] Change Page Size'),
  PAGE_TO: type('[Campaign Medias] Page To'),
  ORDER_BY: type('[Campaign Medias] Order By'),
  LOAD: type('[Campaign Medias] Load'),
  LOAD_SUCCESS: type('[Campaign Medias] Load Success'),
  LOAD_FAIL: type('[Campaign Medias] Load Fail'),
};

export class ChangeMediasPageSizeAction implements Action {
  readonly type = MediasListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageMediasToAction implements Action {
  readonly type = MediasListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderMediasByAction implements Action {
  readonly type = MediasListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadMediasAction implements Action {
  readonly type = MediasListActionTypes.LOAD;
  constructor() {}
}

export class LoadMediasSuccessAction implements Action {
  readonly type = MediasListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Media>) {}
}

export class LoadMediasFailAction implements Action {
  readonly type = MediasListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

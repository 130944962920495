/* tslint:disable */
export const localNl = {
  'de': 'German',
  'en': 'English',
  'es': 'Spanish',
  'fr': 'French',
  'it': 'Italian',
  'nl': 'Dutch',
  'cn': 'Chinese',
  'LANG_DE': 'German',
  'LANG_EN': 'English',
  'LANG_ES': 'Spanish',
  'LANG_FR': 'French',
  'LANG_IT': 'Italian',
  'LANG_NL': 'Dutch',
  'LANG_CN': 'Chinese',
  'IMPERSONATE_USER': 'Impersonate User',
  'LEAVE_IMPERSONATION': 'Leave Impersonation',
  'LOGOUT': 'Log Out',
  'RUN_AS': 'as',
  'CLOSE': 'Close',
  'OPEN': 'Open',
  'INTRO': 'Intro',
  'EXTRO': 'Extro',
  'IMPERSONATE_DIALOG': 'Impersonate user list dialog',
  'IMPERSONATE_DIALOG_TITLE': 'Impersonate User',
  'IMPERSONATE_DIALOG_EXPLAIN_P1':
    'With this option you can browse the AD Manager like if you were connected with another user. This option is only available for administrators.',
  'IMPERSONATE_DIALOG_EXPLAIN_P2':
    'Any action performed under "impersonation" will be done with your identity. The "impersonation" is just a simulation of what other users can see.',
  'SHOW_X_ELEMENTS_START': 'Display',
  'SHOW_X_ELEMENTS_END': 'elements',
  'SEARCH': 'Search',
  'NAME': 'Name',
  'EMAIL': 'Email',
  'ROLES_GROUP': 'Roles group',
  'IMPERSONATE': 'Impersonate',
  'CANCEL': 'Cancel',
  'PAGINATION_INFO': 'Showing {{from}} to {{to}} of {{filteredLength}} elements',
  'CLIENT_SELECTOR_PLACEHOLDER': 'Change Client...',
  'DASHBOARD': 'Dashboard',
  'MY_DASHBOARD': 'My Dashboard',
  'TOAST_ERROR_TITLE': 'Error',
  'TOAST_SUCCESS_TITLE': 'Success',
  'TOAST_INFO_TITLE': 'Information',
  'TOAST_WARNING_TITLE': 'Warning',
  'GROUP_ROLES_LOAD_ERROR': 'An error occurred while fetching group roles for the current client.',
  'LANGUAGES_LOAD_ERROR': 'An error occurred while loading available languages.',
  'MY_CLIENTS_LOAD_ERROR': 'Cannot load the list of clients you have access to.',
  'TIMEZONES_LOAD_ERROR': 'An error occurred while loading available timezones.',
  'USER_CONTEXT_IMPERSONATE_ERROR': 'An error occurred while trying to impersonate {{username}}.',
  'USER_CONTEXT_IMPERSONATE_SUCCESS': 'Impersonate success.',
  'USER_CONTEXT_GET_ROLES_ERROR': 'Unable to retrieve your roles for client "{{clientName}}". Please contact your administrator.',
  'AUTH_GET_USER_INFO_ERROR': 'An error occurred while retrieving your user info from authentication provider.',
  'ERROR_PAGE_NOT_FOUND': '404',
  'ERROR_PAGE_NOT_FOUND_TITLE': 'Houston, we have a problem.',
  'ERROR_PAGE_NOT_FOUND_PARA': 'Actually, the page you are looking for does not exist.',
  'ERROR_PAGE_RETURN_HOME': 'Return home',
  'ERROR_PAGE_ACCESS_DENIED': '403',
  'ERROR_PAGE_ACCESS_DENIED_TITLE': 'Houston, you have a problem.',
  'ERROR_PAGE_ACCESS_DENIED_PARA': 'Actually, you do not have access to the page.',

  'ERROR_INVALID_ORDER_TYPE': 'Type ongeldige opdracht: {{0}}',
  'ERROR_FIRST_PROGRAM_NOT_PERMANENT': 'Het eerste programma van {{0}} is niet permanent',
  'ERROR_SUBSCRIPTIONS_REMAINS': 'Sommige abonnementen blijven voor {{0}}',
  'ERROR_PROGRAM_LIST_EMPTY': 'Lijst met lege programma\'s voor {{0}}',
  'ERROR_NAME_EMPTY': 'De naam ontbreekt',
  'ERROR_MUSIC_PUBLICATION_REQUIRED': 'Een muzikale publicatie is vereist',
  'ERROR_NO_SCHEDULE_FOUND': 'Geen planning gevonden',
  'ERROR_NO_DATA': 'Geen gegevens',
  'ERROR_PROGRAM_NOT_FOUND': 'Programma van {{0}} werdt niet gevonden',
  'ERROR_SUBSCRIPTION_NOT_ALLOWED': 'Het abonnement van {{0}} is niet toegestaan',
  'ERROR_CULTURE_INVALID': 'De cultuur is niet geldig.',
  'ERROR_NO_TEMPLATE_LINKED': 'Er is geen template aan verbonden.',
  'ERROR_DUPLICATE_DATE_OPENING_HOUR': 'De data is dubbel op de beperking van de openingsuren: {{0}}',
  'ERROR_FAILED_REMOVE_BLACKLIST_MEDIA': 'Gebruiker \'{{0}}\'  heeft de verwijdering van de beperkte media gefaald (id: {{1}})',
  'ERROR_FAILED_INSERT_BLACKLIST_MEDIA': 'Gebruiker \'{{0}} het inbrengen van de beperkte media {{1}} voor het abonnement {{2}}  is mislukt',
  'ERROR_DUPLICATE_DATE': 'Deze datum is al aanwezig: {{0}}',
  'ERROR_NO_CULTURE_FOUND': 'Geen cultuur gevonden voor {{0}}',
  'ERROR_COHERENCE_CULTURE': 'De verschillende culturen zijn niet overeenstemmend ',
  'ERROR_SCHEDULE_FOUND': 'Planning gevonden voor {{0}}',
  'ERROR_FAILED_LINK_SUBSCRIPTION_TO_AUDIO_SCHEDULE': 'Gebruiker \'{{0}}\' Koppeling naar het abonnement {{1}} naar de audio player {{2}}  is mislukt',
  'ERROR_INVALID_SUBSCRIPTION': 'ongeldig abonnement (UUID: {{0}})',
  'ERROR_INVALID_SHARING_TYPE': 'Ongeldige gezamelijke waarden: {{0}}',
  'ERROR_MEDIA_NOT_FOUND': 'Media niet gevonden (Id: {{0}})',
  'ERROR_MEDIA_NOT_VALID': 'De media is ongeldig',
  'ERROR_INVARIANT_CULTURE_NOT_COMPATIBLE_WITH_OTHERS': 'De onveranderlijke cultuur is niet gelijkstemd met andere culturen',
  'ERROR_DUPLICATE_NAME': 'Duplicatie van naam voor {{0}} (ID: {{1}})',
  'ERROR_LINK_TAG_TO_MEDIA': 'Gebruiker \'{{0}}\' koppeling van de de tags {{1}} naar de media {{2}} is mislukt',
  'ERROR_LINK_CULTURE_TO_MEDIA': 'Koppeling van de Cultuur {{0}} tot de media {{1}} is mislukt',
  'ERROR_FAILED_DELETE_MEDIA_RESTRICTION_FOR_SUBSCRIPTIONS':
    'Gebruiker \'{{0}}\' de opheffing van de beperking tot het abonnement {{1}} tot de media {2} is mislukt',
  'ERROR_MEDIA_ALREADY_VALIDATED': 'Media reeds gevalideerd',
  'ERROR_MEDIA_NOT_CONVERTED': 'Digital onbekeerde',
  'ERROR_NO_TARGET_FOR_FILE_CONTENT': 'Geen bestemming om de inhoud van een bestand te schrijven',
  'ERROR_FLOATING_REPETITION_LESS_THAN_ONE': 'Drijvende herhaling < 1',
  'ERROR_TOO_MANY_MEDIA_JIT_OR_CLOSE': 'Te veel media voor{{0}}',
  'ERROR_MISMATCH_MEDIA_ENTRIES': 'De audio kalender bevat {{0}} medias in plaats van {{1}}',
  'ERROR_MISMATCH_LINK_ENTRIES': 'De audio kalender bevat {{0}} medias in plaats van {{1}}',
  'ERROR_LINK_TAG_TO_AUDIO_SCHEDULE': 'Gebruiker \'{{0}}\' koppeling van de tags {{1}} voor het audioprogramma {{2}} is mislukt',
  'ERROR_FILE_NOT_UPLOADED_IN_AZURE_BLOB': 'Bestand niet geüpload naar Azure Blob',
  'ERROR_SECOND_CONNECTION_AZURE_FAILED': 'De tweede poging van de verbinding te Azure Blob is mislukt',
  'ERROR_FILE_NOT_FOUND': 'Bestand {{0}} werd niet gevonden',
  'ERROR_INVALID_PARAMETER': 'De instelling is ongeldig',
  'ERROR_ADDRESS_NAME_EMPTY': 'De naam van het adres ontbreekt',
  'ERROR_ADDRESS_NAME_LENGTH_EXCEEDED': 'Het maximum aantal tekens van het adres wordt overschreden',
  'ERROR_ADDRESS_LINE1_EMPTY': 'Het adres op lijn 1 ontbreekt',
  'ERROR_ADDRESS_LINE1_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de adres op regel 1 wordt overschreden',
  'ERROR_ADDRESS_LINE2_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de adres op regel 2 wordt overschreden',
  'ERROR_ADDRESS_LINE3_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de adres op regel 3 wordt overschreden',
  'ERROR_ADDRESS_ZIP_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de postcode wordt overscheden',
  'ERROR_ADDRESS_CITY_EMPTY': 'De stadsnaam ontbreekt',
  'ERROR_ADDRESS_CITY_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de stadsnaam wordt overschreden',
  'ERROR_ADDRESS_COUNTRY_EMPTY': 'Het land ontbreekt',
  'ERROR_ADDRESS_COUNTRY_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor het land wordt overschreden',
  'ERROR_INVALID_ID': 'ongeldige ID',
  'ERROR_USER_AD_ACCOUNT_NOT_FOUND': 'AD rekening van de gebruiker {{0}} is niet gevonden',
  'ERROR_USER_NOT_FOUND': '{{0}} gebruiker niet gevonden',
  'ERROR_CLIENT_NOT_ALLOWED': 'Klant is niet toegestaan',
  'ERROR_ITEM_IS_DELETED': '{{0}} is verwijderd',
  'ERROR_ITEM_IS_OFF': '{{0}} Off',
  'ERROR_ITEM_IS_DEFAULT': '{{0}} Is fout',
  'ERROR_SITE_NOT_ALLOWED': 'De website is niet toegestaan',
  'ERROR_USER_NOT_ALLOWED_FOR_ROLE': '{{0}} Gebruiker is niet toegestaan om {{1}}',
  'ERROR_INVALID_CLIENT': 'De klant is ongeldig',
  'ERROR_INVALID_NAME': 'De naam is niet correct',
  'ERROR_USER_NOT_ALLOWED': 'De gebruiker is niet toegestaan',
  'ERROR_USER_NOT_ALLOWED_TO_EDIT': 'De gebruiker is niet toegestaan om veranderingen aan te brengen ',
  'ERROR_USER_NOT_ALLOWED_TO_VIEW': 'De gebruiker is niet toegestaan voor waarneming',
  'ERROR_NAME_MISSING': 'De naam ontbreekt',
  'ERROR_CLIENT_NAME_ALREADY_EXIST': 'Naam van de klant \'{{0}}\' bestaat al',
  'ERROR_OFFICE_MISSING': 'Bureau ontbreekt',
  'ERROR_SALES_MISSING': 'De verkoop ontbreekt',
  'ERROR_LOGIN_LIMIT_IS_REACHED': 'Het verbindingslimiet is bereikt',
  'ERROR_INVALID_PARENT': 'De producent is ongeldig',
  'ERROR_PRODUCER_INVALID': 'De producent ontbreekt of is ongeldig',
  'ERROR_PRODUCTS_MISSING': 'Geen producten geselecteerd',
  'ERROR_INVALID_CLIENT_PARAMETER': 'De opgeven parameters van de klant zijn onjuist',
  'ERROR_PLAYER_STILL_LINKED': 'Speler is nog steeds gebonden',
  'ERROR_USER_ALREADY_EXIST': 'Gebruiker \'{{0}}\' bestaat al',
  'ERROR_DEPARTMENT_MISSING': 'Afdeling ontbreekt',
  'ERROR_EMAIL_PHONE_MOBILE_SKYPE_MISSING': 'E-mail, telefoon, mobiel en Skype ontbreken',
  'ERROR_INVALID_TEMPLATE_NOT_SHARED': 'Model is ongeldig: niet gedeeld ',
  'ERROR_INVALID_TEMPLATE': 'model ongeldig',
  'ERROR_GROUP_ALREADY_EXIST': 'Groep {{0}} bestaat al',
  'ERROR_DEVICE_NOT_WITH_SUBSCRIPTION': 'Het toestel hoort niet bij een abonnement',
  'ERROR_SET_PLAYER_DEFAULT_FAILED': 'De update als standaard speler is mislukt',
  'ERROR_PLAYER_NOT_FOUND': 'Speler {{0}} werdt niet gevonden',
  'ERROR_SITE_NAME_MISSING': 'De naam van de  Site ontbreekt',
  'ERROR_SITE_NAME_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de naam van de site is overschreden ({{0}} karakters)',
  'ERROR_EXTERNAL_SITE_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de externe site is overschreden ({{0}} karakters)',
  'ERROR_DEFAULT_SITE_ADDRESS_MISSING': 'Het standaard adres van de site ontbreekt',
  'ERROR_INVALID_USER': 'ongeldige gebruikersnaam',
  'ERROR_SITE_INVALID': 'ongeldige website',
  'ERROR_SUBSCRIPTION_INVALID': 'abonnement ongeldig',
  'ERROR_ORDER_ALREADY_OPENED_FOR_SUBSCRIPTION': 'Een bestelling is al open voor dit abonnement {{0}}',
  'ERROR_SUBSCRIPTION_NAME_MISSING': 'De naam van het abonnement ontbreekt',
  'ERROR_SUBSCRIPTION_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor het abonnement is overschreden ({{0}} karakters)',
  'ERROR_PRODUCT_INVALID': 'Het product is ongeldig',
  'ERROR_STATUS_INVALID': 'De status is ongeldig',
  'ERROR_NAME_LENGTH_EXCEEDED': 'Het maximum aantal tekens voor de naam is overschrijding ({{0}} karakters)',
  'ERROR_COMMENT_EMPTY': 'De reacties zijn leeg',
  'ERROR_INVALID_DEVICE': 'Ongeldige apparaat {{0}}',
  'ERROR_INVALID_DEVICE_MUST_BE_PRODUCT': 'Ongeldige apparaatmodel {{0}} - moet worden  geproduceerd {{1}}',
  'ERROR_INVALID_PLAYER': 'Speler Ongeldige {{0}}',
  'ERROR_DEVICE_ALREADY_USED': 'Inrichting reeds van {{0}}',
  'ERROR_NOT_ACTIVE': 'Niet actief ',
  'ERROR_AVATAR_NOT_FOUND': 'Avatar is niet gevonden voor de gebruiker {{0}}',
  'ERROR_USER_EMAIL_NOT_FOUND': '{{0}} niet gevonden',
  'ERROR_USER_CODE_NOT_FOUND': 'Code is niet gevonden',
  'ERROR_INVALID_PASSWORD': 'Onjuist wachtwoord',
  'ERROR_NO_ROLE_FOUND': 'Geen rol gevonden',
  'ERROR_INVALID_GROUP_ROLE': 'Rollen van de groep is ongeldig',
  'ERROR_DELETE_HIMSELF': 'Het is niet toegestaan om zichzelf te verwijderen',
  'ERROR_INVALID_ADDRESS_TYPE': 'Type adres is niet herkent: \'{{0}}\' (bekende waarden: {{1}})',
  'ERROR_EMAIL_MISSING': 'e-mail ontbreekt',
  'ERROR_PHONE_MISSING': 'Phone, Mobile is leeg',
  'ERROR_SCREENROTATION_INVALID': 'Schermrotatie van het scherm is ongeldig: {{0}} (bekende waarden: {{1}})',
  'ERROR_SCHEDULE_NOT_FOUND': 'Geen resultaat van het uurrooster ',

  'GET_SET_ROW_FAILED_SIZE': 'Cannot load the user preferences.',
  'AUTH_LOGIN_GENERIC_ERROR': 'Something went wrong when trying to connect you. Please contact your administrator.',
  'AUTH_LOGIN_WRONG_CREDENTIALS': 'Wrong e-mail or password.',
  'AUTH_LOGIN_BLOCKED': 'Your account is blocked. Please contact your administrator.',
  'AUTH_LOGIN_LEAKED': 'This login has been blocked because your password has been leaked in another website. Please contact your administrator.',
  'AUTH_LOGIN_SOCIAL_FIRST': 'You cannot login with a social account which is not linked to an existing local account.',
  'AUTH_LOGIN_ACCESS_DENIED': 'Access is denied. Please contact your administrator.',
  'AUTH_LOGIN_INVALID_TOKEN': 'Invalid Token:',
  'AUTH_LOGIN_AUTO_TITLE': 'Please wait...',
  'AUTH_LOST_PASSWORD_TITLE': 'Forget Password ?',
  'AUTH_LOST_PASSWORD_PARA': 'Enter your e-mail address below to reset your password.',
  'AUTH_LOST_PASSWORD_ERROR': 'An error occurred while sending your request.',
  'AUTH_LOST_PASSWORD_SUCCESS': 'An e-mail with a reset password link has been sent to you.',
  'AUTH_GOTO_LOGIN_PAGE': 'Click here to go to the login page',
  'AUTH_LOST_PASSWORD_EMAIL': 'Email',
  'AUTH_LOST_PASSWORD_EMAIL_REQUIRED': 'The e-mail is required.',
  'AUTH_LOST_PASSWORD_EMAIL_INVALID': 'The e-mail is not valid.',
  'AUTH_LOST_PASSWORD_BACK': 'Back',
  'AUTH_LOST_PASSWORD_SUBMIT': 'Submit',
  'AUTH_LOST_PASSWORD_SUBMITTING': 'Submitting...',
  'PAGE_SIZE_ALL': 'All',
  'SOCIAL_LOGIN_INTRO': 'Or login with',
  'LOGIN_TITLE': 'Sign In',
  'CURRENT_ALERT_MESSAGES_ERROR': 'An error occurred while loading current alert messages.',
  'EXTERNAL_APPS': 'More Apps',
  'SITE_TITLE': 'AD Manager',
  'AUTH_LOGIN_PAGE_TITLE': 'AD Manager | Login',
  'AUTH_LOST_PASSWORD_PAGE_TITLE': 'AD Manager | Lost Password',
  'DASHBOARD_PAGE_TITLE': 'AD Manager | Dashboard',
  'ERROR_ACCESS_DENIED_PAGE_TITLE': 'AD Manager | Access Denied',
  'ERROR_NOT_FOUND_PAGE_TITLE': 'AD Manager | Not Found',
  'MEDIAS_LIST_COLUMN_NAME': 'Name',
  'MEDIAS_LIST_COLUMN_DURATION': 'Duration',
  'MEDIAS_LIST_COLUMN_TAG': 'Tag',
  'MEDIAS_LIST_COLUMN_CREATED': 'Created',
  'MEDIAS_LIST_COLUMN_UPDATE': 'Updated',
  'MEDIAS_LIST_COLUMN_FILENAME': 'Filename',
  'MEDIAS_LIST_TITLE': 'Medias',
  'MEDIAS_LIST_CREATE': 'Create',
  'MEDIAS_LIST_IMPORT': 'Import',
  'MEDIAS_LIST_SELECT_PAGE': 'Select Page',
  'MEDIAS_LIST_UNSELECT_PAGE': 'Unselect Page',
  'MEDIAS_LIST_ACTIONS': 'Actions',
  'MEDIAS_LIST_COLUMN_STATUS': 'Status',
  'MEDIA_ARCHIVED': 'Archived',
  'MEDIA_NOT_ARCHIVED': 'Not archived',
  'MEDIA_SHARING_PRIVATE': 'Private',
  'MEDIA_SHARING_PUBLIC': 'Public',
  'MEDIA_SHARING_USER_SHARING': 'Share with users',
  'MEDIA_SHARING_ADMIN': 'Admin',
  'EDIT_MEDIA_NAME': 'Name',
  'EDIT_MEDIA_NAME_REQUIRED': 'Required name',
  'EDIT_MEDIA_SHARING': 'Sharing',
  'EDIT_MEDIA_LOCK': 'Lock media',
  'EDIT_MEDIA_LOCKED': 'Lock',
  'EDIT_MEDIA_UNLOCKED': 'Unlock',
  'EDIT_MEDIA_ARCHIVE': 'Archive',
  'EDIT_MEDIA_DURATION': 'Duration',
  'EDIT_MEDIA_FILENAME': 'Filename',
  'EDIT_MEDIA_COMMENTS': 'Comment',
  'EDIT_MEDIA_CREATE': 'Create',
  'EDIT_MEDIA_CREATEBY': 'Create by',
  'EDIT_MEDIA_LANGUAGE_REQUIRED': 'Required Culture',
  'EDIT_MEDIA_UPDATED': 'Updated',
  'EDIT_MEDIA_UPDATEDBY': 'Updated by',
  'EDIT_MEDIA_TO_TIME_LESS_THAN_FROM': 'Time end need to be bigger than',
  'EDIT_MEDIA_TIME_REQUIRED': 'Time required',
  'MEDIAS_LIST_ADD': 'Add',
  'ADD_MEDIA_DIALOG_TITLE': 'Media CLOUD',
  'ADD_MEDIA_DIALOG_TITLE2': 'Upload your files here',
  'ADD_MEDIA_DIALOG_DROPPED_FILES': 'Dropped files',
  'EDIT_MEDIA_TITLE': 'Media info',
  'MEDIA_EDIT': 'Media General info',
  'MEDIA_EDIT_CAMPAIGN_LINKS': 'Campaign info',
  'MEDIA_EDIT_BLACKLIST': 'Subscriptions blacklisted',
  'EDIT_MEDIA_TAGS': 'Tags',
  'SAVE_CHANGES': 'Save',
  'MEDIA__DELETE': 'Delete',
  'CAMPAIGNS_LIST_COLUMN_START': 'Start Date',
  'CAMPAIGNS_LIST_COLUMN_END': 'End Date',
  'CAMPAIGNS_LIST_TITLE': 'Campaigns',
  'CAMPAIGNS_TYPE_FLOATING': 'Floating Type',
  'CAMPAIGNS_TYPE_JUST_IN_TIME': 'Just in time Type',
  'CAMPAIGNS_ORDER_ASCENDING': 'Order ascending',
  'CAMPAIGNS_ORDER_RANDOM': 'Order random',
  'EDIT_CAMPAIGN_START_DATE': 'Start date',
  'EDIT_CAMPAIGN_END_DATE': 'End date',
  'EDIT_CAMPAIGN_START_TIME': 'From',
  'EDIT_MEDIA_LANGUAGE_INVARIANT_AND_OTHER_NOT_PERMITTED': 'Invariant and other culture not permitted',
  'MY_DASHBOARD_ROW_PUBLICATION_IN_PROGRESS': 'Publication in progress',
  'Floating': 'Floating',
  'JustInTime': 'Just in time',
  'CloseMessage': 'Close message',
  'Jingle': 'Jingle',
  'ENDED': 'ENDED',
  'EDIT_CAMPAIGN_END_TIME': 'To',
  'EDIT_CAMPAIGN_AT_TIME': 'At',
  'EDIT_CAMPAIGN_SINGLE': 'Single',
  'EDIT_CAMPAIGN_ALL': 'All',
  'EDIT_CAMPAIGN_MONDAY': 'Monday',
  'EDIT_CAMPAIGN_TUESDAY': 'Tuesday',
  'EDIT_CAMPAIGN_Wednesday': 'Wednesday',
  'EDIT_CAMPAIGN_Thursday': 'Thursday',
  'EDIT_CAMPAIGN_Friday': 'Friday',
  'EDIT_CAMPAIGN_Saturday': 'Saturday',
  'EDIT_CAMPAIGN_Sunday': 'Sunday',

  'MONDAY': 'Monday',
  'TUESDAY': 'Tuesday',
  'WEDNESDAY': 'Wednesday',
  'THURSDAY': 'Thursday',
  'FRIDAY': 'Friday',
  'SATURDAY': 'Saturday',
  'SUNDAY': 'Sunday',

  'EDIT_CAMPAIGN_JINGLE_INTRO_EXTRO': 'intro / extro',
  'EDIT_CAMPAIGN_CLOSING_MESSAGE_OPEN_CLOSE': 'open / close',
  'CREATE_CAMPAIGN_DIALOG': '',
  'ADD_CAMPAIGN_DIALOG_TITLE': 'Create a new campaign',
  'ADD_CAMPAIGN_NAME': 'Name',
  'ADD_CAMPAIGN_NAME_REQUIRED': 'The name is required.',
  'ADD_CAMPAIGN_TYPE': 'Type',
  'ADD_CAMPAIGN_TYPE_REQUIRED': 'The type of campaign is required.',
  'EDIT_CAMPAIGN_TITLE': 'Campaign',
  'CAMPAIGN_SAVE_SUCCESS': 'Succes met het opslaan van campagne ',
  'CAMPAIGN_EDIT': 'Campaign edit',
  'EDIT_CAMPAIGN_MEDIAS': 'Medias',
  'EDIT_CAMPAIGN_SUBSCRIPTIONS': 'Subscriptions',
  'CAMPAIGN_MEDIAS_ASSIGNED': 'Assigned medias',
  'CAMPAIGN_MEDIAS_ASSIGNED_TEXT': 'Drag media from unassigned medias list to assign it to the campaign.',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE': 'assign all medias',
  'CAMPAIGN_MEDIAS_ASSIGNED_EMPTY': 'The campaign has not access to any media.',
  'CAMPAIGN_MEDIAS_UNASSIGNED_FILTER': 'Filter unassigned medias',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOGGLE<': 'Unassign all medias',
  'CAMPAIGN_MEDIAS_ASSIGNED_FILTER': 'Filter assigned medias',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TEXT': 'Drag media from assigned medias list to unassign it from the campaign.',
  'CAMPAIGN_MEDIAS_UNASSIGNED': 'Unassigned medias',
  'CAMPAIGN_MEDIAS_UNASSIGNED_EMPTY': 'The campaign has all the medias.',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOGGLE': 'Unassign all medias',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED': 'Assigned subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TEXT': 'Drag subscription from unassigned subscriptions list to assign it to the campaign.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TOGGLE': 'assign all subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_EMPTY': 'The campaign has not access to any subscriptions.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_FILTER': 'Filter unassigned subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TOGGLE<': 'Unassign all subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_FILTER': 'Filter assigned subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TEXT': 'Drag subscription from assigned subscriptions list to unassign it from the campaign.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED': 'Unassigned subscriptions',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_EMPTY': 'The campaign has all the subscriptions.',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TOGGLE': 'Unassign all subscriptions',
  'EDIT_CAMPAIGN_MODE': 'Selection mode',
  'MEDIA_IS_NOT_VALIDE': 'Media is not valide',
  'EDIT_MEDIA_VALIDATEDBY': 'Validated by',
  'EDIT_MEDIA_VALIDATED': 'Validated',
  'AUTH_LOGIN_TITLE': 'Sign In',
  'EDIT_CAMPAIGN_HOUR': 'Hour',
  'EDIT_CAMPAIGN_OFFSET': 'Offset',
  'EDIT_CAMPAIGN_HOURS': 'Hours',
  'AUTH_LOGIN_ERROR_TITLE': 'An error occurred',
  'EDIT_CAMPAIGN_OPEN_CLOSE': 'open/close',
  'AUTH_LOGIN_ERROR_PARA': 'The following error occurred while logging in:',
  'AUTH_LOGIN_GO_BACK_BUTTON': 'Go back to the login page',
  'MEDIAS_LIST_FILTER': 'Filter',
  'MEDIAS_LIST_QUICK_SEARCH': 'Search by media name',
  'MEDIAS_LIST_FILTER_NAME': 'Name',
  'MEDIAS_LIST_FILTER_FILENAME': 'Filename',
  'MEDIAS_LIST_FILTER_TAGS': 'Tags',
  'MEDIAS_LIST_FILTER_SHARING': 'Sharing',
  'MEDIAS_LIST_FILTER_ARCHIVED': 'Archived',
  'MEDIAS_LIST_FILTER_VALIDATED': 'Validated',
  'MEDIAS_LIST_FILTER_STARTDATE': 'Start date',
  'MEDIAS_LIST_FILTER_ENDDATE': 'End date',
  'MEDIAS_LIST_FILTER_SEARCH': 'Search',
  'MEDIAS_LIST_FILTER_RESET': 'Reset',
  'CAMPAIGNS_LIST_FILTER': 'Filter',
  'CAMPAIGNS_LIST_QUICK_SEARCH': 'Search by campaign name',
  'CAMPAIGNS_LIST_FILTER_TYPE': 'Selection mode',
  'MEDIAS_LIST_FILTER_ISONLINE': 'Is online',
  'MEDIA_REMOVE_FROM_LIST': 'Remove from list',
  'ADD_MEDIA_TOO_SMALL': 'Too small',
  'ADD_MEDIA_TOO_BIG': 'Too Big',
  'CAMPAIGNS_LIST_FILTER_STARTDATE': 'Start date creation',
  'CAMPAIGNS_LIST_FILTER_ENDDATE': 'End date creation',
  'CAMPAIGNS_LIST_FILTER_COVER_STARTDATE': 'Start date campaigns',
  'CAMPAIGNS_LIST_FILTER_COVER_ENDDATE': 'End date campaigns',
  'CAMPAIGN_ADVENCE_FILTER_MEDIAS_DIALOG_TITLE': 'Advance search for medias',
  'CAMPAIGN_MEDIAS_FILTER_TAG': 'Tags',
  'CAMPAIGN_MEDIAS_FILTER_CULTURES_REQUIRED': 'The cultures is required.',
  'CAMPAIGN_MEDIAS_FILTER_SEARCH': 'search',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOTAL': 'Total',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOTAL': 'Total',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE_FILTER': 'Assign filter medias',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ADDRESS': 'Address',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_CITY': 'City',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_COUNTRY': 'Country',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ZIP': 'Zip',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_SITE_NUM': 'External ID',
  'CAMPAIGN_ADVANCE_FILTER_SUBSCRIPTIONS_DIALOG_TITLE': 'Advance search for subscriptions',
  'EDIT_CAMPAIGN_ARCHIVE': 'Archived',
  'MEDIAS_LIST_DELETE': 'Delete',
  'MEDIAS_LIST_ARCHIVE': 'Archive',
  'CONFIRM_DELETE_MEDIAS_DIALOG': 'Delete Confirmation',
  'CONFIRM_DELETE_MEDIAS_DIALOG_TITLE': 'Delete Confirmation',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT': 'Delete all',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT_ONLY': 'Delete only medias how are not planed',
  'CONFIRM_DELETE_MEDIAS_DIALOG_SINGLE': 'Are you sure you want to delete this media?',
  'CONFIRM_DELETE_MEDIAS_DIALOG_MULTI': 'Are you sure you want to delete these medias?',
  'CONFIRM_DELETE_MEDIAS_USED_MEDIAS_TITLE': 'These media(s) are used in the following campaign(s)',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG': 'Archive Confirmation',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE1': 'Do you want to',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE2': 'this ',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE3': 'selected medias ?',
  'CONFIRM_ARCHIVE_CAMPAIGN_DIALOG_MESSAGE3': 'selected campaign ?',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_ACCEPT': 'Archive',
  'EDIT_CAMPAIGN_INFO': 'Campaign info',
  'CAMPAIGN_UPDATED': 'Updated :',
  'CAMPAIGN_UPDATED_BY': 'Updated by :',
  'CAMPAIGN_CREATED': 'Created :',
  'CAMPAIGN_CREATED_BY': 'Created by :',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_UNACCEPT': 'Unarchive',
  'DUPLICATION_CAMPAIGN_TITLE': 'Duplication Campaign',
  'DUPLICATION_CAMPAIGN_LANG': 'Duplicate cultures',
  'DUPLICATION_CAMPAIGN_SITES': 'Duplicate subscriptions ',
  'DUPLICATION_CAMPAIGN_MEDIAS': 'Duplicate medias',
  'DUPLICATION_CAMPAIGN_DATA': 'Duplicate campaign Tags',
  'DUPLICATION_BTN_SUBMIT': 'Duplicate',
  'DUPLICATION_BTN': 'Duplication',
  'PROGRAM_NO_PLANNING': 'Program with ended planning.',
  'TEMPLATES_PROGRAMS': 'Templates and programmes.',
  'TEMPLATES_PROGRAMS_LOAD_ERROR': 'An error occurred while loading the templates and programs.',
  'TEMPLATES_PROGRAMS_TITLE': 'Templates & Programs',
  'TEMPLATES_PROGRAMS_TEMPLATES_TITLE': 'Templates',
  'TEMPLATES_PROGRAMS_PROGRAMS_TITLE': 'Programs',
  'TEMPLATES_PROGRAMS_EDIT_BUTTON': 'Edit',
  'TEMPLATES_PROGRAMS_DELETE_TEMPLATE_BUTTON': 'Delete',
  'TEMPLATES_PROGRAMS_DELETE_PROGRAM_BUTTON': 'Unassign',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_TITLE': 'Program Details',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME': 'Name',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_IS24H': 'Is 24h program?',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM': 'Program 24h',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE': 'Start date',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE': 'End date',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_UPDATE_BUTTON': 'Update',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_CANCEL_BUTTON': 'Cancel',
  'TEMPLATES_PROGRAMS_SAVE_BUTTON': 'Save',
  'TEMPLATES_PROGRAMS_SAVING_BUTTON': 'Saving...',
  'TEMPLATES_PROGRAMS_CANCEL_BUTTON': 'Cancel',
  'DATETIME_PICKER_TODAY': 'Go to today',
  'DATETIME_PICKER_CLEAR': 'Clear selection',
  'DATETIME_PICKER_CLOSE': 'Close the picker',
  'DATETIME_PICKER_SELECT_MONTH': 'Select Month',
  'DATETIME_PICKER_PREV_MONTH': 'Previous Month',
  'DATETIME_PICKER_NEXT_MONTH': 'Next Month',
  'DATETIME_PICKER_SELECT_YEAR': 'Select Year',
  'DATETIME_PICKER_PREV_YEAR': 'Previous Year',
  'DATETIME_PICKER_NEXT_YEAR': 'Next Year',
  'DATETIME_PICKER_SELECT_DECADE': 'Select Decade',
  'DATETIME_PICKER_PREV_DECADE': 'Previous Decade',
  'DATETIME_PICKER_NEXT_DECADE': 'Next Decade',
  'DATETIME_PICKER_PREV_CENTURY': 'Previous Century',
  'DATETIME_PICKER_NEXT_CENTURY': 'Next Century',
  'DATETIME_PICKER_INCREMENT_HOUR': 'Increment Hour',
  'DATETIME_PICKER_PICK_HOUR': 'Pick Hour',
  'DATETIME_PICKER_DECREMENT_HOUR': 'Decrement Hour',
  'DATETIME_PICKER_INCREMENT_MINUTE': 'Increment Minute',
  'DATETIME_PICKER_PICK_MINUTE': 'Pick Minute',
  'DATETIME_PICKER_DECREMENT_MINUTE': 'Decrement Minute',
  'DATETIME_PICKER_INCREMENT_SECOND': 'Increment Second',
  'DATETIME_PICKER_PICK_SECOND': 'Pick Second',
  'DATETIME_PICKER_DECREMENT_SECOND': 'Decrement Second',
  'DATE_TIME_PLACEHOLDER': 'DD/MM/YYYY HH:mm',
  'DATE_PLACEHOLDER': 'DD/MM/YYYY',
  'TIME_PLACEHOLDER': 'HH:mm',
  'TEMPLATES_PROGRAMS_TEMPLATE_DIALOG_TITLE': 'Template Details',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME': 'Name',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_CANCEL_BUTTON': 'Cancel',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_SAVE_BUTTON': 'Save',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_REQUIRED': 'The template name is required.',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_MAX_LENGTH': 'The template name cannot be longer than 50 characters.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_REQUIRED': 'The program name is required.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_MAX_LENGTH': 'The program name cannot be longer than 50 characters.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM_REQUIRED': 'The 24h program is required.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_REQUIRED': 'The start date is required.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_MOMENT': 'The start date must be a valid date (The format is DD/MM/YYYY).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_REQUIRED': 'The end date is required.',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_MOMENT': 'The end date must be a valid date (The format is DD/MM/YYYY).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_AFTER': 'The end date must be after the start date.',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_DIALOG_TITLE': 'Add program',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME_REQUIRED': 'The program is required.',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_SAVE_BUTTON': 'Save',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_CANCEL_BUTTON': 'Cancel',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_TITLE': 'Confirm delete template',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_TITLE': 'Confirm unassign program',
  'TEMPLATES_PROGRAMS_CANCEL_DELETE_TEMPLATE_BUTTON': 'No, don\'t delete it',
  'TEMPLATES_PROGRAMS_CANCEL_UNASSIGN_PROGRAM_BUTTON': 'No, don\'t unassign it',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_BUTTON': 'Yes, unassign it',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_BUTTON': 'Yes, delete it',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_MESSAGE': 'Are you sure you want to delete the template "{{name}}"?',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_MESSAGE':
    'Are you sure you want to unassign the program "{{programName}}" from the template "{{templateName}}"?',
  'PROGRAMS_LOAD_ERROR': 'A server error occurred while loading the programs.',
  'PROGRAMS_24H_LOAD_ERROR': 'A server error occurred while loading the programs 24h.',
  'TEMPLATES_PROGRAMS_SAVE_SUCCESS': 'The templates & programs have been saved successfully.',
  'TEMPLATES_PROGRAMS_SAVE_VALIDATION': 'A validation error occurred while saving the templates & programs.',
  'TEMPLATES_PROGRAMS_SAVE_FAIL': 'A server error occurred while saving the templates & programs.',
  'DELETE_CONFIRMATION_MEDIAS': 'Are you sure you want to delete this media "{{name}}"?',
  'DELETE_CONFIRMATION_CAMPAIGNS': 'Are you sure you want to delete this media "{{name}}"?',
  'SUBSCRIPTION_LIST_TITLE': 'SUBCRIPTIONS',
  'COMMANDS_FILTER': 'Filter',
  'PUBLICATION_LIST_TITLE': 'Publications',
  'PUBLICATIONS_LIST_COLUMN_NAME': 'Name',
  'PUBLICATIONS_LIST_COLUMN_TYPE': 'Type',
  'PUBLICATIONS_LIST_COLUMN_START_DATE': 'Start',
  'PUBLICATIONS_LIST_COLUMN_END_DATE': 'End',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_CD': 'CD',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_SD': 'SD',
  'PUBLICATIONS_LIST_COLUMN_SUBSCRIPTIONS_AFFECTED': 'Subscription affected',
  'PUBLICATIONS_LIST_COLUMN__SUBSCRIPTIONS_FAILED': 'Subscription failed',
  'PUBLICATIONS_LIST_COLUMN__CREATED': 'Created',
  'PUBLICATIONS_LIST_COLUMN_CREATEDBY': 'Created by',
  'PUBLICATIONS_LIST_COLUMN_STATUS': 'Status',
  'No': 'No',
  'Yes': 'Yes',
  'FILTER_RESET': 'Reset',
  'CITY': 'City',
  'COUNTRY': 'Country',
  'SUBSCRIPTION_DETAILS_INFO_PAGE_TITLE': 'Subscription Detail page',
  'PUBLICATIONS_LIST_QUICK_SEARCH': 'Start date',
  'PUBLICATION_MUSIC': 'Music',
  'PUBLICATION_PUB': 'Commercials',
  'SUBSCRIPTION_HOURS': 'Subscription hours',
  'CONFIRM_PUBLISH_DIALOG_TITLE': 'Publication Confirmation',
  'UNSAVED_CHANGES': 'nl You have unsaved changes. Are you sure you want to leave this page ?',
  'CONFIRM_PUBLISH_DIALOG_ACCEPT': 'publish',
  'PUBLICATION_DETAILS_INFO': 'Info',
  'PUBLICATION_DETAILS_LIST': 'Details',
  'PUBLICATIONS_GENERAL_INFO': 'General info',
  'PUBLICATION_NAME': 'Name',
  'PUBLICATIONS_TYPE': 'Type',
  'PUBLICATIONS_TYPE_MUSIC': 'Music',
  'PUBLICATIONS_TYPE_COMMERCIAL': 'Commercial',
  'PUBLICATION_START_DATE': 'Start date',
  'PUBLICATION_END_DATE': 'End date',
  'PUBLICATION_NUMBER_OF_CD': 'Number of cd',
  'PUBLICATION_NUMBER_OF_SD': 'Number of sd',
  'PUBLICATIONS_STATUS': 'Status',
  'PROGRESS': 'PROGRESS',
  'READY': 'READY',
  'FAILED': 'FAILED',
  'PUBLICATION_NUMBER_OF_SUBSCRIPTION': 'Subscriptions affected',
  'PUBLICATION_NUMBER_OF_FAILED_PUBLICATION': 'Publications failed',
  'PUBLICATION_CREATEDBY': 'Created by',
  'PUBLICATION_SUBCRIPTION_NAME': 'Subscription name',
  'PUBLICATION_CITY': 'City',
  'PUBLICATION_COUNTRY': 'Country',
  'PUBLICATION_EXT_ID': 'External ID',
  'PUBLICATIONS_LIST_FILTERD': 'Search by subscription name',
  'PUBLICATION_STATUS': 'Publication status',
  'STATUS': 'Status',
  'TRANSFERT': ' Transfer type',
  'PUBLICATION_FILTER_SUBSCRIPTION_NAME': 'Subscription name',
  'PULICATION_FILTER_SUBSCRIPTION_EXT_ID': 'External ID',
  'PUBLICATIONS_LIST_FILTER_START_DATE': 'Start date',
  'PUBLICATIONS_LIST_FILTER_END_DATE': 'End date',
  'PUBLICATION_FILTER_SUBSCRIPTION_CITY': 'City',
  'PUBLICATION_FILTER_SUBSCRIPTION_COUNTRY': 'Country',
  'PUBLICATION_FILTER_SUBSCRIPTION_PRODUCT': 'Product',
  'PUBLICATION_CANCEL_CD_SD': 'Cancel SD/CD',
  'PUBLICATION_CD': 'CD',
  'PUBLICATION_SD': 'SD',
  'PUBLICATION_CANCEL_SD': 'Cancel SD',
  'PUBLICATION_CANCEL_CD': 'Cancel CD',
  'PUBLICATION_CANCEL_MORE_INFO': 'More info',
  'PUBLICATION_MORE_INFO': 'Publication campaign detail',
  'AUDIO_SCHEDULED_MESSAGES_LOAD_ERROR': 'An error occurred while loading the audio scheduled messages.',
  'AUDIO_SCHEDULED_MESSAGES_FILTER': 'Filter',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY': 'Day',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_SEARCH': 'Search',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_RESET': 'Reset',
  'AUDIO_SCHEDULED_MESSAGES_ANNOUNCEMENT': 'Type',
  'AUDIO_SCHEDULED_MESSAGES_CAMPAIGN': 'Campaign',
  'AUDIO_SCHEDULED_MESSAGE_START_DATE': 'From (date)',
  'AUDIO_SCHEDULED_MESSAGE_END_DATE': 'To (date)',
  'AUDIO_SCHEDULED_MESSAGE_START_TIME': 'From (time)',
  'AUDIO_SCHEDULED_MESSAGE_END_TIME': 'To (time)',
  'AUDIO_SCHEDULED_MESSAGE_MONDAY': 'Mon.',
  'AUDIO_SCHEDULED_MESSAGE_TUESDAY': 'Tue.',
  'AUDIO_SCHEDULED_MESSAGE_WEDNESDAY': 'Wed.',
  'AUDIO_SCHEDULED_MESSAGE_THURSDAY': 'Thu.',
  'AUDIO_SCHEDULED_MESSAGE_FRIDAY': 'Fri.',
  'AUDIO_SCHEDULED_MESSAGE_SATURDAY': 'Sat.',
  'AUDIO_SCHEDULED_MESSAGE_SUNDAY': 'Sun.',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_NAME': 'Name',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_DURATION': 'Duration',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_PLAY': 'Play',
  'PLAYER_AUDIO_SCHEDULED_MESSAGES_TITLE': 'Scheduled Messages',
  'CANCEL_CONFIRMATION': 'Are you sure you want to cancel ?',
  'CANCEL_CONFIRMATION_BUTTON': 'No, don\'t cancel it',
  'CANCEL_CANCEL_CONFIRMATION_BUTTON': 'Yes, cancel it',
  'TOPUBLISH': 'To publish',
  'PUBLISHED': 'Published',
  'OPENING_HOURS': 'Opening hours',
  'SUBSCRIPTION_EDIT': 'Edit',
  'SUBSCRIPTIONS_LIST_TAG': 'Tags',
  'CULTURES': 'Cultures',
  'SUBSCRIPTIONS_LIST_OPENING_HOURS': 'Opening hours',
  'SUBSCRIPTIONS_LIST_PROGRAM': 'Programs',
  'TAGS_SUBSCRIPTION_DIALOG_TITLE': 'Add tags to your selected subscriptions',
  'DELETE_TAGS_MEDIA_DIALOG_TITLE': 'Delete tags to your selected medias',
  'DELETE_TAGS_SUBSCRIPTION_DIALOG_TITLE': 'Delete tags to your selected subscriptions',
  'TAGS_CAMPAIGN_DIALOG_TITLE': 'Add tags to your selected campaign(s)',
  'DELETE_TAGS_CAMPAIGN_DIALOG_TITLE': 'Delete tags to your selected campaign(s)',
  'OPENING_HOURS_SUBSCRIPTION_DIALOG_TITLE': 'Set opening hours to selected subscriptions',
  'PROGRAM_CHANGE_SUBSCRIPTION_DIALOG_TITLE': 'Link your selected subscription(s) to a templates & programs',
  'PROGRAMS': 'Templates & Programs',
  'APPLY': 'Apply',
  'PLAYER_NAME': 'Player name',
  'LAST_MODIFICATION': 'Last modification',
  'MODEL': 'Model',
  'NO_PROGRAM': 'No program selected',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME': 'Program',
  'CAMPAIGNS_LIST_PAGE_TITLE': 'AD Manager | Campaigns',
  'CAMPAIGNS_DETAILS_INFO_PAGE_TITLE': 'AD Manager | Campaigns | Details',
  'MEDIAS_LIST_PAGE_TITLE': 'AD Manager | Medias',
  'MEDIAS_DETAILS_INFO_PAGE_TITLE': 'AD Manager | Medias | Details',
  'PUBLICATIONS_LIST_PAGE_TITLE': 'AD Manager | Publications',
  'PUBLICATION_DETAILS_INFO_PAGE_TITLE': 'AD Manager | Publications | Details',
  'SUBSCRIPTIONS_LIST_PAGE_TITLE': 'AD Manager | Subscriptions',
  'SUBSCRIPTIONS_DETAILS_INFO_PAGE_TITLE': 'AD Manager | Subscriptions| Details',
  'TEMPLATES_PROGRAMS_INFO_PAGE_TITLE': 'AD Manager | Templates & Programs',
  'MEDIAS_LIST_VALIDATE': 'Validate medias',
  'PLACING': 'placing',
  'MEDIA_TO_VALIDATE': 'media to validate',
  'MEDIAS_TO_VALIDATE': 'medias to validate',
  'GO_TO_VALIDATE_MEDIA': 'Go to validation media page',
  'GO_TO_PUBLICATION': 'Go to publications',
  'GO_TO_CAMPAIGN': 'Go to campaigns',
  'GO_TO_SUBSCRIPTION': 'Go to subscriptions',
  'LAST_CAMPAIGN': 'last campaign',
  'LAST_CAMPAIGNS': 'last campaigns',
  'LAST_PUBLICATION': 'last publication ',
  'LAST_PUBLICATIONS': 'last publications ',
  'FAILED_PUBLICATION': 'publication failed',
  'FAILED_PUBLICATIONS': 'publications failed',
  'SITE_WITH_NO_TEMPLATE': 'site with no template',
  'ACTIVITY_FEED': 'activity feed',
  'DELETED': '(deleted)',
  'DASHBOARD_ACTIVITY_FEED_TITLE': 'Activity Feed',
  'DASHBOARD_ACTIVITY_FEED_ERROR': 'An error occurred while loading the activity feed.',

  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULE': '{{userName}} created <strong>schedule</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULE': '{{userName}} updated <strong>schedule</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULE': '{{userName}} deleted <strong>schedule</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULES': '{{userName}} created <strong>schedules</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULES': '{{userName}} updated <strong>schedules</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULES': '{{userName}} deleted <strong>schedules</strong>',

  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTION': '{{userName}} created <strong>subscription</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTION': '{{userName}} updated <strong>subscription</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTION': '{{userName}} deleted <strong>subscription</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTIONS': '{{userName}} created <strong>subscriptions</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTIONS': '{{userName}} updated <strong>subscriptions</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTIONS': '{{userName}} deleted <strong>subscriptions</strong>',
  'DASHBOARD_ACTIVITY_FEED_PUBLISH_SUBSCRIPTIONS': 'Publication has been published by <strong> {{userName}} </strong> ',

  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIA': '{{userName}} created <strong>media</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIA': '{{userName}} updated <strong>media</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIA': '{{userName}} deleted <strong>media</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIA': '{{userName}} validated <strong>media</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIAS': '{{userName}} created <strong>medias</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIAS': '{{userName}} updated <strong>medias</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIAS': '{{userName}} deleted <strong>medias</strong>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIAS': '{{userName}} validated <strong>medias</strong>',

  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATE': '{{userName}} created <strong>program template</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATE': '{{userName}} updated <strong>program template</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATE': '{{userName}} deleted <strong>program template</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATES': '{{userName}} created <strong>program templates</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATES': '{{userName}} updated <strong>program templates</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATES': '{{userName}} deleted <strong>program templates</strong>',

  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTION': '{{userName}} linked <strong>program to subscription</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTIONS': '{{userName}} linked <strong>program to subscriptions</strong>',

  'DASHBOARD_ACTIVITY_FEED_NO_DATA': 'There is no data to show here.',
  'PRIVACY_POLICY': 'Privacybeleid',
  'TERMS_OF_USE': 'Gebruiksvoorwaarden',
  'IMPORT_FROM': 'Import from',
  'CLONE_FROM': 'CLONE FROM',
  'STEP': 'STEP',
  'NEXT': 'Next',
  'COPY': 'Import',
  'IMPORT_CAMPAIGNS_FROM': 'Importer vos Campaigns pour les Subscriptions sélectionées',
  'PREVIOUS': 'PREVIOUS',
  'FIRST': 'First',
  'LAST': 'Last',
  'CLONED_DATA_RESUME': 'Cloned data resume',
  'IMPORT_CAMPAIGNS_FROM_ERROR': 'Import campaign(s) cause an error.',
  'SELECTED_SUBSCRIPTIONS_TO_CLONE': 'Selected subscriptions.',
  'SELECTED_CAMPAIGNS_TO_CLONE': 'Campaign selected to clone',
  'DELETE_TAGS_SUCCESS': 'Delete tags to your selected campaign(s)',
  'DELETE_TAGS_ERROR': 'An error occurred while deleting tags.',
  'ACTION_LIST_TAG_DELETE': 'Delete tags',
  'CONFIRM_PUBLISH_CD': 'cd',
  'TEMPLATES_AND_PROGRAMS': 'Templates and programs',
  'NO_DATA_SELECTED': 'No data selected',
  'SOME_COMMENTS': 'Some comments...',
  'VALIDATE_ALL': 'Validate all',
  'EXTRA_PLAY': 'Extra play',
  'CHANGE_SUBSCRIPTION': 'Change subscription',
  'FILTER_BY_NAME': 'Filter by name',
  'END_DATE': 'End Date',
  'MINUTES': 'minute(s)',
  'NO_LANGUAGE_SELECTED': 'No language selected',
  'NO_TAGS_SELECTED': 'No tags selected',
  'NO_CULTURES_SELECTED': 'No cultures selected',
  'FIVE': '5',
  'TEN': '10',
  'TWENTY': '20',
  'FIFTY': '50',
  'HUNDRED': '100',
  'ON_LINE': 'On line',
  'DATE_VALIDITY': 'Date validity',
  'ADD_MEDIA_SUCCESS': 'Add media success',
  'EMPTY_TEXT': 'There is no data to display here',
  'MEDIA_SAVE_SUCCESS': 'Media save success',
  'FIRMWARE': 'Firmware',
  'ARCHIVE_RELATED_MEDIA': 'Archief gerelateerde media.',
  'TEMPLATE_PROGRAM_DUPLICATE_NAME': 'Naam bestaat al',
  'SUBSCRIPTION_SAVE_ERROR': 'Fout bij opslaan  subscription',
  'CONTENT': 'inhoud',
  'MEDIAS_LIST_FILTER_EXPIRED': 'Niet meer geldig',
  'MUSIC_MANAGER_LINK': 'Link naar Music Manager',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION': 'Forceer een update',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION_LISA_PLAYERS': 'Lisa players',
  'PUBLICATION_DIALOG_TOTAL_PLAYERS': 'Totaal aantal players: ',
  'PUBLICATION_DIALOG_ONLINE_PLAYERS': 'Online players: ',
  'PUBLICATION_DIALOG_OFFLINE_PLAYERS': 'Offline players: '

}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Media} from '../../shared/models/media';
import {AddMediaActionTypes} from '../actions/add-media';
import {DeleteMediaActionTypes} from '../actions/delete-media';
import {LoadMediaDetailsSuccessAction, MediaDetailsActionType} from '../actions/media-details';
import {SaveMediaActionTypes} from '../actions/save-media';

export interface MediaDetailsState {
  value: Media;
  submitting: boolean;
}

const initialState: MediaDetailsState = {
  value: undefined,
  submitting: false
};

export function mediaDetailsReducer(state = initialState, action: Action): MediaDetailsState {
  switch (action.type) {
    case MediaDetailsActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case MediaDetailsActionType.LOAD_FAIL:
      return handleLoadFailAction(state);
    case DeleteMediaActionTypes.DELETE_SUCCESS:
      return handleDeleteMediaAction(state);
    case AddMediaActionTypes.ADD_MEDIA_SUCCESS:
      return handleAddMediaAction(state);
    case AddMediaActionTypes.ADD_MEDIA_FAIL:
      return handleAddFailAction(state, action);
    case SaveMediaActionTypes.SAVE:
    case SaveMediaActionTypes.SAVE_SUCCESS:
    case SaveMediaActionTypes.SAVE_VALIDATION_FAIL:
    case SaveMediaActionTypes.SAVE_NOT_FOUND:
    case SaveMediaActionTypes.SAVE_FAIL:
      return handleEndSubmitActions(state);

    default:
      return state;
  }
}

function handleLoadSuccessAction(state: MediaDetailsState, action: LoadMediaDetailsSuccessAction): MediaDetailsState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleLoadFailAction(state: MediaDetailsState): MediaDetailsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}
function handleAddFailAction(state: MediaDetailsState, action: LoadMediaDetailsSuccessAction): MediaDetailsState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleDeleteMediaAction(state: MediaDetailsState): MediaDetailsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}
function handleAddMediaAction(state: MediaDetailsState): MediaDetailsState {
  const newState = _.cloneDeep(state);
  newState.value = undefined;
  newState.submitting = true;
  return newState;
}

function handleEndSubmitActions(state: MediaDetailsState): MediaDetailsState {
  const newState = _.clone(state);
  newState.submitting = false;
  return newState;
}

export const MediaDetailsSelectors = {
  value: (state: MediaDetailsState) => _.get(state, 'value'),
  submitting: (state: MediaDetailsState) => _.get(state, 'submitting', false)
};

import {Action} from '@ngrx/store';

import {Publication} from '../../publications/models/publication';
import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';

export const PublicationListActionTypes = {
  LOAD_LAST: type('[Publication] last Load'),
  LOAD_LAST_SUCCESS: type('[Publication] last Load Success'),
  LOAD_LAST_FAIL: type('[Publication] last Load Fail'),
};

// last publications
export class PublicationLastAction implements Action {
  readonly type = PublicationListActionTypes.LOAD_LAST;
  constructor(public payload?: any) {}
}

export class PublicationLastSuccessAction implements Action {
  readonly type = PublicationListActionTypes.LOAD_LAST_SUCCESS;
  constructor(public payload?: ArrayResponse<Publication>) {}
}

export class PublicationLastFailAction implements Action {
  readonly type = PublicationListActionTypes.LOAD_LAST_FAIL;
  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const ArchiveMediasActionTypes = {
  ARCHIVE: type('[Medias] Archive'),
  ARCHIVE_ONE: type('[Medias] Archive One'),
  ARCHIVE_SUCCESS: type('[Medias] Archive Success'),
  ARCHIVE_SELF: type('[Medias] Archive Self'),
  ARCHIVE_FAIL: type('[Medias] Archive Server Error')
};

export class ArchiveMediasAction implements Action {
  readonly type = ArchiveMediasActionTypes.ARCHIVE;
  constructor(public payload?: boolean) {}
}
export class ArchiveMediaAction implements Action {
  readonly type = ArchiveMediasActionTypes.ARCHIVE_ONE;
  constructor(public payload?: boolean) {}
}

export class ArchiveMediasSuccessAction implements Action {
  readonly type = ArchiveMediasActionTypes.ARCHIVE_SUCCESS;
  constructor() {}
}

export class ArchiveMediasSelfAction implements Action {
  readonly type = ArchiveMediasActionTypes.ARCHIVE_SELF;
  constructor() {}
}

export class ArchiveMediasFailAction implements Action {
  readonly type = ArchiveMediasActionTypes.ARCHIVE_FAIL;
  constructor(public payload?: number) {}
}

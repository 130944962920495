import {Action} from '@ngrx/store';

import {Language} from '../models/language';
import {type} from '../utilities/type';

export const LanguagesActionTypes = {
  LOAD: type('[Languages] Load'),
  LOAD_SUCCESS: type('[Languages] Load Success'),
  LOAD_FAIL: type('[Languages] Load Fail')
};

export class LoadLanguagesAction implements Action {
  readonly type = LanguagesActionTypes.LOAD;

  constructor() {}
}

export class LoadLanguagesSuccessAction implements Action {
  readonly type = LanguagesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Language[]) {}
}

export class LoadLanguagesFailAction implements Action {
  readonly type = LanguagesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

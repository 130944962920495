import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {ArrayResponse, BaseEffect, error, SendBackResult} from '../../shared';
import {Activity} from '../../shared/models/activity';
import * as fromRoot from '../../shared/reducers';
import {ActivityFeedActionTypes, LoadActivityFeedFailAction, LoadActivityFeedSuccessAction} from '../actions/activity-feed';
import * as fromDashboard from '../reducers';
import {DashboardService} from '../services/dashboard.service';

@Injectable()
export class ActivityFeedEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$  = createEffect(
    () => {  return this.actions$.pipe(
    ofType(ActivityFeedActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Activity feed load action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.dashboardService.activityFeed(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<Activity>>) => new LoadActivityFeedSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(ActivityFeedActionTypes.LOAD_FAIL),
                       debug('A server error occurred while retrieving activity feed.'),
                       map(() => error(this.translation.translate('DASHBOARD_ACTIVITY_FEED_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromDashboard.AppState>,
              translation: L10nTranslationService,
              private dashboardService: DashboardService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadActivityFeedFailAction(response.status); }
}

import {ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import * as _ from 'lodash';
import * as fromRoot from '../../shared/reducers';

import {CampaignDetailsReducer, CampaignDetailsSelectors, CampaignDetailsState} from './campaign-details';
import {CampaignsListReducer, CampaignsListSelectors, CampaignsListState} from './campaign-list';
import {MediasListReducer, MediasListSelectors, MediasListState} from './campaign-medias';
import {SubscriptionsListReducer, SubscriptionsListSelectors, SubscriptionsListState} from './campaign-subscriptions';
import {PublicationSelectors, publicationSimpleListReducer, PublicationState} from './publication-simple-list';

export interface CampaignsState {
  campaigns: CampaignsListState;
  campaign: CampaignDetailsState;
  medias: MediasListState;
  subscriptions: SubscriptionsListState;
  publication: PublicationState;
}

export interface AppState extends fromRoot.AppState {
  campaigns: CampaignsState;
}

export const reducers: ActionReducerMap<CampaignsState> = {
  campaigns: CampaignsListReducer,
  campaign: CampaignDetailsReducer,
  medias: MediasListReducer,
  subscriptions: SubscriptionsListReducer,
  publication: publicationSimpleListReducer,

};

const getCampaignsState = createFeatureSelector('campaigns');
const rootSelectors = {
  medias: createSelector(getCampaignsState, (state: CampaignsState) => _.get(state, 'medias')),
  campaigns: createSelector(getCampaignsState, (state: CampaignsState) => _.get(state, 'campaigns')),
  campaign: createSelector(getCampaignsState, (state: CampaignsState) => _.get(state, 'campaign')),
  subscriptions: createSelector(getCampaignsState, (state: CampaignsState) => _.get(state, 'subscriptions')),
  publication: createSelector(getCampaignsState, (state: PublicationState) => _.get(state, 'publication')),
};

export interface Selectors {
  getShowFilter: any;
  getQuickSearch: any;
  getFilter: any;
  getPage: any;
  getPageSize: any;
  getOrderBy: any;
  getCampaign: any;
  getCampaignCount: any;
  getDownloading: any;
  getCampaignDetails: any;
  getMedia: any;
  getSubscriptions: any;
  getSelectedCampaigns: any;
  getPublication: any;
  getSelectedTagsList: any;
}

export const selectors: Selectors = {
  getShowFilter: createSelector(rootSelectors.campaigns, CampaignsListSelectors.showFilter),
  getQuickSearch: createSelector(rootSelectors.campaigns, CampaignsListSelectors.quickSearch),
  getFilter: createSelector(rootSelectors.campaigns, CampaignsListSelectors.filter),
  getPage: createSelector(rootSelectors.campaigns, CampaignsListSelectors.page),
  getPageSize: createSelector(rootSelectors.campaigns, CampaignsListSelectors.pageSize),
  getOrderBy: createSelector(rootSelectors.campaigns, CampaignsListSelectors.orderBy),
  getCampaign: createSelector(rootSelectors.campaigns, CampaignsListSelectors.array),
  getCampaignCount: createSelector(rootSelectors.campaigns, CampaignsListSelectors.count),
  getDownloading: createSelector(rootSelectors.campaigns, CampaignsListSelectors.downloading),
  getCampaignDetails: createSelector(rootSelectors.campaign, CampaignDetailsSelectors.value),
  getMedia: createSelector(rootSelectors.medias, MediasListSelectors.array),
  getSubscriptions: createSelector(rootSelectors.subscriptions, SubscriptionsListSelectors.array),
  getSelectedCampaigns: createSelector(rootSelectors.campaigns, CampaignsListSelectors.selectedCampaigns),
  getPublication: createSelector(rootSelectors.publication, PublicationSelectors.array),
  getSelectedTagsList: createSelector(rootSelectors.campaigns, CampaignsListSelectors.selectedTags)

};

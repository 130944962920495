import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {catchError, map, skipWhile, timeout} from 'rxjs/operators';

import {Publication} from '../../../publications/models/publication';
import {Campaign, Client, Role} from '../../../shared';
import {Activity} from '../../../shared/models/activity';
import {Media} from '../../../shared/models/media';
import * as fromRoot from '../../../shared/reducers';
import {Subscriptions} from '../../../subscription/models/subscriptions';
import {LoadActivityFeedAction} from '../../actions/activity-feed';
import {CampaignLastLoadAction} from '../../actions/campaign-list';
import {LoadMediaToValidateAction} from '../../actions/medias-to-validate-list';
import {ProgramNoPlanningAction} from '../../actions/program-no-planning';
import {PublicationFailedAction} from '../../actions/publication-failed-list';
import {PublicationLastAction} from '../../actions/publication-list';
import {SiteWithNoTemplateAction} from '../../actions/site-with-no-template-list';
import * as fromDashboard from '../../reducers';

@Component({
  selector: 'storever-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewChecked {

  client$: Observable<Client>;
  clientData: any;

  roles$: Observable<Role[]>;

  campaignLast$: Observable<Campaign[]>;
  campaignLastData = [];
  campaignLastOrder = 6;

  publicationLast$: Observable<Publication[]>;
  publicationLastData = [];
  publicationLastOrder = 3;

  publicationFailed$: Observable<Publication[]>;
  publicationFailedData = [];
  publicationFailedOrder = 2;

  mediaToValidate$: Observable<Media[]>;
  mediaToValidateData = [];
  mediaToValidateOrder = 4;

  siteWithNoTemplate$: Observable<Subscriptions[]>;
  siteWithNoTemplateData = [];
  siteWithNoTemplateOrder = 1;

  activityFeed$: Observable<Activity[]>;
  activityFeedData = [];
  activityFeedOrder = 5;

  programNoPlanning$: Observable<any[]>;
  programNoPlanningData = [];
  programNoPlanningOrder = 7;

  order = 7;
  posInc = 0;
  private _timeout = 10000;

  spinnerTemplate = true;
  spinnerActivityFeed = true;
  spinnerMedia = true;
  spinnerPublicationFailed = true;
  spinnerPublicationLast = true;
  spinnerCampaignLast = true;
  spinnerProgramNoPlanning = true;

  constructor(private storeRoot: Store<fromRoot.AppState>,
              private store: Store<fromDashboard.AppState>,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private cd: ChangeDetectorRef,
              private router: Router) {}

  ngOnInit() { this.storeSelect(); }

  ngAfterViewChecked() {}

  storeSelect() {
    this.client$ = this.storeRoot.select(fromRoot.selectors.getCurrentClient);
    this.roles$ = this.store.select(fromRoot.selectors.getMyRoles);
    this.publicationLast$ = this.store.select(fromDashboard.selectors.getPublicationLast);
    this.campaignLast$ = this.store.select(fromDashboard.selectors.getCampaign);
    this.publicationFailed$ = this.store.select(fromDashboard.selectors.getPublicationFailed);
    this.mediaToValidate$ = this.store.select(fromDashboard.selectors.getMediaToValidate);
    this.siteWithNoTemplate$ = this.store.select(fromDashboard.selectors.getSiteWithNoTemplate);
    this.activityFeed$ = this.store.select(fromDashboard.selectors.getActivityFeed);
    this.programNoPlanning$ = this.store.select(fromDashboard.selectors.getProgramNoPlanning);

    this.client$.subscribe(data => { this.clientData = data.uuid; });
    this.roles$.subscribe(data => this.storeDispatch(data), err => console.log('err', err));
  }

  storeDispatch(data) {

    this.store.dispatch(new ProgramNoPlanningAction()); // TODO: right ?

    this.store.dispatch(new LoadActivityFeedAction());
    if (_.findIndex(data, ['code', 'APS_PROGRAM']) !== -1) {
      this.store.dispatch(new SiteWithNoTemplateAction());
    }
    if (_.findIndex(data, ['code', 'APS_CAMPAIGN']) !== -1) {
      this.store.dispatch(new CampaignLastLoadAction());
    }
    if (_.findIndex(data, ['code', 'APS_PUBLISH']) !== -1) {
      this.store.dispatch(new PublicationLastAction());
      this.store.dispatch(new PublicationFailedAction());
    }
    if (_.findIndex(data, ['code', 'APS_MUSTVALIDMEDIA']) !== -1) {
      this.store.dispatch(new LoadMediaToValidateAction());
    }
    this.subscribe();
  }

  subscribe() {
    this.campaignLast$
      .subscribe(data => {
        if (data) {
          this.spinnerCampaignLast = false;
          this.campaignLastOrder = data.length > 0 ? this.posInc : this.order;
          this.campaignLastData = data;
          this.cd.markForCheck();
        }
      })

        this.publicationLast$.subscribe(data => {
          if (data) {
            this.spinnerPublicationLast = false;
            this.publicationLastOrder = data.length > 0 ? this.posInc : this.order;
            this.publicationLastData = data;
            this.cd.markForCheck();
          }
        });

    this.publicationFailed$.subscribe(data => {
      if (data) {
        this.spinnerPublicationFailed = false;
        this.publicationFailedOrder = data.length > 0 ? this.posInc : this.order;
        this.publicationFailedData = data;
        this.cd.markForCheck();
      }
    });

    this.mediaToValidate$.subscribe(data => {
      if (data) {
        this.spinnerMedia = false;
        this.mediaToValidateOrder = data.length > 0 ? this.posInc : this.order;
        this.mediaToValidateData = data;
        this.cd.markForCheck();
      }
    });

    this.siteWithNoTemplate$.subscribe(data => {
      if (data) {
        this.spinnerTemplate = false;
        this.siteWithNoTemplateOrder = data.length > 0 ? this.posInc : this.order;
        this.siteWithNoTemplateData = data;
        this.cd.markForCheck();
      }
    });

    this.activityFeed$.subscribe(data => {
      if (data) {
        this.spinnerActivityFeed = false;
        this.activityFeedOrder = data.length > 0 ? this.posInc : this.order;
        this.activityFeedData = data;
        this.cd.markForCheck();
      }
    });

    this.programNoPlanning$.subscribe(data => {
      if (data) {
        this.spinnerProgramNoPlanning = false;
        this.programNoPlanningOrder = data.length > 0 ? this.posInc : this.order;
        this.programNoPlanningData = data;
        this.cd.markForCheck();
      }
    });
  }

  ngOnDestroy() {}

  isInRole$(role: string): Observable<boolean> { return this.roles$.pipe(map(roles => _.findIndex(roles, ['code', role]) > -1)); }

  getTranslationKey(activity: Activity): string {
    let key = 'DASHBOARD_ACTIVITY_FEED_';
    key += _.toUpper(activity.name);
    key += '_';
    key += _.toUpper(activity.objectType);
    if (activity && activity.objectIds && activity.objectIds.length > 1) {
      key += 'S';
    }
    return key;
  }

  navigateTo(route: string, id: number, param?) {
    if (route === 'ProgramTemplate') {
      route = 'templates-programs';
    } else if (route === 'Schedule') {
      route = 'campaign';
    }
    if (id > 0) {
      this.router.navigate(['../' + this.clientData, _.toLower(route), id]);
    } else {
      if (param && param.length) {
        this.router.navigate(['../' + this.clientData, _.toLower(route)], { queryParams: { [param[0]]: param[1] } });
      } else {
        this.router.navigateByUrl('/' + this.clientData + '/' + _.toLower(route));
      }
    }
  }

  isDatePlanning(published: Date, endDate: Date): boolean {
    const now = moment();
    const resp1 = moment(published).subtract(10, 'd').isAfter(now); // true
    const resp2 = moment(published).isBefore(endDate);              // true
    const resp3 = resp1 && resp2;
    if (this.programNoPlanningOrder !== -1 && !resp3) {
      this.programNoPlanningOrder = -1;
    }
    return resp3;
  }
}

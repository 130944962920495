import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';

import {TagsList} from '../../models/tags-list';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({ selector: 'storever-select-tags', templateUrl: './select-tags.component.html', styleUrls: ['./select-tags.component.scss'] })
export class SelectTagsComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() edition: boolean;
  @Input() tagsList: any[];
  @Output() saveTags = new EventEmitter<void>();
  @Input() injectTags: TagsList[];
  @Input() toDeleteTags: boolean;
  @Input()  selectedItem: any;
  @ViewChild('select') select: NgSelectComponent;
  public selectCopy: Array<any>;   // used to be see by parent
  public tagsToDelete: Array<any>; // tags to delete
  public tagsArray: Array<String> = [];
  public tagsTyped: String;
  public isFirstTimeSet = true;
  selectedTags: Array<any>
  selectTagsList: Array<any>
  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private cdr: ChangeDetectorRef, private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tagsList && changes.tagsList.currentValue && changes.tagsList.currentValue.length) {
      if (this.tagsArray.length !== this.tagsList.length) {
        console.log(this.selectedTags , this.tagsList);
        if (this.tagsList && this.tagsList[0] && this.tagsList[0].name) {
          this.tagsArray = this.objectToArrayString(this.tagsList);
        } else {
          this.selectedTags = this.objectArrayToArrayString(this.tagsList);
        }


      }
    }
    if (changes.selectedItem && changes.selectedItem.currentValue) {
      this.selectedTags = [];
      this.selectTagsList = [];
      console.log(changes.selectedItem.currentValue , this.tagsList)
      changes.selectedItem.currentValue.forEach(tagCode => {

            this.selectedTags.push(tagCode.name);
            this.selectTagsList.push(tagCode.name);
      });


    }
  }

  ngOnInit() {}

  ngAfterViewChecked() {
   // this.selectCopy = this.select.active;
    // this.setTagsSelectToDeleteDataAndStyle();
  }
    removedTags($event): void {
      console.log($event , this.tagsArray)
      console.log(this.tagsArray , this.selectedTags)
      if ($event) {
      }
      if (this.toDeleteTags) {
       // this.select.active = this.selectCopy.filter(data => data.text !== $event.text);
        this.tagsToDelete.push($event);
      }
      this.submitForm($event.value);
    }
  setTagsSelectToDeleteDataAndStyle() {
    if (this.isFirstTimeSet && this.tagsList && this.tagsList[0] && this.tagsList[0].tagsList) {
      this.tagsToDelete = [];
      this.isFirstTimeSet = false;
      this.selectCopy = _.uniqBy(this.objectSelectedTagsListToArrayString(this.tagsList), 'text');
     // this.select.active = this.selectCopy;
      // const part = this.elRef.nativeElement.querySelector('input.form-control.ui-select-search');
     // this.renderer.addClass(part, 'display-none');
    }
  }

  @HostListener('document:click', ['$event'])
  clickedOutside() {
    const clickedComponent = event.target;
    if (clickedComponent !== this.elRef.nativeElement) {
      this.saveTagsTyped();
    }
  }


  changeTags($event): void {
    this.selectTagsList = $event;
    this.submitForm($event);
  }

  onKeydownHandler(evt: KeyboardEvent) {
    console.log('onKeydownHandler' , evt)
    if (evt.keyCode === 13) {
      this.saveTagsTyped();
    }
    evt.preventDefault();
  }

  saveTagsTyped(): void {
    console.log(this.tagsTyped)
    if (this.tagsTyped && this.tagsTyped.length > 1 && this.tagsList) {
      const newValueTagList: TagsList = <TagsList>{ id: null, name: this.tagsTyped };
      const newValueSelectedItem: any = <any>{ id: null, text: this.tagsTyped };
      this.tagsList.push(newValueTagList);
      this.cdr.markForCheck();
      this.tagsArray = this.objectToArrayString(this.tagsList);
 //     this.select.itemObjects.push(newValueSelectedItem);
  //    this.select.active.push(newValueSelectedItem);
      this.cleanDouble();
     // this.submitForm();
      this.resetTypedTags();
     // this.elRef.nativeElement.querySelectorAll('.ui-select-search')[0].value = '';
    }
  }

  cleanDouble() {
    this.tagsList = _.uniqBy(this.tagsList, 'name');
    this.tagsArray = _.uniq(this.tagsArray);
  //  this.select.itemObjects = _.uniqBy(this.select.itemObjects, 'text');
  //  this.select.active = _.uniqBy(this.select.active, 'text');
  }

  submitForm($event: any) { this.saveTags.emit($event); }

  typedTags(value: string): any {
    console.log(value)
    if (!this.tagsTyped ) {
      this.tagsTyped = value;
    }
    console.log( this.tagsTyped)
  }

  addTagFn(name) {
    console.log(name , this.tagsArray)
    console.log( this.selectedTags)
    if (!this.selectedTags) {
      this.selectedTags = [];
    }
    if (!this.selectTagsList) {
      this.selectTagsList = [];
    }

    this.selectedTags.push(name);
    this.selectTagsList.push(name);
    console.log(name , this.tagsArray)
    console.log( this.selectedTags)
    return { name: name, tag: true };
  }

  resetTypedTags(): void {
    // this.tagsTyped = '';
    // this.elRef.nativeElement.querySelectorAll('.ui-select-search')[0].value = '';
  }

  objectArrayToArrayString(val: any): Array<string> {
    const resp = [];
    if (!val.length) {
      return resp
    }
    console.log(val)
    val.forEach((item: any) => {
      for (const prop of item.tagsList) {
        console.log(prop)
        resp.push(prop.name);
      }
    });
    return resp;
  }
  objectToArrayString(val: TagsList[]): Array<string> {
    const resp = [];
    if (!val.length) {
      return resp
    }
    for (const prop of val) {
      resp.push(prop.name);
    }
    return resp;
  }

  objectSelectedTagsListToArrayString(tagsList: any): Array<any> {
    const resp = [];
    for (const tagList of tagsList) {
      for (const tags of tagList.tagsList) {
        resp.push({ id: tags.id, text: tags.name });
      }
    }
    return resp;
  }
  // // tags
  // private objectToArrayString(val: TagsList[]): Array<string> {
  //   const resp = [];
  //   if (!val.length) {
  //     return resp
  //   }
  //   for (const prop of val) {
  //     resp.push(prop.name);
  //   }
  //   return resp;
  // }

  convertTags(): Array<TagsList> {
    const tagListArrayToSend = [];
    this.selectTagsList.forEach(tag => {
      console.log('----------------------------------', tag)
      if (tag.name) {

      tagListArrayToSend.push({
        displayName: tag.name,
        id: 0,
        name: tag.name
      });
    } else {
        this.tagsList.forEach(tagName => {
          console.log(tag , tagName)
          if (tagName.name) { // case add tags
            console.log(' case add tags')
            if (tagName.name === tag) {
              tagListArrayToSend.push(tagName);
            }
          }
      });
      }
      });
    console.log(tagListArrayToSend)
    return tagListArrayToSend;
   }
}

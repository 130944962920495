import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BrandSettings} from '../../models/app-settings';
import {LayoutType} from '../../models/layout-type.enum';
import {AppSettingsService} from '../../services/app-settings.service';
import {LayoutService} from '../../services/layout.service';

@Component({
  selector: 'storever-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent implements OnInit {
  copyrightYear: number;
  brand$: Observable<BrandSettings>;

  constructor(private layoutService: LayoutService, appSettingsService: AppSettingsService) {
    this.copyrightYear = moment().year();
    this.brand$ = appSettingsService.settings$.pipe(map(appSettings => appSettings.brand));
  }

  ngOnInit() { this.layoutService.setLayout(LayoutType.Login); }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {LayoutActionTypes, SetLanguageAction} from '../actions/layout';

export interface LayoutState {
  sidebarClosed: boolean;
  language: string;
}

const initialState: LayoutState = {
  sidebarClosed: false,
  language: undefined
};

export function layoutReducer(state = initialState, action: Action): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.TOGGLE_SIDEBAR:
      return { sidebarClosed: !state.sidebarClosed, language: state.language };
    case LayoutActionTypes.SET_LANGUAGE:
      return handleSetLanguageAction(state, action);
    default:
      return state;
  }
}

function handleSetLanguageAction(state: LayoutState, action: SetLanguageAction): LayoutState {
  return { sidebarClosed: state.sidebarClosed, language: action.payload };
}

export const layoutSelectors = {
  sidebarClosed: (state: LayoutState) => _.get(state, 'sidebarClosed', false),
  language: (state: LayoutState) => _.get(state, 'language')
};

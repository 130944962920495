import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {EditMediaForm} from '../models/edit-media-form';

export const SaveMediaActionTypes = {
  SAVE: type('[Media] Save'),
  SAVE_SUCCESS: type('[Media] Save Success'),
  SAVE_VALIDATION_FAIL: type('[Media] Save Validation Fail'),
  SAVE_NOT_FOUND: type('[Media] Save Not Found'),
  SAVE_FAIL: type('[Media] Save Fail')
};

export class SaveMediaAction implements Action {
  readonly type = SaveMediaActionTypes.SAVE;

  constructor(public payload?: EditMediaForm) {}
}

export class SaveMediaSuccessAction implements Action {
  readonly type = SaveMediaActionTypes.SAVE_SUCCESS;

  constructor() {}
}

export class SaveMediaValidationFailAction implements Action {
  readonly type = SaveMediaActionTypes.SAVE_VALIDATION_FAIL;

  constructor() {}
}

export class SaveMediaNotFoundAction implements Action {
  readonly type = SaveMediaActionTypes.SAVE_NOT_FOUND;

  constructor() {}
}

export class SaveMediaFailAction implements Action {
  readonly type = SaveMediaActionTypes.SAVE_FAIL;

  constructor(public payload?: number) {}
}

<div bsModal #impersonateUserDialog="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()" [attr.aria-label]="'CLOSE' | translate:locale.language">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" translate>IMPERSONATE_DIALOG_TITLE</h4>
      </div>
      <div class="modal-body">
        <div class="note note-info">
          <p translate>IMPERSONATE_DIALOG_EXPLAIN_P1</p>
          <p>
            <strong translate>IMPERSONATE_DIALOG_EXPLAIN_P2</strong>
          </p>
        </div>
        <div class="table-responsive">
          <storever-datatable [striped]="true"
                              [hover]="true"
                              [data]="users | impersonableUsersFilter:userFilter | paginate:currentPage:itemsPerPage"
                              [count]="users | impersonableUsersFilterCount:userFilter"
                              [currentPage]="currentPage"
                              [itemsPerPage]="itemsPerPage"
                              (pageSizeChange)="pageSizeChanged($event)"
                              (pageChange)="gotoPage($event)">
            <storever-datatable-actions className="form-inline">
              <ng-template storeverDatatableActionsTemplate>
                <div class="form-group">
                  <label for="search" translate>SEARCH</label>
                  <input type="text" id="search" class="form-control" [(ngModel)]="userFilter">
                </div>
              </ng-template>
            </storever-datatable-actions>
            <storever-datatable-column [name]="''"
                                       [prop]="'uuid'">
              <ng-template let-value="value" storeverDatatableColumnCell>
                <button type="button" class="btn btn-info" (click)="impersonateUser(value)">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                  {{ 'IMPERSONATE' | translate:locale.language }}
                </button>
              </ng-template>
            </storever-datatable-column>
            <storever-datatable-column [name]="'NAME' | translate:locale.language"
                                       [prop]="'name'">
              <ng-template let-value="value" let-row="row" storeverDatatableColumnCell>
                <img class="avatar img-circle" [src]="row.uuid | avatar | async">
                {{value}}
              </ng-template>
            </storever-datatable-column>
            <storever-datatable-column [name]="'EMAIL' | translate:locale.language"
                                       [prop]="'email'"></storever-datatable-column>
            <storever-datatable-column [name]="'ROLES_GROUP' | translate:locale.language"
                                       [prop]="'groupRoleName'"></storever-datatable-column>
          </storever-datatable>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="close()" translate>{{ 'CANCEL' | translate:locale.language }}</button>
      </div>
    </div>
  </div>
</div>

import {Action} from '@ngrx/store';

import {type} from '../utilities/type';

export const DownloadMediasActionTypes = {
  DOWNLOAD: type('[Medias] Download'),
  DOWNLOAD_START: type('[Medias] Download Start'),
  DOWNLOAD_SUCCESS: type('[Medias] Download Success'),
  DOWNLOAD_VALIDATION_FAIL: type('[Medias] Download Validation Fail'),
  DOWNLOAD_FAIL: type('[Medias] Download Fail')
};

export class DownloadMediasAction implements Action {
  readonly type = DownloadMediasActionTypes.DOWNLOAD;

  constructor() {}
}

export class StartDownloadMediasAction implements Action {
  readonly type = DownloadMediasActionTypes.DOWNLOAD_START;

  constructor() {}
}

export class DownloadMediasSuccessAction implements Action {
  readonly type = DownloadMediasActionTypes.DOWNLOAD_SUCCESS;

  constructor(public payload?: string) {}
}

export class DownloadMediasValidationFailAction implements Action {
  readonly type = DownloadMediasActionTypes.DOWNLOAD_VALIDATION_FAIL;

  constructor() {}
}

export class DownloadMediasFailAction implements Action {
  readonly type = DownloadMediasActionTypes.DOWNLOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';

import {ExternalLink} from '../models/external-link';
import {type} from '../utilities/type';

export const ExternalLinksActionTypes = {
  LOAD: type('[External Links] Load'),
  LOAD_SUCCESS: type('[External Links] Load Success'),
  LOAD_FAIL: type('[External Links] Load Fail')
};

export class LoadExternalLinksAction implements Action {
  readonly type = ExternalLinksActionTypes.LOAD;

  // payload is the client id
  constructor(public payload?: string) {}
}

export class LoadExternalLinksSuccessAction implements Action {
  readonly type = ExternalLinksActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ExternalLink[]) {}
}

export class LoadExternalLinksFailAction implements Action {
  readonly type = ExternalLinksActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';

import {PublishForm} from '../models/publish';
import {type} from '../utilities/type';

export const AddPublishedActionTypes = {
  ADD: type('[Published] Add'),
  ADD_SUCCESS: type('[Published] Add Success'),
  ADD_VALIDATION_FAIL: type('[Published] Add Validation Fail'),
  ADD_FAIL: type('[Published] Add Fail')
};

export class AddPublishedAction implements Action {
  readonly type = AddPublishedActionTypes.ADD;

  constructor(public payload?: PublishForm) {}
}

export class AddPublishedSuccessAction implements Action {
  readonly type = AddPublishedActionTypes.ADD_SUCCESS;

  constructor(public payload?: number) {}
}

export class AddPublishedValidationFailAction implements Action {
  readonly type = AddPublishedActionTypes.ADD_VALIDATION_FAIL;

  constructor() {}
}

export class AddPublishedFailAction implements Action {
  readonly type = AddPublishedActionTypes.ADD_FAIL;

  constructor(public payload?: number) {}
}

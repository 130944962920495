import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LanguagesActionTypes, LoadLanguagesFailAction, LoadLanguagesSuccessAction} from '../actions/languages';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {Language} from '../models/language';
import {SendBackResult} from '../models/send-back-result';
import {LanguagesService} from '../services/languages.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class LanguagesEffect extends BaseEffect {
  load$  = createEffect(
    () => {  return this.actions$.pipe(ofType(LanguagesActionTypes.LOAD),
                             debug('Load languages action received;'),
                             switchMap(() => this.languagesService.getList().pipe(
                                         map((payload: SendBackResult<ArrayResponse<Language>>) => new LoadLanguagesSuccessAction(payload.data.array)),
                                         catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$  = createEffect(
    () => {  return this.actions$.pipe(ofType(LanguagesActionTypes.LOAD_FAIL),
                                 debug('Load languages fail action received.'),
                                 map(() => error(this.translation.translate('LANGUAGES_LOAD_ERROR'),
                                   this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions, translation: L10nTranslationService, private languagesService: LanguagesService, router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadLanguagesFailAction(response.status); }
}

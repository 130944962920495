<ng-select  #select
            [multiple]="true"
            [items]="CulturesArray"
            [readonly]="disabled"
            (change)="changeCultures($event)"
            placeholder="{{'NO_DATA_SELECTED' | translate:locale.language}}"
            class="scroll"
            id="ngSelect"
            [(ngModel)]="selectedCultures"
></ng-select>

<!--            [class.ngSelect]="required && !select?.active.length || invalid_cultures"-->

import {NavigationExtras, Params} from '@angular/router';
import {Action} from '@ngrx/store';

import {type} from '../utilities/type';

export const RouterActions = {
  GO: type('[Router] Go'),
  REPLACE: type('[Router] Replace'),
  BACK: type('[Router] Back'),
  FORWARD: type('[Router] Forward')
};

export class GoAction implements Action {
  readonly type = RouterActions.GO;
  constructor(public payload: { path: any[]; query?: Params; extras?: NavigationExtras; }) {}
}

export class ReplaceAction implements Action {
  readonly type = RouterActions.REPLACE;
  constructor(public payload: { path: any[]; query?: Params; extras?: NavigationExtras; }) {}
}

export class BackAction implements Action { readonly type = RouterActions.BACK; }

export class ForwardAction implements Action { readonly type = RouterActions.FORWARD; }

export function go(path: any[], query?: Params, extras?: NavigationExtras): GoAction {
  return new GoAction({ path, query, extras });
}

export function replace(path: any[], query?: Params, extras?: NavigationExtras): ReplaceAction {
  return new ReplaceAction({ path, query, extras });
}

export function back(): BackAction {
  return new BackAction();
}

export function forward(): ForwardAction {
  return new ForwardAction();
}

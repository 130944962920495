import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {L10nTranslationModule} from 'angular-l10n';

import {AuthRoutingModule} from './auth-routing.module';
import {CallbackComponent} from './pages/callback/callback.component';
import {LoginComponent} from './pages/login/login.component';
import {LostPasswordComponent} from './pages/lost-password/lost-password.component';

@NgModule({
  imports: [AuthRoutingModule, CommonModule, ReactiveFormsModule, L10nTranslationModule],
  declarations: [CallbackComponent, LoginComponent, LostPasswordComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthModule {
}

import {Component, Inject, OnInit} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';

import {Auth0AuthenticationService} from '../../../shared';

@Component({ selector: 'storever-login', templateUrl: './login.component.html', styleUrls: ['./login.component.scss'] })
export class LoginComponent implements OnInit {
  constructor(private authService: Auth0AuthenticationService, @Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit() {
    // sessionStorage.clear();
    this.authService.login();
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {LayoutType} from '../models/layout-type.enum';

import {AppSettingsService} from './app-settings.service';
import {BodyService} from './body.service';

@Injectable()
export class LayoutService {
  constructor(private appSettingsService: AppSettingsService, private bodyService: BodyService) {}

  setLayout(type: LayoutType): void {
    switch (type) {
      case LayoutType.Login:
        this.setLoginLayout();
        break;
      case LayoutType.Error:
        this.setErrorLayout();
        break;
      case LayoutType.Full:
        this.setFullLayout();
        break;
    }
  }

  toggleSidebar(): void { this.bodyService.toggleClass('page-sidebar-closed'); }

  private setLoginLayout(): void {
    this.bodyService.setClass('loaded login');
    this.getBrand().subscribe(brand => this.bodyService.addClass(brand));
  }

  private setErrorLayout(): void {
    this.bodyService.setClass('loaded page-404-3');
    this.getBrand().subscribe(brand => this.bodyService.addClass(brand));
  }

  private setFullLayout(): void {
    this.bodyService.setClass(`loaded page-header-fixed page-sidebar-closed-hide-logo page-container-bg-solid page-sidebar-fixed`);
    this.bodyService.setContentMinHeight();
    this.getBrand().subscribe(brand => this.bodyService.addClass(brand));
  }

  private getBrand(): Observable<string> { return this.appSettingsService.settings$.pipe(map(appSettings => appSettings.brand.id)); }
}

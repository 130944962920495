import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
  transform(value: string[], nameFilter1: [any]): string {
    if (value) {
      if (!value[0].length) {
        value = ['inv'];
      }
      if (nameFilter1) {
        let tab = '';
        for (const entry of nameFilter1) {
          for (const valueEntry of value) {
            if (entry.code === valueEntry) {
              tab = tab + ' ' + entry.name;
            } else if (valueEntry === 'inv') {
              tab = ' ' +
                    'Invariant Language';
            }
          }
        }
        if (tab) {
          return tab;
        }
      } else {
        const tab = value.join(', ');

        if (tab) {
          return tab;
        }
      }
    }
    return null;
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ArrayResponse} from '../models/array-response';
import {Client} from '../models/client';
import {SendBackResult} from '../models/send-back-result';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class ClientsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'ClientsService'; }

  protected get isAnonymous(): boolean { return false; }

  getMine(): Observable<SendBackResult<ArrayResponse<Client>>> { return this.get<ArrayResponse<Client>>(`/clients/mine`); }

  getOne(clientId: string): Observable<SendBackResult<Client>> { return this.get<Client>(`/clients/${clientId}`); }

  getRowSize(tableName: any): Observable<any> {
    const nameOfTable = tableName.payload;
    return this.get<any>(`/users/me/settings`).pipe(map(elem => { return elem.data.array.filter(data => { return data.name === nameOfTable; }) }));
  }

  setRowSize(action: any): Observable<any> {
    return this.post<any>(`/users/me/settings`, action.payload)
      .pipe(map(elem => { return elem.data.array.filter(data => { return data.name === action.payload.name; }) }));
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ErrorLayoutComponent} from '../shared';

import {AccessDeniedComponent} from './pages/access-denied/access-denied.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: ErrorLayoutComponent,
    children: [
      { path: 'access-denied', component: AccessDeniedComponent, data: { title: 'ERROR_ACCESS_DENIED_PAGE_TITLE' } },
      { path: 'not-found', component: NotFoundComponent, data: { title: 'ERROR_NOT_FOUND_PAGE_TITLE' } }
    ]
  },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({ imports: [RouterModule.forChild(routes)], exports: [RouterModule] })
export class ErrorRoutingModule {}

import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({ name: 'nl2br' })
export class Nl2brPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const str = this.sanitizer.sanitize(SecurityContext.HTML, value);
    return this.sanitizer.bypassSecurityTrustHtml(str.replace(/\n/g, '<br>'));
  }
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {TemplatesPrograms} from '../models/templates-programs';

export const TemplatesProgramsActionType = {
  LOAD: type('[Templates Programs] Load'),
  LOAD_SUCCESS: type('[Templates Programs] Load Success'),
  LOAD_FAIL: type('[Templates Programs] Load Server Error')
};

export class LoadTemplatesProgramsAction implements Action { readonly type = TemplatesProgramsActionType.LOAD; }

export class LoadTemplatesProgramsSuccessAction implements Action {
  readonly type = TemplatesProgramsActionType.LOAD_SUCCESS;

  constructor(public payload?: TemplatesPrograms) {}
}

export class LoadTemplatesProgramsFailAction implements Action {
  readonly type = TemplatesProgramsActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

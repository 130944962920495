import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {Subscriptions} from '..//models/subscriptions';

export const SubscriptionDetailActionTypes = {
  LOAD: type('[ Subscription] Load'),
  LOAD_SUCCESS: type('[ Subscription] Load Success'),
  LOAD_FAIL: type('[ Subscription] Load Fail')
};

export class LoadSubscriptionDetailAction implements Action {
  readonly type = SubscriptionDetailActionTypes.LOAD;
  constructor(public payload?: string) {}
}

export class LoadSubscriptionDetailSuccessAction implements Action {
  readonly type = SubscriptionDetailActionTypes.LOAD_SUCCESS;
  constructor(public payload?: Subscriptions) {}
}

export class LoadSubscriptionDetailFailAction implements Action {
  readonly type = SubscriptionDetailActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

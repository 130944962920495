import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {Campaign} from '../../shared/models/campaign';
import {CampaignDetailsActionType, LoadCampaignDetailsSuccessAction} from '../actions/campaign-details';
import {DeleteCampaignActionTypes} from '../actions/delete-campaign';
import {SaveCampaignActionTypes} from '../actions/save-campaign';

export interface CampaignDetailsState {
  value: Campaign;
  submitting: boolean;
}

const initialState: CampaignDetailsState = {
  value: undefined,
  submitting: false
};

export function CampaignDetailsReducer(state = initialState, action: Action): CampaignDetailsState {
  switch (action.type) {

    case CampaignDetailsActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CampaignDetailsActionType.LOAD_FAIL:
      return handleLoadFailAction(state);
    case DeleteCampaignActionTypes.DELETE_SUCCESS:
      return handleDeleteCampaignAction(state);
    case SaveCampaignActionTypes.SAVE_SUCCESS:
    case SaveCampaignActionTypes.SAVE_VALIDATION_FAIL:
    case SaveCampaignActionTypes.SAVE_NOT_FOUND:
    case SaveCampaignActionTypes.SAVE_FAIL:
      return handleEndSubmitActions(state);
    case SaveCampaignActionTypes.SAVE:
      return handleNewState(state, action);
    case CampaignDetailsActionType.ADD_MEDIA:
      return handleAddMedia(state, action);
    case CampaignDetailsActionType.REMOVE_MEDIA:
      return handleRemoveMedia(state, action);
    case CampaignDetailsActionType.ORDER_MEDIA:
      return handleOrderMedia(state, action);
    case CampaignDetailsActionType.ADD_SUBSCRIPTION:
      return handelAddSubscription(state, action);
    case CampaignDetailsActionType.REMOVE_SUBSCRIPTION:
      return handelRemoveSubscription(state, action);
    case SaveCampaignActionTypes.RESET_CAMPAIGN:
      return initialState;

    default:
      return state;
  }
}

function handleOrderMedia(state, action): CampaignDetailsState {
  const newState = _.clone(state);
  newState.value.media = action.payload;
  const res = Object.assign({}, state, newState);
  return res;
}

function handleNewState(state, action): CampaignDetailsState {
  if (action.payload.placing !== null) {
    const newValue = Object.assign({}, state.value, action.payload);
    const newCalue2 = Object.assign({}, { value: state.value, submitting: false }, { value: newValue, submitting: false });
    return newCalue2;
  }
  return state;
}
function handleLoadSuccessAction(state: CampaignDetailsState, action: LoadCampaignDetailsSuccessAction): CampaignDetailsState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}
function handleLoadFailAction(state: CampaignDetailsState): CampaignDetailsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}
function handleDeleteCampaignAction(state: CampaignDetailsState): CampaignDetailsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}
function handleEndSubmitActions(state: CampaignDetailsState): CampaignDetailsState {
  const newState = _.clone(state);
  newState.submitting = false;
  return newState;
}

function handleAddMedia(state, action) {
  const newState = _.clone(state);
  for (const item of action.payload.items) {
    newState.value.media.push(item);
  }
  return Object.assign({}, state, newState);
}
function handleRemoveMedia(state, action) {
  if (action.payload[0] === 0) {
    state.value.media = [];
  } else {
    for (const id of action.payload) {
      state.value.media = state.value.media.filter(data => data.id !== id);
    }
  }

  return state;
}

function handelAddSubscription(state, action) {
  console.log(state, action)
  const newState = _.clone(state);
  for (const item of action.payload.items) {
    newState.value.subscriptions.push(item);
  }
  return Object.assign({}, state, newState);
}
function handelRemoveSubscription(state, action) {
  for (const id of action.payload) {
    state.value.subscriptions = state.value.subscriptions.filter(data => data.id !== id);
  }
  return state;
}

export const CampaignDetailsSelectors = {
  value: (state: CampaignDetailsState) => _.get(state, 'value'),
  submitting: (state: CampaignDetailsState) => _.get(state, 'submitting', false)
};

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {Media} from '../../shared/models/media';

export const MediaToValidateListActionTypes = {
  LOAD: type('[Medias] to validate Load'),
  LOAD_SUCCESS: type('[Medias] to validate Load Success'),
  LOAD_FAIL: type('[Medias] to validate Load Fail'),
};

export class LoadMediaToValidateAction implements Action {
  readonly type = MediaToValidateListActionTypes.LOAD;
  constructor(public payload?: any) {}
}

export class LoadMediaToValidateSuccessAction implements Action {
  readonly type = MediaToValidateListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Media>) {}
}

export class LoadMediaToValidateFaildAction implements Action {
  readonly type = MediaToValidateListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CurrentClientActionTypes, LoadCurrentClientSuccessAction} from '../actions/current-client';
import {ChangeClientAction, LoadRolesSuccessAction, StartImpersonationSuccessAction, UserContextActionTypes} from '../actions/user-context';
import {Client} from '../models/client';
import {ImpersonateUser} from '../models/impersonate-user';
import {Role} from '../models/role';

export interface UserContextState {
  client: Client;
  roles: Role[];
  impersonate: ImpersonateUser;
}

const initialState: UserContextState = {
  client: undefined,
  roles: [],
  impersonate: undefined
};

export function userContextReducer(state = initialState, action: Action): UserContextState {
  switch (action.type) {
    case UserContextActionTypes.LOAD_ROLES_SUCCESS:
      return handleLoadRolesSuccessAction(state, action);
    case UserContextActionTypes.LOAD_ROLES_FAIL:
      return handleLoadRolesFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction(state, action);
    case UserContextActionTypes.START_IMPERSONATION_SUCCESS:
      return handleStartImpersonationSuccessAction(state, action);
    case UserContextActionTypes.START_IMPERSONATION_FAIL:
    case UserContextActionTypes.CLEAR_IMPERSONATION:
      return handleClearImpersonateAction(state);
    case CurrentClientActionTypes.LOAD_SUCCESS:
      return handleLoadCurrentClientDetailsAction(state, action);
    default:
      return state;
  }
}

function handleLoadRolesSuccessAction(state: UserContextState, action: LoadRolesSuccessAction): UserContextState {
  return { client: state.client, roles: action.payload, impersonate: state.impersonate };
}

function handleLoadRolesFailAction(state: UserContextState): UserContextState {
  return { client: state.client, roles: [], impersonate: state.impersonate };
}

function handleChangeClientAction(state: UserContextState, action: ChangeClientAction): UserContextState {
  return { client: { uuid: action.payload.clientId }, roles: state.roles, impersonate: state.impersonate };
}

function handleLoadCurrentClientDetailsAction(state: UserContextState, action: LoadCurrentClientSuccessAction): UserContextState {
  return { client: action.payload, roles: state.roles, impersonate: state.impersonate };
}

function handleStartImpersonationSuccessAction(state: UserContextState, action: StartImpersonationSuccessAction): UserContextState {
  return { client: state.client, roles: state.roles, impersonate: action.payload };
}

function handleClearImpersonateAction(state: UserContextState): UserContextState {
  return { client: state.client, roles: state.roles, impersonate: undefined };
}

export const userContextSelectors = {
  client: (state: UserContextState) => _.get(state, 'client'),
  roles: (state: UserContextState) => _.get(state, 'roles', []),
  impersonate: (state: UserContextState) => _.get(state, 'impersonate')
};

import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {Subscriptions} from '../../subscription/models/subscriptions';

export const SiteWithNoTemplateListActionTypes = {
  LOAD: type('[Subscriptions] no template Load'),
  LOAD_SUCCESS: type('[Subscriptions] no template Load Success'),
  LOAD_FAIL: type('[Subscriptions] no template Load Fail'),
};

export class SiteWithNoTemplateAction implements Action {
  readonly type = SiteWithNoTemplateListActionTypes.LOAD;
  constructor() {}
}

export class SiteWithNoTemplateSuccessAction implements Action {
  readonly type = SiteWithNoTemplateListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Subscriptions>) {}
}

export class SiteWithNoTemplateFaildAction implements Action {
  readonly type = SiteWithNoTemplateListActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

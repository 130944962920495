import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {LoadTemplatesProgramsSuccessAction, TemplatesProgramsActionType} from '../actions/templates-programs';
import {TemplatesPrograms} from '../models/templates-programs';

export interface TemplatesProgramsState {
  value: TemplatesPrograms;
}

const initialState: TemplatesProgramsState = {
  value: undefined,
};

export function templatesProgramsReducer(state = initialState, action: Action): TemplatesProgramsState {
  switch (action.type) {
    case TemplatesProgramsActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case TemplatesProgramsActionType.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: TemplatesProgramsState, action: LoadTemplatesProgramsSuccessAction): TemplatesProgramsState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleLoadFailAction(state: TemplatesProgramsState): TemplatesProgramsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}

function handleChangeClientAction(): TemplatesProgramsState {
  return initialState;
}

export const templatesProgramsSelectors = {
  value: (state: TemplatesProgramsState) => _.get(state, 'value'),
};

import {ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';

import * as fromRoot from '../../shared/reducers';

import {activityFeedReducer, activityFeedSelectors, ActivityFeedState} from './activity-feed';
import {campaignLastReducer, CampaignLastSelectors, CampaignLastState} from './campaign-list.reducer';
import {MediasToValidateSelectors, mediaToValidateReducer, MediaToValidateState} from './media-to-validate-list.reducer';
import {programNoPlanningReducer, ProgramNoPlanningSelectors, ProgramNoPlanningState} from './program-no-planning.reducer';
import {publicationFailedReducer, PublicationFailedSelectors, PublicationFailedState} from './publication-failed.reducer';
import {publicationLastReducer, PublicationLastSelectors, PublicationLastState} from './publication-last.reducer';
import {siteWithNoTemplateReducer, SiteWithNoTemplateState, SiteWithNoTemplatSelectors} from './site-with-no-template.reducer';

export interface AppState extends fromRoot.AppState {
  dashboard: DashboardState;
}
const getDashboardState = createFeatureSelector('dashboard');

export interface DashboardState {
  campainLast: CampaignLastState, publicationLast: PublicationLastState, publicationFailed: PublicationFailedState, mediaToValidate: MediaToValidateState,
    siteWithNoTemplate: SiteWithNoTemplateState, activityFeed: ActivityFeedState;
  programNoPlanning: ProgramNoPlanningState;
}

export const reducers: ActionReducerMap<DashboardState> = {
  campainLast: campaignLastReducer,
  publicationLast: publicationLastReducer,
  publicationFailed: publicationFailedReducer,
  mediaToValidate: mediaToValidateReducer,
  siteWithNoTemplate: siteWithNoTemplateReducer,
  activityFeed: activityFeedReducer,
  programNoPlanning: programNoPlanningReducer,
};

export const rootSelectors = {
  campainLast: createSelector(getDashboardState, (state: DashboardState) => {return state.campainLast}),
  publicationLast: createSelector(getDashboardState, (state: DashboardState) => {return state.publicationLast}),
  publicationFailed: createSelector(getDashboardState, (state: DashboardState) => {return state.publicationFailed}),
  mediaToValidate: createSelector(getDashboardState, (state: DashboardState) => {return state.mediaToValidate}),
  siteWithNoTemplate: createSelector(getDashboardState, (state: DashboardState) => {return state.siteWithNoTemplate}),
  activityFeed: createSelector(getDashboardState, (state: DashboardState) => {return state.activityFeed}),
  programNoPlanning: createSelector(getDashboardState, (state: DashboardState) => {return state.programNoPlanning}),
};

export interface Selectors {
  getCampaign: any;
  getCampaignCount: any;

  getPublicationLast: any;
  getPublicationLastCount: any;

  getPublicationFailed: any;
  getPublicationFailedCount: any;

  getMediaToValidate: any;
  getMediaToValidateCount: any;

  getSiteWithNoTemplate: any;
  getSiteWithNoTemplateCount: any;

  getActivityFeed: any;

  getProgramNoPlanning: any;
  getProgramNoPlanningCount: any;
}

export const selectors: Selectors = {
  getCampaign: createSelector(rootSelectors.campainLast, CampaignLastSelectors.array),
  getCampaignCount: createSelector(rootSelectors.campainLast, CampaignLastSelectors.count),

  getPublicationLast: createSelector(rootSelectors.publicationLast, PublicationLastSelectors.array),
  getPublicationLastCount: createSelector(rootSelectors.publicationLast, PublicationLastSelectors.count),

  getPublicationFailed: createSelector(rootSelectors.publicationFailed, PublicationFailedSelectors.array),
  getPublicationFailedCount: createSelector(rootSelectors.publicationFailed, PublicationFailedSelectors.count),

  getMediaToValidate: createSelector(rootSelectors.mediaToValidate, MediasToValidateSelectors.array),
  getMediaToValidateCount: createSelector(rootSelectors.mediaToValidate, MediasToValidateSelectors.count),

  getSiteWithNoTemplate: createSelector(rootSelectors.siteWithNoTemplate, SiteWithNoTemplatSelectors.array),
  getSiteWithNoTemplateCount: createSelector(rootSelectors.siteWithNoTemplate, SiteWithNoTemplatSelectors.count),

  getActivityFeed: createSelector(rootSelectors.activityFeed, activityFeedSelectors.array),

  getProgramNoPlanning: createSelector(rootSelectors.programNoPlanning, ProgramNoPlanningSelectors.array),
  getProgramNoPlanningCount: createSelector(rootSelectors.programNoPlanning, ProgramNoPlanningSelectors.count)

};

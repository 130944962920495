import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {ActionReducerMap, createSelector, MemoizedSelector} from '@ngrx/store';
import * as _ from 'lodash';

import {Auth0UserInfo} from '../models/auth0-user-info';
import {Client} from '../models/client';
import {DiscardableAlertMessage} from '../models/discardable-alert-message';
import {ExternalLink} from '../models/external-link';
import {GroupRole} from '../models/group-role';
import {ImpersonableUserVM} from '../models/impersonable-user.vm';
import {ImpersonateUser} from '../models/impersonate-user';
import {Language} from '../models/language';
import {Media} from '../models/media';
import {MediasFilterForm} from '../models/medias-filter-form';
import {Publish} from '../models/publish';
import {Role} from '../models/role';
import {RouterStateUrl} from '../models/router-state-url';
import {SelectedMedias} from '../models/selected-medias';
import {TagsList} from '../models/tags-list';
import {Timezone} from '../models/timezone';

import {alertsReducer, alertsSelectors, AlertsState} from './alerts';
import {campaignTagsReducer, campaignTagsSelectors, CampaignTagsState} from './campaign-tags';
import {externalLinksReducer, externalLinksSelectors, ExternalLinksState} from './external-links';
import {groupRolesReducer, groupRolesSelectors, GroupRolesState} from './group-roles';
import {impersonableUsersReducer, impersonableUsersSelectors, ImpersonableUsersState} from './impersonable-users';
import {languagesReducer, languagesSelectors, LanguagesState} from './languages';
import {layoutReducer, layoutSelectors, LayoutState} from './layout';
import {mediasListReducer, MediasListSelectors, MediasListState} from './medias-list';
import {myClientsReducer, myClientsSelectors, MyClientsState} from './my-clients';
import {playLogsReducer, playLogsSelectors, PlayLogsState} from './play-logs';
import {publishReducer, publishSelectors, PublishState} from './publish';
import {tagsReducer, tagsSelectors, TagsState} from './tags';
import {timezonesReducer, timezonesSelectors, TimezonesState} from './timezones';
import {userContextReducer, userContextSelectors, UserContextState} from './user-context';
import {userInfoReducer, userInfoSelectors, UserInfoState} from './user-info';
import {countersReducer, countersSelectors, CountersState} from './counters';

export interface AppState {
  router: RouterReducerState;
  layout: LayoutState;
  myClients: MyClientsState;
  userInfo: UserInfoState;
  userContext: UserContextState;
  impersonableUsers: ImpersonableUsersState;
  languages: LanguagesState;
  tags: TagsState;
  campaignTags: CampaignTagsState;
  timezones: TimezonesState;
  publish: PublishState;
  alerts: AlertsState;
  groupRoles: GroupRolesState;
  externalLinks: ExternalLinksState;
  medias: MediasListState;
  playLog: PlayLogsState;
  counters: CountersState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  layout: layoutReducer,
  myClients: myClientsReducer,
  userInfo: userInfoReducer,
  userContext: userContextReducer,
  impersonableUsers: impersonableUsersReducer,
  languages: languagesReducer,
  tags: tagsReducer,
  campaignTags: campaignTagsReducer,
  timezones: timezonesReducer,
  publish: publishReducer,
  alerts: alertsReducer,
  groupRoles: groupRolesReducer,
  externalLinks: externalLinksReducer,
  medias: mediasListReducer,

  playLog: playLogsReducer,
  counters: countersReducer,
};

const rootSelectors = {
  router: (state: AppState) => _.get(state, 'router'),
  layout: (state: AppState) => _.get(state, 'layout'),
  myClients: (state: AppState) => _.get(state, 'myClients'),
  userInfo: (state: AppState) => _.get(state, 'userInfo'),
  userContext: (state: AppState) => _.get(state, 'userContext'),
  impersonableUsers: (state: AppState) => _.get(state, 'impersonableUsers'),
  languages: (state: AppState) => _.get(state, 'languages'),
  tags: (state: AppState) => _.get(state, 'tags'),
  campaignTags: (state: AppState) => _.get(state, 'campaignTags'),
  timezones: (state: AppState) => _.get(state, 'timezones'),
  publish: (state: AppState) => _.get(state, 'publish'),
  alerts: (state: AppState) => _.get(state, 'alerts'),
  groupRoles: (state: AppState) => _.get(state, 'groupRoles'),
  externalLinks: (state: AppState) => _.get(state, 'externalLinks'),
  medias: (state: AppState) => _.get(state, 'medias'),
  playLog: (state: AppState) => _.get(state, 'playing'),
  counters: (state: AppState) => _.get(state, 'counters')
};

export interface Selectors {
  getRouterState: any;
  getNavigationId: any;
  getSidebarClosed: any;
  getLanguage: any;
  getMyClients: any;
  getUserInfo: any;
  getCurrentClient: any;
  getMyRoles: any;
  getImpersonateUser: any;
  getImpersonableUsers: any;
  getLanguages: any;
  getTags: any;
  getCampaignTags: any;
  getTimezones: any;
  getPublish: any;
  getAlerts: any;
  getGroupRoles: any;
  getExternalLinks: any;

  getShowFilter: any;
  getQuickSearch: any;
  getFilter: any;
  getPage: any;
  getPageSize: any;
  getOrderBy: any;
  getMediaCount: any;
  getMedia: any;
  getDownloading: any;
  getSelectedMedias: any;
  getSelectedTagsList: any;
  getPlayLogsPlaying: any;
  getCounters: any;
}

export const selectors: Selectors = {
  getRouterState: createSelector(rootSelectors.router, (router: RouterReducerState) => _.get(router, 'state')),
  getNavigationId: createSelector(rootSelectors.router, (router: RouterReducerState) => _.get(router, 'navigationId')),
  getSidebarClosed: createSelector(rootSelectors.layout, layoutSelectors.sidebarClosed),
  getLanguage: createSelector(rootSelectors.layout, layoutSelectors.language),
  getMyClients: createSelector(rootSelectors.myClients, myClientsSelectors.array),
  getUserInfo: createSelector(rootSelectors.userInfo, userInfoSelectors.value),
  getCurrentClient: createSelector(rootSelectors.userContext, userContextSelectors.client),
  getMyRoles: createSelector(rootSelectors.userContext, userContextSelectors.roles),
  getImpersonateUser: createSelector(rootSelectors.userContext, userContextSelectors.impersonate),
  getImpersonableUsers: createSelector(rootSelectors.impersonableUsers, impersonableUsersSelectors.array),
  getLanguages: createSelector(rootSelectors.languages, languagesSelectors.array),
  getTags: createSelector(rootSelectors.tags, tagsSelectors.array),
  getCampaignTags: createSelector(rootSelectors.campaignTags, campaignTagsSelectors.array),
  getTimezones: createSelector(rootSelectors.timezones, timezonesSelectors.array),
  getPublish: createSelector(rootSelectors.publish, publishSelectors.publish),
  getAlerts: createSelector(rootSelectors.alerts, alertsSelectors.array),
  getGroupRoles: createSelector(rootSelectors.groupRoles, groupRolesSelectors.array),
  getExternalLinks: createSelector(rootSelectors.externalLinks, externalLinksSelectors.array),
  getShowFilter: createSelector(rootSelectors.medias, MediasListSelectors.showFilter),
  getQuickSearch: createSelector(rootSelectors.medias, MediasListSelectors.quickSearch),
  getFilter: createSelector(rootSelectors.medias, MediasListSelectors.filter),
  getPage: createSelector(rootSelectors.medias, MediasListSelectors.page),
  getPageSize: createSelector(rootSelectors.medias, MediasListSelectors.pageSize),
  getOrderBy: createSelector(rootSelectors.medias, MediasListSelectors.orderBy),
  getMedia: createSelector(rootSelectors.medias, MediasListSelectors.array),
  getMediaCount: createSelector(rootSelectors.medias, MediasListSelectors.count),
  getDownloading: createSelector(rootSelectors.medias, MediasListSelectors.downloading),
  getPlayLogsPlaying: createSelector(rootSelectors.playLog, playLogsSelectors.playing),
  getSelectedMedias: createSelector(rootSelectors.medias, MediasListSelectors.selectedMedias),
  getSelectedTagsList: createSelector(rootSelectors.medias, MediasListSelectors.selectedTags),
  getCounters: createSelector(rootSelectors.counters, countersSelectors.value),

};

<div class="portlet box brand">
  <div class="portlet-title">
    <div class="caption">
      <i class="fa fa-search" aria-hidden="true"></i>
      {{ 'MEDIAS_LIST_FILTER' | translate:locale.language }}
    </div>
    <div *ngIf="!showSearchForm" [@fade]="!showSearchForm" class="quick-search">
      <form [formGroup]="quickSearchForm" novalidate>
        <div class="btn-group">
          <input type="text" class="form-control" id="quickSearchFormNameMedia" formControlName="name"
                 [placeholder]="'MEDIAS_LIST_QUICK_SEARCH' | translate:locale.language">
          <span *ngIf="showResetQuickSearch()" [@fade]="showResetQuickSearch()"
                class="fa fa-times clear-search" (click)="resetQuickSearch($event)"></span>
        </div>
      </form>
    </div>
    <div class="tools">
      <a href="#" [title]="'EXPANDER_TITLE' | translate:locale.language"
         (click)="toggleSearchForm($event)" [class.collapse]="showSearchForm" [class.expand]="!showSearchForm"> </a>
    </div>
  </div>
  <div class="portlet-body form" *ngIf="showSearchForm" [@slide]="showSearchForm">
    <form class="form-horizontal" [formGroup]="searchForm" (submit)="applyFilter(searchForm.value)" novalidate>
      <div class="form-body">

        <div class="row form-group">
          <div class="">
            <label for="mediaName" class="col-sm-2 control-label" translate>MEDIAS_LIST_FILTER_NAME</label>
            <div class="col-sm-4">
              <input id="mediaName" type="text" class="form-control" id="searchFormNameMedia" formControlName="name">
            </div>
          </div>
          <div class="" *ngIf="!simpleForm">
            <label for="mediaName" class="col-sm-1 control-label" translate>MEDIAS_LIST_FILTER_FILENAME</label>
            <div class="col-sm-4">
              <input id="mediaFileName" type="text" class="form-control" formControlName="filename">
            </div>
          </div>
          <div class="" *ngIf="simpleForm">
            <label class="col-sm-1 control-label" translate>MEDIAS_LIST_FILTER_TAGS</label>
            <div class="col-sm-4">
              <div>
                <storever-select-tags
                  #selectTags
                  [tagsList]="allowTagsList"
                  [injectTags]="injectTags"
                  (saveTags)="saveTags($event)">
                </storever-select-tags>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="">
            <label class="col-sm-2 control-label" translate>CULTURES</label>
              <div class="col-sm-4">
                <div>
                <!--<ng-select   #selectCultures-->
                             <!--[multiple]="true"-->
                            <!--[(items)]="lg"-->
                            <!--(selected)="selected($event)"-->
                            <!--(removed)="removedL($event)"-->
                            <!--placeholder="No language selected"-->
                            <!--[formControlName]="'cultures'">-->
                <!--</ng-select>-->
                  <storever-select-cultures
                    #selectCultures
                    [culturesList]="this.languages"
                    [required]="false"
                    (saveCultures)="saveCultures($event)">
                  </storever-select-cultures>
                </div>

              </div>
            </div>

          <div class="" *ngIf="!simpleForm">
            <label class="col-sm-1 control-label" translate>MEDIAS_LIST_FILTER_TAGS</label>
            <div class="col-sm-4">
              <div>
                <storever-select-tags
                  #selectTags
                  [tagsList]="tagsList"
                  [injectTags]="injectTags"
                  (saveTags)="saveTags($event)">
                </storever-select-tags>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group" *ngIf="!simpleForm">
          <div class="">
            <label for="mediaStartDate" class="col-sm-2 control-label" translate>MEDIAS_LIST_FILTER_STARTDATE</label>
            <div class="col-sm-4">
              <input id="mediaStartDate" type="text" class="form-control" #startDatePicker
                     [placeholder]="'DATE_PLACEHOLDER' | translate:locale.language"
                     formControlName="startDate"
                     [dateTimePicker]="startDateOptions"
                     [maxDate]="searchForm.get('endDate').valueChanges | async">
            </div>
            <span *ngIf="searchForm.get('startDate').dirty && searchForm.get('startDate').hasError('moment')" class="help-block error" translate>TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_MOMENT</span>
          </div>
          <div class="">
            <label for="mediaEndDate" class="col-sm-1 control-label" translate>MEDIAS_LIST_FILTER_ENDDATE</label>
            <div class="col-sm-4">
              <input id="mediaEndDate" type="text" class="form-control"
                     [placeholder]="'DATE_PLACEHOLDER' | translate:locale.language"
                     formControlName="endDate"
                     [dateTimePicker]="startDateOptions"
                     [minDate]="searchForm.get('startDate').valueChanges | async">
            </div>
            <span *ngIf="searchForm.get('endDate').dirty && searchForm.get('endDate').hasError('moment')" class="help-block error" translate>TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_MOMENT</span>
            <span *ngIf="searchForm.get('endDate').dirty && searchForm.get('endDate').hasError('isAfter')" class="help-block error" translate>TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_AFTER</span>

          </div>
        </div>
      </div>

        <div class="container-sub" *ngIf="!simpleForm">
          <div class="sub-elem">
            <div class="elem">
              <label translate>MEDIAS_LIST_FILTER_ARCHIVED</label>
              <div class="part">
                <p-triStateCheckbox formControlName="archived"></p-triStateCheckbox>
                <div>
                  <span class="label label-sm label-warning" *ngIf="searchForm.get('archived').value  === null || searchForm.get('archived').value  === undefined" translate>PAGE_SIZE_ALL</span>
                  <span class="label label-sm label-success" *ngIf="searchForm.get('archived').value &&  searchForm.get('archived').value.toString() ==='true'" translate>YES</span>
                  <span class="label label-sm label-default" *ngIf="!searchForm.get('archived').value && searchForm.get('archived').value  !== null" translate>NO</span>
                </div>
              </div>
            </div>
            <div class="elem">
              <label translate>MEDIAS_LIST_FILTER_VALIDATED</label>
              <div class="part">
                <p-triStateCheckbox formControlName="validated"></p-triStateCheckbox>
                <div>
                  <span class="label label-sm label-warning" *ngIf="searchForm.get('validated').value  === null || searchForm.get('validated').value  === undefined" translate>PAGE_SIZE_ALL</span>
                  <span class="label label-sm label-success" *ngIf="searchForm.get('validated').value &&  searchForm.get('validated').value.toString() ==='true'" translate>YES</span>
                  <span class="label label-sm label-default" *ngIf="!searchForm.get('validated').value && searchForm.get('validated').value  !== null" translate>NO</span>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-elem">
            <div class="elem">
              <label translate>MEDIAS_LIST_FILTER_ONLINE</label>
              <div class="part">
                <p-triStateCheckbox formControlName="isOnline"></p-triStateCheckbox>
                <div>
                  <span class="label label-sm label-warning" *ngIf="searchForm.get('isOnline').value  === null || searchForm.get('isOnline').value  === undefined"
                         translate>PAGE_SIZE_ALL</span>
                  <span class="label label-sm label-success" *ngIf="searchForm.get('isOnline').value &&  searchForm.get('isOnline').value.toString() ==='true'"
                        translate>Online</span>
                  <span class="label label-sm label-default" *ngIf="!searchForm.get('isOnline').value && searchForm.get('isOnline').value  !== null" translate>Offline</span>
                </div>
              </div>
            </div>
            <div class="elem">
              <label translate>MEDIAS_LIST_FILTER_SHARING</label>
              <div class="part">
                <p-triStateCheckbox formControlName="sharing"></p-triStateCheckbox>
                <div>
                  <span class="label label-sm label-warning" *ngIf="searchForm.get('sharing').value  === null || searchForm.get('sharing').value  === undefined"  translate>PAGE_SIZE_ALL</span>
                  <span class="label label-sm label-success" *ngIf="searchForm.get('sharing').value &&  searchForm.get('sharing').value.toString() ==='true'" translate>ADMIN</span>
                  <span class="label label-sm label-default" *ngIf="!searchForm.get('sharing').value && searchForm.get('sharing').value  !== null" translate>PUBLIC</span>
                </div>
              </div>
            </div>
          </div>
        </div>


      <div class="form-actions">
        <div class="row">
          <div class="col-sm-12 text-center">
            <button type="submit" class="btn btn-info"  (click)="applyFilter(searchForm.value)" translate>MEDIAS_LIST_FILTER_SEARCH</button>
            <button type="button" class="btn brand btn-outline" (click)="resetFilter($event)" translate>MEDIAS_LIST_FILTER_RESET</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>


import {Action} from '@ngrx/store';

import {type} from '../utilities/type';

export const UserPreferences = {
  SET_ROW_SIZE: type('[Preferences] Set row size'),
  GET_ROW_SIZE: type('[Preferences] Get row size'),
  GET_SET_ROW_FAILED_SIZE: type('[Preferences] Get Set row size failed')
};

export class SetRowSizeAction implements Action {
  readonly type = UserPreferences.SET_ROW_SIZE;
  constructor(public payload: { name: string, value: number }) {}
}
export class GetRowSizeAction implements Action {
  readonly type = UserPreferences.GET_ROW_SIZE;
  constructor(public payload: string) {}
}
export class GetSetRowSizeFailedAction implements Action {
  readonly type = UserPreferences.GET_SET_ROW_FAILED_SIZE;
  constructor(public payload?: number) {}
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {AlertsActionType, LoadAlertsFailAction, LoadAlertsSuccessAction} from '../actions/alerts';
import {error} from '../actions/toasts';
import {AlertMessage} from '../models/alert-message';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import * as fromRoot from '../reducers';
import {AlertsService} from '../services/alerts.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class AlertsEffect extends BaseEffect {
  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$  = createEffect(
    () => {  return  this.actions$.pipe(ofType(AlertsActionType.LOAD),
                             delayWhen(() => this.client$),
                             debug('Load current alert messages action received.'),
                             withLatestFrom(this.client$),
                             switchMap(([action, client]) => this.alertsService.getCurrentMessages(client.uuid)
                                                               .pipe(map((payload: SendBackResult<ArrayResponse<AlertMessage>>) => new LoadAlertsSuccessAction(
                                                                           _.map(payload.data.array, msg => ({...msg, discarded: false })))),
                                                                     catchError((res: Response) => this.catchResponseError(res)))))});

  loadServerError$  = createEffect(   () => {  return this.actions$.pipe(ofType(AlertsActionType.LOAD_FAIL),
                       debug('An error occurred while loading current alert messages.'),
                       map(() => error(this.translation.translate('CURRENT_ALERT_MESSAGES_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromRoot.AppState>,
              translation: L10nTranslationService,
              private alertsService: AlertsService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadAlertsFailAction(response.status); }
}

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';

import {Language} from '../../models/language';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({ selector: 'storever-select-cultures', templateUrl: './select-cultures.component.html', styleUrls: ['./select-cultures.component.scss'] })
export class SelectCulturesComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() edition: boolean;
  @Input() culturesList: Language[];
  @Input() disabled: Boolean;
  @Input() required: Boolean;
  @Input()  selectedItem: any;
  @Output() saveCultures = new EventEmitter<void>();
  @Input() injectCultures: Language[];
  @ViewChild('select') select: NgSelectComponent;
  selectCopy: Array<any>; // used to be see by parent
  public CulturesArray: Array<String> = [];
  public selectCulturesArray: Array<String> = [];
  public culturesTyped: String;
  invalid_cultures = false;
  selectedCultures: any;


  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private cdr: ChangeDetectorRef, private elRef: ElementRef) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.culturesList && changes.culturesList.currentValue && changes.culturesList.currentValue.length) {
      if (this.CulturesArray.length !== this.culturesList.length) {
        this.CulturesArray = this.objectToArrayString(this.culturesList);
      }
    }
    if (changes.injectCultures && changes.injectCultures.currentValue) {
     // this.select.active = changes.injectCultures.currentValue;
    }
    if (changes.selectedItem && changes.selectedItem.currentValue) {
      console.log( changes)
      this.selectedCultures = [];
      if ( typeof changes.selectedItem.currentValue === 'string') {
        this.culturesList.forEach(culturesName => {
          if (culturesName.name === changes.selectedItem.currentValue) {
            this.selectedCultures.push(culturesName.name);

          }
        });
        this.selectCulturesArray = this.selectedCultures;
      } else {
        changes.selectedItem.currentValue.forEach(cultureCode => {
          this.culturesList.forEach(culturesName => {
            if (culturesName.code === cultureCode) {
              this.selectedCultures.push(culturesName.name);

            }
          });
          this.selectCulturesArray = this.selectedCultures;
        });
      }

    }
  }
  ngOnInit() {}
  ngAfterViewChecked() {
    // this.selectCopy = this.select.active;
  }

  changeCultures($event): void {
    console.log($event)
    if ($event) {
    }
    this.selectCulturesArray = $event;
    this.submitForm($event);
  }


  submitForm($event) { this.saveCultures.emit($event); }

  private objectToArrayString(val: Language[]): Array<string> {
    const resp = [];
    if (val) {
      for (const entry of val) {
        resp.push(entry.name);
      }
    }
    return resp;
  }
  objectToArrayStringSave(langS: Array<string>): Array<any> {
    const langSub: Array<any> = [];
    if (langS) {
      for (const entry of langS) {
        const str = entry;
        for (const entry2 of this.culturesList) {
          if (entry2.name === str) {
            langSub.push(entry2.code);
          }
        }
      }
    }
    return langSub;
  }
  typedCultures(value: string): void { this.culturesTyped = value; }
  resetTypedCultures(): void {
    this.culturesTyped = '';
   // this.elRef.nativeElement.querySelectorAll('.ui-select-search')[0].value = '';
  }
  saveCulturesTyped(): void {
    if (this.culturesTyped && this.culturesTyped.length > 1) {
      const newValuecultureList: Language = <Language>{ name: this.culturesTyped };
      const newValueSelectedItem: any = <any>{ id: null, text: this.culturesTyped };
      this.culturesList.push(newValuecultureList);
      this.culturesList = _.uniqBy(this.culturesList, 'name');
      this.cdr.markForCheck();
      this.CulturesArray = this.objectToArrayString(this.culturesList);
      // this.select.itemObjects.push(newValueSelectedItem);
      // this.select.itemObjects = _.uniqBy(this.select.itemObjects, 'text');
      // this.select.active.push(newValueSelectedItem);
      // this.select.active = _.uniqBy(this.select.active, 'text');
      // this.submitForm();
      this.resetTypedCultures();
     // this.elRef.nativeElement.querySelectorAll('.ui-select-search')[0].value = '';
    }
  }
  convertCultures(): Array<String> {
    const culturesListArrayToSend = [];
    console.log(this.culturesList,  this.selectCulturesArray)
    this.selectCulturesArray.forEach(cultures => {
      this.culturesList.forEach(culturesName => {
        if (culturesName.name === cultures) {
          if (!culturesName && !culturesName.code && !culturesName.code.length) { // used for invariant culture empty send to server
            culturesListArrayToSend.push('');
          } else {
            culturesListArrayToSend.push(culturesName.code);
          }
        }
      });
    });
    return culturesListArrayToSend;
  }

  ArrayStringCultures(value, culturesList): Array<String> {
    const langS = [];
    if (value.cultures && culturesList) {
      for (const entry of value.cultures) {
        if (langS.indexOf(entry) === -1) {
          const str = entry;
          for (const entry2 of culturesList) {
            if (entry2.code === str) {
              langS.push(entry2.name);
            }
          }
        }
      }
    }
    return langS;
  }

  addErrorCulturesCSS(value: boolean): void {
    this.invalid_cultures = value;
    this.cdr.markForCheck();
  }
}

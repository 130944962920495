import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Activity} from '../../shared/models/activity';

export const ActivityFeedActionTypes = {
  LOAD: type('[Dashboard] Load Activity Feed'),
  LOAD_SUCCESS: type('[Dashboard] Load Activity Feed Success'),
  LOAD_FAIL: type('[Dashboard] Load Activity Feed Fail')
};

export class LoadActivityFeedAction implements Action {
  readonly type = ActivityFeedActionTypes.LOAD;
  constructor() {}
}

export class LoadActivityFeedSuccessAction implements Action {
  readonly type = ActivityFeedActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Activity>) {}
}

export class LoadActivityFeedFailAction implements Action {
  readonly type = ActivityFeedActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

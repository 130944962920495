import {Action} from '@ngrx/store';

import {type} from '../';
import {ArrayResponse} from '../models/array-response';
import {Campaign} from '../models/campaign';

export const CampaignSimpleListActionType = {
  LOAD: type('[Campaign Simple List] Load'),
  LOAD_SUCCESS: type('[Campaign Simple List] Load Success'),
  LOAD_FAIL: type('[Campaign Simple List] Load Server Error'),
  LOAD_NOT_FOUND: type('[Campaign Simple List] Load NOT FOUND')
};

export class LoadCampaignSimpleListAction implements Action {
  readonly type = CampaignSimpleListActionType.LOAD;

  constructor(public payload?: string) {}
}

export class LoadCampaignSimpleListSuccessAction implements Action {
  readonly type = CampaignSimpleListActionType.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Campaign>) {}
}
export class LoadCampaignSimpleListNotFoundAction implements Action {
  readonly type = CampaignSimpleListActionType.LOAD_NOT_FOUND;

  constructor(public payload?: Campaign) {}
}

export class LoadCampaignSimpleListFailAction implements Action {
  readonly type = CampaignSimpleListActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

import {localCn} from './locale-cn';
import {localEn} from './locale-en';
import {localEs} from './locale-es';
import {localFr} from './locale-fr';
import {localNl} from './locale-nl';

export const i18nAsset = {
  'en': localEn,
  'es': localEs,
  'fr': localFr,
  'nl': localNl,
  'cn': localCn,

}

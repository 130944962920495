import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {AppSettingsService, ArrayResponse, BaseRestService, BlockUIService, Campaign, SendBackResult} from '../../shared/index';
import {Media} from '../../shared/models/media';
import {AddMediaForm} from '../models/add-media';
import {MediaUpload} from '../models/media-upload';

@Injectable({ providedIn: 'root' })
export class MediaService extends BaseRestService {
  private subject = new Subject<any>();

  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'MediaService'; }
  protected get isAnonymous(): boolean { return false; }

  load(clientId: string, mediaId: string): Observable<SendBackResult<Media>> {
    return this.get<Media>(`/clients/${clientId}/audio/media/commercials/${mediaId}`);
  }

  add(clientId: string, data: AddMediaForm): Observable<SendBackResult<{}>> {
    const fileData = data[0];
    const idSendData = data[1];
    const formData = new FormData();
    formData.append('file', fileData);
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/?idsenddata=${idSendData}`, { formData });
  }
  uploadFile(clientId: string, data: MediaUpload, fileId: number): Observable<SendBackResult<{}>> {
    console.log('uploadFile')
    return this.upload<{}>(`/clients/${clientId}/audio/media/commercials/upload?idsenddata=${fileId}`, data);
  }

  update(clientId: string, mediaId: number, media: Media): Observable<SendBackResult<Media>> {
    return this.put<Media>(`/clients/${clientId}/audio/media/commercials/${mediaId}`, media);
  }

  remove(clientId: string, mediaId: number): Observable<any> { return this.delete(`/clients/${clientId}/audio/media/commercials/${mediaId}`); }

  archive(clientId: string, archive: object): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/audio/media/commercials/archive`, archive);
  }

  load_campaign(clientId: string, mediaId: any): Observable<SendBackResult<ArrayResponse<Campaign>>> {
    return this.get<ArrayResponse<Campaign>>(`/clients/${clientId}/Audio/Schedules/Commercials?mediaid=${mediaId.payload}`);
  }

  sendMessage(message: string) { this.subject.next({ text: message }); }

  getResponse(): Observable<any> { return this.subject.asObservable(); }

  clearMessage() { this.subject.next(); }

  getMessage(): Observable<any> { return this.subject.asObservable(); }
}

import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppSettingsService} from '../services/app-settings.service';

@Pipe({ name: 'avatar' })
export class AvatarPipe implements PipeTransform {
  constructor(private appSettingsService: AppSettingsService) {}

  transform(value: string): Observable<string> {
    return this.appSettingsService.settings$.pipe(map(appSettings => `${appSettings.api.url}${appSettings.api.avatarPath}/${value}`));
  }
}

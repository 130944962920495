import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';
import {Subscriptions} from '../../subscription/models/subscriptions';
import {Program} from '../../templates-programs/models/program';

export const ProgramNoPlaningActionTypes = {
  LOAD: type('[Program No Planing] Load'),
  LOAD_SUCCESS: type('[Program No Planing]  Load Success'),
  LOAD_FAIL: type('[Program No Planing] Load Fail'),
};

export class ProgramNoPlanningAction implements Action {
  readonly type = ProgramNoPlaningActionTypes.LOAD;
  constructor() {}
}

export class ProgramNoPlanningSuccessAction implements Action {
  readonly type = ProgramNoPlaningActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Program>) {}
}

export class ProgramNoPlanningFaildAction implements Action {
  readonly type = ProgramNoPlaningActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

  <div bsModal #archiveMediaModal="bs-modal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" translate>CONFIRMATION</h4>
      </div>
      <div class="modal-body">

        <div class="list-group-container">
          <div *ngIf="!archive" >

            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE1' | translate:locale.language }}
            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_ACCEPT' | translate:locale.language }}
            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE2' | translate:locale.language }}
          <strong>{{ selectedMedias.length}}</strong>
          {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE3' | translate:locale.language }}
        </div>

          <div *ngIf="archive" >

            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE1' | translate:locale.language }}
            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_UNACCEPT' | translate:locale.language }}
            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE2' | translate:locale.language }}
            <strong>{{ selectedMedias.length}}</strong>
            {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE3' | translate:locale.language }}
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info" (click)="accept()" *ngIf="!archive">

          {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_ACCEPT' | translate:locale.language }}
        </button>
        <button type="button" class="btn btn-info" (click)="Unaccept()" *ngIf="archive">

          {{ 'CONFIRM_ARCHIVE_MEDIAS_DIALOG_UNACCEPT' | translate:locale.language }}
        </button>
        <button type="button" class="btn btn-default" (click)="close()" translate>{{ 'CANCEL' | translate:locale.language }}</button>
      </div>
    </div>
  </div>
</div>

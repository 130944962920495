import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {ImpersonableUsersActionTypes, LoadImpersonableUsersSuccessAction} from '../actions/impersonable-users';
import {UserContextActionTypes} from '../actions/user-context';
import {ImpersonableUserVM} from '../models/impersonable-user.vm';

export interface ImpersonableUsersState {
  array: ImpersonableUserVM[];
}

const initialState: ImpersonableUsersState = {
  array: []
};

export function impersonableUsersReducer(state = initialState, action: Action): ImpersonableUsersState {
  switch (action.type) {
    case ImpersonableUsersActionTypes.LOAD_SUCCESS:
      return handlerLoadSuccessAction(state, action);
    case ImpersonableUsersActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handlerLoadSuccessAction(state: ImpersonableUsersState, action: LoadImpersonableUsersSuccessAction): ImpersonableUsersState {
  const newState: ImpersonableUsersState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: ImpersonableUsersState): ImpersonableUsersState {
  const newState: ImpersonableUsersState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): ImpersonableUsersState {
  return { array: [] };
}

export const impersonableUsersSelectors = {
  array: (state: ImpersonableUsersState) => _.get(state, 'array', [])
};

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CampaignSimpleListActionType, LoadCampaignSimpleListSuccessAction} from '../../shared/actions/campaign-simple-list';
import {Campaign} from '../../shared/models/campaign';
import {SelectedCampaign} from '../models/selected-campaign';

export interface CampaignState {
  array: Campaign[];
  SelectedCampaign: SelectedCampaign[];
}

const initialState: CampaignState = {
  array: [],
  SelectedCampaign: []
};

export function campaignReducer(state = initialState, action: Action): CampaignState {
  switch (action.type) {
    case CampaignSimpleListActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CampaignSimpleListActionType.LOAD_NOT_FOUND:
    case CampaignSimpleListActionType.LOAD_FAIL:
      return handleLoadErrorActions(state);

    default:
      return state;
  }
}

function handleLoadSuccessAction(state: CampaignState, action: LoadCampaignSimpleListSuccessAction): CampaignState {
  const newState: CampaignState = { array: action.payload.array, SelectedCampaign: state.SelectedCampaign };

  return newState;
}

function handleLoadErrorActions(state: CampaignState): CampaignState {
  const newState: CampaignState = { array: [], SelectedCampaign: state.SelectedCampaign };
  return newState;
}

export const CampaignSelectors = {
  array: (state: CampaignState) => _.get(state, 'array', []),
  SelectedCampaign: (state: CampaignState) => _.get(state, 'SelectedCampaign', [])
};

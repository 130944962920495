import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, filter, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {CurrentClientActionTypes, LoadCurrentClientAction, LoadCurrentClientFailAction, LoadCurrentClientSuccessAction} from '../actions/current-client';
import {error} from '../actions/toasts';
import {Client} from '../models/client';
import {SendBackResult} from '../models/send-back-result';
import * as fromRoot from '../reducers';
import {ClientsService} from '../services/clients.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class CurrentClientEffect extends BaseEffect {

  private client$ = this.store.select(fromRoot.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));

  load$ = createEffect(
    () => {  return this.actions$.pipe(
    ofType(CurrentClientActionTypes.LOAD),
    debug('Load current client info action received.'),
    switchMap((action: LoadCurrentClientAction) => this.clientsService.getOne(action.payload)
                                                     .pipe(map((payload: SendBackResult<Client>) => new LoadCurrentClientSuccessAction(payload.data)),
                                                           catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$ = createEffect(
    () => {  return this.actions$.pipe(ofType(CurrentClientActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while loading the current client details info.'),
                                 map(() => error(this.translation.translate('CURRENT_CLIENT_LOAD_ERROR'),
                                   this.translation.translate('TOAST_ERROR_TITLE'))))});
  //
  // setPreferencesRowSize$ = this.actions$.ofType(CurrentClientActionTypes.SET_PREFERENCES_ROW_SIZE)
  //                            .delayWhen(() => this.client$)
  //                            .debug('Group roles load action received.')
  //                            .withLatestFrom(this.client$)
  //                            .switchMap(([action, client]) => {
  //                              return new SetCurrentPreferencesRowSizeSuccessAction();
  //                                // return this.clientsService.setRowSize(client, action)
  //                            });

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private clientsService: ClientsService,
              router: Router,
              private store: Store<fromRoot.AppState>) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadCurrentClientFailAction(response.status); }
}

import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {AlertsActionType, DiscardAlertAction, LoadAlertsSuccessAction} from '../actions/alerts';
import {DiscardableAlertMessage} from '../models/discardable-alert-message';

export interface AlertsState {
  array: DiscardableAlertMessage[];
}

const initialState: AlertsState = {
  array: []
};
export function alertsReducer(state = initialState, action: Action): AlertsState {
  switch (action.type) {
    case AlertsActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case AlertsActionType.LOAD_FAIL:
      return handleLoadFailAction(state);
    case AlertsActionType.DISCARD:
      return handleDiscardAction(state, action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: AlertsState, action: LoadAlertsSuccessAction): AlertsState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: AlertsState): AlertsState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleDiscardAction(state: AlertsState, action: DiscardAlertAction): AlertsState {
  const newState: AlertsState = { array: state.array.map(msg => (msg.id === action.payload ? {...msg, discarded: true } : msg)) };
  return newState;
}

export const alertsSelectors = {
  array: (state: AlertsState) => _.get(state, 'array', [])
};

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ArrayResponse} from '../models/array-response';
import {ImpersonateUser} from '../models/impersonate-user';
import {Role} from '../models/role';
import {SendBackResult} from '../models/send-back-result';
import {SimpleValue} from '../models/simple-value';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';
import {BlockUIService} from './block-ui.service';

@Injectable()
export class UserContextService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService, blockUIService: BlockUIService) { super(http, appSettingsService, blockUIService); }

  protected get className(): string { return 'UserContextService'; }

  protected get isAnonymous(): boolean { return false; }

  loadRoles(clientId: string): Observable<SendBackResult<ArrayResponse<Role>>> { return this.get<ArrayResponse<Role>>(`/clients/${clientId}/roles`); }

  createImpersonationToken(clientId: string, user: ImpersonateUser, timeout: number): Observable<ImpersonateUser> {
    const body = { uuid: user.uuid, timeout };
    return this.post<SimpleValue<string>>(`/clients/${clientId}/users/impersonate`, body)
      .pipe(map(res => Object.assign({}, user, { token: res.data.value, expires: moment().add(moment.duration(timeout, 'minutes')).valueOf() })));
  }
}

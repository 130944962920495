/* tslint:disable */
export const localEs = {
  'de': 'Alemán',
  'en': 'Ingles',
  'es': 'Español',
  'fr': 'Frances',
  'it': 'Italiano',
  'nl': 'Holandés',
  'cn': 'China',
  'LANG_DE': 'Alemán',
  'LANG_EN': 'Ingles',
  'LANG_ES': 'Español',
  'LANG_FR': 'Frances',
  'LANG_IT': 'Italiano',
  'LANG_NL': 'Holandés',
  'LANG_CN': 'China',
  'IMPERSONATE_USER': 'Reproducir como',
  'LEAVE_IMPERSONATION': 'Cancelar Reproducir como',
  'LOGOUT': 'Salir',
  'RUN_AS': 'como',
  'CLOSE': 'Cerrar',
  'OPEN': 'Abrir',
  'INTRO': 'Intro',
  'EXTRO': 'Extro',
  'IMPERSONATE_DIALOG': 'Suplantar el diálogo de lista de usuarios',
  'IMPERSONATE_DIALOG_TITLE': 'Reproducir como',
  'IMPERSONATE_DIALOG_EXPLAIN_P1': 'Con esta opción puedes navegar AD Manager como si fuese otro usuario. Esta opción es solo para administradores',
  'IMPERSONATE_DIALOG_EXPLAIN_P2':
    'Cualquier acción hecha en "personificar" será registrada con tu identidad. La "personificación" es solo una simulación de lo que ese usuario ve.',
  'SHOW_X_ELEMENTS_START': 'Mostrar',
  'SHOW_X_ELEMENTS_END': 'elementos',
  'SEARCH': 'Buscar',
  'NAME': 'Nombre',
  'EMAIL': 'Correo',
  'ROLES_GROUP': 'Rol de grupo',
  'IMPERSONATE': 'Reproducir como',
  'CANCEL': 'Cancelar',
  'DATE': 'Fecha',
  'PAGINATION_INFO': 'Mostrar{{from}} de {{to}} a {{filteredLength}} elementos',
  'CLIENT_SELECTOR_PLACEHOLDER': 'Cambiar Cliente…',
  'DASHBOARD': 'Panel de Control',
  'MY_DASHBOARD': 'Mi Panel de Control',
  'MY_DASHBOARD_PLACEHOLDER_NAME': 'Buscar por Nombre',
  'MY_DASHBOARD_ROW_NAME': 'Nombre',
  'MY_DASHBOARD_ROW_MEDIA_VALIDATE': 'Validar Archivo',
  'MY_DASHBOARD_ROW_FAILED_PUBLICATION': 'Error en la Publicación',
  'MY_DASHBOARD_ROW_SUBSCRIPTION_TO_PUBLISH': 'Suscripción a publicar',
  'MY_DASHBOARD_ROW_MUSIC_PROGRAM_TO_PUBLISH': 'Programa de Música a publicar',
  'MY_DASHBOARD_ROW_SUBSCRIPTION_WITHOUT_TEMPLATE': 'Suscripción sin plantilla',
  'MY_DASHBOARD_ROW_PROGRAM_OUTDATED': 'Programa caducado o pronto',
  'MY_DASHBOARD_ROW_PUBLICATION_IN_PROGRESS': 'Publicación en progreso',
  'TOAST_ERROR_TITLE': 'Error',
  'TOAST_SUCCESS_TITLE': 'Éxito',
  'TOAST_INFO_TITLE': 'Información',
  'TOAST_WARNING_TITLE': 'Alerta',
  'GROUP_ROLES_LOAD_ERROR': 'Ha ocurrido un error al capturar los roles de grupo para el cliente actual.',
  'LANGUAGES_LOAD_ERROR': 'Ha ocurrido un error al cargar los idiomas disponibles',
  'MY_CLIENTS_LOAD_ERROR': 'No es posible cargar la lista de clientes a la que has accedido',
  'GET_SET_ROW_FAILED_SIZE': 'No es posible cargar las preferencias de usuario',
  'TIMEZONES_LOAD_ERROR': 'Ha ocurrido un error al cargar las zonas horarias disponibles.',
  'USER_CONTEXT_IMPERSONATE_ERROR': 'Ha ocurrido un error al tratar de reproducir como {{username}}.',
  'USER_CONTEXT_GET_ROLES_ERROR': 'Imposible recuperar sus roles para el cliente  "{{clientName}}". Por favor contacte a su administrador.',
  'AUTH_GET_USER_INFO_ERROR': 'Ha ocurrido un error al intentar recuperar su información de usuario del proveedor de autentificación.',
  'ERROR_PAGE_NOT_FOUND': '404',
  'ERROR_PAGE_NOT_FOUND_TITLE': 'Houston, tenemos un problema.',
  'ERROR_PAGE_NOT_FOUND_PARA': 'La pagina que buscas no existe.',
  'ERROR_PAGE_RETURN_HOME': 'Volver al Inicio',
  'ERROR_PAGE_ACCESS_DENIED': '403',
  'ERROR_PAGE_ACCESS_DENIED_TITLE': 'Houston, tenemos un problema.',
  'ERROR_PAGE_ACCESS_DENIED_PARA': 'No tienes acceso a esta pagina.',
  'AUTH_LOGIN_GENERIC_ERROR': 'Ha ocurrido un error al tratar de conectarte. Por favor contacte a su administrador.',
  'AUTH_LOGIN_WRONG_CREDENTIALS': 'Error en el correo electrónico o contraseña',
  'AUTH_LOGIN_BLOCKED': 'Tu cuenta ha sido bloqueada. Por favor contacte a su administrador.',
  'AUTH_LOGIN_LEAKED':
    'Este inicio de sesión se ha bloqueado porque su contraseña se ha filtrado en otro sitio web. Por favor, póngase en contacto con su administrador.',
  'AUTH_LOGIN_SOCIAL_FIRST': 'No es posible registrarse con un cuenta social que no este enlazada a una cuanta local existente.',
  'AUTH_LOGIN_ACCESS_DENIED': 'Acceso  denegado. Por favor contacte a su administrador.',
  'AUTH_LOGIN_INVALID_TOKEN': 'Identificador no válido:',
  'AUTH_LOGIN_AUTO_TITLE': 'Por favor espera...',
  'AUTH_LOST_PASSWORD_TITLE': '¿Olvidaste tu contraseña?',
  'AUTH_LOST_PASSWORD_PARA': 'Introduce tu correo para recuperar tu contraseña.',
  'AUTH_LOST_PASSWORD_ERROR': 'Ha ocurrido un error al enviar tu solicitud.',
  'AUTH_LOST_PASSWORD_SUCCESS': 'Se ha enviado un email con un enlace para recuperar tu contraseña.',
  'AUTH_GOTO_LOGIN_PAGE': ' Has Click aquí para ir a iniciar sesión',
  'AUTH_LOST_PASSWORD_EMAIL': 'Correo',
  'AUTH_LOST_PASSWORD_EMAIL_REQUIRED': 'Se requiere de un correo',
  'AUTH_LOST_PASSWORD_EMAIL_INVALID': 'El correo no es valido',
  'AUTH_LOST_PASSWORD_BACK': 'Atrás',
  'AUTH_LOST_PASSWORD_SUBMIT': 'Enviar',
  'AUTH_LOST_PASSWORD_SUBMITTING': 'Enviando...',
  'PAGE_SIZE_ALL': 'Todos',
  'SOCIAL_LOGIN_INTRO': 'O regístrate con',
  'LOGIN_TITLE': 'Iniciar sesión',
  'CURRENT_ALERT_MESSAGES_ERROR': 'Ha ocurrido un error al cargar las alertas de mensajes.',
  'EXTERNAL_APPS': 'Más Apps',
  'SITE_TITLE': 'AD Manager',
  'AUTH_LOGIN_PAGE_TITLE': 'AD Manager | Iniciar Sesión',
  'AUTH_LOST_PASSWORD_PAGE_TITLE': 'AD Manager | Contraseña olvidada',
  'DASHBOARD_PAGE_TITLE': 'AD Manager | Panel de Control',
  'ERROR_ACCESS_DENIED_PAGE_TITLE': 'AD Manager | Acceso Denegado',
  'ERROR_NOT_FOUND_PAGE_TITLE': 'AD Manager | No Encontrado',
  'MEDIAS_LIST_COLUMN_NAME': 'Nombre',
  'MEDIAS_LIST_COLUMN_DURATION': 'Validez',
  'MEDIAS_LIST_COLUMN_TAG': 'Etiqueta',
  'MEDIAS_LIST_COLUMN_CREATED': 'Creado',
  'MEDIAS_LIST_COLUMN_UPDATE': 'Actualizado',
  'MEDIAS_LIST_COLUMN_FILENAME': 'Nombre del Archivo',
  'MEDIAS_LIST_TITLE': 'Archivos',
  'MEDIAS_LIST_CREATE': 'Creado',
  'MEDIAS_LIST_IMPORT': 'Importar',
  'MEDIAS_LIST_SELECT_PAGE': 'Seleccionar pagina',
  'MEDIAS_LIST_UNSELECT_PAGE': 'Anular seleccionar pagina',
  'MEDIAS_LIST_ACTIONS': 'Acciones',
  'MEDIAS_LIST_COLUMN_STATUS': 'Estado',
  'MEDIA_ARCHIVED': 'Guardado',
  'MEDIA_NOT_ARCHIVED': 'No guardado',
  'MEDIA_SHARING_PRIVATE': 'Privado',
  'MEDIA_SHARING_PUBLIC': 'Público',
  'MEDIA_SHARING_USER_SHARING': 'Compartir con usuarios',
  'MEDIA_SHARING_ADMIN': 'Admin',
  'EDIT_MEDIA_NAME': 'Nombre',
  'EDIT_MEDIA_NAME_REQUIRED': 'Se requiere nombre',
  'EDIT_MEDIA_LOCK': 'Bloquear archivo',
  'EDIT_MEDIA_LOCKED': 'Bloquear',
  'EDIT_MEDIA_UNLOCKED': 'Desbloquear',
  'EDIT_MEDIA_SHARING': 'Compartido',
  'EDIT_MEDIA_ARCHIVE': 'Archivo',
  'EDIT_MEDIA_DURATION': 'Validez',
  'EDIT_MEDIA_FILENAME': 'Nombre del Archivo',
  'EDIT_MEDIA_COMMENTS': 'Comentario',
  'EDIT_MEDIA_CREATE': 'Creado',
  'EDIT_MEDIA_CREATEBY': 'Creado por',
  'EDIT_MEDIA_LANGUAGE_REQUIRED': 'Idioma requerido',
  'EDIT_MEDIA_LANGUAGE_INVARIANT_AND_OTHER_NOT_PERMITTED': 'Otros idiomas e Invariantes no permitidos',
  'EDIT_MEDIA_BY': 'por',
  'EDIT_MEDIA_UPDATED': 'Actualizado',
  'EDIT_MEDIA_UPDATEDBY': 'Actualizado por',
  'EDIT_MEDIA_TO_TIME_LESS_THAN_FROM': 'El Tiempo necesita ser mayor a',
  'EDIT_MEDIA_TIME_REQUIRED': 'Tiempo requerido',
  'MEDIAS_LIST_ADD': 'Añadir',
  'ADD_MEDIA_DIALOG_TITLE': 'Archivo CLOUD',
  'ADD_MEDIA_DIALOG_TITLE2': 'Sube tus archivos aquí',
  'ADD_MEDIA_DIALOG_DROPPED_FILES': 'Archivos descargados',
  'EDIT_MEDIA_TITLE': 'Información del Archivo',
  'MEDIA_EDIT': 'Información general del Archivo',
  'MEDIA_EDIT_CAMPAIGN_LINKS': 'Información de Campaña',
  'MEDIA_EDIT_BLACKLIST': 'Suscripción en Lista Negra',
  'MEDIA_EDIT_BLACKLIST_BY': 'Lista Negra por',
  'EDIT_MEDIA_TAGS': 'Etiquetas',
  'SAVE_CHANGES': 'Guardar',
  'MEDIA__DELETE': 'Borrar',
  'CAMPAIGNS_LIST_COLUMN_START': 'Fecha de inicio',
  'CAMPAIGNS_LIST_COLUMN_END': 'Creado',
  'CAMPAIGNS_LIST_FILTER_COVERS_STARTDATE': 'Fecha de inicio',
  'CAMPAIGNS_LIST_FILTER_COVERS_ENDDATE': 'Fecha de finalización',

  'CAMPAIGNS_LIST_TITLE': 'Campañas',
  'CAMPAIGNS_NEW_LIST_FILTER_STARTDATE': 'Creado desde',
  'CAMPAIGNS_NEW_LIST_FILTER_ENDDATE': 'Actualizado para',
  'CAMPAIGNS_TYPE_FLOATING': 'Flotante',
  'CAMPAIGNS_TYPE_JUST_IN_TIME': 'Justo a tiempo',
  'CAMPAIGNS_TYPE_JINGLE': 'Jingle',
  'CAMPAIGNS_TYPE_CLOSE_MESSAGE_OPENING': 'Abrir mensaje',
  'CAMPAIGNS_TYPE_CLOSE_MESSAGE_CLOSE': 'Cerrar mensaje',

  'Floating': 'Flotante',
  'JustInTime': 'Justo a tiempo',
  'CloseMessage': 'Cerrar mensaje',
  'Jingle': 'Jingle',

  'CAMPAIGNS_ORDER_ASCENDING': 'Ascendente',
  'CAMPAIGNS_ORDER_RANDOM': 'Aleatorio',
  'EDIT_CAMPAIGN_START_DATE': 'Fecha de inicio',
  'EDIT_CAMPAIGN_END_DATE': 'Creado para',
  'EDIT_CAMPAIGN_START_TIME': 'Desde',
  'EDIT_CAMPAIGN_END_TIME': 'Para',
  'EDIT_CAMPAIGN_AT_TIME': 'A',
  'EDIT_CAMPAIGN_SINGLE': 'Single',
  'EDIT_CAMPAIGN_ALL': 'Todos',
  'EDIT_CAMPAIGN_MONDAY': 'Lunes',
  'EDIT_CAMPAIGN_TUESDAY': 'Martes',
  'EDIT_CAMPAIGN_Wednesday': 'Miércoles',
  'EDIT_CAMPAIGN_Thursday': 'Jueves',
  'EDIT_CAMPAIGN_Friday': 'Viernes',
  'EDIT_CAMPAIGN_Saturday': 'Sábado',
  'EDIT_CAMPAIGN_Sunday': 'Domingo',
  'EDIT_CAMPAIGN_JINGLE_INTRO_EXTRO': 'intro / extro',
  'EDIT_CAMPAIGN_CLOSING_MESSAGE_OPEN_CLOSE': 'abrir / cerrar',
  'CREATE_CAMPAIGN_DIALOG': '',
  'ADD_CAMPAIGN_DIALOG_TITLE': 'Crear nueva campaña',
  'ADD_CAMPAIGN_NAME': 'Nombre',
  'ADD_CAMPAIGN_NAME_REQUIRED': 'El nombre es requerido',
  'ADD_CAMPAIGN_TYPE': 'Tipo',
  'ADD_CAMPAIGN_TYPE_REQUIRED': 'El tipo de campaña es requerido',
  'EDIT_CAMPAIGN_TITLE': 'Campaña',
  'CAMPAIGN_EDIT': 'Editar Campaña',
  'EDIT_CAMPAIGN_MEDIAS': 'Archivos',
  'EDIT_CAMPAIGN_SUBSCRIPTIONS': 'Suscripción',
  'CAMPAIGN_MEDIAS_ASSIGNED': 'Archivos asignados',
  'CAMPAIGN_MEDIA_ASSIGNED': 'Archivo asignado',
  'CAMPAIGN_MEDIAS_ASSIGNED_TEXT': 'Arrastrar Archivo para asignarlo a la campaña',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE': 'Asignar todos los archivos',
  'CAMPAIGN_MEDIAS_ASSIGNED_EMPTY': 'La campaña no tiene acceso a ningún archivo',

  'CAMPAIGN_MEDIAS_UNASSIGNED_FILTER': 'eliminar archivos',
  'CAMPAIGN_MEDIAS_ASSIGNED_FILTER': 'archivos asignados',

  'CAMPAIGN_MEDIAS_UNASSIGNED_TEXT': 'Arrastrar archivos para eliminarlos de la campaña.',
  'CAMPAIGN_MEDIA_UNASSIGNED': 'Eliminar Archivo',
  'CAMPAIGN_MEDIAS_UNASSIGNED': 'Eliminar Archivos',
  'CAMPAIGN_MEDIAS_UNASSIGNED_EMPTY': 'La campaña contiene todos los archivos',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOGGLE': 'Eliminar Archivos',
  'CAMPAIGN_MEDIA_ASSIGNED_TOGGLE': 'Eliminar Archivo',
  'CAMPAIGN_SUBSCRIPTION_ASSIGNED': 'Suscripción asignada',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED': 'Suscripciones asignadas',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TEXT': 'Arrastrar Player para asignarlo a la campaña',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TOGGLE': 'asignar todas las suscripciones',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_EMPTY': 'La campaña no tiene acceso a ninguna suscripción',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_FILTER': 'Eliminar suscripción',
  'CAMPAIGN_SUBSCRIPTION_UNASSIGNED': 'Eliminar suscripción',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED': 'Eliminar suscripciones',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_FILTER': 'Filtrar por suscripciones asignadas',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_TEXT': 'Arrastrar Player para eliminarlo de la campaña.',
  'CAMPAIGN_SUBSCRIPTIONS_UNASSIGNED_EMPTY': 'La campaña contiene todas las suscripciones',
  'CAMPAIGN_SUBSCRIPTIONS_ASSIGNED_TOGGLE': 'Eliminar todas las suscripciones',
  'EDIT_CAMPAIGN_MODE': 'Seleccionar estilo',
  'MEDIA_IS_NOT_VALIDE': 'Archivo no valido',
  'EDIT_MEDIA_VALIDATEDBY': 'Validado por',
  'EDIT_MEDIA_VALIDATED': 'Validado',
  'AUTH_LOGIN_TITLE': 'Iniciar sesión',
  'EDIT_CAMPAIGN_REPETITION': 'Repetir',
  'EDIT_CAMPAIGN_OFFSET': 'Offset',
  'EDIT_CAMPAIGN_HOUR': 'Hora',
  'EDIT_CAMPAIGN_HOURS': 'Horas',
  'EDIT_CAMPAIGN_OPEN_CLOSE': 'abrir/cerrar',
  'AUTH_LOGIN_ERROR_TITLE': 'Ha ocurrido un error',
  'AUTH_LOGIN_ERROR_PARA': 'El siguiente error ha ocurrido al iniciar sesión',
  'AUTH_LOGIN_GO_BACK_BUTTON': 'Volver a iniciar sesión',
  'MEDIAS_LIST_FILTER': 'Filtro',
  'MEDIAS_LIST_QUICK_SEARCH': 'Buscar por nombre',
  'MEDIAS_LIST_FILTER_NAME': 'Nombre',
  'MEDIAS_LIST_FILTER_FILENAME': 'Nombre del archivo',
  'MEDIAS_LIST_FILTER_TAGS': 'Etiquetas',
  'MEDIAS_LIST_FILTER_SHARING': 'Compartido',
  'MEDIAS_LIST_FILTER_ARCHIVED': 'Guardado',
  'MEDIAS_LIST_FILTER_VALIDATED': 'Validado',
  'MEDIAS_LIST_FILTER_STARTDATE': 'Creado desde',
  'MEDIAS_LIST_FILTER_ENDDATE': 'Creado hasta',
  'MEDIAS_LIST_FILTER_SEARCH': 'Buscar',
  'MEDIAS_LIST_FILTER_RESET': 'Restablecer',
  'MEDIA_REMOVE_FROM_LIST': 'Quitar de la lista',
  'ADD_MEDIA_TOO_SMALL': 'Demasiado pequeño',
  'ADD_MEDIA_TOO_BIG': 'Demasiado grande',
  'CAMPAIGNS_LIST_FILTER': 'Filtro',
  'CAMPAIGNS_LIST_QUICK_SEARCH': 'Buscar por nombre de campaña',
  'CAMPAIGNS_LIST_FILTER_TYPE': 'Estilo',
  'MEDIAS_LIST_FILTER_ISONLINE': 'Está online',
  'CAMPAIGNS_LIST_FILTER_STARTDATE': 'Comenzar fecha creación',
  'CAMPAIGNS_LIST_FILTER_ENDDATE': 'Finalizar fecha de creación',
  'CAMPAIGNS_LIST_FILTER_COVER_STARTDATE': 'Comenzar fecha de campaña',
  'CAMPAIGNS_LIST_FILTER_COVER_ENDDATE': 'Finalizar fecha de campaña',
  'CAMPAIGN_ADVENCE_FILTER_MEDIAS_DIALOG_TITLE': 'Búsqueda avanzada de archivos',
  'CAMPAIGN_MEDIAS_FILTER_TAG': 'Etiquetas',
  'CAMPAIGN_MEDIAS_FILTER_CULTURES_REQUIRED': 'El idioma es requerido',
  'CAMPAIGN_MEDIAS_FILTER_SEARCH': 'búsqueda',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOTAL': 'Total',
  'CAMPAIGN_MEDIAS_ASSIGNED_TOTAL': 'Total',
  'CAMPAIGN_MEDIAS_UNASSIGNED_TOGGLE_FILTER': 'Asignar a archivos filtrados',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ADDRESS': 'Dirección',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_CITY': 'Ciudad',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_COUNTRY': 'País',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_ZIP': 'Código Postal',
  'CAMPAIGN_SUBSCRIPTIONS_FILTER_SITE_NUM': 'ID Externo',
  'CAMPAIGN_ADVANCE_FILTER_SUBSCRIPTIONS_DIALOG_TITLE': 'Búsqueda avanzada de suscripciones',
  'EDIT_CAMPAIGN_ARCHIVE': 'Guardado',
  'MEDIAS_LIST_DELETE': 'Borrar',
  'MEDIAS_LIST_ARCHIVE': 'Guardar',

  'CONFIRM_LOCK_BLACKLIST_MEDIAS_DIALOG': 'Bloquear los archivos de Lista Negra',
  'CONFIRM_LOCK_BLACKLIST_MEDIAS_DIALOG_TITLE': 'SI',
  'REVOKE_LOCK_BLACKLIST_MEDIAS_DIALOG_TITLE': 'NO',

  'CONFIRM_DELETE_MEDIAS_DIALOG': 'Borrar confirmación',
  'CONFIRM_DELETE_MEDIAS_DIALOG_TITLE': 'Borrar confirmación',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT': 'Borrar todo',
  'CONFIRM_DELETE_MEDIAS_DIALOG_ACCEPT_ONLY': 'Borrar archivos no planificados',
  'CONFIRM_DELETE_MEDIAS_DIALOG_SINGLE': '¿Estas seguro que quieres borrar este archivo?',
  'CONFIRM_DELETE_MEDIAS_DIALOG_MULTI': '¿Estas seguro que quieres borrar estos archivos?',
  'CONFIRM_DELETE_CAMPAIGN_DIALOG_SINGLE': '¿Estas seguro que quieres borrar esta campaña?',
  'CONFIRM_DELETE_CAMPAIGN_DIALOG_MULTI': '¿Estas seguro que quieres borrar estas campañas?',
  'CONFIRM_DELETE_MEDIAS_USED_MEDIAS_TITLE': 'Este archivo(s) esta en uso por la siguiente campaña(s)',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG': 'Confirmación de Archivo',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE1': '¿Quieres',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE2': 'esto',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_MESSAGE3': 'archivos seleccionados?',
  'CONFIRM_ARCHIVE_CAMPAIGN_DIALOG_MESSAGE3': 'campaña seleccionada?',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_ACCEPT': 'Archivo',
  'EDIT_CAMPAIGN_INFO': 'Información de Campaña',
  'CAMPAIGN_UPDATED': 'Actualizado.',
  'CAMPAIGN_UPDATED_BY': 'Actualizado por.',
  'CAMPAIGN_CREATED': 'Creado.',
  'CAMPAIGN_CREATED_BY': 'Creado por.',
  'CONFIRM_ARCHIVE_MEDIAS_DIALOG_UNACCEPT': 'Sin Guardar',
  'DUPLICATION_CAMPAIGN_TITLE': 'Duplicar Campaña',
  'DUPLICATION_CAMPAIGN_LANG': 'Duplicar idioma',
  'DUPLICATION_CAMPAIGN_SITES': 'Duplicar suscripciones',
  'DUPLICATION_CAMPAIGN_MEDIAS': 'Duplicar archivos',
  'DUPLICATION_CAMPAIGN_DATA': 'Duplicar etiqueta de campaña',
  'DUPLICATION_BTN_SUBMIT': 'Duplicar',
  'DUPLICATION_BTN': 'Duplicar',
  'TEMPLATES_PROGRAMS': 'Plantillas y programas',
  'TEMPLATES_PROGRAMS_LOAD_ERROR': 'Ha ocurrido un error al cargar plantillas y programas',
  'TEMPLATES_PROGRAMS_TITLE': 'Plantillas & Programas',
  'TEMPLATES_PROGRAMS_TEMPLATES_TITLE': 'Plantillas',
  'TEMPLATES_PROGRAMS_PROGRAMS_TITLE': 'Programas',
  'TEMPLATES_PROGRAMS_EDIT_BUTTON': 'Editar',
  'TEMPLATES_PROGRAMS_DELETE_TEMPLATE_BUTTON': 'Borrar',
  'TEMPLATES_PROGRAMS_DELETE_PROGRAM_BUTTON': 'Borrar',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_TITLE': 'Detalles del Programa',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME': 'Nombre',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_IS24H': '¿Es un programa de 24h?',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM': 'Programa 24h',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE': 'Fecha de inicio',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE': 'Creado para',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE2': 'Fecha de finalización',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_UPDATE_BUTTON': 'Actualizar',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_CANCEL_BUTTON': 'Cancelar',
  'TEMPLATES_PROGRAMS_SAVE_BUTTON': 'Guardar',
  'TEMPLATES_PROGRAMS_SAVING_BUTTON': 'Guardando…',
  'TEMPLATES_PROGRAMS_CANCEL_BUTTON': 'Cancelar',
  'DATETIME_PICKER_TODAY': 'Ir a Hoy',
  'DATETIME_PICKER_CLEAR': 'Limpiar selección',
  'DATETIME_PICKER_CLOSE': 'Cerrar el selector',
  'DATETIME_PICKER_SELECT_MONTH': 'Seleccionar Mes',
  'DATETIME_PICKER_PREV_MONTH': 'Mes anterior',
  'DATETIME_PICKER_NEXT_MONTH': 'Siguiente mes',
  'DATETIME_PICKER_SELECT_YEAR': 'Seleccionar Año',
  'DATETIME_PICKER_PREV_YEAR': 'Año anterior',
  'DATETIME_PICKER_NEXT_YEAR': 'Siguiente año',
  'DATETIME_PICKER_SELECT_DECADE': 'Seleccionar Década',
  'DATETIME_PICKER_PREV_DECADE': 'Década anterior',
  'DATETIME_PICKER_NEXT_DECADE': 'Siguiente Década',
  'DATETIME_PICKER_PREV_CENTURY': 'Siglo anterior',
  'DATETIME_PICKER_NEXT_CENTURY': 'Siguiente Siglo',
  'DATETIME_PICKER_INCREMENT_HOUR': 'Aumentar hora',
  'DATETIME_PICKER_PICK_HOUR': 'Escoger hora',
  'DATETIME_PICKER_DECREMENT_HOUR': 'Disminuir hora',
  'DATETIME_PICKER_INCREMENT_MINUTE': 'Aumentar Minuto',
  'DATETIME_PICKER_PICK_MINUTE': 'Escoger Minuto',
  'DATETIME_PICKER_DECREMENT_MINUTE': 'Disminuir Minuto',
  'DATETIME_PICKER_INCREMENT_SECOND': 'Aumentar Segundo',
  'DATETIME_PICKER_PICK_SECOND': 'Escoger Segundo',
  'DATETIME_PICKER_DECREMENT_SECOND': 'Disminuir Segundo',
  'DATE_TIME_PLACEHOLDER': 'DD/MM/AAAA HH:mm',
  'DATE_PLACEHOLDER': 'DD/MM/AAAA',
  'TIME_PLACEHOLDER': 'HH:mm',
  'TEMPLATES_PROGRAMS_TEMPLATE_DIALOG_TITLE': 'Detalles de Plantilla',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME': 'Nombre',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_CANCEL_BUTTON': 'Cancelar',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_SAVE_BUTTON': 'Guardar',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_REQUIRED': 'Nombre de Plantilla requerido',
  'TEMPLATES_PROGRAMS_EDIT_TEMPLATE_NAME_MAX_LENGTH': 'El nombre de la plantilla no puede tener mas de 50 caracteres',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_REQUIRED': 'Nombre de Programa requerido',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_NAME_MAX_LENGTH': 'El nombre del programa no puede tener mas de 50 caracteres',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_24H_PROGRAM_REQUIRED': 'Se requiere el programa 24h',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_REQUIRED': 'Fecha de inicio requerida',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_START_DATE_MOMENT': 'Fecha válida de inicio  (Formato DD/MM/AAAA).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_REQUIRED': 'Fecha de fin requerida',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_MOMENT': 'Fecha válida de fin  (Formato DD/MM/AAAA).',
  'TEMPLATES_PROGRAMS_EDIT_PROGRAM_END_DATE_AFTER': 'La fecha de fin debe ser después de la fecha de inicio',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_DIALOG_TITLE': 'Añadir programa',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME_REQUIRED': 'El programa es requerido',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_SAVE_BUTTON': 'Guardar',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_CANCEL_BUTTON': 'Cancelar',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_TITLE': 'Confirmar borrar plantilla',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_TITLE': 'Confirmar borrar programa',
  'TEMPLATES_PROGRAMS_CANCEL_DELETE_TEMPLATE_BUTTON': 'No, no borrar',
  'TEMPLATES_PROGRAMS_CANCEL_UNASSIGN_PROGRAM_BUTTON': 'No, no borrar',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_BUTTON': 'Si, borrar',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_BUTTON': 'Si, borrar',
  'TEMPLATES_PROGRAMS_CONFIRM_DELETE_TEMPLATE_MESSAGE': '¿Estas seguro que quieres borrar esta plantilla "{{name}}"?',
  'TEMPLATES_PROGRAMS_CONFIRM_UNASSIGN_PROGRAM_MESSAGE': '¿Estas seguro que quieres quitar el programa "{{programName}}" de la plantilla "{{templateName}}"?',
  'PROGRAMS_LOAD_ERROR': 'Error del servidor al cargar los programas',
  'PROGRAMS_24H_LOAD_ERROR': 'Error del servidor al cargar programas 24h',
  'TEMPLATES_PROGRAMS_SAVE_SUCCESS': 'Las plantillas & programas has sido guardados correctamente',
  'TEMPLATES_PROGRAMS_SAVE_VALIDATION': 'Error de validación al guardar plantillas & programas',
  'TEMPLATES_PROGRAMS_SAVE_FAIL': 'Error del servidor al guardar plantillas &  programas',
  'DELETE_CONFIRMATION_MEDIAS': '¿Seguro de querer borrar este archivo "{{name}}"?',
  'DELETE_CONFIRMATION_CAMPAIGNS': '¿Seguro de querer borrar esta campaña?',
  'SUBSCRIPTION_LIST_TITLE': 'SUSCRIPCIONES',
  'COMMANDS_FILTER': 'Filtro',
  'PUBLICATION_LIST_TITLE': 'Publicaciones',
  'PUBLICATIONS_LIST_COLUMN_NAME': 'Nombre',
  'PUBLICATIONS_LIST_COLUMN_TYPE': 'Estilo',
  'PUBLICATIONS_LIST_COLUMN_START_DATE': 'Creado desde',
  'PUBLICATIONS_LIST_COLUMN_END_DATE': 'Creado para',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_CD': 'CD',
  'PUBLICATIONS_LIST_COLUMN_NUMBER_OF_SD': 'SD',
  'PUBLICATIONS_LIST_COLUMN_SUBSCRIPTIONS_AFFECTED': 'Suscripción dañada',
  'PUBLICATIONS_LIST_COLUMN__SUBSCRIPTIONS_FAILED': 'Error en la Suscripción',
  'PUBLICATIONS_LIST_COLUMN__CREATED': 'Creado',
  'PUBLICATIONS_LIST_COLUMN_CREATEDBY': 'Creado por',
  'PUBLICATIONS_LIST_COLUMN_STATUS': 'Estado',
  'No': 'No',
  'Yes': 'Si',
  'FILTER_RESET': 'Restablecer',
  'CITY': 'Ciudad',
  'COUNTRY': 'País',
  'UNSAVED_CHANGES': 'Tienes cambios sin guardar. ¿Está seguro de que desea salir de esta página ?',
  'SUBSCRIPTION_DETAILS_INFO_PAGE_TITLE': 'Página de detalles de suscripción',
  'PUBLICATIONS_LIST_QUICK_SEARCH': 'Creado desde',
  'PUBLICATION_MUSIC': 'Música',
  'PUBLICATION_PUB': 'Comerciales',
  'SUBSCRIPTION_HOURS': 'Horas de suscripción',
  'CONFIRM_PUBLISH_PROGRAM_DIALOG_TITLE': 'Confirmación del Programa Publicación Hasta',
  'CONFIRM_PUBLISH_PUBLISH_DIALOG_TITLE': 'Confirmación de la publicación Comercial',
  'CONFIRM_PUBLISH_PROGRAM_PUBLISH_DIALOG_TITLE': 'Programa de Publicación y Confirmación Comercial Hasta',
  'CONFIRM_PUBLISH_DIALOG_ACCEPT': 'Publicar hasta',
  'CONFIRM_PUBLISH_DIALOG_ACCEPT1': 'Publicar',
  'PUBLICATION_DETAILS_INFO': 'Info',
  'PUBLICATION_DETAILS_LIST': 'Detalles',
  'PUBLICATIONS_GENERAL_INFO': 'Información General',
  'PUBLICATION_NAME': 'Nombre',
  'PUBLICATIONS_TYPE': 'Tipo',
  'PUBLICATIONS_TYPE_MUSIC': 'Música',
  'PUBLICATIONS_TYPE_COMMERCIAL': 'Comercial',
  'PUBLICATION_START_DATE': 'Creado desde',
  'PUBLICATION_END_DATE': 'Creado para',
  'PUBLICATION_NUMBER_OF_CD': 'Número de cd',
  'PUBLICATION_NUMBER_OF_SD': 'Numero de sd',
  'PUBLICATIONS_STATUS': 'Estado',
  'PROGRESS': 'PROGRESO',
  'READY': 'LISTO',
  'FAILED': 'ERROR',
  'PUBLICATION_NUMBER_OF_SUBSCRIPTION': 'Suscripciones afectadas',
  'PUBLICATION_NUMBER_OF_FAILED_PUBLICATION': 'Error en la Publicación',
  'PUBLICATION_CREATEDBY': 'Creado por',
  'PUBLICATION_SUBCRIPTION_NAME': 'Nombre de la Suscripción',
  'PUBLICATION_CITY': 'Ciudad',
  'PUBLICATION_COUNTRY': 'País',
  'PUBLICATION_EXT_ID': 'ID Externo',
  'PUBLICATIONS_LIST_FILTERD': 'Buscar por nombre de suscripción',
  'PUBLICATION_STATUS': 'Estado de la publicación',
  'STATUS': 'Estado',
  'TRANSFERT': 'Tipo de transferencia',
  'PUBLICATION_FILTER_SUBSCRIPTION_NAME': 'Nombre de la Suscripción',
  'PULICATION_FILTER_SUBSCRIPTION_EXT_ID': 'ID Externo',
  'PUBLICATIONS_LIST_FILTER_START_DATE': 'Creado desde',
  'PUBLICATIONS_LIST_FILTER_END_DATE': 'Creado para',
  'PUBLICATION_FILTER_SUBSCRIPTION_CITY': 'Ciudad',
  'PUBLICATION_FILTER_SUBSCRIPTION_COUNTRY': 'País',
  'PUBLICATION_FILTER_SUBSCRIPTION_PRODUCT': 'Producto',
  'PUBLICATION_CANCEL_CD_SD': 'Cancelar SD/CD',
  'PUBLICATION_CD': 'CD',
  'PUBLICATION_SD': 'SD',
  'PUBLICATION_CANCEL_SD': 'Cancelar SD',
  'PUBLICATION_CANCEL_CD': 'Cancelar CD',
  'PUBLICATION_CANCEL_MORE_INFO': 'Más información',
  'PUBLICATION_MORE_INFO': 'Detalle de la Publicación de Campaña',
  'AUDIO_SCHEDULED_MESSAGES_LOAD_ERROR': 'Se ha producido un error al cargar los mensajes de audio programados.',
  'AUDIO_SCHEDULED_MESSAGES_FILTER': 'Filtro',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_DAY': 'Día',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_SEARCH': 'Buscar',
  'AUDIO_SCHEDULED_MESSAGES_FILTER_RESET': 'Restablecer',
  'AUDIO_SCHEDULED_MESSAGES_ANNOUNCEMENT': 'Tipo',
  'AUDIO_SCHEDULED_MESSAGES_CAMPAIGN': 'Campaña',
  'AUDIO_SCHEDULED_MESSAGE_START_DATE': 'Desde(fecha)',
  'AUDIO_SCHEDULED_MESSAGE_END_DATE': 'Hasta (fecha)',
  'AUDIO_SCHEDULED_MESSAGE_START_TIME': 'Desde (horario)',
  'AUDIO_SCHEDULED_MESSAGE_END_TIME': 'Hasta (horario)',
  'AUDIO_SCHEDULED_MESSAGE_MONDAY': 'M',
  'AUDIO_SCHEDULED_MESSAGE_TUESDAY': 'T',
  'AUDIO_SCHEDULED_MESSAGE_WEDNESDAY': 'W',
  'AUDIO_SCHEDULED_MESSAGE_THURSDAY': 'T',
  'AUDIO_SCHEDULED_MESSAGE_FRIDAY': 'F',
  'AUDIO_SCHEDULED_MESSAGE_SATURDAY': 'S',
  'AUDIO_SCHEDULED_MESSAGE_SUNDAY': 'S',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_NAME': 'Nombre',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_DURATION': 'Duración',
  'AUDIO_SCHEDULED_MESSAGE_MEDIA_PLAY': 'Reproducir',
  'PLAYER_AUDIO_SCHEDULED_MESSAGES_TITLE': 'Mensajes Programados',
  'CANCEL_CONFIRMATION': '¿Estás seguro de querer cancelar?',
  'CANCEL_CONFIRMATION_BUTTON': 'No, no cancelar',
  'CANCEL_CANCEL_CONFIRMATION_BUTTON': 'Si, cancelar',
  'TOPUBLISH': 'Para publicar',
  'PUBLISHED': 'Publicado',
  'OPENING_HOURS': 'Horario de Apertura',
  'SUBSCRIPTION_EDIT': 'Editar',
  'SUBSCRIPTIONS_LIST_TAG': 'Etiquetas',
  'CULTURES': 'Idioma',
  'SUBSCRIPTIONS_LIST_OPENING_HOURS': 'Horario de Apertura',
  'SUBSCRIPTIONS_LIST_PROGRAM': 'Programas',
  'TAGS_SUBSCRIPTION_DIALOG_TITLE': 'Añadir etiquetas a las suscripciones seleccionadas',
  'DELETE_TAGS_SUBSCRIPTION_DIALOG_TITLE': 'Eliminar etiquetas a las suscripciones seleccionadas',
  'TAGS_MEDIA_DIALOG_TITLE': 'Añadir etiquetas al archivo(s) seleccionado(s)',
  'DELETE_TAGS_MEDIA_DIALOG_TITLE': 'Quitar etiqueta(s) que desea eliminar del archivo(s) seleccionado(s)',
  'TAGS_CAMPAIGN_DIALOG_TITLE': 'Añadir etiquetas a la campaña(s) seleccionada(s)',
  'DELETE_TAGS_CAMPAIGN_DIALOG_TITLE': 'Eliminar etiquetas de la campaña(s) seleccionada(s)',
  'CULTURES_MEDIA_DIALOG_TITLE': 'Añadir idioma a los archivos seleccionados',
  'OPENING_HOURS_SUBSCRIPTION_DIALOG_TITLE': 'Establecer horario de apertura a las suscripciones seleccionadas',
  'PROGRAM_CHANGE_SUBSCRIPTION_DIALOG_TITLE': 'Enlazar la suscripción(s) seleccionada(s) a la plantillas & programas',
  'PROGRAMS': 'Plantillas & Programas',
  'APPLY': 'Aplicar',
  'PLAYER_NAME': 'Nombre del Player',
  'LAST_MODIFICATION': 'Ultima modificación',
  'MODEL': 'Modelo',
  'NO_PROGRAM': 'No se ha seleccionado ningún programa',
  'TEMPLATES_PROGRAMS_ADD_PROGRAM_NAME': 'Programa',
  'CAMPAIGNS_LIST_PAGE_TITLE': 'AD Manager | Campañas',
  'CAMPAIGNS_DETAILS_INFO_PAGE_TITLE': 'AD Manager  | Campañas | Detalles',
  'MEDIAS_LIST_PAGE_TITLE': 'AD Manager  | Archivos',
  'MEDIAS_DETAILS_INFO_PAGE_TITLE': 'AD Manager  | Archivos | Detalles',
  'PUBLICATIONS_LIST_PAGE_TITLE': 'AD Manager  | Publicaciones',
  'PUBLICATION_DETAILS_INFO_PAGE_TITLE': 'AD Manager | Publicaciones | Detalles',
  'SUBSCRIPTIONS_LIST_PAGE_TITLE': 'AD Manager  | Subscripciones',
  'SUBSCRIPTIONS_DETAILS_INFO_PAGE_TITLE': 'AD Manager  | Subscripciones| Detalles',
  'TEMPLATES_PROGRAMS_INFO_PAGE_TITLE': 'AD Manager  | Plantillas & Programas',
  'MEDIAS_LIST_VALIDATE': 'Validar archivos',
  'MEDIA_CONVERSION_STATUS': 'Convirtiendo',
  'MEDIA_CONVERSION_FAILED': 'Error en la Conversión',
  'NOT_FOUND': 'No encontrado',
  'TAGS_NOT_SELECTED': 'No hay etiqueta seleccionada',
  'CULTURES_NOT_SELECTED': 'No hay idioma seleccionado',
  'PENDING_PUBLICATIONS': 'Publicaciones pendientes',
  'PUBLICATION_SIGNALR_TITLE\'': 'Publicaciones',
  'NEW_MEDIA_IS_AVAILABLE': 'Nuevos archivos disponibles',
  'NEW_MEDIA_IS_AVAILABLE_TITLE': 'Información de Archivos',
  'PUBLICATION_COMPLETED': 'Publicación completa',
  'PUBLICATION_FAILED': 'Error en la publicación',
  'PUBLICATION_DELIVERY_FAILED': 'Error en la entrega de la publicación',
  'ALL_PROGRAMS': 'Todos los programas',
  'CONFIRM_PUBLISH_CD': 'CD',
  'CONFIRM_PUBLISH_SD': 'SD',
  'SUBSCRIPTION_LIST_QUICK_SEARCH': 'buscar por nombre de suscripción',
  'DELETE_USED_TEMPLATES': 'Solo puede borrar las Plantillas que no estén en uso',
  'SUBSCRIPTION_SAVE_SUCCESS': 'La suscripción se ha guardado correctamente',
  'SUBSCRIPTION_SAVE_VALIDATION_ERROR': 'Error en Dia o/y hora',
  'SUBSCRIPTION_NOT_FOUND': 'Se ha producido un error en el servidor al guardar la suscripción.',
  'CHANGE_PROGRAM_TEMPLATE_SUCCESS': 'Las nuevas plantillas y programas se añadieron a la suscripciones seleccionadas',
  'CHANGE_PROGRAM_TEMPLATE_ERROR': 'Error al enlazar las plantillas & programas',
  'MEDIAS_VALIDATE_SUCCESS': 'El Archivo has sido validado correctamente.',
  'MEDIAS_VALIDATE_FAIL': 'Error al guardar la validación del Archivo.',
  'SAVE_ONLY_FULL_TEMPLATES': 'Guardar sólo plantillas con programas asociados.',
  'MEDIAS_DELETE_SUCCESS': 'El archivo ha sido eliminado correctamente',
  'MEDIAS_DELETE_ERROR': 'Error al eliminar el Archivo',
  'ADD_TAGS_SUCCESS': 'Añadir etiquetas al archivo(s) seleccionado(s)',
  'ADD_TAGS_ERROR': 'Error al añadir etiquetas',
  'ADD_OPENING_HOURS_ERROR': 'Error al actualizar el horario de apertura',
  'MEDIAS_ARCHIVE_SUCCESS': 'El archivo ha sido archivado correctamente',
  'ADD_OPENING_HOURS_SUCCESS': 'Horario de Apertura actualizado correctamente',
  'MEDIAS_ARCHIVE_ERROR': 'Error al guardar los archivos',
  'CAMPAIGN_REMOVE_SUCCESS': 'La campaña(s) han sido eliminadas correctamente',
  'CAMPAIGN_REMOVE_SELF_ERROR': 'Error al eliminar la campaña',
  'CAMPAIGN_REMOVE_ERROR': 'Se ha producido un error al eliminar la campaña',
  'ADD_TAGS_CAMPAIGN_SUCCESS': 'Añadir etiquetas a la campaña(s) seleccionada(s)',
  'ADD_TAGS_CAMPAIGN_ERROR': 'Se ha producido un error al añadir etiquetas',
  'DELETE_TAGS_SUCCESS': 'Eliminar etiquetas de la campaña(s) seleccionada(s)',
  'DELETE_TAGS_ERROR': 'Se ha producido un error al eliminar las etiquetas',
  'CAMPAIGNS_ARCHIVE_SUCCESS': 'La campaña se ha guardado correctamente',
  'CAMPAIGNS_ARCHIVE_ERROR': 'Se ha producido un error al guardar las etiquetas',
  'ADD_TAGS_SUBSCRIPTION_SUCCESS': 'Añadir etiquetas a tu suscripción(s) seleccionada(s)',
  'ADD_TAGS_SUBSCRIPTION_ERROR': 'Se ha producido un error al añadir etiquetas',
  'MEDIAS_LIST_FILTER_ONLINE': 'Online',
  'CONTENT': 'Contenido',
  'CAMPAIGN_LIST': 'Lista de Campañas',
  'PUBLICATION_LIST': 'Lista de Publicaciones',
  'EDIT_CAMPAIGN_PUBLICATION_INFO': 'Información de Publicaciones',
  'EXCEPTION_SUBSCRIPTION_DIALOG_TITLE': 'Excepción en la gestión de Horario de Apertura',
  'ADD_EXCEPTION_GENERATE_SUCCESS': 'Añadir Horario de Apertura a la suscripción(s) seleccionada(s)',
  'ADD_EXCEPTION_GENERATE_ERROR': 'Se ha producido un error al añadir Horarios de Apertura',
  'SAVE_DUPLICATED_ERROR': 'Existe un nombre duplicado',
  'PLACING': 'colocar',
  'MEDIA_TO_VALIDATE': 'Archivo para validar',
  'MEDIAS_TO_VALIDATE': 'archivo para validar',
  'GO_TO_VALIDATE_MEDIA': 'archivo validado',
  'GO_TO_PUBLICATION': 'publicaciones',
  'GO_TO_CAMPAIGN': 'campañas',
  'GO_TO_SUBSCRIPTION': 'suscripciones',
  'LAST_CAMPAIGN': 'Ultima campaña no válida',
  'LAST_CAMPAIGNS': 'últimas campañas no válidas',
  'LAST_PUBLICATION': 'última publicación',
  'LAST_PUBLICATIONS': 'última publicación',
  'FAILED_PUBLICATION': 'Error en la publicación',
  'FAILED_PUBLICATIONS': 'error en la publicación',
  'SITE_WITH_NO_TEMPLATE': 'Sitio sin plantilla',
  'ACTIVITY_FEED': 'actividades recientes',
  'DELETED': '(eliminado)',
  'ENDED': 'FINALIZADO',
  'DASHBOARD_ACTIVITY_FEED_TITLE': 'Actividades recientes',
  'PROGRAM_NO_PLANNING': 'Programa  con planificación terminada',
  'DASHBOARD_ACTIVITY_FEED_ERROR': 'Se ha producido un error al cargar las actividades',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULE': '{{userName}} creado <strong>calendario</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULE': '{{userName}} actualizado <strong>calendario</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULE': '{{userName}} eliminado <strong>calendario</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SCHEDULES': '{{userName}} creado <strong>calendario</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SCHEDULES': '{{userName}} actualizado <strong>calendario</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SCHEDULES': '{{userName}} eliminado <stron+C587:C653g>calendario</strong>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTION': '{{userName}} creado <strong>subscripcione</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTION': '{{userName}} actualizado <strong>subscripcione</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTION': '{{userName}} eliminado <strong>subscripcione</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_SUBSCRIPTIONS': '{{userName}} creado <strong>subscripciones</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_SUBSCRIPTIONS': '{{userName}} actualizado <strong>subscripciones</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_SUBSCRIPTIONS': '{{userName}} eliminado <strong>subscripciones</strong>',
  'DASHBOARD_ACTIVITY_FEED_PUBLISH_SUBSCRIPTIONS': 'La publicación ha sido publicada por <strong> {{userName}} </strong> ',
  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIA': '{{userName}} creado <strong>archivo</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIA': '{{userName}} actualizado <strong>archivo</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIA': '{{userName}} eliminado <strong>archivo</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIA': '{{userName}} validado <strong>archivo</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_MEDIAS': '{{userName}} creado <strong>archivo</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_MEDIAS': '{{userName}} actualizado <strong>archivo</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_MEDIAS': '{{userName}} eliminado <strong>archivo</strong>',
  'DASHBOARD_ACTIVITY_FEED_VALIDE_MEDIAS': '{{userName}} validado <strong>archivo</strong>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATE': '{{userName}} creado <strong>plantillas y programas</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATE': '{{userName}} actualizado <strong>plantillas y programas</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATE': '{{userName}} eliminado <strong>plantillas y programas</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_CREATE_PROGRAMTEMPLATES': '{{userName}} creado <strong>plantillas y programas</strong>',
  'DASHBOARD_ACTIVITY_FEED_UPDATE_PROGRAMTEMPLATES': '{{userName}} actualizado <strong>lantillas y programas</strong>',
  'DASHBOARD_ACTIVITY_FEED_DELETE_PROGRAMTEMPLATES': '{{userName}} eliminado <strong>lantillas y programas</strong>',
  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTION':
    '{{userName}} enlazado <strong>programas y subscripcione</strong> <span class="feed_name">{{name}}</span>',
  'DASHBOARD_ACTIVITY_FEED_LINKPROGRAM_SUBSCRIPTIONS': '{{userName}} enlazado <strong>programas y subscripcione</strong>',
  'DASHBOARD_ACTIVITY_FEED_NO_DATA': 'No hay datos que mostrar aquí.',
  'MEDIAS_LIST_FILTER_UPDATE': 'Actualizar fecha',
  'CORPORATE_LIST': 'Listado corporativo',
  'MEDIAS_LIST_COLUMN_DIFFUSION': 'Difusión',
  'LOCAL_CAMPAIGN_EDIT': 'Editar',
  'LOCAL_CAMPAIGN_REMOVE': 'Eliminar',
  'LOCAL_CAMPAIGN_LIST': 'Campaña Local',
  'ADD_MEDIA_IN_YOUR_LOCAL_CAMPAIGN': 'Agregar Archivo',
  'MEDIA_LOCK_STATUS': 'Este archivo está bloqueado',
  'VALIDATION_LIST_COLUMN_NAME': '  Etiqueta de nombre de idiomas ',
  'MEDIAS_LIST_LOCK_STATUS': 'Difusión bloqueada',
  'MEDIAS_LIST_BLACKLIST_STATUS': 'La Lista Negra contiene alguna(s) suscripcione(s)',
  'MY_PROFILE': 'Mi perfil',
  'CONFIRMATION': 'Confirmación',
  'END_PUBLICATION': 'Finalizar la planificación',
  'PUBLICATION_PROGRAM_WARNING': 'Alerta',
  'PUBLICATION_PROGRAM_READY': 'Listo',
  'PUBLICATION_PROGRAM_ERROR': 'Error',
  'PUBLICATION_COMPLETED_WITH_WARNING': 'Publicación completa',
  'UPLOAD_TITLE': 'Deja tus archivos aquí o has click to cargarlos',
  'PUBLICATION_STARTED': 'Inicio de la publicación',
  'DEFAULT_TITLE': 'AD Manager',
  'PUBLICATION_SIGNALR_TITLE': 'Publicación',
  'PRIVACY_POLICY': 'Política de Privacidad',
  'TERMS_OF_USE': 'Términos de Uso',
  'IMPORT_FROM': 'Importar desde',
  'IMPORT_CAMPAIGNS_FROM': 'Importar Campaña a las Suscripciones seleccionadas',
  'CLONE_FROM': 'CLONAR DESDE',
  'STEP': 'paso',
  'NEXT': 'siguiente',
  'COPY': 'Importar',
  'PREVIOUS': 'Anterior',
  'CLONED_DATA_RESUME': 'Informe de datos clonados',
  'IMPORT_CAMPAIGNS_FROM_ERROR': 'La(s) campaña(s) importada(s) han ocasionado un error.',
  'SELECTED_SUBSCRIPTIONS_TO_CLONE': 'Subscripciones seleccionadas.',
  'SELECTED_CAMPAIGNS_TO_CLONE': 'Campaña seleccionada par clonar',
  'CAMPAIGNS_INVALID': 'Campaña no válida',
  'NO_PLAYER': 'Sin player',
  'ONLINE_PLAYER': 'Online',
  'OFFLINE_PLAYER': 'Offline',
  'PUBLICATION_COUNT_MESSAGE': 'Tienes',
  'PUBLICATION_COUNT_MESSAGE_ONE': 'publicaciones pendientes',
  'SPLIT_BY_CULTURES': 'Dividir por idioma',
  'PUBLICATION_ALL': 'Publicar Todo',
  'ACTION_LIST_TAG_DELETE': 'Eliminar Etiquetas',
  'LAST_PUBLISHED_PLANNING': 'Ultima planificación publicada',
  'PUBLICATION_CULTURES_ERROR': 'Error de Idiomas',
  'PUBLICATION_COUNT_ERROR': 'Alerta de recuento',
  'CURRENT_CULTURES': 'Idiomas actuales',
  'EXPECTED_CULTURES': 'Idiomas predeterminados',
  'CAMPAIGN_MEDIA_COUNT': 'Recuento de archivos de campaña',

  'ERROR_INVALID_ORDER_TYPE': 'Orden invalida: {{0}}',
  'ERROR_FIRST_PROGRAM_NOT_PERMANENT': 'Primer programa {{0}} no es permanente',
  'ERROR_SUBSCRIPTIONS_REMAINS': 'Algunas suscripciones permanecen para {{0}}',
  'ERROR_PROGRAM_LIST_EMPTY': 'Lista de Programas vacía para {{0}}',
  'ERROR_NAME_EMPTY': 'Nombre esta vacío',
  'ERROR_MUSIC_PUBLICATION_REQUIRED': 'Publicación Musical requerida',
  'ERROR_NO_SCHEDULE_FOUND': 'No se ha encontrado ningún horario',
  'ERROR_NO_DATA': 'Sin datos',
  'ERROR_PROGRAM_NOT_FOUND': 'Programa {{0}} no encontrado',
  'ERROR_SUBSCRIPTION_NOT_ALLOWED': 'Subscripción {{0}} no permitida',
  'ERROR_CULTURE_INVALID': 'Idioma no válido',
  'ERROR_NO_TEMPLATE_LINKED': 'No hay plantilla vinculada.',
  'ERROR_DUPLICATE_DATE_OPENING_HOUR': 'Fecha duplicada en Horario de Apertura Excepción: {{0}}',
  'ERROR_FAILED_REMOVE_BLACKLIST_MEDIA': 'Usuario \'{{0}}\'  fallo al eliminar el archivo del Lista Negra (id: {{1}})',
  'ERROR_FAILED_INSERT_BLACKLIST_MEDIA': 'Usuario \'{{0}}\' Archivo Lista Negra {{1}} para  Subscripción {{2}} error',
  'ERROR_DUPLICATE_DATE': 'Fecha duplicada: {{0}}',
  'ERROR_NO_CULTURE_FOUND': 'Idioma no encontrado para {{0}}',
  'ERROR_COHERENCE_CULTURE': 'Error en la coherencia de idioma',
  'ERROR_SCHEDULE_FOUND': 'Horario encontrado para {{0}}',
  'ERROR_FAILED_LINK_SUBSCRIPTION_TO_AUDIO_SCHEDULE': 'Usuario \'{{0}}\' enlazar suscripción {{1}} a la programación de audio {{2}} error',
  'ERROR_INVALID_SUBSCRIPTION': 'Subscripción no válida (Uuid: {{0}})',
  'ERROR_INVALID_SHARING_TYPE': 'Valor de uso compartido no válido: \'{{0}}\'',
  'ERROR_MEDIA_NOT_FOUND': 'Archivo no encontrado (Id: {{0}})',
  'ERROR_MEDIA_NOT_VALID': 'Archivo no válido',
  'ERROR_INVARIANT_CULTURE_NOT_COMPATIBLE_WITH_OTHERS': 'Los idiomas fijos no son compatibles con otros idiomas',
  'ERROR_DUPLICATE_NAME': 'Nombre Duplicado para {{0}} (Id: {{1}})',
  'ERROR_LINK_TAG_TO_MEDIA': 'Usuario \'{{0}}\' enlazar etiquetas {{1}} al archivo {{2}} error',
  'ERROR_LINK_CULTURE_TO_MEDIA': 'Enlazar idioma {{0}} al archivo {{1}} error',
  'ERROR_FAILED_DELETE_MEDIA_RESTRICTION_FOR_SUBSCRIPTIONS':
    'Usuario \'{{0}}\' borrar la restricción de archivos de suscripciones {{1}} a Archivos {{2}} error',
  'ERROR_MEDIA_ALREADY_VALIDATED': 'Archivo ya validado',
  'ERROR_MEDIA_NOT_CONVERTED': 'Archivo no convertido',
  'ERROR_NO_TARGET_FOR_FILE_CONTENT': 'Sin destino para escribir el contenido del Archivo',
  'ERROR_FLOATING_REPETITION_LESS_THAN_ONE': 'Repetición flotante < 1',
  'ERROR_TOO_MANY_MEDIA_JIT_OR_CLOSE': 'Demasiados archivos para {{0}}',
  'ERROR_MISMATCH_MEDIA_ENTRIES': 'Horario de Audio guardado {{0}} en lugar de entrada de archivos {{1}}',
  'ERROR_MISMATCH_LINK_ENTRIES': 'Enlace a Subscripciones guardado {{0}} entrada en lugar de {{1}}',
  'ERROR_LINK_TAG_TO_AUDIO_SCHEDULE': 'Usuario \'{{0}}\' enlazar etiquetas {{1}} al horario de audio {{2}} error',
  'ERROR_FILE_NOT_UPLOADED_IN_AZURE_BLOB': 'Archivo no cargado Azure Blob',
  'ERROR_SECOND_CONNECTION_AZURE_FAILED': 'Error en la segunda conexión provisional con Azure Blob',
  'ERROR_FILE_NOT_FOUND': 'Archivo {{0}} no encontrado',
  'ERROR_INVALID_PARAMETER': 'Parámetro no válido',
  'ERROR_ADDRESS_NAME_EMPTY': 'Falta la dirección',
  'ERROR_ADDRESS_NAME_LENGTH_EXCEEDED': 'Longitud del nombre de la dirección superada',
  'ERROR_ADDRESS_LINE1_EMPTY': 'Falta la línea de dirección1',
  'ERROR_ADDRESS_LINE1_LENGTH_EXCEEDED': 'Longitud de la línea de dirección1 superada',
  'ERROR_ADDRESS_LINE2_LENGTH_EXCEEDED': 'Longitud de la línea de dirección2 superada',
  'ERROR_ADDRESS_LINE3_LENGTH_EXCEEDED': 'Longitud de la línea de dirección3 superada',
  'ERROR_ADDRESS_ZIP_LENGTH_EXCEEDED': 'Longitud del código postal superada',
  'ERROR_ADDRESS_CITY_EMPTY': 'Falta la Ciudad',
  'ERROR_ADDRESS_CITY_LENGTH_EXCEEDED': 'Longitud de la Ciudad superada',
  'ERROR_ADDRESS_COUNTRY_EMPTY': 'Falta el País',
  'ERROR_ADDRESS_COUNTRY_LENGTH_EXCEEDED': 'Longitud del país superada',
  'ERROR_INVALID_ID': 'ID no válido',
  'ERROR_USER_AD_ACCOUNT_NOT_FOUND': 'Cuenta de Usuario AD {{0}} no encontrado',
  'ERROR_USER_NOT_FOUND': 'Usuario {{0}} no encontrado',
  'ERROR_CLIENT_NOT_ALLOWED': 'Cliente no permitido',
  'ERROR_ITEM_IS_DELETED': '{{0}} esta Eliminado',
  'ERROR_ITEM_IS_OFF': '{{0}} esta desactivado',
  'ERROR_ITEM_IS_DEFAULT': '{{0}} por defecto',
  'ERROR_SITE_NOT_ALLOWED': 'Site no permitido',
  'ERROR_USER_NOT_ALLOWED_FOR_ROLE': 'Usuario {{0}} no permitido para {{1}}',
  'ERROR_INVALID_CLIENT': 'Cliente no válido',
  'ERROR_INVALID_NAME': 'Nombre no válido',
  'ERROR_USER_NOT_ALLOWED': 'Usuario no permitido',
  'ERROR_USER_NOT_ALLOWED_TO_EDIT': 'Usuario no permitido para editar',
  'ERROR_USER_NOT_ALLOWED_TO_VIEW': 'Usuario no permitido para ver',
  'ERROR_NAME_MISSING': 'Falta el nombre',
  'ERROR_CLIENT_NAME_ALREADY_EXIST': 'Nombre del  cliente \'{{0}}\' ya existe',
  'ERROR_OFFICE_MISSING': 'Falta la oficina',
  'ERROR_SALES_MISSING': 'Falta comercial de ventas',
  'ERROR_LOGIN_LIMIT_IS_REACHED': 'Limite de inicio de sesión alcanzado',
  'ERROR_INVALID_PARENT': 'Parent No válido',
  'ERROR_PRODUCER_INVALID': 'Productor no válido o faltante',
  'ERROR_PRODUCTS_MISSING': 'No hay productos seleccionados',
  'ERROR_INVALID_CLIENT_PARAMETER': 'Parámetro de cliente no válido',
  'ERROR_PLAYER_STILL_LINKED': 'Player enlazado',
  'ERROR_USER_ALREADY_EXIST': 'Usuario \'{{0}}\' ya existe',
  'ERROR_DEPARTMENT_MISSING': 'Falta el área',
  'ERROR_EMAIL_PHONE_MOBILE_SKYPE_MISSING': 'Faltan correo, teléfono, móvil y/o skype',
  'ERROR_INVALID_TEMPLATE_NOT_SHARED': 'Plantilla no válida: sin compartir',
  'ERROR_INVALID_TEMPLATE': 'Plantilla no válida',
  'ERROR_GROUP_ALREADY_EXIST': 'El Grupo {{0}} ya existe',
  'ERROR_DEVICE_NOT_WITH_SUBSCRIPTION': 'El dispositivo no pertenece a la suscripción',
  'ERROR_SET_PLAYER_DEFAULT_FAILED': 'Error al actualizar el player como predeterminado',
  'ERROR_PLAYER_NOT_FOUND': 'Player {{0}} no encontrado',
  'ERROR_SITE_NAME_MISSING': 'Falta el Nombre del Site',
  'ERROR_SITE_NAME_LENGTH_EXCEEDED': 'La longitud del nombre del Site superó {{0}} los caracteres',
  'ERROR_EXTERNAL_SITE_LENGTH_EXCEEDED': 'La longitud del External site superó {{0}} los caracteres',
  'ERROR_DEFAULT_SITE_ADDRESS_MISSING': 'Falta la dirección predeterminada',
  'ERROR_INVALID_USER': 'Usuario no válido',
  'ERROR_SITE_INVALID': 'Site no válido',
  'ERROR_SUBSCRIPTION_INVALID': 'Subscripción no válida',
  'ERROR_ORDER_ALREADY_OPENED_FOR_SUBSCRIPTION': 'Ya existe una orden abierta para esta suscripción {{0}}',
  'ERROR_SUBSCRIPTION_NAME_MISSING': 'Falta el nombre de la suscripción',
  'ERROR_SUBSCRIPTION_LENGTH_EXCEEDED': 'La longitud del nombre de la suscripción superó {{0}} los caracteres',
  'ERROR_PRODUCT_INVALID': 'El producto no válido',
  'ERROR_STATUS_INVALID': 'Estado  no válido',
  'ERROR_NAME_LENGTH_EXCEEDED': 'La longitud del nombre superó {{0}} los caracteres',
  'ERROR_COMMENT_EMPTY': 'Comentarios están vacíos',
  'ERROR_INVALID_DEVICE': 'Dispositivo no válido {{0}}',
  'ERROR_INVALID_DEVICE_MUST_BE_PRODUCT': 'Modelo de dispositivo no válido {{0}} -  debe generarse{{1}}',
  'ERROR_INVALID_PLAYER': 'Player no válido {{0}}',
  'ERROR_DEVICE_ALREADY_USED': 'Dispositivo  {{0}} en uso',
  'ERROR_NOT_ACTIVE': 'No activo',
  'ERROR_AVATAR_NOT_FOUND': 'Avatar no encontrado para este usuario {{0}}',
  'ERROR_USER_EMAIL_NOT_FOUND': '{{0}} no encontrado',
  'ERROR_USER_CODE_NOT_FOUND': 'Código no encontrado',
  'ERROR_INVALID_PASSWORD': 'Contraseña de parámetro no válida',
  'ERROR_NO_ROLE_FOUND': 'Rol no encontrado',
  'ERROR_INVALID_GROUP_ROLE': 'Grupo de roles no válidos',
  'ERROR_DELETE_HIMSELF': 'Auto borrar no permitido',
  'ERROR_INVALID_ADDRESS_TYPE': 'Tipo de dirección no reconocida: \'{{0}}\' (valores conocidos: {{1}})',
  'ERROR_EMAIL_MISSING': 'Falta correo electrónico',
  'ERROR_PHONE_MISSING': 'Teléfono, el móvil esta vacío',
  'ERROR_SCREENROTATION_INVALID': 'Rotación de Pantalla no válida: {{0}} (valores conocidos: {{1}})',
  'ERROR_SCHEDULE_NOT_FOUND': 'No se ha encontrado ningún horario',
  'TEMPLATES_AND_PROGRAMS': 'Plantillas y programas',

  'VALIDATE_ALL': 'Validar todo',
  'SAVE': 'Guardar',
  'EXTRA_PLAY': 'Tiempo extra',
  'CHANGE_SUBSCRIPTION': 'Cambiar subscripcion',
  'FILTER_BY_NAME': 'Filtrar por nombre',
  'END_DATE': 'Fecha de fin',
  'NO_DATA_SELECTED': 'sin datos seleccionados',
  'MONDAY': 'Lunes',
  'TUESDAY': 'Martes',
  'WEDNESDAY': 'Miércoles',
  'THURSDAY': 'Jueves',
  'FRIDAY': 'Viernes',
  'SATURDAY': 'Sábado',
  'SUNDAY': 'Domingo',
  'FIVE': '5',
  'TEN': '10',
  'TWENTY': '20',
  'FIFTY': '50',
  'HUNDRED': '100',
  'DATE_VALIDITY': 'Fecha validez',
  'ADD_MEDIA_SUCCESS': 'Añadir éxito de medios',
  'EMPTY_TEXT': 'No hay datos para mostrar aquí',
  'MEDIA_SAVE_SUCCESS': 'Los medios guardan el éxito',
  'FIRMWARE': 'Firmware',
  'ARCHIVE_RELATED_MEDIA': 'Archivo de medios relacionados.',
  'TEMPLATE_PROGRAM_DUPLICATE_NAME': 'Nombre ya existe',
  'SOME_COMMENTS': 'Algunos comentarios...',
  'SUBSCRIPTIONS_LOAD_ERROR': 'Error de carga de suscripción',
  'CAMPAIGN_SAVE_SUCCESS': 'Los la campaña guardan el éxito',
  'MEDIA_UPLOAD_INFO_TITLE': 'Información',
  'MEDIA_UPLOAD_INFO_FILES_SUPPORTED': 'Archivos soportados :',
  'MEDIA_UPLOAD_INFO_FILES_MAX_SIZE': 'Tamaño máximo de archivo : ',
  'MEDIA_UPLOAD_INFO_FILES_MAX_UPLOAD': 'Carga máxima de archivos :',
  'MEDIAS_LIST_FILTER_EXPIRED': 'Caducada',
  'MUSIC_MANAGER_LINK': 'Enlace al Music Manager',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION': 'Forzar una actualización',
  'PUBLICATION_DIALOG_FORCED_PUBLICATION_LISA_PLAYERS': 'Lisa players',
  'PUBLICATION_DIALOG_TOTAL_PLAYERS': 'Players totales: ',
  'PUBLICATION_DIALOG_ONLINE_PLAYERS': 'Players en línea: ',
  'PUBLICATION_DIALOG_OFFLINE_PLAYERS': 'Players desconectado: '

}

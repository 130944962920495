import {Action} from '@ngrx/store';

import {Publication} from '../../publications/models/publication';
import {type} from '../../shared';
import {ArrayResponse} from '../../shared/models/array-response';

export const PublicationFailedListActionTypes = {
  LOAD_FAILED: type('[Publication] failed Load'),
  LOAD_FAILED_SUCCESS: type('[Publication] failed Load Success'),
  LOAD_FAILED_FAIL: type('[Publication] failed Load Fail'),
};

// failed publications
export class PublicationFailedAction implements Action {
  readonly type = PublicationFailedListActionTypes.LOAD_FAILED;
  constructor() {}
}

export class PublicationFailedSuccessAction implements Action {
  readonly type = PublicationFailedListActionTypes.LOAD_FAILED_SUCCESS;
  constructor(public payload?: ArrayResponse<Publication>) {}
}

export class PublicationFaildAction implements Action {
  readonly type = PublicationFailedListActionTypes.LOAD_FAILED_FAIL;
  constructor(public payload?: number) {}
}

import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const DeleteMediasActionTypes = {
  DELETE: type('[Medias] Delete'),
  DELETE_SUCCESS: type('[Medias] Delete Success'),
  DELETE_SELF: type('[Medias] Delete Self'),
  DELETE_FAIL: type('[Medias] Delete Fail')
};

export class DeleteMediasAction implements Action {
  readonly type = DeleteMediasActionTypes.DELETE;

  constructor() {}
}

export class DeleteMediasSuccessAction implements Action {
  readonly type = DeleteMediasActionTypes.DELETE_SUCCESS;

  constructor() {}
}

export class DeleteMediasSelfAction implements Action {
  readonly type = DeleteMediasActionTypes.DELETE_SELF;

  constructor() {}
}

export class DeleteMediasFailAction implements Action {
  readonly type = DeleteMediasActionTypes.DELETE_FAIL;

  constructor(public payload?: number) {}
}

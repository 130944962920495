import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as moment from 'moment';

import {CUSTOM_CLAIMS_NAME} from '../../consts';
import {Auth0UserInfo} from '../../models/auth0-user-info';
import {ImpersonateUser} from '../../models/impersonate-user';

@Component({
  selector: 'storever-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent {
  @Input() userInfo: Auth0UserInfo;
  @Input() canImpersonate: boolean;
  @Input() impersonatedUser: ImpersonateUser;
  @Input() availableLanguages: string[] = [];
  @Input() currentLanguage = 'en';
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() impersonate: EventEmitter<'Enter'|'Leave'> = new EventEmitter<'Enter'|'Leave'>();
  @Output() selectLanguage: EventEmitter<string> = new EventEmitter<string>();
  schema = this.config.schema;
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private config: L10nConfig,
    private translation: L10nTranslationService
  ) { }
  get profilePicture(): string {
    if (this.userInfo && this.userInfo.picture) {
      const updateDate = moment.parseZone(this.userInfo.updated_at);
      return `${this.userInfo.picture}?_=${updateDate.format('YYYYMMDDHHmmssSSS')}`;
    }

    return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y';
  }

  get profileName(): string {
    if (this.userInfo) {
      return this.userInfo[CUSTOM_CLAIMS_NAME] || this.userInfo.name;
    }

    return 'Anonymous';
  }

  get impersonating(): boolean { return this.impersonatedUser != undefined; }

    getFlag(language: string): string {
    const flagsPerLanguage = { de: 'de', en: 'gb', es: 'es', fr: 'fr', it: 'it', nl: 'nl', cn: 'cn' };
    return flagsPerLanguage[language];
  }

  changeLanguage($event: MouseEvent, language: any): void {
    $event.preventDefault();
    for (let i = 0; i < this.schema.length; ++i) {
      if (this.schema[i].locale.language === language.language) {
        this.translation.setLocale(this.schema[i].locale);
      }
    }
    }


  doLogout($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.logout.emit();
  }

  impersonateUser($event: MouseEvent) {
    console.log('impersonateUser')
    $event.preventDefault();
    this.impersonate.emit('Enter');
  }

  leaveImpersonation($event: MouseEvent) {
    $event.preventDefault();
    this.impersonate.emit('Leave');
  }
}

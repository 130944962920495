import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {BaseEffect, error} from '../../shared';
import {AddPublishedAction, AddPublishedActionTypes, AddPublishedFailAction, AddPublishedSuccessAction} from '../actions/published';
import * as fromRoot from '../reducers';
import {PublishService} from '../services/publish.service';

@Injectable()
export class AddPublishedEffect extends BaseEffect {
 Add$ = createEffect(
   () => {  return this.actions$.pipe(
    ofType(AddPublishedActionTypes.ADD),
    debug('Add Published action received.'),
    map((action: AddPublishedAction) => action.payload),
    withLatestFrom(this.store.select(fromRoot.selectors.getCurrentClient)),
    switchMap(([payload, client]) => this.publishService.publish(client.uuid, payload)
                                       .pipe(map(() => new AddPublishedSuccessAction()), catchError((res: Response) => this.catchResponseError(res)))))});

  AddFail$ = createEffect(
    () => {  return this.actions$.pipe(ofType(AddPublishedActionTypes.ADD_FAIL),
                                debug('A server error occurred while creating a new published.'),
                                map(() => error(this.translation.translate('PUBLISHED_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions,
              private store: Store<fromRoot.AppState>,
              translation: L10nTranslationService,
              private publishService: PublishService,
              router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new AddPublishedFailAction(response.status); }
}

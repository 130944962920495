import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Subscriptions} from '../../subscription/models/subscriptions';
import {SiteWithNoTemplateListActionTypes, SiteWithNoTemplateSuccessAction} from '../actions/site-with-no-template-list';

export interface SiteWithNoTemplateState {
  array: Subscriptions[];
  count: number;
}
const initialState: SiteWithNoTemplateState = {
  array: [],
  count: 0,
};

export function siteWithNoTemplateReducer(state = initialState, action: Action): SiteWithNoTemplateState {
  switch (action.type) {
    case SiteWithNoTemplateListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(action: SiteWithNoTemplateSuccessAction): SiteWithNoTemplateState {
  const newState: SiteWithNoTemplateState = {
    array: action.payload.array,
    count: action.payload.count,
  };
  return newState;
}

export const SiteWithNoTemplatSelectors = {
  array: (state: SiteWithNoTemplateState) => _.get(state, 'array', []),
  count: (state: SiteWithNoTemplateState) => _.get(state, 'count', 0)
};

import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';

import {SelectTagsComponent} from '../../../shared/components/select-tags/select-tags.component';
import {TagsList} from '../../models/tags-list';

@Component({
  selector: 'storever-media-tags-action-list-dialog',
  templateUrl: './tags-action-list-dialog.component.html',
  styleUrls: ['./tags-action-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsMediaActionListDialogComponent {
  @ViewChild('SubListTagModal') modal: ModalDirective;
  @Output() confirm = new EventEmitter<object[]>();
  // tags
  @Input() tagsList: TagsList[];
  @Input() toDeleteTags: Observable<boolean>;
  @ViewChild('selectTags') selectTags: SelectTagsComponent;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  accept(): void {
    let conv;
    if (this.selectTags.toDeleteTags) { // case delete tags
      this.selectTags.isFirstTimeSet = true;
      // removed tags + taglistConverted
      conv = this.selectTags.tagsToDelete;
    } else { // case add tags
      this.selectTags.saveTagsTyped();
      // added tags + original tagsList
      conv = this.selectTags.convertTags();
    }
    console.log('accept' , conv)
    console.log(this.selectTags.toDeleteTags)
    this.confirm.emit(conv);
    this.modal.hide();
  }
  send(): void {
    this.selectTags.isFirstTimeSet = true;
    this.modal.hide();
  }
  open(): void {
    this.modal.show();
    // tags
    if (this.selectTags) {
     // this.selectTags.select.active = [];
      this.selectTags.setTagsSelectToDeleteDataAndStyle();
    }
  }
  close($event?: MouseEvent): void {
    this.selectTags.isFirstTimeSet = true;
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.modal.hide();
  }

  public submitFromChild($event: any): void {}
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {error} from '../../shared';
import {LoadMyClientsFailAction, LoadMyClientsSuccessAction, MyClientsActionTypes} from '../actions/my-clients';
import {ArrayResponse} from '../models/array-response';
import {Client} from '../models/client';
import {SendBackResult} from '../models/send-back-result';
import {MyClientsService} from '../services/my-clients.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class MyClientsEffect extends BaseEffect {
  load$ = createEffect(
    () => {  return this.actions$.pipe(ofType(MyClientsActionTypes.LOAD),
                             debug('Load my clients action received.'),
                             switchMap(() => this.myClientsService.getList().pipe(
                                         map((result: SendBackResult<ArrayResponse<Client>>) => new LoadMyClientsSuccessAction(result.data.array)),
                                         catchError((res: Response) => this.catchResponseError(res)))))});

  loadFail$ = createEffect(
    () => {  return this.actions$.pipe(ofType(MyClientsActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving clients list.'),
                                 map(() => error(this.translation.translate('MY_CLIENTS_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))))});

  constructor(private actions$: Actions, translation: L10nTranslationService, private myClientsService: MyClientsService, router: Router) {
    super(router, translation);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadMyClientsFailAction(response.status); }
}

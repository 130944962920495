import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, skipWhile, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {ArrayResponse, BaseEffect, SendBackResult} from '../../shared';
import * as rootSelectors from '../../shared/reducers';
import {ProgramNoPlaningActionTypes, ProgramNoPlanningFaildAction, ProgramNoPlanningSuccessAction} from '../actions/program-no-planning';
import {DashboardService} from '../services/dashboard.service';

@Injectable()
export class ProgramNoPlaningEffect extends BaseEffect {
  private client$ = this.storeClient.select(rootSelectors.selectors.getCurrentClient).pipe(filter(client => !_.isEmpty(client)));
  private roles$ = this.storeClient.select(rootSelectors.selectors.getMyRoles).pipe(filter(roles => !_.isEmpty(roles)));

  load$  = createEffect(
    () => {  return this.actions$.pipe(
    ofType(ProgramNoPlaningActionTypes.LOAD),
    delayWhen(() => this.roles$),
    withLatestFrom(this.roles$),
    // @ts-ignore
    skipWhile(roles => !_.find(roles, { 'code': 'APS_PROGRAM' })),
    delayWhen(() => this.client$),
    debug('Load site with program no programme action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.dashboardService.getProgramNoPlanning(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<any>>) => new ProgramNoPlanningSuccessAction(payload.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))))});

  protected handleUnhandledError(response: Response): Action { return new ProgramNoPlanningFaildAction(response.status); }

  constructor(private actions$: Actions,
              translation: L10nTranslationService,
              private storeClient: Store<rootSelectors.AppState>,
              private dashboardService: DashboardService,
              router: Router) {
    super(router, translation);
  }
}
